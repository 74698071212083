import { BACKEND_URL } from '@/config/api';
import TokenService from '../_services/TokenService';

export const postFetcher = async <T>([url, data]: [string, T]) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${TokenService.getAccessToken()}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const getFetcher = async (url: string) => {
  try {
    const response = await fetch(url, {
      cache: 'no-store',
      headers: { Authorization: `Bearer ${TokenService.getAccessToken()}` },
    });
    if (response.status === 401) {
      throw new Error('Unauthorized');
    }
    return response.json();
  } catch (error: any) {
    if (error.message === 'Unauthorized') {
      try {
        const refreshToken = TokenService.getRefreshToken();
        const refreshResponse = await fetch(
          `${BACKEND_URL}/login/web_admin/auth_token`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
          }
        );

        if (refreshResponse.ok) {
          const tokens = await refreshResponse.json();
          TokenService.setToken(tokens);

          // Retry the original request with the new access token
          const retryResponse = await fetch(url, {
            cache: 'no-store',
            headers: {
              Authorization: `Bearer ${TokenService.getAccessToken()}`,
            },
          });

          if (retryResponse.ok) {
            return retryResponse.json();
          }
        }
      } catch (refreshError) {
        // Handle refresh token error
        console.error('Failed to refresh token:', refreshError);
      }
      // Redirect to sign-in page if retry failed or other errors occurred
      TokenService.clearToken();
      window.location.href = '/';
    }
    throw error;
  }
};

export const getFetcherWithCallback =
  (callback: (data: Promise<any>) => void) => async (url: string) => {
    const response = await fetch(url, {
      cache: 'no-store',
      headers: { Authorization: `Bearer ${TokenService.getAccessToken()}` },
    });
    return callback(response.json());
  };
