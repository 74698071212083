import { mutate } from 'swr';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from '@/_helpers/axios_interceptor';
import classNames from 'classnames';
import { API_ENDPOINT } from '../../config/api';
import { getFetcher } from '../../_helpers/swr';
import { generatePDFElement } from '../pages/pdf/PrescriptionSheet';
import { useCasesheet } from '../pages/WalkIn/states';

/** @typedef {object} Bookings
 * @property {number} appointment_id
 * @property {number} token_number
 * @property {string} appointment_date
 * @property {string} uin
 * @property {number} person_id
 * @property {string} name
 * @property {string} phone
 * @property {string} email_id
 * @property {string} gender
 * @property {number} age
 * @property {string} file_no
 * @property {number} payment_due
 * @property {boolean} is_tatkal
 * @property {string} consult_start_time
 * @property {string} consult_end_time
 * @property {string} consultation_type
 * @property {boolean} is_patient_absent
 * @property {null} transactions
 * @property {string} normal_consult_fee
 * @property {string} tatkal_consult_fee
 * @property {number} avg_pat_time
 * @property {string} pdf_location
 * @property {null} attachment_location
 * @property {string} initial_op_time
 * @property {null} proc_data
 * @property {string} clinic_logo
 * @property {string} patient_place
 */

/** @typedef {object} CaseSheet
 * @property {null} casesheet_id
 * @property {string} pdf_id
 * @property {{[key:string]: {
 * med: string,
 * dosage: string,
 * duration: string
 * }}} prescription
 * @property {string|undefined} prescription_details
 * @property {string} special_instruction
 * @property {string} validity_in_days
 */

/**
 * @typedef {object} PrintButtonProps
 * @property {BasicDetails} info
 * @property {CaseSheet} casesheet
 * @property {string} provider_sign_url
 * @property {Function} afterPrint

 * @param {PrintButtonProps} props
 */
const PrintButton = ({
  schedule_id,
  appointmentId,
  info,
  selectedProcedures,
  casesheet,
  provider_sign_url,
  isWalkin,
  afterPrint,
}) => {
  const date = moment().format('YYYY-MM-DD');
  const cs = useCasesheet((s) => s.submitCaseSheet());
  const _caseSheet = casesheet || cs;
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = async () => {
    if (isPrinting) return;
    setIsPrinting(true);
    try {
      //   onClick();
      await generatePDFElement(
        info,
        _caseSheet,
        provider_sign_url,
        appointmentId,
        isWalkin,
        selectedProcedures
      );
      await mutate(
        `${API_ENDPOINT}/PatientAppointments/load_bookings_for_recent_list?schedule_id=${schedule_id}&appointment_date=${date}`
      );
      //   const app = data?.find((a) => a.appointment_date === appointmentId);
      //   app?.pdf_location && window.open(`${STORAGE_DOMAIN}${app.pdf_location}`);
      afterPrint?.();
    } catch (error) {
      console.log(error);
    }
    setIsPrinting(false);
  };

  return (
    <div
      onClick={handlePrint}
      data-toggle="tooltip"
      data-placement="top"
      title="Print Casesheet"
      className={classNames(
        ' _bg-slate-100 _flex _items-center _border _border-solid _border-slate-400 _font-bold _text-[13px] _px-2 _rounded-full',
        {
          '_cursor-pointer hover:_text-teal-700 hover:_bg-teal-100':
            !isPrinting,
          '_cursor-wait hover:_text-slate-700 hover:_bg-slate-100': isPrinting,
        }
      )}
    >
      {isPrinting ? (
        <i className="fa fa-spinner _animate-spin" />
      ) : (
        <i className="fa fa-print  " />
      )}
      <div className="_ml-1">
        {isPrinting ? 'Printing...' : afterPrint ? 'Save and Print' : 'Print'}
      </div>
    </div>
  );
};

export default PrintButton;

export const DownloadButton = ({
  onClick,
  appointmentId,
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [caseSheet, setcaseSheet] = useState(null);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  useEffect(() => {
    appointmentId &&
      !disabled &&
      axios
        .get(
          `${API_ENDPOINT}/ProviderPatientConsultation/get_consult_details?appointment_id=${appointmentId}`
        )
        .then(({ data }) => {
          if (data && data.case_sheets) {
            setcaseSheet(data.case_sheets);
          }
        });
  }, [appointmentId]);

  return (
    <a
      onClick={handleOnClick}
      target="_blank"
      style={{
        cursor: 'pointer',
      }}
      rel="noopener noreferrer"
      data-toggle="tooltip"
      data-placement="top"
      title="Download"
    >
      {loading ? (
        <span className="border-icon">
          <span className="spinner-border spinner-border-sm" />
        </span>
      ) : (
        <i
          className={`fa fa-download border-icon ${
            (disabled || (appointmentId && !caseSheet)) && 'disabled'
          }`}
        />
      )}
    </a>
  );
};
