import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  CasesheetActions,
  PValueType,
  CasesheetState,
  VitalsResponse,
  casesheetInitialState,
  initialStateOfTab,
  prescriptionDetailsInitialState,
  vitalsInitialState,
  AppointmentState,
  AppointmentActions,
  InitialCasesheet,
} from './types';
import { objectify } from 'radash';

const decodePrescription = (data: string, force = false) => {
  const ini = {
    Diagnosis: { ...initialStateOfTab },
    Treatments: { ...initialStateOfTab },
    History: { ...initialStateOfTab },
    Observations: { ...initialStateOfTab },
    Lab: { ...initialStateOfTab },
  };

  try {
    const p = JSON.parse(data) as PValueType | string;
    if (typeof p === 'string') {
      ini.Diagnosis.value = p;
      return ini;
    }
    if (force) {
      if (p.Observations) ini.Observations.value = p.Observations;
      if (p.observationupdatedAt)
        ini.Observations.updatedAt = new Date(p.observationupdatedAt);
      if (p.History) ini.History.value = p.History;
      if (p.historyUpdatedAt)
        ini.History.updatedAt = new Date(p.historyUpdatedAt);
    }
    if (p.Diagnosis) ini.Diagnosis.value = p.Diagnosis;
    if (p.Treatments) ini.Treatments.value = p.Treatments;
    if (p.Lab) ini.Lab.value = p.Lab;
    return ini;
  } catch (error) {
    ini.Diagnosis.value = data;
    return ini;
  }
};

export const useCasesheet = create<CasesheetState & CasesheetActions>()(
  immer<CasesheetState & CasesheetActions>((set, get) => ({
    caseSheet: casesheetInitialState,
    vitals: vitalsInitialState,
    previousCsData: undefined,
    setCaseSheet: (casesheet, force = false) =>
      set((s) => {
        const previous = force ? {} : get().previousCsData;
        if (casesheet.prescription_details) {
          const dec = decodePrescription(casesheet.prescription_details);
          s.caseSheet = {
            ...casesheet,
            prescription_details: {
              ...s.caseSheet.prescription_details,
              ...dec,
              ...previous,
            },
          };
        } else {
          s.caseSheet = {
            ...casesheet,
            prescription_details: { ...decodePrescription(''), ...previous },
          };
        }
      }),
    setVitals: (vitals: VitalsResponse[]) =>
      set((s) => {
        if (vitals.length)
          s.vitals = objectify(
            vitals,
            (f) => f.vitalname,
            (f) => ({
              value: f.value,
              updatedAt: new Date(f.modifiedat),
              updatedBy: f.modifiedby,
            })
          );
        else s.vitals = vitalsInitialState;
      }),
    setVitalValue: (key, value) =>
      set((s) => {
        const v = (
          typeof value === 'number' ? parseFloat(value.toString()) : value
        ).toString();
        if (s.vitals[key]) {
          s.vitals[key]!.value = v;
          s.vitals[key]!.updatedAt = new Date();
        } else {
          s.vitals = {
            [key]: {
              value: v,
              updatedAt: new Date(),
            },
          };
        }
      }),
    removeAttachment: () =>
      set((s) => {
        s.caseSheet.attachment_id = undefined;
        s.caseSheet.attachment_url = undefined;
      }),
    addAttachment: (id, url) =>
      set((s) => {
        s.caseSheet.attachment_id = id;
        s.caseSheet.attachment_url = url;
      }),
    resetCaseSheet: () =>
      set((s) => {
        s.caseSheet = casesheetInitialState;
        s.vitals = vitalsInitialState;
      }),
    onChangeSpecialInstructions: (value: string) =>
      set((s) => {
        s.caseSheet.special_instruction = value;
      }),
    onChangePrescriptionDetails: (item, value, silent = false) => {
      set((s) => {
        if (!s.caseSheet.prescription_details) {
          s.caseSheet.prescription_details = prescriptionDetailsInitialState();
        }
        s.caseSheet.prescription_details[item].value = value || '';
        if (!silent) {
          s.caseSheet.prescription_details[item].updatedAt = new Date();
          s.caseSheet.prescription_details[item].isTouched = true;
        }
      });
    },
    clearPrescription: (force = false) =>
      set((s) => {
        s.caseSheet.prescription = {};
        s.caseSheet.special_instruction = '';
        if (!s.caseSheet.prescription_details) {
          s.caseSheet.prescription_details = prescriptionDetailsInitialState();
        }
        s.caseSheet.prescription_details.Diagnosis = initialStateOfTab;
        s.caseSheet.prescription_details.Treatments = initialStateOfTab;
        s.caseSheet.prescription_details.Lab = initialStateOfTab;
        if (force) {
          s.caseSheet.prescription_details.History = initialStateOfTab;
          s.caseSheet.prescription_details.Observations = initialStateOfTab;
        }
      }),
    onChangeMedicineName: (index, value, incredients, note) =>
      set((s) => {
        if (!s.caseSheet.prescription[index]) {
          s.caseSheet.prescription[index] = {};
        }
        s.caseSheet.prescription[index]!.med = value;
        s.caseSheet.prescription[index]!.incredients = incredients;
        s.caseSheet.prescription[index]!.note = note;
      }),
    onChangeDosage: (index, value) =>
      set((s) => {
        if (!s.caseSheet.prescription[index]) {
          s.caseSheet.prescription[index] = {};
        }

        s.caseSheet.prescription[index]!.dosage = value;
      }),
    onChangeDuration: (index, value) =>
      set((s) => {
        if (!s.caseSheet.prescription[index]) {
          s.caseSheet.prescription[index] = {};
        }

        s.caseSheet.prescription[index]!.duration = value;
      }),
    setPreviousCsData: (data) =>
      set((s) => {
        const dec = JSON.parse(data) as PValueType | string;
        if (typeof dec !== 'string') {
          if (!s.caseSheet.prescription_details) {
            s.caseSheet.prescription_details =
              prescriptionDetailsInitialState();
          }
          const newPreviousData = {
            History: {
              isTouched: false,
              updatedAt: dec.historyUpdatedAt
                ? new Date(dec.historyUpdatedAt)
                : new Date(),
              value: dec.History || '',
            },
            Observations: {
              isTouched: false,
              updatedAt: dec.observationupdatedAt
                ? new Date(dec.observationupdatedAt)
                : new Date(),
              value: dec.Observations || '',
            },
          };
          s.previousCsData = { ...newPreviousData };
          s.caseSheet.prescription_details.Observations = {
            ...newPreviousData.Observations,
          };
          s.caseSheet.prescription_details.History = {
            ...newPreviousData.History,
          };
        } else {
          s.previousCsData = undefined;
        }
      }),
    clearMedicine: (index) =>
      set((s) => {
        delete s.caseSheet.prescription[index];
      }),
    submitCaseSheet: () => {
      const cs = get().caseSheet;
      const val: InitialCasesheet = {
        validity_in_days: '0',
        pdf_id: '',
        ...cs,
        prescription_details: JSON.stringify({
          History: cs.prescription_details?.History.value,
          Observations: cs.prescription_details?.Observations.value,
          Diagnosis: cs.prescription_details?.Diagnosis.value,
          Treatments: cs.prescription_details?.Treatments.value,
          Lab: cs.prescription_details?.Lab.value,
          historyUpdatedAt: cs.prescription_details?.History.updatedAt,
          observationupdatedAt: cs.prescription_details?.Observations.updatedAt,
        }),
      };
      return val;
    },
    submitVitals: () => {
      const val: Record<string, string> = {};
      const vitals = get().vitals;
      Object.keys(vitals).forEach((k: any) => {
        const _v = vitals[k as keyof typeof vitals]?.value;
        if (_v) val[k] = _v;
      });
      return val;
    },
    clearVitals: () => {
      set((s) => {
        s.vitals = vitalsInitialState;
      });
    },
  }))
);

export const useAppoinment = create<AppointmentState & AppointmentActions>()(
  immer<AppointmentState & AppointmentActions>((set) => ({
    appointment: null,
    patient: null,
    allAppoinments: [],
    familyMemberList: [],
    currentToken: 1,
    proceduresList: [],
    startTime: new Date(),

    setAppointment: (appointment) =>
      set({ appointment, startTime: new Date() }),
    setPatient: (data) =>
      set({ patient: data, familyMemberList: data?.family_members || [] }),
    clearAppointment: () =>
      set({ appointment: null, patient: null, startTime: new Date() }),
    setFee: (fee) =>
      set((s) => {
        if (!s.appointment)
          s.appointment = {
            fee,
          };
        else s.appointment.fee = fee;
      }),
    setProceduresList: (proceduresList) => set({ proceduresList }),
  }))
);
