import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import { API_ENDPOINT } from '../../../config/api';
import LoadingBox from '../../layout/LoadingBox';
import Select from '../../form/Select';
import { useUser } from '@/hooks/useUser';

const _date = moment().format('MM-DD-YYYY');

const DoctorSelectionModal = ({ clinic_id, onClose }) => {
  const user = useUser();
  const [showLoading, setShowLoading] = useState(false);
  const [transferDoctors, setTransferDoctors] = useState(undefined);
  const [selectedDoctor, setSelectedDoctor] = useState(undefined);
  const provider_id = user?.user_data?.user_id;
  //   Get Schedules Availabilty for Transfer
  const getSchedulesAvailabilty = () => {
    if (!clinic_id) return;
    setShowLoading(true);
    Axios.get(
      `${API_ENDPOINT}/MedicalStoreAppointment/get_schedules_availability?clinic_id=${clinic_id}&booking_date=${_date}`
    )
      .then((res) => {
        if (res.data) {
          setTransferDoctors(
            res.data?.provider_list?.filter(
              (dr) => dr?.value !== provider_id
            ) || []
          );
        }
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (transferDoctors?.length === 1) {
      setSelectedDoctor(transferDoctors[0]?.value);
    }
  }, [transferDoctors]);

  useEffect(() => {
    getSchedulesAvailabilty();
  }, []);

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="dr-selection-modal"
      role="dialog"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-lg modal-center-content"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header p-4">
            <h4 className="modal-title">Transfer</h4>
            <button
              aria-label="Close"
              className="btn btn-light"
              style={{
                padding: '0px 2px 0px 5px',
                border: 'none',
                background: '#fff',
              }}
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <h6>Transfer To:</h6>
              <div>
                <Select
                  className="outlined"
                  values={transferDoctors}
                  value={selectedDoctor}
                  onChange={({ target: { value } }) => {
                    setSelectedDoctor(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="modal-footer justify-content-between"
            style={{ padding: '20px 35px' }}
          >
            <button
              className="btn px-5"
              data-dismiss="modal"
              style={{ cursor: 'pointer' }}
            >
              Cancel
            </button>
            <button
              className="btn btn-info px-5"
              onClick={() => {
                const _dr = transferDoctors?.find(
                  (dr) => dr.value === parseInt(selectedDoctor)
                );
                onClose(_dr);
              }}
              disabled={!selectedDoctor}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      <LoadingBox show={showLoading} />
    </div>
  );
};

export default DoctorSelectionModal;
