import { Helmet } from 'react-helmet';

function PageTitle({ title, desc }: { title: string; desc?: string }) {
  return (
    <div className="_mb-2">
      <h2 className="_scroll-m-20 _text-2xl _mb-0 _font-semibold _tracking-tight _first:mt-0">
        {title}
      </h2>
      <Helmet>
        <title>{title} - UNQ</title>
        <meta name="description" content={desc} />
      </Helmet>
      {desc && (
        <p className="_leading-7 _text-secondary-foreground _mb-0">{desc}</p>
      )}
    </div>
  );
}

export default PageTitle;
