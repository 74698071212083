import React from 'react';
import { DentalChartContextProvider } from '../dental-chart/DentalChartContext';
import DentalChartModal from '../dental-chart/DentalChartModal';
import { PATIENT_TYPES } from '../dental-chart/constants';

function DentalChart() {
  return (
    <div>
      <div className="app-container d-flex justify-content-center align-items-center">
        <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <button
            type="button"
            className="btn btn-primary mr-2"
            data-toggle="modal"
            data-target="#adult-chart"
          >
            Open Adult Dental Chart
          </button>
          <br />
          <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#pedo-chart"
          >
            Open Pediatric Dental Chart
          </button>
        </div>

        <DentalChartContextProvider>
          <DentalChartModal
            id="adult-chart"
            patientType={PATIENT_TYPES.adult}
          />
        </DentalChartContextProvider>

        <DentalChartContextProvider>
          <DentalChartModal id="pedo-chart" patientType={PATIENT_TYPES.pedo} />
        </DentalChartContextProvider>
      </div>
    </div>
  );
}

export default DentalChart;
