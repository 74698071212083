import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// import Alert from '../layout/Alert'
// import LoadingBox from '../layout/LoadingBox'
import Select from '../form/Select';
import Table from '../layout/Table';
import showCurrency from '../common/showCurrency';
import { useUser } from '@/hooks/useUser';

const consultData = [
  ['01-01-2021', '120', '40', '160', '-'],
  ['02-01-2021', '150', '25', '155', '-'],
  ['03-01-2021', '130', '40', '160', '-'],
  [{ className: 'text-right', colSpan: 3, value: 'Grand Total' }, '475', '-'],
];

const consultHeadings = ['Date', 'OP', 'Walkin', 'Total', 'Payment'];

const smsData = [
  ['01-01-2021', '3', '1', '14', '2', '-', '1', '16', '16', '₹8'],
  ['02-01-2021', '4', '4', '15', '3', '4', '4', '12', '28', '₹14'],
  ['03-01-2021', '3', '-', '17', '3', '7', '-', '11', '32', '₹16'],
  [{ className: 'text-right', colSpan: 8, value: 'Grand Total' }, '475', '-'],
];

const smsHeadings = [
  [
    { rowSpan: 2, value: 'Date' },
    {
      rowSpan: 2,
      value: (
        <>
          New
          <br />
          Reg
        </>
      ),
    },
    {
      rowSpan: 2,
      value: (
        <>
          Profile
          <br />
          Access
          <br />
          OTP
        </>
      ),
    },
    { className: 'text-center', colSpan: 4, value: 'Appointment' },
    {
      rowSpan: 2,
      value: (
        <>
          Consult
          <br />
          Success
        </>
      ),
    },
    { rowSpan: 2, value: 'Total' },
    { rowSpan: 2, value: 'Payments' },
  ],
  ['Confirm', 'Reminder', 'Delay', 'Cancel'],
];

const tableHeadings = [
  [
    { rowSpan: 2, value: 'Date' },
    { rowSpan: 2, value: 'Clinic' },
    { rowSpan: 2, value: 'Booking' },
    { rowSpan: 2, value: 'Providers' },
    {
      rowSpan: 2,
      value: (
        <>
          Subscription
          <br />
          <a
            data-toggle="modal"
            data-target="#subscription-modal"
            href="#subscription-modal"
            style={{
              color: '#ffffff99',
              textDecoration: 'online',
            }}
          >
            View Plans
          </a>
        </>
      ),
    },
    { rowSpan: 2, value: 'Recurring Pay' },
    { className: 'text-center', colSpan: 4, value: 'Charges' },
    { rowSpan: 2, value: 'Total Payment' },
    { rowSpan: 2, value: 'Online Paid' },
    { rowSpan: 2, value: 'Payment' },
  ],
  [
    'Consult',
    'TP',
    'TK',
    {
      className: 'text-center',
      value: (
        <>
          SMS
          <br />
          <a
            data-toggle="modal"
            data-target="#sms-modal"
            href="#sms-modal"
            style={{
              color: '#ffffff99',
              textDecoration: 'online',
            }}
          >
            View Plans
          </a>
        </>
      ),
    },
  ],
];

const PaymentsClinics = () => {
  const navigate = useNavigate();
  const user = useUser();
  const user_role = user?.user_role;
  // const [alertMsg, setAlertMsg] = useState('')
  // const [alertShow, setAlertShow] = useState(false)
  // const [alertType, setAlertType] = useState('alert')
  const [chargesData, setChargesData] = useState([]);
  const [chargesHeadings, setChargesHeadings] = useState([]);
  const [chargesInfo, setChargesInfo] = useState({
    date: '',
    title: '',
  });
  // const [showLoading, setShowLoading] = useState(true)
  const [tableData, setTableData] = useState([]);

  // const showAlert = (msg, type) => {
  // 	if (type) setAlertType(type)
  // 	else setAlertType('alert')
  // 	setAlertMsg(msg)
  // 	setAlertShow(true)
  // }

  useEffect(() => {
    setTableData([
      [
        'Jan-2021',
        <Link to="/clinic/view/1">ClinicID</Link>,
        <div style={{ minWidth: 72 }}>
          <Select
            className="outlined"
            name="status"
            values={[{ name: 'Active', value: 'Active' }]}
            value="Active"
          />
        </div>,
        '5',
        'SCS Pro',
        'Yes',
        <a
          data-toggle="modal"
          data-target="#charges-modal"
          href="#consult-charges"
          onClick={() => {
            setChargesData(consultData);
            setChargesHeadings(consultHeadings);
            setChargesInfo({
              date: 'Jan-2021',
              title: 'Consult Charges',
            });
          }}
        >
          ₹431
        </a>,
        '0',
        '0',
        <a
          data-toggle="modal"
          data-target="#charges-modal"
          href="#consult-charges"
          onClick={() => {
            setChargesData(smsData);
            setChargesHeadings(smsHeadings);
            setChargesInfo({
              date: 'Jan-2021',
              title: 'SMS Charges',
            });
          }}
        >
          ₹431
        </a>,
        '₹431',
        '0',
        '₹431',
      ],
    ]);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row m-b-10">
        <div className="col-md-4 m-b-10">
          <Select
            value="Payment"
            values={[
              { name: 'Payment', value: 'Payment' },
              { name: 'All', value: 'All' },
              { name: 'Paid', value: 'Paid' },
              { name: 'Unpaid', value: 'Unpaid' },
            ]}
          />
        </div>
        <div className="col-md-4 m-b-10">
          <Select
            value="Category"
            values={[{ name: 'Category', value: 'Category' }]}
          />
        </div>
        <div className="col-md-4" />
        <div className="col-md-4 m-b-10">
          <input className="form-control" placeholder="Place" />
        </div>
        <div className="col-md-4 m-b-10">
          <input className="form-control" placeholder="Clinic" />
        </div>
      </div>

      <div className="row m-b-10">
        <div className="col-md-12 m-b-10">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className="p-20"
              style={{ display: 'inline-block', paddingLeft: '0px' }}
            >
              <b>Payment History</b>
            </div>
            <input
              className="form-control"
              style={{ maxWidth: '240px' }}
              type="date"
            />
            <div className="p-20" style={{ display: 'inline-block' }}>
              <b>To</b>
            </div>
            <input
              className="form-control"
              style={{ maxWidth: '240px' }}
              type="date"
            />
            <div className="p-10 m-l-30" style={{ fontSize: '18px' }}>
              <i className="ion-printer" />
            </div>
            <div className="p-10" style={{ fontSize: '18px' }}>
              <i className="c" />
            </div>
          </div>
        </div>
      </div>

      <div className="m-b-20">
        <span className="m-10">
          <b>Notes</b>
        </span>
        <div
          className="m-10 p-b-10 p-t-10"
          style={{ background: 'white', borderRadius: '6px' }}
        >
          <ol>
            <li>
              The amount will be automatically deducted from credit/debit card
              on the 1st of every month.
            </li>
            <li>
              The subscription will be automatically moved to the next tier when
              the limit is crossed.
            </li>
            <li>
              Failure to pay for two consequtive months may automatically
              deactivate your account.
            </li>
          </ol>
        </div>
      </div>

      <div className="m-b-40 m-t-10">
        <Table data={tableData} headings={tableHeadings} />
        {/* {pageCount > 1 && (
					<div className='card-block hex-pagination'>
						<nav aria-label='Page navigation example'>
							<ul className='pagination'>
								<li className='page-item'>
									<button
										className='page-link'
										onClick={() => {
											multipleCheck((curr - 1) * (data.length / number), false)
											setSelectedMain(false)
											if (curr !== 1) setCurr(curr - 1)
										}}
										aria-label='Previous'
									>
										<span aria-hidden='true'>«</span>
										<span className='sr-only'>Previous</span>
									</button>
								</li>
								{range(pageCount).map(page => (
									<li
										className={curr === page ? 'page-item active' : 'page-item'}
										key={page}
										onClick={() => {
											multipleCheck((curr - 1) * (data.length / number), false)
											setSelectedMain(false)
											setCurr(page)
										}}
									>
										<button className='page-link'>{page}</button>
									</li>
								))}
								<li className='page-item'>
									<button
										className='page-link'
										onClick={() => {
											multipleCheck((curr - 1) * (data.length / number), false)
											setSelectedMain(false)
											if (curr !== pageCount) setCurr(curr + 1)
										}}
										aria-label='Next'
									>
										<span aria-hidden='true'>»</span>
										<span className='sr-only'>Next</span>
									</button>
								</li>
							</ul>
						</nav>
					</div>
				)} */}
      </div>

      {/* <Alert
				msg={alertMsg}
				setShow={setAlertShow}
				show={alertShow}
				type={alertType}
			/> */}
      {/* <LoadingBox show={showLoading} /> */}
      <ChargesModal
        data={chargesData}
        headings={chargesHeadings}
        info={chargesInfo}
      />
      <SMSModal />
      <SubscriptionModal user_role={user_role} />
    </>
  );
};

const ChargesModal = ({ data, headings, info }) => (
  <div
    aria-hidden="true"
    className="modal fade"
    id="charges-modal"
    role="dialog"
    style={{ backgroundColor: '#000000aa' }}
    tabIndex="-1"
  >
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{info.title}</h4>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            style={{
              backgroundColor: '#e74c3c',
              borderRadius: '6px',
              color: '#fff',
              padding: '12px',
            }}
            type="button"
          >
            <span aria-hidden="true">
              <i className="fa fa-close" />
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <b>{info.date}</b>
            <div>
              <span className="p-10 m-l-30" style={{ fontSize: '18px' }}>
                <i className="ion-printer" />
              </span>
              <span className="p-10" style={{ fontSize: '18px' }}>
                <i className="ion-ios-cloud-download" />
              </span>
            </div>
          </div>
          <Table data={data} headings={headings} />
        </div>
      </div>
    </div>
  </div>
);

const SMSModal = () => {
  const [compulsory, setCompulsory] = useState(false);

  const data1 = [
    [
      '1',
      'BASIC',
      'Booking',
      '3',
      '₹1.5',
      <>Booking details, clinic contact and live status link.</>,
    ],
    [
      ' ',
      ' ',
      'New Registration',
      '2',
      '₹1',
      <>Booking confirmation details, clinic contact and live status link.</>,
    ],
    [
      ' ',
      ' ',
      'OTP',
      '1',
      '₹0.5',
      <>Booking confirmation details, clinic contact and live status link.</>,
    ],
    [
      '2',
      'FULL',
      'Booking',
      '3',
      '₹1.2',
      <>Booking confirmation details, clinic contact and live status link.</>,
    ],
    [
      ' ',
      ' ',
      'Reminder',
      '3',
      '₹1.2',
      <>Appointment reminder before 12 hrs, with all details.</>,
    ],
    [' ', ' ', 'Cancel', '2', '₹0.8', <>Appointment cancel.</>],
    [
      ' ',
      ' ',
      'First Delay',
      '3',
      '₹1.2',
      <>First appointment delay above 15 minutes.</>,
    ],
    [
      ' ',
      ' ',
      'Consult Success',
      '2',
      '₹0.8',
      <>After successful completion of Consulatation.</>,
    ],
    [
      ' ',
      ' ',
      'New Registration',
      '2',
      '₹0.8',
      <>Booking confirmation details, clinic contact and live status link.</>,
    ],
    [
      ' ',
      ' ',
      'OTP',
      '1',
      '₹0.4',
      <>Booking confirmation details, clinic contact and live status link.</>,
    ],
  ];

  const data2 = [
    ['1', 'New Registration', '2', '₹1', <>New patient profile creation.</>],
    [
      '2',
      'OTP',
      '1',
      '₹0.5',
      <>
        Patient profile access OTP to view/Delete other
        <br />
        clinic bookings.
      </>,
    ],
  ];

  const headings1 = ['Sl no', 'Plan', 'Title', 'Length', 'Charges', 'Features'];

  const headings2 = ['Sl no', 'Title', 'Length', 'Charges', 'Features'];

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="sms-modal"
      role="dialog"
      style={{ backgroundColor: '#000000aa' }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">SMS Charges</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              style={{
                backgroundColor: '#e74c3c',
                borderRadius: '6px',
                color: '#fff',
                padding: '12px',
              }}
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <form>
                <div
                  className="form-radio radio radiofill radio-info radio-inline"
                  style={{ display: 'inline-flex' }}
                >
                  <label>
                    <input
                      checked={!compulsory}
                      name="sms_actvate"
                      onChange={() => setCompulsory(false)}
                      type="radio"
                      value="off"
                    />
                    <i className="helper" />
                    <span className="radio-label">Off</span>
                  </label>
                </div>
                <div
                  className="form-radio radio radiofill radio-info radio-inline"
                  style={{ display: 'inline-flex' }}
                >
                  <label>
                    <input
                      checked={compulsory}
                      name="sms_actvate"
                      onChange={() => setCompulsory(true)}
                      type="radio"
                      value="on"
                    />
                    <i className="helper" />
                    <span className="radio-label">On</span>
                  </label>
                </div>
              </form>
            </div>
            {!compulsory ? (
              <h6 className="m-t-20">Compulsory Service SMS</h6>
            ) : null}
            <Table
              data={compulsory ? data1 : data2}
              headings={compulsory ? headings1 : headings2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionModal = () => {
  const [user_role, setUser_role] = useState('clinic');

  const subscriptionOptions = [
    { name: 'SCS PRO', value: 'SCS PRO' },
    { name: 'Demo', value: 'Demo' },
    { name: 'Unlimited', value: 'Unlimited' },
    { name: 'Deactivate', value: 'Deactivate' },
  ];

  const validityOptions = [
    { name: ' ', value: ' ' },
    { name: '1 month', value: '1 month' },
    { name: '2 months', value: '2 months' },
    { name: '3 months', value: '3 months' },
    { name: '6 months', value: '6 months' },
    { name: '1 year', value: '1 year' },
  ];

  const data1 = [
    [
      '1',
      user_role === 'admin' ? (
        <Select
          className="outlined"
          value="SCS Pro"
          values={subscriptionOptions}
        />
      ) : (
        'SCS Pro'
      ),
      'Package',
      <i className="fa fa-refresh" />,
      user_role === 'admin' ? (
        <input style={{ width: '60px' }} value="₹990 only" />
      ) : (
        '₹990 only'
      ),
      <>
        {user_role === 'admin' && (
          <Select
            className="outlined"
            style={{ marginBottom: '6px', padding: '6px', width: '110px' }}
            value=" "
            values={validityOptions}
          />
        )}
        Default
        <br />
        Consult upto 1,100 patients per month
        <br />
        without extra charges.
      </>,
    ],
    [
      '2',
      user_role === 'admin' ? (
        <Select
          className="outlined"
          value="SCS Pro"
          values={subscriptionOptions}
        />
      ) : (
        'SCS Pro'
      ),
      'Consultations',
      <i className="fa fa-refresh" />,
      user_role === 'admin' ? (
        <input style={{ width: '60px' }} value={`${showCurrency()}1`} />
      ) : (
        `${showCurrency()}1`
      ),
      <>
        {user_role === 'admin' && (
          <Select
            className="outlined"
            style={{ marginBottom: '6px', padding: '6px', width: '110px' }}
            value="3 months"
            values={validityOptions}
          />
        )}
        3 months left (Default {showCurrency()}2)
        <br />
        Consultation above the Subscription package
        <br />
        limit is accounted.
      </>,
    ],
    [
      '3',
      user_role === 'admin' ? (
        <Select
          className="outlined"
          value="SCS Pro"
          values={subscriptionOptions}
        />
      ) : (
        'SCS Pro'
      ),
      'General Booking',
      <i className="fa fa-refresh" />,
      'Nil',
      <>
        General Booking from Clinic
        <br />
        no extra charges.
      </>,
    ],
    [
      '4',
      user_role === 'admin' ? (
        <Select
          className="outlined"
          value="SCS Pro"
          values={subscriptionOptions}
        />
      ) : (
        'SCS Pro'
      ),
      'Priority Booking',
      <i className="fa fa-refresh" />,
      user_role === 'admin' ? (
        <input style={{ width: '60px' }} value="8%" />
      ) : (
        '8%'
      ),
      <>
        {user_role === 'admin' && (
          <Select
            className="outlined"
            style={{ marginBottom: '6px', padding: '6px', width: '110px' }}
            value="6 months"
            values={validityOptions}
          />
        )}
        6 months left (Default 10%)
        <br />
        Priority Booking charges applicable
        <br />
        (Non refundable).
      </>,
    ],
    [
      '5',
      user_role === 'admin' ? (
        <Select
          className="outlined"
          value="SCS Pro"
          values={subscriptionOptions}
        />
      ) : (
        'SCS Pro'
      ),
      'TP Booking',
      <i className="fa fa-refresh" />,
      user_role === 'admin' ? (
        <input style={{ width: '60px' }} value="7%" />
      ) : (
        '7%'
      ),
      <>
        {user_role === 'admin' && (
          <Select
            className="outlined"
            style={{ marginBottom: '6px', padding: '6px', width: '110px' }}
            value="2 months"
            values={validityOptions}
          />
        )}
        2 months left (Default 10%)
        <br />
        Third-party Booking charges applicable
        <br />
        only after consultation.
      </>,
    ],
  ];

  const data2 = [
    [
      '1',
      'Demo',
      'Nil',
      <>
        No subscription charges & no tatkal charges.
        <br />
        Demo will be converted to Base plan after 1 month.
      </>,
    ],
    [
      '2',
      'Deactivated',
      'Nil',
      <>
        Consultation & booking will be blocked.
        <br />
        All upcoming appointments will be cancelled.
      </>,
    ],
  ];

  const headings1 = [
    'Sl no',
    'Subscription',
    'Feature',
    'Reset',
    'Payment',
    'Validity & Details',
  ];

  const headings2 = ['Sl no', 'Title', 'Payment', 'Features'];

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="subscription-modal"
      role="dialog"
      style={{ backgroundColor: '#000000aa' }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Subscription Plan</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              style={{
                backgroundColor: '#e74c3c',
                borderRadius: '6px',
                color: '#fff',
                padding: '12px',
              }}
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <form>
                <div
                  className="form-radio radio radiofill radio-info radio-inline"
                  style={{ display: 'inline-flex' }}
                >
                  <label>
                    <input
                      checked={user_role === 'clinic'}
                      name="sms_actvate"
                      onChange={() => setUser_role('clinic')}
                      type="radio"
                      value="clinic"
                    />
                    <i className="helper" />
                    <span className="radio-label">Clinic View</span>
                  </label>
                </div>
                <div
                  className="form-radio radio radiofill radio-info radio-inline"
                  style={{ display: 'inline-flex' }}
                >
                  <label>
                    <input
                      checked={user_role === 'admin'}
                      name="sms_actvate"
                      onChange={() => setUser_role('admin')}
                      type="radio"
                      value="admin"
                    />
                    <i className="helper" />
                    <span className="radio-label">Admin View</span>
                  </label>
                </div>
              </form>
            </div>
            <p>
              <b>Special Offer</b>: Connect your Credit/Debit card now and enble
              recurring payment to get ₹100 discount every month.
            </p>
            <Table data={data1} headings={headings1} />
            <Table data={data2} headings={headings2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsClinics;
