import * as React from 'react';
import { Cross2Icon } from '@radix-ui/react-icons';
import * as ToastPrimitives from '@radix-ui/react-toast';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      '_fixed _top-0 _z-[100] _flex _max-h-screen _w-full _flex-col-reverse _p-4 sm:_bottom-0 sm:_right-0 sm:_top-auto sm:_flex-col md:_max-w-[420px]',
      className
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  '_group _pointer-events-auto _relative _flex _w-full _items-center _justify-between _space-x-2 _overflow-hidden _rounded-md _border _p-4 _pr-6 _shadow-lg _transition-all data-[swipe=cancel]:_translate-x-0 data-[swipe=end]:_translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:_translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:_transition-none data-[state=open]:_animate-in data-[state=closed]:_animate-out data-[swipe=end]:_animate-out data-[state=closed]:_fade-out-80 data-[state=closed]:_slide-out-to-right-full data-[state=open]:_slide-in-from-top-full data-[state=open]:sm:_slide-in-from-bottom-full',
  {
    variants: {
      variant: {
        default: '_border _bg-background _text-foreground',
        destructive:
          '_destructive _group _border-destructive _bg-destructive _text-destructive-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(toastVariants({ variant }), className)}
      {...props}
    />
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      '_inline-flex _h-8 _shrink-0 _items-center _justify-center _rounded-md _border _bg-transparent _px-3 _text-sm _font-medium _transition-colors hover:_bg-secondary focus:_outline-none focus:_ring-1 focus:_ring-ring disabled:_pointer-events-none disabled:_opacity-50 group-[.destructive]:_border-muted/40 group-[.destructive]:hover:_border-destructive/30 group-[.destructive]:hover:_bg-destructive group-[.destructive]:hover:_text-destructive-foreground group-[.destructive]:focus:_ring-destructive',
      className
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      '_absolute _right-1 _top-1 _rounded-md _p-1 _text-foreground/50 _opacity-0 _transition-opacity hover:_text-foreground focus:_opacity-100 focus:_outline-none focus:_ring-1 group-hover:_opacity-100 group-[.destructive]:_text-red-300 group-[.destructive]:hover:_text-red-50 group-[.destructive]:focus:_ring-red-400 group-[.destructive]:focus:_ring-offset-red-600',
      className
    )}
    toast-close=""
    {...props}
  >
    <Cross2Icon className="_h-4 _w-4" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn('_text-sm _font-semibold [&+div]:_text-xs', className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn('_text-sm _opacity-90', className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
