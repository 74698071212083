import React, { useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { useNavigate, useParams } from 'react-router-dom';

import Alert from '../layout/Alert';
import { BACKEND_URL } from '../../config/api';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [formErrorMsg, SetFormErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [userType, setSetUserType] = useState('');
  const [password, setPassword] = useState({
    new_password: '',
    consirm: '',
  });
  const inputHandler = ({ target: { name, value } }) =>
    setPassword({ ...password, [name]: value });

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    SetFormErrorMsg('');
    if (password.new_password !== password.confirm) {
      SetFormErrorMsg("Passwords don't match");
      return;
    }

    const data = {
      token,
      password: password.new_password,
    };
    setLoading(true);
    Axios.post(`${BACKEND_URL}/login/web_admin/token_reset_password`, data)
      .then(({ data: resData }) => {
        setLoading(false);
        if (resData && resData.success) {
          setSetUserType(resData.user_type);
          showAlert(resData.message, 'success');
        } else {
          SetFormErrorMsg(resData.message || '');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="j-wrapper j-wrapper-400" style={{ paddingTop: '10px' }}>
      <form onSubmit={submitHandler} className="j-pro" id="sign-in" noValidate>
        <div className="j-content">
          <div className="j-unit">
            <div className="j-input">
              <label className="j-icon-right" htmlFor="password">
                <i className="ion-locked" />
              </label>
              <input
                name="new_password"
                onChange={inputHandler}
                placeholder="New Password"
                required
                type="password"
                value={password.new_password}
              />
            </div>
          </div>
          <div className="j-unit">
            <div className="j-input">
              <label className="j-icon-right" htmlFor="password">
                <i className="ion-locked" />
              </label>
              <input
                name="confirm"
                onChange={inputHandler}
                placeholder="Confirm Password"
                required
                type="password"
                value={password.confirm}
              />
            </div>
          </div>
        </div>
        <div className="p-l-30 p-b-10 text-danger">{formErrorMsg}</div>
        <div className="j-footer">
          <div className="j-unit p-b-20">
            <button
              disabled={loading}
              className="btn btn-primary"
              type="submit"
            >
              {loading ? 'Loading...' : 'Reset'}
            </button>
          </div>
        </div>
      </form>
      <Alert
        action={() =>
          window.location.replace(
            `${
              userType === 'clinic' ||
              userType === 'clinic_owner' ||
              userType === 'clinic_staff'
                ? '/clinic-login'
                : userType === 'pharmacy'
                ? '/pharmacy'
                : '/'
            }`
          )
        }
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
    </div>
  );
};

export default ResetPassword;
