const Checkbox = ({ label, id, ...restProps }) => {
  return (
    <div className="form-group form-check mr-3">
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        {...restProps}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
