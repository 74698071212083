import React from 'react';
import cn from 'classnames';

const NoResults = ({ message = 'No results found', classNames }) => (
  <div
    className={cn(
      'd-flex flex-column justify-content-center align-items-center',
      classNames || 'p-5 text-muted'
    )}
  >
    <img src={`/img/icons/no_results.svg`} alt="No Results Found" width="100" />
    <h6 className="my-3">{message}</h6>
  </div>
);

export { NoResults as default };
