/* eslint-disable prettier/prettier */
import moment from 'moment';

export const _date = moment().format('YYYY-MM-DD');

export const toAmPm = (time_24hr_format) =>
  time_24hr_format
    ? moment(`${_date} ${time_24hr_format}`).format('hh:mm A')
    : '';

export const to24Hrs = (time_12hr_format) =>
  time_12hr_format
    ? moment(`${_date} ${time_12hr_format}`).format('HH:mm:ss')
    : '';

export const formatTime = (_time, minutesToAdd = 0, toFormat = 'hh:mm A') =>
  _time
    ? moment(`${_date} ${_time}`, "yyyy-MM-DD HH:mm:ss").add(minutesToAdd, 'minutes').format(toFormat)
    : '';

export const timeToDate = (_time) =>
  _time ? moment(`${_date} ${_time}`) : moment();

export const asInitials = (string, firstNameOnly) => {
  const strList = string.split(' ');
  let initials = '';
  if (strList.length > 1 && !firstNameOnly) {
    initials = `${strList.shift()?.charAt(0)}${strList.pop()?.charAt(0)}`;
  } else {
    initials = `${string?.charAt(0) || '-'}${string?.charAt(1) || '-'}`;
  }
  return initials.toUpperCase();
};

export const joinWithoutDupes = (objArr1, objArr2, iteratee) => {
  const arr_combined = [...objArr1, ...objArr2];
  const new_arr = [
    ...new Map(arr_combined.map((m) => [m?.[iteratee], m])).values(),
  ];
  return new_arr;
};

export const formatSelectOptionData = (
  optionList,
  propertyName,
  propertyValue = propertyName
) =>
  optionList.map(({ [propertyName]: name, [propertyValue]: value }) => ({
    name,
    value,
  }));

export const parseAddressComponents = (
  location,
  addressTypes = [
    'country',
    'administrative_area_level_1',
    'administrative_area_level_2',
    'locality',
    'route',
    'sublocality',
    'postal_code',
  ]
) => {
  const values = location?.address_components?.reduce((values2, component) => {
    for (let i = 0; i < component?.types.length; i += 1) {
      const _type = component?.types[i];
      if (addressTypes.includes(_type)) {
        values2[_type] = component.long_name;
      }
    }
    return values2;
  }, {});

  return values;
};

export const arrFilterByKeyValFirst = (arr, key, firstValue) => {
  const newArr = [
    ...arr.filter((x) => x?.[key] === firstValue),
    ...arr.filter((x) => x?.[key] !== firstValue),
  ];
  return newArr;
};

export const calculateDateFromAge = (years, months, days) => {
  // Get the current date
  const currentDate = new Date();
  // Calculate the date
  const birthDate = new Date(
    currentDate.getFullYear() - years,
    currentDate.getMonth() - months,
    currentDate.getDate() - days
  );

  return birthDate;
};

export const calculateAge = (dateOfBirth) => {
  const currentDate = new Date();
  const dobDate = new Date(dateOfBirth);

  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const dobMonth = dobDate?.getMonth() || 0;
  const dobDay = dobDate?.getDate() || 0;

  const dateForDaysCalc = new Date(currentDate.getFullYear(), dobMonth, dobDay);
  const diffInMilli = currentDate - dateForDaysCalc;

  let years = currentDate.getFullYear() - (dobDate?.getFullYear() || 0);
  let months = parseInt(currentMonth - dobMonth);
  const days = Math.abs(Math.floor(diffInMilli / (24 * 60 * 60 * 1000)) % 30);
  if (diffInMilli < 0) {
    months -= 1;
  }

  // Adjust for negative values
  if (currentDay - dobDay < 0) {
    months -= 1;
  }
  if (months < 0) {
    years -= 1;
    months += 12;
  }

  return {
    years,
    months,
    days,
  };
};
