/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import Calendar from 'react-calendar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import Table from '../layout/Table';
import { API_ENDPOINT } from '../../config/api';
import ShareLinkModal from '../modals/ShareLinkModal';
import ConfirmAction from '../layout/ConfirmAction';
import classNames from 'classnames';
import { useFullUser, usePageDetails } from '@/hooks/useUser';
import PageTitle from '../layout/page-title';
import PageHeader from '../layout/PageHeader';

const IconButton = ({ icon, onClick, url, ...props }) =>
  url ? (
    <Link to={url} {...props}>
      <i className={`fa fa-${icon} hex-icon`} />
    </Link>
  ) : (
    <i className={`fa fa-${icon} hex-icon`} onClick={onClick} {...props} />
  );

const BookingProvider = () => {
  const naviagate = useNavigate();
  const { pathname } = useLocation();

  const { user, getSelectedClinic } = useFullUser();
  const {
    preferences,
    user_data,
    clinic_data,
    doctors,
    schedules,
    procedures,
  } = user;

  const initialEditData = {
    block_date_id: null,
    provider_id: null,
    clinic_id: null,
    event_type: '',
    prev_from_date: '',
    from_date: '',
    too_date: '',
    from_time: '',
    to_time: '',
    provider_comment: '',
    block_status: true,
    modify_by: 'myself',
    total_affected_bookings: null,
    unq_new_pat_count: null,
    free_visit: null,
  };
  const clinic_id = preferences?.selectedClinicId;
  const clinicData = Array.isArray(clinic_data)
    ? clinic_data
    : clinic_data
    ? [clinic_data]
    : [];
  const selectedClinic = clinic_id
    ? clinicData?.find((clinic) => clinic?.clinic_id === clinic_id)
    : clinicData?.[0];
  const clinic_no = selectedClinic?.clinic_no;
  const [clinicList, setClinicList] = useState([]);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [blockedData, setBlockedData] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);
  const [editData, setEditData] = useState(initialEditData);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    date: new Date(),
    provider: null,
    clinic: null,
  });
  const [mode, setMode] = useState('bookings');
  const [providers, setProviders] = useState([]);
  const [showBlockDateForm, setShowBlockDateForm] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [datesLoading, setDatesLoading] = useState(false);
  const [scheduleSlots, setScheduleSlots] = useState([]);
  const [bookingListByDate, setBookingListByDate] = useState([]);
  const [shareData, setShareData] = useState({});
  const [appmntsToCancel, setAppmntsToCancel] = useState({
    type: null,
    appmntData: null,
  });

  const downloadData = (
    date_ref = filters.date,
    provider_id = filters.provider ||
      (user_data?.user_role === 'provider' ? user_data?.user_id : null),
    clinic = filters.clinic || clinic_id
  ) => {
    setScheduleSlots([]);
    setShowLoading(true);
    const requestData = {
      provider_id,
      current_date_pref: moment(date_ref).format('YYYY-MM-DD'),
      clinic_id: clinic,
    };
    Axios.post(
      `${API_ENDPOINT}/Providers/web_admin/appointments_list`,
      requestData,
      {
        id: 'provider_apmnts_list',
      }
    )
      .then(({ data: resData }) => {
        setData(resData.map((row) => ({ ...row, display: true })));
        const _schedule_slots = resData.reduce((unique, o) => {
          if (!unique.some((obj) => obj.value === o.schedule_id)) {
            unique.push({
              name: o.schedule_slot,
              value: o.schedule_id,
              selected: true,
            });
          }
          return unique;
        }, []);
        setScheduleSlots(_schedule_slots);
        if (provider_id) {
          getBookingsCountForDates(
            provider_id,
            clinic_id,
            moment(date_ref).format('YYYY-MM-DD')
          );
        }
      })
      .catch((err) => showAlert('Loading appointments failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const downloadDates = (provider_id) => {
    setDatesLoading(true);

    Axios.get(
      `${API_ENDPOINT}/Providers/web_admin/get_block_dates/${provider_id}`,
      { id: 'get-block-dates' }
    )
      .then(({ data: resData }) => {
        setBlockedData(resData);
        setBlockedDates(resData.map((row) => [row.from_date, row.too_date]));
        setEditData(initialEditData);
      })
      .catch((err) => showAlert('Loading dates failed', 'error'))
      .finally(() => setDatesLoading(false));
  };

  const downloadProviders = () =>
    Axios.get(`${API_ENDPOINT}/Providers/web_admin/provider_list/${clinic_id}`)
      .then(({ data: resData }) => {
        resData = resData.map((row) => ({
          name:
            row.is_rotation === true
              ? `${row.rotation_name} - ${row.provider_name}`
              : row.provider_name,
          value: row.provider_id,
        }));
        setProviders(resData);
        filterChangeHandler({
          target: { name: 'provider', value: resData[0].value },
        });
      })
      .catch((err) => {
        showAlert('Loading providers failed', 'error');
        setShowLoading(false);
      });

  const cancelBooking = (type, _appointment) => {
    if (!type || !_appointment) return false;
    setAppmntsToCancel({
      type,
      appmntData: _appointment,
    });
    window.$('#action-modal').modal('show');
  };

  const confirmCancelBooking = (actionStatus) => {
    Axios.storage?.remove?.('provider_apmnts_list');
    window.$('#action-modal').modal('hide');
    if (!actionStatus) {
      setAppmntsToCancel(null);
      return;
    }
    if (!appmntsToCancel?.type || !appmntsToCancel?.appmntData) return;
    setShowLoading(true);
    const requestData =
      appmntsToCancel?.type === 'by_date'
        ? {
            date_pref: appmntsToCancel?.appmntData?.appointment_date,
            provider_id: filters.provider,
            clinic_id: filters.clinic || clinic_id,
            clinic_no,
          }
        : {
            appointment_id: appmntsToCancel?.appmntData?.appointment_id,
            chart_date: moment(filters?.date)?.format('YYYY-MM-DD'),
            clinic_no,
            schedule_id: appmntsToCancel?.appmntData?.schedule_id,
          };
    Axios.post(
      `${API_ENDPOINT}/PatientAppointments/web_admin/${
        appmntsToCancel?.type === 'by_date'
          ? 'cancel_appointment_by_date'
          : 'cancel_appointment_by_id'
      }`,
      requestData
    )
      .then(({ data: resData }) => {
        if (user_data?.user_role === 'provider') {
          downloadData(filters.date, user_data.user_id);
        } else {
          downloadData();
        }
      })
      .catch((err) => showAlert('Cancelling appointment/s failed', 'error'))
      .finally(() => {
        setShowLoading(false);
        setAppmntsToCancel(null);
      });
  };

  const filterChangeHandler = ({ target: { name, value } }) => {
    Axios.storage?.remove?.('get-block-dates');
    setShowLoading(true);
    if (name === 'date') downloadData(value);
    if (name === 'provider') {
      downloadDates(parseInt(value));
      downloadData(filters.date, parseInt(value));
      setFilters({ ...filters, [name]: parseInt(value) });
    } else if (name === 'clinic') {
      downloadDates(user_data.user_id);
      downloadData(filters.date, user_data.user_id, parseInt(value));
      setFilters({ ...filters, [name]: parseInt(value) });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const slotFilterHandler = (checked, schedule_id) => {
    const _newScheduleSlots = scheduleSlots.map((slot) =>
      slot.value === schedule_id
        ? { ...slot, ...{ selected: !slot.selected } }
        : slot
    );
    setScheduleSlots(_newScheduleSlots);
    const dataCopy = [...data];
    const _appointments = dataCopy.map((appointment) =>
      appointment.schedule_id === schedule_id
        ? { ...appointment, ...{ display: checked } }
        : appointment
    );
    setData(_appointments);
  };

  const getBookingsCountForDates = (provider_id, clinic_id, from_date) => {
    setShowLoading(true);

    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/web_admin/get_appointments_count_for_dates/${provider_id}/${clinic_id}/${from_date}`
    )
      .then(({ data: resData }) => {
        setBookingListByDate(
          resData.map((row) => ({
            ...row,
            appointment_date: moment(row.appointment_date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY'
            ),
            display: true,
          }))
        );
      })
      .catch((err) => showAlert('Loading dates failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const getSchedulePrefix = (schlID) => {
    const _schl_index = scheduleSlots?.findIndex((scl) => scl.value === schlID);
    return _schl_index >= 0 ? _schl_index + 1 : '';
  };

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const loadData = (rows) => {
    const clinicDetails = getSelectedClinic();
    const isLiveq = !!clinicDetails?.settings?.liveQ;
    const today = moment();
    const prefDate = moment(filters?.date);
    const isPast =
      today?.format('DD-MM-YYYY') !== prefDate?.format('DD-MM-YYYY') &&
      moment(filters?.date)?.isBefore?.(moment());
    return rows
      .filter((row) => row.display)
      .map((row) =>
        mode === 'bookings'
          ? [
              <span className="text-center d-flex flex-column">
                {`S${getSchedulePrefix(row.schedule_id)} (${row.token_number})`}
                {!row.is_walkin && !isPast ? (
                  <i
                    className={classNames('ion-share text-info h5', {
                      '_cursor-pointer': isLiveq,
                      '_cursor-not-allowed': !isLiveq,
                    })}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      isLiveq
                        ? 'Share Live OP Link'
                        : 'LiveQ share disabled in settings'
                    }
                    onClick={
                      isLiveq
                        ? () => {
                            const liveLink = `https://${
                              window.location.hostname
                            }/lv/${row.person_no?.substring(3)}`;
                            const isMobile =
                              navigator.share &&
                              (navigator.userAgent.match(/Android/i) ||
                                navigator.userAgent.match(/iPhone/i) ||
                                navigator.userAgent.match(/iPad/i));

                            const _shareData = {
                              obj: row,
                              patient: {
                                name: row.person_name,
                                phone: row.phone_no,
                              },
                              title: 'Live OP Link',
                              description: encodeURIComponent(
                                `Dear User,\n\nYour appointment at ${selectedClinic?.clinic_name} is confirmed.\nTo view current status,\n${liveLink}\nYou should report at the reception 10 mins earlier.\n\nRegards,\n${selectedClinic?.clinic_name}`
                              ),
                              description_mob: `Dear User, Your appointment at ${selectedClinic?.clinic_name} is confirmed. To view current status, ${liveLink}. You should report at the reception 10 mins earlier. Regards, ${selectedClinic?.clinic_name}`,
                              link: liveLink,
                            };
                            setShareData(_shareData);
                            if (isMobile) {
                              navigator.share({
                                url: liveLink,
                                title: _shareData.title,
                                text: _shareData.description_mob,
                              });
                            } else {
                              window.$('#share-link-modal').modal('show');
                            }
                          }
                        : undefined
                    }
                  />
                ) : null}
              </span>,
              <Link to={`/patient/edit/${row.person_id}`}>
                {row.person_no}
              </Link>,
              row.status,
              `${row.person_name}`,
              <span className="text-capitalize">{row.visit_purpose}</span>,
              row.phone_no,
              row.booking_status === 'transferred' ? (
                row.transferred_by ? (
                  `T-${row.transferred_by}`
                ) : (
                  'Transferred'
                )
              ) : row.booking_status === 'cancelled' ? (
                <div>
                  Cancelled By <br />
                  {row.modify_by}
                </div>
              ) : isPast ? (
                ''
              ) : (
                <IconButton
                  icon="trash"
                  onClick={() => cancelBooking('by_id', row)}
                />
              ),
            ]
          : [
              row.appointment_date,
              row.total_bookings,
              row.tatkal_bookings,
              <IconButton
                icon="trash"
                onClick={() => cancelBooking('by_date', row)}
              />,
            ]
      );
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const tileClassName = ({ date, view }) => {
    if (
      view === 'month' &&
      blockedDates.find((range) => isDayInRange(date, range))
    )
      return 'hex-date-blocked';
  };

  useEffect(() => {
    let _clinicList;
    if (clinicData && clinicData.length) {
      _clinicList = clinicData.map((item) => ({
        name: item.clinic_name,
        value: item.clinic_id,
      }));
      _clinicList.unshift({ name: 'All Clinics', value: '' });
      setClinicList(_clinicList);
    }
    if (user_data?.user_role === 'provider') {
      setFilters({
        ...filters,
        clinic: _clinicList[1] ? _clinicList[1].value : null,
      });
      filterChangeHandler({
        target: {
          name: 'clinic',
          value: _clinicList[1] ? _clinicList[1].value : null,
        },
      });
    } else {
      downloadProviders();
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      <PageHeader
        title={'Booking Provider'}
        desc={'This page is used to manage appointments'}
      />
      <div className="row">
        <div className="col-12 col-md-3 m-t-20 m-b-50">
          {user_data?.user_role !== 'provider' ? (
            <Select
              className="form-control m-b-20"
              name="provider"
              onChange={filterChangeHandler}
              values={providers}
              value={filters.providerName}
            />
          ) : (
            <Select
              className="form-control m-b-20"
              name="clinic"
              onChange={filterChangeHandler}
              values={clinicList}
              value={filters.clinic}
            />
          )}
          <button
            className="btn btn-info m-b-20"
            data-toggle="modal"
            data-target="#block-date-modal"
            onClick={() => setEditData(initialEditData)}
            style={{ width: '100%' }}
          >
            Block Dates
          </button>
          <div className="row m-b-20" style={{ alignItems: 'center' }}>
            <div className="col-3" style={{ fontSize: '1.2em' }}>
              Schedule
            </div>
          </div>
          <div className="row m-b-20">
            {scheduleSlots.length
              ? scheduleSlots.map((slot, idx) => (
                  <div className="col-12 checkbox-fade fade-in-info form-group">
                    <label>
                      <input
                        checked={slot.selected}
                        name="slot"
                        onChange={({ target: { checked } }) =>
                          slotFilterHandler(checked, slot.value)
                        }
                        type="checkbox"
                      />
                      <span className="cr">
                        <i className="cr-icon ion-checkmark txt-primary" />
                      </span>
                      <span>{`${slot.name} (S${idx + 1})`}</span>
                    </label>
                  </div>
                ))
              : ''}
          </div>
          <div>
            <Calendar
              onChange={(value) => {
                let flag = 0;
                blockedData.map((item, index) => {
                  const today = moment().format('MM-DD-YYYY');
                  if (
                    moment(value).isAfter(today) &&
                    isDayInRange(value, blockedDates[index])
                  ) {
                    flag = 1;
                    setShowBlockDateForm(true);
                    item.prev_from_date = item.from_date;
                    item.from_date = value;
                    setEditData(item);
                  }
                  return item;
                });
                if (flag === 0)
                  filterChangeHandler({
                    target: { name: 'date', value: new Date(value) },
                  });
              }}
              tileClassName={tileClassName}
              value={filters.date}
            />
          </div>
        </div>
        <div className="col-12 col-md-9">
          <div
            className="m-b-20"
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <div
              className="j-pro col-12"
              style={{ backgroundColor: 'transparent', border: 'none' }}
            >
              <div className="row" style={{ alignItems: 'center' }}>
                <label className="m-l-10 m-r-10" htmlFor="sex">
                  View By :-
                </label>
                <label
                  className="j-radio m-l-10 m-r-10"
                  style={{ marginBottom: '0px' }}
                >
                  <input
                    checked={mode === 'bookings'}
                    onChange={() => setMode('bookings')}
                    type="radio"
                  />
                  <i />
                  Appointments
                </label>
                <label className="j-radio m-l-10 m-r-10">
                  <input
                    checked={mode === 'days'}
                    onChange={() => setMode('days')}
                    type="radio"
                  />
                  <i />
                  Date
                </label>
              </div>
            </div>
            <div className={mode === 'bookings' ? 'col-12' : 'col-12 col-sm-6'}>
              <Table
                data={
                  mode === 'bookings'
                    ? loadData(data)
                    : loadData(bookingListByDate)
                }
                headings={mode === 'bookings' ? headingsBooking : headingsDays}
                number={8}
                // selectAll={true}
              />
            </div>
            {mode === 'bookings' ? (
              <div className="col-12 m-b-20 m-t-20">
                Total - {data.length} Bookings
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {mode !== 'slot' && (
          <div className="fab" title="Add Appointment">
            <Link
              className="btn btn-inverse btn-icon"
              to="/booking/availability"
            >
              <i className="ion-plus-round" />
            </Link>
          </div>
        )}
        <Alert
          msg={alertMsg}
          setShow={setAlertShow}
          show={alertShow}
          type={alertType}
        />
        <LoadingBox show={showLoading || datesLoading} />
        <ModalBox
          downloadData={downloadData}
          downloadDates={downloadDates}
          editData={editData}
          provider_id={
            filters.provider ||
            (user_data?.user_role === 'provider' ? user_data.user_id : null)
          }
          clinic_id={clinic_id}
          setShowBlockDateForm={setShowBlockDateForm}
          setShowLoading={setShowLoading}
          showBlockDateForm={showBlockDateForm}
          clinicList={clinicList}
          user_data={user_data}
          key={editData.block_date_id ? Math.random() : null}
        />
        <ShareLinkModal data={shareData} />
        <ConfirmAction
          message="Please confirm that you want to proceed with this action?"
          onAction={confirmCancelBooking}
        />
      </div>
    </>
  );
};

const ModalBox = ({
  downloadData,
  downloadDates,
  editData,
  provider_id,
  clinic_id,
  setShowBlockDateForm,
  setShowLoading,
  showBlockDateForm,
  clinicList,
  user_data,
}) => {
  const initBlockFormState = () => ({
    clinic_id: null,
    from_date: undefined,
    too_date: undefined,
    from_time: '00:00:00',
    to_time: '23:59:00',
  });
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [data, setData] = useState(initBlockFormState());
  const [fullData, setFullData] = useState({
    clinic_id,
    event_type: 'leave',
    // selected_clinic: clinic_id || '',
    provider_comment: '',
    block_status: true,
    created_by: user_data?.user_no,
  });
  const [impact, setImpact] = useState({
    affected_bookings: null,
    unq_new_pat_count: null,
    clinic_new_pat_count: null,
    prov_new_pat_count: null,
    free_visit: null,
  });

  const blockStatusHandler = ({ target: { name } }) => {
    const copy = { ...fullData };
    setFullData({
      ...copy,
      block_status: name === 'block_status_block',
    });
  };

  const resetDateInputs = () => {
    document.getElementById('from_date_input').value = '';
    document.getElementById('to_date_input').value = '';
  };

  const changeHandler = ({ target: { name, value } }) => {
    const copy = { ...data };
    setData({ ...copy, [name]: value });
  };

  const fullDataHandler = ({ target: { name, value } }) => {
    const copy = { ...fullData };
    const calcSummaryDataCopy = { ...data };
    setFullData({ ...copy, [name]: value });
    if (name === 'clinic_id') {
      setData({ ...calcSummaryDataCopy, [name]: value });
    }
  };

  const addBlock = () => {
    Axios.storage?.remove?.('get-block-dates');
    setShowLoading(true);
    const requestData = {
      provider_id,
      ...data,
      ...fullData,
      total_affected_bookings: impact.affected_bookings || 0,
      unq_new_pat_count: impact.unq_new_pat_count || 0,
      free_visit: impact.free_visit || 0,
    };
    Axios.post(`${API_ENDPOINT}/Providers/web_admin/block_date`, requestData)
      .then(({ data: resData }) => {
        setData(initBlockFormState);
        resetDateInputs();
        downloadData();
        downloadDates(provider_id);
      })
      .catch((err) => showAlert('Add block dates  failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const calculateImpact = () => {
    const requestData = { provider_id, ...data };
    Axios.post(
      `${API_ENDPOINT}/Providers/web_admin/affected_bookings`,
      requestData
    )
      .then(({ data: resData }) => setImpact(resData[0]))
      .catch((err) => showAlert('Impact Calculation failed', 'error'));
  };

  const editBlock = (block_date_id, prev_from_date) => {
    Axios.storage?.remove?.('get-block-dates');
    setShowLoading(true);
    // Edit end point just updates the From Date and To Date.
    // To Unblock the specific dates selected by the user re-calculate the dates from the prev: blockdates
    const new_to_date = new Date(data.from_date);
    new_to_date.setDate(new_to_date.getDate() - 1);
    // dateProper
    data.too_date = new_to_date;
    data.from_date = prev_from_date;
    const requestData = {
      block_date_id,
      provider_id,
      ...data,
      ...fullData,
      total_affected_bookings: impact.affected_bookings || 0,
      unq_new_pat_count: impact.unq_new_pat_count || 0,
      free_visit: impact.free_visit || 0,
      is_active: false,
    };
    Axios.post(
      `${API_ENDPOINT}/Providers/web_admin/edit_block_date`,
      requestData
    )
      .then(({ data: resData }) => {
        setData(initBlockFormState);
        downloadData();
        downloadDates(provider_id);
      })
      .catch((err) => showAlert('Edit block dates failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  useEffect(() => {
    setData({
      from_date: editData.from_date
        ? dateProper(new Date(editData.from_date))
        : undefined,
      too_date: editData.too_date
        ? dateProper(new Date(editData.too_date))
        : undefined,
      from_time: editData.from_time ? editData.from_time : '00:00:00',
      to_time: editData.to_time ? editData.to_time : '23:59:00',
    });
    setFullData({
      clinic_id,
      event_type: editData.event_type || 'leave',
      provider_comment: editData.provider_comment || '',
      block_status: editData.block_status || true,
      created_by: user_data?.user_no,
    });
    setImpact({
      affected_bookings: editData.total_affected_bookings || null,
      unq_new_pat_count: editData.unq_new_pat_count || null,
      free_visit: editData.free_visit || null,
    });
  }, [editData]);

  return (
    <div
      aria-hidden="true"
      className={showBlockDateForm ? 'modal fade show' : 'modal fade'}
      id="block-date-modal"
      role="dialog"
      style={{
        display: showBlockDateForm ? 'block' : 'none',
      }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {editData && editData.block_date_id ? 'Unblock' : 'Block'} Dates
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              onClick={() => {
                setData(initBlockFormState());
                setShowBlockDateForm(false);
              }}
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-12 col-sm-6 form-group">
                <Select
                  className="outlined"
                  name="clinic_id"
                  onChange={fullDataHandler}
                  placeholder="Choose Clinic"
                  values={clinicList}
                  defaultValue={fullData.clinic_id}
                />
                <div className="messages" />
              </div>
              <div className="col-12 col-sm-6 form-group">
                <Select
                  className="outlined"
                  name="event_type"
                  onChange={fullDataHandler}
                  placeholder="Event Type"
                  values={[{ name: 'Leave', value: 'leave' }]}
                  defaultValue={fullData.event_type}
                />
                <div className="messages" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row form-group">
                  <div className="col-4 col-sm-3 text-right text-muted">
                    From date
                  </div>
                  <div className="col-8 col-sm-9">
                    <input
                      className="form-control outlined"
                      name="from_date"
                      onChange={changeHandler}
                      type="date"
                      id="from_date_input"
                      defaultValue={data.from_date}
                      disabled={editData && editData.block_date_id}
                    />
                  </div>
                  <div className="col-12 messages" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <div className="col-4 col-sm-3 text-right text-muted">
                    To date
                  </div>
                  <div className="col-8 col-sm-9">
                    <input
                      className="form-control outlined"
                      name="too_date"
                      onChange={changeHandler}
                      type="date"
                      value={data.too_date}
                      id="to_date_input"
                      disabled={editData && editData.block_date_id}
                    />
                  </div>
                  <div className="col-12 messages" />
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-6">
                <div className="row form-group">
                  <div className="col-4 col-sm-3 text-right text-muted">
                    From time
                  </div>
                  <div className="col-8 col-sm-9">
                    <input
                      className="form-control outlined"
                      name="from_time"
                      onChange={changeHandler}
                      type="time"
                      defaultValue={data.from_time}
                      disabled
                    />
                  </div>
                  <div className="col-12 messages" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="row form-group">
                  <div className="col-4 col-sm-3 text-right text-muted">
                    To time
                  </div>
                  <div className="col-8 col-sm-9">
                    <input
                      className="form-control outlined"
                      name="to_time"
                      onChange={changeHandler}
                      type="time"
                      defaultValue={data.to_time}
                      disabled
                    />
                  </div>
                  <div className="col-12 messages" />
                </div>
              </div> */}
              {editData && editData.block_date_id ? (
                ''
              ) : (
                <div className="col-12">
                  <div className="text-left m-b-10">
                    <button
                      className="btn btn-info btn-sm"
                      onClick={calculateImpact}
                      disabled={!data.from_date || !data.too_date}
                    >
                      Calculate Impact
                    </button>
                  </div>
                  <div className="row m-b-20" style={{ alignItems: 'center' }}>
                    <div className="col">
                      <table className="table table-hex table-styling regular">
                        <thead>
                          <tr>
                            <th>Total</th>
                            <th>New Patient</th>
                            <th>Clinic new</th>
                            <th>Prov New</th>
                            <th>Connected</th>
                            <th>Free Visit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {impact.affected_bookings == 0
                                ? 0
                                : impact.affected_bookings || '_'}
                            </td>
                            <td>
                              {impact.unq_new_pat_count == 0
                                ? 0
                                : impact.unq_new_pat_count || '_'}
                            </td>
                            <td>
                              {impact.clinic_new_pat_count == 0
                                ? 0
                                : impact.clinic_new_pat_count || '_'}
                            </td>
                            <td>
                              {impact.prov_new_pat_count == 0
                                ? 0
                                : impact.prov_new_pat_count || '_'}
                            </td>
                            <td>
                              {impact.affected_bookings == 0
                                ? 0
                                : impact.affected_bookings -
                                    impact.unq_new_pat_count || '_'}
                            </td>
                            <td>
                              {impact.free_visit == 0
                                ? 0
                                : impact.free_visit || '_'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 form-group">
                <textarea
                  className="form-control"
                  name="provider_comment"
                  onChange={fullDataHandler}
                  placeholder="Comments"
                  rows="6"
                  value={fullData.provider_comment}
                />
                <div className="messages" />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12 col-sm-6">
                A notfication email &amp; SMS will be send to the Clinic owner
              </div>
              <div className="col-12 messages" />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div className="text-center">
                <button
                  className="btn btn-info"
                  data-dismiss="modal"
                  onClick={() => {
                    setShowBlockDateForm(false);
                    if (editData.block_date_id === null) {
                      addBlock();
                    } else {
                      editBlock(
                        editData.block_date_id,
                        editData.prev_from_date
                      );
                    }
                  }}
                >
                  {editData.block_date_id === null ? 'Add' : 'Unblock'}
                </button>
              </div>
              <div className="text-center m-l-20">
                <button
                  className="btn btn-info"
                  data-dismiss="modal"
                  onClick={() => {
                    resetDateInputs();
                    setData(initBlockFormState());
                    setShowBlockDateForm(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
    </div>
  );
};

const dateFormat = (value) => (value < 10 ? `0${value}` : value);

const dateProper = (value) =>
  `${value.getUTCFullYear()}-${dateFormat(value.getMonth() + 1)}-${dateFormat(
    value.getDate()
  )}`;

// const dateReverse = value => {
// 	return `${dateFormat(value.getMonth() + 1)}-${dateFormat(
// 		value.getDate()
// 	)}-${value.getUTCFullYear()}`
// }

const headingsBooking = [
  'TNo',
  'UIN',
  'Status',
  'Name',
  'Purpose',
  'Phone',
  'Action',
];

const headingsDays = ['Date', 'Total Bookings', 'Priority Slot', 'Action'];

const isDayInRange = (dateCheck, range) => {
  dateCheck = new Date(dateCheck);
  const dateFrom = new Date(range[0]).setHours(0, 0, 0, 0);
  const dateTo = new Date(range[1]).setHours(0, 0, 0, 0);
  return dateCheck >= dateFrom && dateCheck <= dateTo;
};

const slots = [
  { name: '10AM to 2PM', value: '10' },
  { name: '3PM to 7PM', value: '15' },
];

export default BookingProvider;
