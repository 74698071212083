import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropModal = ({ onCrop, inputImage, setShow, show }) => {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 250,
    height: 250,
  });
  const [completedCrop, setCompletedCrop] = useState(undefined);

  return (
    <div
      aria-hidden="true"
      className={show ? 'modal fade show' : 'modal fade'}
      style={{ display: show ? 'block' : 'none' }}
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-lg modal-center-content"
        role="document"
      >
        <div
          className="modal-content"
          style={{
            // minWidth: '600px',
            borderRadius: '10px',
          }}
        >
          <div
            className="modal-body p-20 mt-3"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '85vh',
            }}
          >
            {!!inputImage && (
              <ReactCrop
                crop={crop}
                // aspect={1}
                // locked
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img ref={imgRef} alt="Crop me" src={inputImage} />
              </ReactCrop>
            )}
          </div>
          <div className="modal-footer d-flex align-items-center justify-content-around">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={() => {
                setCrop({
                  unit: 'px', // Can be 'px' or '%'
                  x: 25,
                  y: 25,
                  width: 250,
                  height: 250,
                });
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              disabled={!crop}
              onClick={() => onCrop(completedCrop, imgRef?.current)}
            >
              Set Image
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCropModal;
