import axios from '@/_helpers/axios_interceptor';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { API_ENDPOINT } from '../../config/api';

export function useCalculateFreeVisitPeriod(
  appoinmentId
  //   lastAppointmentDate,
  //   revisit_period = 25,
  //   dateFormat = undefined
) {
  const [astatus, setStatus] = useState({});
  useEffect(() => {
    appoinmentId &&
      axios
        .get(
          `${API_ENDPOINT}/Transaction/web_admin/get_apmnt_transactions/${appoinmentId}`,
          {
            id: 'get-apmnt-transactions',
          }
        )
        .then(({ data: resData }) => {
          const fetchedTransctions = resData?.length ? resData : [];
          const revisitdata = fetchedTransctions?.[0]?.revisitdata?.[0];
          if (
            !revisitdata?.free_revisit_enabled ||
            !revisitdata?.last_appointment_date
          )
            return {};
          const diff = moment().diff(
            revisitdata?.last_appointment_date,
            'days'
          );
          const lastVisit = moment(
            revisitdata?.last_appointment_date,
            'YYYY-MM-DD'
          ).format('DD-MM-YYYY');
          setStatus(
            revisitdata?.free_revisit_period &&
              diff <= revisitdata?.free_revisit_period
              ? {
                  status: true,
                  diff,
                  lastVisit,
                }
              : { status: false, diff, lastVisit }
          );
        });
  }, [appoinmentId]);

  return astatus;
}

export default 'No default exports available for func.js';
