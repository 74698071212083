import React, { useState } from 'react';
import viewIcon from './assets/viewIcon.svg';
import downloadIcon from './assets/download.svg';

const PatientDetailedInfo = () => {
  const [view, setView] = useState('personal');

  return (
    <div className="op-session-details-chooser-container">
      <div className="op-session-details-chooser">
        <p className="op-session-details-chooser-title">EHR</p>

        <div className="op-session-details-chooser-selector">
          <p
            className={
              view === 'personal' ? 'op-session-details-chooser-active' : ''
            }
            onClick={() => {
              setView('personal');
            }}
          >
            Personal
          </p>
          <p
            className={
              view === 'doctor' ? 'op-session-details-chooser-active' : ''
            }
            onClick={() => {
              setView('doctor');
            }}
          >
            Doctor
          </p>
          <p
            className={
              view === 'lab' ? 'op-session-details-chooser-active' : ''
            }
            onClick={() => {
              setView('lab');
            }}
          >
            Lab
          </p>
          <p
            className={
              view === 'e-record' ? 'op-session-details-chooser-active' : ''
            }
            onClick={() => {
              setView('e-record');
            }}
          >
            e-Record
          </p>
        </div>
      </div>

      {view === 'personal' ? <PersonalDetailsTab /> : ''}
      {view === 'doctor' ? <DoctorTab /> : ''}
      {view === 'lab' ? <LabTab /> : ''}
      {view === 'e-record' ? <ErecordTab /> : ''}
    </div>
  );
};

const PersonalDetailsTab = () => (
  <div>
    <div className="personal-details-form">
      <div className="personal-details-form-field">
        <p>Ht</p>
        <input
          type="number"
          name="height_cm"
          defaultValue=""
          onChange={() => {}}
        />
        <p className="personal-details-form-units">CM</p>
        <p className="personal-details-form-dates">22-3-2021</p>
      </div>
      <div className="personal-details-form-field">
        <p>Wt</p>
        <input
          type="number"
          name="weight_kg"
          defaultValue=""
          onChange={() => {}}
        />
        <p className="personal-details-form-units">KG</p>
        <p className="personal-details-form-dates">22-3-2021</p>
      </div>
      <div className="personal-details-form-field">
        <p>BG</p>
        <select name="blood_group" defaultValue="" onChange={() => {}}>
          {/* {bloodTypes.map((option, index) => (
							<option key={index} value={option}>
								{option}
							</option>
						))} */}
        </select>
        <p className="personal-details-form-dates">22-3-2021</p>
      </div>
      <div className="personal-details-form-field">
        <p>PR</p>
        <input
          type="number"
          name="pulse_rate"
          defaultValue=""
          onChange={() => {}}
        />
        <p className="personal-details-form-units">BPM</p>
        <p className="personal-details-form-dates">22-3-2021</p>
      </div>
      <div className="personal-details-form-field">
        <p>S-BP:</p>
        <input
          type="number"
          name="bp_sys"
          defaultValue=""
          onChange={() => {}}
        />
        <p className="personal-details-form-units">mmHg</p>
        <p className="personal-details-form-dates">22-3-2021</p>
      </div>
      <div className="personal-details-form-field">
        <p>D-BP:</p>
        <input
          type="number"
          name="bp_diastolic"
          defaultValue=""
          onChange={() => {}}
        />
        <p className="personal-details-form-units">mmHg</p>
        <p className="personal-details-form-dates">22-3-2021</p>
      </div>
      <div className="personal-details-form-bpm">
        BMI: <span className="personal-details-form-bpm-ideal">Ideal</span>
      </div>
    </div>
    <div className="basic-medical-deatails-container">
      <input
        type="text"
        placeholder="Allergies"
        name="allergies"
        defaultValue=""
        onChange={() => {}}
      />
      <input
        type="text"
        placeholder="Medical Conditions"
        name="medical_conditions"
        defaultValue=""
        onChange={() => {}}
      />
      <input
        type="text"
        placeholder="habits"
        name="habits"
        defaultValue=""
        onChange={() => {}}
      />
    </div>
    <div className="insurance-deatails-container">
      <input
        type="text"
        placeholder="Insurance Provider"
        name="insurance_provider"
        defaultValue=""
        onChange={() => {}}
      />
      <input
        type="text"
        placeholder="Policy Number"
        name="policy_number"
        defaultValue=""
        onChange={() => {}}
      />
      <input
        type="date"
        placeholder="Policy Expiry Date"
        name="policy_expiry_date"
        defaultValue=""
        onChange={() => {}}
      />
    </div>
  </div>
);

const DoctorTab = () => (
  <div className="op-session-doctor-card-container">
    <div className="op-session-doctor-card">
      <div>
        <p className="op-session-doctor-title">Dr Muhammed Thahir</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">MD Pediatrician</p>
        <p className="op-session-doctor-card-infobox">RTG Clinic</p>
      </div>
      <div>
        <p className="op-session-doctor-card-infobox">22-11-2020 11:45 AM</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">#24</p>
        <p className="op-session-doctor-card-infobox">Lep Pain</p>
      </div>
    </div>
    <div className="op-session-doctor-card">
      <div>
        <p className="op-session-doctor-title">Dr Muhammed Thahir</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">MD Pediatrician</p>
        <p className="op-session-doctor-card-infobox">RTG Clinic</p>
      </div>
      <div>
        <p className="op-session-doctor-card-infobox">22-11-2020 11:45 AM</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">#24</p>
        <p className="op-session-doctor-card-infobox">Lep Pain</p>
      </div>
    </div>
    <div className="op-session-doctor-card">
      <div>
        <p className="op-session-doctor-title">Dr Muhammed Thahir</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">MD Pediatrician</p>
        <p className="op-session-doctor-card-infobox">RTG Clinic</p>
      </div>
      <div>
        <p className="op-session-doctor-card-infobox">22-11-2020 11:45 AM</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">#24</p>
        <p className="op-session-doctor-card-infobox">Lep Pain</p>
      </div>
    </div>
    <div className="op-session-doctor-card">
      <div>
        <p className="op-session-doctor-title">Dr Muhammed Thahir</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">MD Pediatrician</p>
        <p className="op-session-doctor-card-infobox">RTG Clinic</p>
      </div>
      <div>
        <p className="op-session-doctor-card-infobox">22-11-2020 11:45 AM</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">#24</p>
        <p className="op-session-doctor-card-infobox">Lep Pain</p>
      </div>
    </div>
    <div className="op-session-doctor-card">
      <div>
        <p className="op-session-doctor-title">Dr Muhammed Thahir</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">MD Pediatrician</p>
        <p className="op-session-doctor-card-infobox">RTG Clinic</p>
      </div>
      <div>
        <p className="op-session-doctor-card-infobox">22-11-2020 11:45 AM</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">#24</p>
        <p className="op-session-doctor-card-infobox">Lep Pain</p>
      </div>
    </div>
    <div className="op-session-doctor-card">
      <div>
        <p className="op-session-doctor-title">Dr Muhammed Thahir</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">MD Pediatrician</p>
        <p className="op-session-doctor-card-infobox">RTG Clinic</p>
      </div>
      <div>
        <p className="op-session-doctor-card-infobox">22-11-2020 11:45 AM</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">#24</p>
        <p className="op-session-doctor-card-infobox">Lep Pain</p>
      </div>
    </div>
    <div className="op-session-doctor-card">
      <div>
        <p className="op-session-doctor-title">Dr Muhammed Thahir</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">MD Pediatrician</p>
        <p className="op-session-doctor-card-infobox">RTG Clinic</p>
      </div>
      <div>
        <p className="op-session-doctor-card-infobox">22-11-2020 11:45 AM</p>
      </div>
      <div className="op-session-doctor-card-infobox-container">
        <p className="op-session-doctor-card-infobox">#24</p>
        <p className="op-session-doctor-card-infobox">Lep Pain</p>
      </div>
    </div>
  </div>
);
const LabTab = () => (
  <div className="under-construction">This feature is under Construction</div>
);

const ErecordTab = () => (
  <div className="e-record-container">
    <div className="e-record-item">
      <div>
        <p>22-11-2020</p>
        <p>Dr. Faisal Prescription</p>
      </div>
      <div>
        <img src={viewIcon} alt="View" />
        <img src={downloadIcon} alt="ownload" />
      </div>
    </div>
    <div className="e-record-item">
      <div>
        <p>22-11-2020</p>
        <p>Dr. Faisal Prescription</p>
      </div>
      <div>
        <img src={viewIcon} alt="View" />
        <img src={downloadIcon} alt="download" />
      </div>
    </div>
    <div className="e-record-item">
      <div>
        <p>22-11-2020</p>
        <p>Dr. Faisal Prescription</p>
      </div>
      <div>
        <img src={viewIcon} alt="View" />
        <img src={downloadIcon} alt="ownload" />
      </div>
    </div>
  </div>
);

export default PatientDetailedInfo;
