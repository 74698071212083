import React, { useState } from 'react';
import axios from '@/_helpers/axios_interceptor';
import moment from 'moment';
import { PrescriptionSheetLayout } from './PrescriptionSheet';
import { API_ENDPOINT } from '../../../config/api';
import { genratePdfFromElement } from './generatePdf';
import { calculateAge } from '../../../_helpers/misc';
import { useFullUser } from '@/hooks/useUser';

const _date = moment().format('YYYY-MM-DD');

export default function BlankSheetPrintButton({
  appointment_id,
  file_no,
  label,
  afterprint,
}) {
  const handlePrint = async () => {
    setLoading(true);
    try {
      await getBlankPdf(appointment_id, file_no).finally((e) => {});
    } catch (error) {
      console.log(error);
    }
    afterprint?.();
    setLoading(false);
  };
  const [loading, setLoading] = useState(false);
  return (
    <span
      onClick={!loading ? handlePrint : undefined}
      className={`badge ${
        loading
          ? 'badge-secondary'
          : 'badge-success cursor-pointer hover-opacity py-1'
      } mx-2 px-3 `}
    >
      {loading ? (
        <span className="spinner-border spinner-border-sm" />
      ) : (
        <>
          <i className="fa fa-file-text mr-1" />
          {label ?? 'Print'}
        </>
      )}
    </span>
  );
}

export async function getBlankPdf(appointment_id, fileNo) {
  const appointment = (
    await axios.get(`${API_ENDPOINT}/provider/appointment/${appointment_id}`)
  ).data;
  const settingsPromise = axios.get(
    `${API_ENDPOINT}/provider/mapping/${appointment.provider_id}/${appointment.clinic_id}`
  );

  const doctorPromise = axios.get(
    `${API_ENDPOINT}/provider/${appointment.provider_id}`
  );

  const patientPromise = axios.get(
    `${API_ENDPOINT}/provider/patient/${appointment.person_id}`
  );

  const vitalsPromise = axios.get(
    `${API_ENDPOINT}/provider/get-vitals-latest/${appointment.clinic_id}/${appointment.person_id}`
  );

  const consultationMappingPromise = axios.get(
    `${API_ENDPOINT}/provider/consultation-mapping/${appointment.clinic_id}/${appointment.provider_id}/${appointment.person_id}`
  );

  const schedulesMappingPromise = axios.get(
    `${API_ENDPOINT}/provider/clinic-provider-schedule-mapping/${appointment.clinic_id}/${appointment.provider_id}`
  );

  const patientClinicMappingPromise = fileNo
    ? undefined
    : axios.get(
        `${API_ENDPOINT}/provider/patient-clinic-mapping/${appointment.clinic_id}/${appointment.person_id}`
      );

  const {
    print_clinic_header,
    print_provider_header,
    print_token_display,
    settings,
  } = (await settingsPromise).data;

  const clinic = useFullUser.getState().getSelectedClinic();
  const provider = (await doctorPromise).data;
  const patient = (await patientPromise).data;
  const _vitals = (await vitalsPromise).data;
  const consultMapping = (await consultationMappingPromise).data;
  const scheduleMapping = (await schedulesMappingPromise).data;
  const patientClinicMapping =
    !fileNo && (await patientClinicMappingPromise).data;

  const vitals =
    moment(_date).diff(_vitals?.vital_time, 'days') < 10
      ? _vitals
      : { height_cm: _vitals?.height_cm };
  const currentSchedule = scheduleMapping.find(
    (e) => e.schedule_id === appointment.schedule_id
  );

  const getVital = (key) => {
    const v = vitals?.find((vital) => vital?.vitalname === key);
    if (!v) return;
    return moment(_date).diff(v?.modifiedat, 'days') < 10
      ? v?.value
      : undefined;
  };

  const age = calculateAge(patient.date_of_birth);

  const previousVisits = consultMapping
    ?.map((e) => moment(e.create_time).format('DD/MM/YYYY'))
    .reverse()
    .slice(0, 5);

  await genratePdfFromElement(
    <BlankSheetPdf
      print_clinic_header={print_clinic_header}
      print_provider_header={print_provider_header}
      doctorName={`${provider?.first_name} ${provider?.last_name}`}
      doctorUpn={provider?.provider_no}
      doctorSpeciality={provider?.speciality}
      doctorDegreeName={provider?.degree_title}
      doctorAddQualification={provider?.add_qualification}
      doctorRegNo={provider?.reg_no}
      weekdays={{
        mon: currentSchedule?.monday_on,
        tue: currentSchedule?.tuesday_on,
        wed: currentSchedule?.wednesday_on,
        thu: currentSchedule?.thursday_on,
        fri: currentSchedule?.friday_on,
        sat: currentSchedule?.saturday_on,
        sun: currentSchedule?.sunday_on,
      }}
      slot_start={currentSchedule?.slot_start}
      slot_end={currentSchedule?.slot_end}
      clinic_place={clinic?.area}
      clinic_logo={clinic?.image_url}
      clinic_phone_no={clinic?.clinic_phone_no}
      name={`${patient?.first_name} ${patient?.last_name}`}
      age={age}
      sex={patient?.sex}
      phone={patient?.phone_no}
      place={patient?.area}
      isWalkin={appointment?.is_walkin}
      patientno={patient?.person_no}
      tokenNo={print_token_display ? appointment?.token_no : undefined}
      vitalsDate={''}
      height={getVital('height_cm')}
      weight={getVital('weight_kg')}
      bloodGroup={patient?.blood_group}
      pulseRate={getVital('pulse_rate')}
      sBp={getVital('bp_sys')}
      dBp={getVital('bp_diastolic')}
      fileNo={fileNo ?? patientClinicMapping?.file_no}
      previousVisits={previousVisits}
      settings={settings?.print}
      schedules={settings?.print?.showMultipleSchedule ? scheduleMapping : null}
    />
  )();
}

function BlankSheetPdf({
  print_clinic_header,
  print_provider_header,
  doctorName,
  doctorUpn,
  doctorSpeciality,
  doctorDegreeName,
  doctorAddQualification,
  doctorRegNo,
  slot_start,
  slot_end,
  clinic_logo,
  clinic_place,
  clinic_phone_no,
  weekdays,
  tokenNo,
  name,
  age,
  sex,
  phone,
  place,
  fileNo,
  isWalkin,
  patientno,
  vitalsDate,
  height,
  weight,
  bloodGroup,
  pulseRate,
  sBp,
  dBp,
  previousVisits,
  settings,
  schedules,
}) {
  return (
    <PrescriptionSheetLayout
      print_clinic_header={print_clinic_header}
      print_provider_header={print_provider_header}
      doctorName={doctorName}
      doctorUpn={doctorUpn}
      doctorSpeciality={doctorSpeciality}
      doctorDegreeName={doctorDegreeName}
      doctorAddQualification={doctorAddQualification}
      doctorRegNo={doctorRegNo}
      slot_start={slot_start}
      slot_end={slot_end}
      clinic_logo={clinic_logo}
      clinic_place={clinic_place}
      clinic_phone_no={clinic_phone_no}
      weekdays={weekdays}
      footNote=""
      settings={settings}
      schedules={schedules}
    >
      <BlankSheetItem
        name={name}
        age={age}
        sex={sex}
        phone={phone}
        place={place}
        fileNo={fileNo}
        tokenNo={tokenNo}
        isWalkin={isWalkin}
        patientno={patientno}
        bloodGroup={bloodGroup}
        pulseRate={pulseRate}
        vitalsDate={vitalsDate}
        height={height}
        weight={weight}
        sBp={sBp}
        dBp={dBp}
        previousVisits={previousVisits}
        doctorName={doctorName}
        doctorRegNo={doctorRegNo}
        settings={settings}
      />
    </PrescriptionSheetLayout>
  );
}

function BlankSheetItem({
  name,
  age,
  sex,
  phone,
  place,
  fileNo,
  tokenNo,
  isWalkin,
  patientno,
  bloodGroup,
  pulseRate,
  vitalsDate,
  height,
  weight,
  sBp,
  dBp,
  previousVisits,
  doctorName,
  doctorRegNo,
  settings,
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        fontSize: 12,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          backgroundColor: '#fff',
          width: '100%',
          padding: '00px 20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 20px',
            borderBottomWidth:
              settings?.casesheet?.borderHorizontal === false ? 0 : 1,
            borderColor: 'green',
          }}
        >
          {/* General details */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',
              width: '100%',
              fontSize: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  textTransform: 'uppercase',
                }}
              >
                {name}
              </div>
              {(age || sex) && (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  Age: {age} {sex && `Sex: ${sex}`}
                </div>
              )}
              {phone && (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  Ph: {phone}
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                }}
              >
                Place: {place}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                {isWalkin ? 'WK' : 'OP'} {tokenNo && `Token No: #${tokenNo}`}
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                {moment().format('DD/MMM/YYYY')}
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                {settings?.showPatientUIN && patientno && `#${patientno}`}
              </div>
              {fileNo && (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  File No: {fileNo}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          width: '100%',
          backgroundColor: '#fff',
          padding: '0px 0px',
          fontSize: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '30%',
            maxWidth: '40%',
            padding: '20px',
            borderRightWidth: settings?.casesheet?.border === false ? 0 : 1,
            borderColor: 'green',
          }}
        >
          {vitalsDate && (
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              Vitals: {vitalsDate}
            </div>
          )}
          {height && (
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              Height = {height} CM
            </div>
          )}
          {weight && (
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              Weight = {weight} KG
            </div>
          )}
          {bloodGroup && (
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              BG = {bloodGroup}
            </div>
          )}
          {pulseRate && (
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              PR = {pulseRate} BPM
            </div>
          )}
          {sBp && (
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              S-BP = {sBp} mmHg
            </div>
          )}
          {dBp && (
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              D-BP = {dBp} mmHg
            </div>
          )}
          {previousVisits && previousVisits.length > 0 && (
            <div
              style={{
                display: 'flex',
                margin: '10px 0px',
                flexDirection: 'column',
              }}
            >
              <span
                style={{
                  fontSize: 10,
                }}
              >
                Previous Visit:
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '10px',
                }}
              >
                {previousVisits.map((e, i) => (
                  <div
                    // biome-ignore lint/suspicious/noArrayIndexKey: <Nothing>
                    key={e + i}
                    style={{
                      display: 'flex',
                      fontSize: 10,
                    }}
                  >
                    {i + 1}. {e}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexGrow: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '30px',
            }}
          >
            {settings?.casesheet?.hideProviderName ? (
              <div />
            ) : (
              <div style={{ fontSize: 14 }}>{doctorName}</div>
            )}
            {doctorRegNo && settings?.casesheet?.regNoHide ? (
              <div />
            ) : (
              <span
                style={{
                  fontSize: 10,
                  display: 'flex',
                }}
              >
                Reg No: {doctorRegNo}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function TestBlankSheet() {
  return (
    <div
      style={{
        height: '841.89px',
        width: '595.28px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#666',
        fontSize: 14,
        fontWeight: 400,
      }}
    >
      <div
        style={{
          height: '130px',
          width: '100%',
          backgroundColor: '#f9fafe',
          display: 'flex',
          padding: '20px 20px 5px 20px',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: 18,
                fontWeight: 600,
                alignItems: 'flex-end',
              }}
            >
              <span>Anna Paul</span>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                -UPN071
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                fontSize: 12,
              }}
            >
              Detal Surgeon Specialist
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                fontSize: 12,
              }}
            >
              BDS, MD, Etc
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                fontSize: 12,
              }}
            >
              M T W _ F _ S
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: 10,
              }}
            >
              06:00 AM to 02:30 PM
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            fontSize: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              // borderWidth: 2,
              // borderColor: "#000",
              overflow: 'hidden',
            }}
          >
            <img
              src="https://iili.io/J12tI5u.png"
              style={{
                width: '70px',
                height: '70px',
              }}
              alt="logo"
              // width="70px" height="70px"
            />
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            Pushpa Junction, 673002
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            clinicemail@gmail.com | +919544599899
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          backgroundColor: '#fff',
          width: '100%',
          padding: '00px 20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 20px',
            borderBottomWidth: 1,
            borderColor: 'green',
          }}
        >
          {/* General details */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',
              width: '100%',
              fontSize: 12,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                Muhammed Sainudheen Bahadoorsha
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                Age: 12 Sex: Male
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                Ph: 9856287452
              </div>

              <div
                style={{
                  display: 'flex',
                }}
              >
                Place: Ramapuram
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                File No: 24-2596
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                OP/WK #UIN32
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                08-12-2024
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                08:56 AM
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          width: '100%',
          backgroundColor: '#fff',
          padding: '0px 0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '40%',
            padding: '20px',
            borderRightWidth: 1,
            borderColor: 'green',
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            File No: 24-5896
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            Vitals: 21-12-2023
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            Height = 175CM
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            Weight = 88KG
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            BMI = 30.4
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            S-BP = 140Hg
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            D-BP = 100Hg
          </div>
          <div
            style={{
              display: 'flex',
              margin: '10px 0px',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                fontSize: 10,
              }}
            >
              Previous Visit:
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '10px',
              }}
            >
              <div>1. 07-12-2023</div>
              <div>2. 07-12-2023</div>
              <div>3. 07-12-2023</div>
              <div>4. 07-12-2023</div>
              <div>5. 07-12-2023</div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexGrow: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '30px',
            }}
          >
            <span
              style={{
                fontSize: 10,
              }}
            >
              Reg No: 25402
            </span>
            <div>Anna Paul</div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          backgroundColor: '#f9fafe',
          height: '20px',
          width: '100%',
          fontSize: 8,
          alignItems: 'center',
          padding: '0 20px',
        }}
      >
        {/*  Footer */}
        Generated from UNQ Dashboard
      </div>
    </div>
  );
}
