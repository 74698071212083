import React from 'react';
import cn from 'classnames';
import { asInitials } from '../../_helpers/common_utils';
import generateRandomColor from '../../_helpers/generate-random-color';

const Avatar = ({
  picture,
  placeholder = '-',
  is_squared = false,
  width = '30px',
  height = '30px',
  dynamicBg = false,
  color = '#555',
  className,
  firstNameOnly = false,
  gender = '',
}) =>
  picture ? (
    <img
      className={cn(
        'border',
        is_squared ? 'rounded' : 'rounded-circle',
        className
      )}
      src={picture}
      alt="Avatar"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = '/img/person.svg';
      }}
    />
  ) : gender ? (
    <img
      className={cn(
        'border',
        is_squared ? 'rounded' : 'rounded-circle',
        className
      )}
      style={{ padding: '2px' }}
      src={
        gender === 'M'
          ? '/img/home_page/male-avatar.png'
          : '/img/home_page/female-avatar.png'
      }
      width={width}
      height={height}
      alt="Avatar"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = '/img/person.svg';
      }}
    />
  ) : (
    <div
      className={cn(
        'd-flex align-items-center uppercase justify-content-center',
        is_squared ? 'rounded' : 'rounded-circle',
        className
      )}
      style={{
        backgroundColor: dynamicBg
          ? generateRandomColor('light_color')
          : '#dddddd',
        color,
        height,
        width,
      }}
    >
      {asInitials(placeholder, firstNameOnly)}
    </div>
  );

export { Avatar as default };
