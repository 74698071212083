import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

const GridMenu = ({ pages }) => (
  <>
    {pages.map((page, index) => (
      <Link
        className={classNames('col-sm-6 col-xl-4 hex-menubox', {
          '!_hidden sm:!_inline-block': page.mobile === false,
        })}
        key={index}
        to={page.link}
        style={
          page.disabled
            ? { pointerEvents: 'none', opacity: 0.5, cursor: 'not-allowed' }
            : {}
        }
      >
        <div className="bg-info card group-widget text-center">{page.name}</div>
      </Link>
    ))}
  </>
);

export default GridMenu;
