import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { Link, useParams } from 'react-router-dom';

import Alert from '../layout/Alert';
import DeleteConfirm from '../layout/DeleteConfirm';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import Table from '../layout/Table';
import { API_ENDPOINT } from '../../config/api';
import showCurrency from '../common/showCurrency';

const headings = ['Name', 'Phone', 'Email', 'Pswd', 'Status', 'Edit', 'Action'];

const status = [
  { name: 'Active', value: 'Active' },
  { name: 'Inactive', value: 'Inactive' },
];

const IconButton = ({ icon, onClick, url, ...props }) =>
  url ? (
    <Link to={url} {...props}>
      <i className={`fa fa-${icon} hex-icon`} />
    </Link>
  ) : (
    <i className={`fa fa-${icon} hex-icon`} onClick={onClick} {...props} />
  );

const ClinicFrontDesk = () => {
  const { id: clinicId } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [clinicInfo, setClinicInfo] = useState({});
  const [deleteID, setDeleteID] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const deleteStaff = () => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Clinic_staff/web_admin/delete_front_desk`, {
      staff_id: deleteID,
      delete_ind: true,
      modified_by: 'any name',
    })
      .then((res) => {
        if (res.data.message === 'deleted successfully') {
          showAlert(res.data.message, 'success');
          return Axios.get(
            `${API_ENDPOINT}/Clinic_staff/Web_admin/clinics_staff/${clinicId}`
          ).then((res) => loadFrontendList(res));
        }
        showAlert(res.data.message);
      })
      .catch((err) => showAlert('Delete failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const loadFrontendList = (res) => {
    setTableData(
      res.data.map((row) => [
        `${row.first_name} ${row.last_name}`,
        row.phone_no,
        row.email_id,
        <Link to="/reset/frontdesk/123">Reset</Link>,
        <div style={{ minWidth: 72 }}>
          <Select
            className="outlined"
            name="status"
            onChange={() => statusSelectHandler(row.staff_id, row.is_active)}
            value={row.is_active ? 'Active' : 'Inactive'}
            values={status}
          />
        </div>,
        <Link to={`/clinic/frontdesk/edit/${row.staff_id}/${row.clinic_id}`}>
          <i className="hex-icon fa fa-edit" />
        </Link>,
        <IconButton icon="trash" onClick={() => setDeleteID(row.staff_id)} />,
      ])
    );
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const statusSelectHandler = (staff_id, status) => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Clinic_staff/web_admin/is_active_status`, {
      staff_id,
      is_active: !status,
      modified_by: 'any name',
    })
      .then((res) => {
        showAlert(res.data.message, 'success');
        return Axios.get(
          `${API_ENDPOINT}/Clinic_staff/Web_admin/clinics_staff/${clinicId}`
        );
      })
      .then((res) => {
        loadFrontendList(res);
        setShowLoading(false);
      })
      .catch((err) => showAlert('Status change failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    Axios.get(`${API_ENDPOINT}/Clinics/web_admin/clinic_list`)
      .then((res) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const i in res.data) {
          if (`${res.data[i].clinic_id}` === clinicId) {
            setClinicInfo(res.data[i]);
          }
        }
        return Axios.get(
          `${API_ENDPOINT}/Clinic_staff/Web_admin/clinics_staff/${clinicId}`
        );
      })
      .then((res) => {
        loadFrontendList(res);
      })
      .catch((err) => showAlert('Failed to load info', 'error'))
      .finally(() => setShowLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="form-group row">
        <div className="col-md-12 hex-info-wrap">
          <div className="row">
            <div className="col-md-2 hex-info-box">
              <div className="hex-info-content">
                <h5>{clinicInfo.clinic_name}</h5>
                <h5>ID: {clinicInfo.clinic_id}</h5>
              </div>
            </div>
            <div className="col-md-2 hex-info-box">
              <div className="hex-info-content">
                <div className="hex-info-line">
                  <span>Contact Person</span>
                  <span>Contact Number</span>
                </div>
                <div className="hex-info-line">
                  <span>Subscription</span>
                  <span>Smart Clinic Suit Pro</span>
                </div>
              </div>
            </div>
            <div className="col-md-2 hex-info-box">
              <div className="hex-info-content">
                <div className="hex-info-line">
                  <span>Status</span>
                  <span>Active</span>
                </div>
                <div className="hex-info-line">
                  <span>Total Bookings</span>
                  <span>75</span>
                </div>
              </div>
            </div>
            <div className="col-md-2 hex-info-box">
              <div className="hex-info-content">
                <div className="hex-info-line">
                  <span>Pending Payments</span>
                  <span>{showCurrency()}. 750</span>
                </div>
                <div className="hex-info-line">
                  <span>Monthly Payment</span>
                  <span>{showCurrency()}. 500</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 hex-info-box">
              <div className="hex-info-content">
                <div className="hex-info-line">
                  <button className="btn">
                    Configure Payment <i className="ti-angle-down" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <Table data={tableData} headings={headings} number={10} />
        </div>
        <div className="fab" title="Add Frontdesk User">
          <Link className="btn btn-inverse btn-icon" to="/clinic/frontdesk/add">
            <i className="ion-plus-round" />
          </Link>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <DeleteConfirm onConfirm={deleteStaff} />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default ClinicFrontDesk;
