import React from 'react';

type Props = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  className?: string;
  placeholder?: string;
  values?: { name: string; value: string; selected?: boolean }[];
  optionStyle?: React.CSSProperties;
};

const Select = React.forwardRef<HTMLSelectElement, Props>(
  ({ className, placeholder, values, optionStyle, ...props }, ref) => (
    <select
      ref={ref}
      className={`form-control${className ? ` ${className}` : ''}`}
      {...props}
    >
      {placeholder && (
        <option value="" disabled selected hidden style={{ color: '#999' }}>
          {placeholder}
        </option>
      )}
      {values?.map(({ name, value, selected }, index) => (
        <option
          key={index}
          value={value}
          selected={selected}
          style={optionStyle || { textTransform: 'capitalize' }}
        >
          {name}
        </option>
      ))}
    </select>
  )
);

export default Select;
