// import { joinWithoutDupes } from './common_utils';
// Calculates the Total bill amount from Procedure data or from bill_info in previous transactions.
export const getPayableBillAmount = (
  appointmentData,
  consultation_fee,
  showDueOnly = false // If true, it will return only the due amount
) => {
  const transactions =
    appointmentData?.transactions?.sort(
      (a, b) => a.transaction_id - b.transaction_id
    ) || null;
  const ALLOW_PREV_DUE_PAY_ONLY =
    appointmentData?.transactions &&
    (appointmentData?.patient?.payment_due || appointmentData?.payment_due);

  const itemList = [
    {
      type: 'px',
      proc_cli_id: 'c_fee',
      procedure_id: '',
      procedure_price: `${consultation_fee}`,
    },
    ...(appointmentData.proc_data || []),
  ];
  const prevBill =
    transactions?.[transactions.length - 1]?.bill_info ||
    appointmentData?.bill_info ||
    {};
  //   let billList = joinWithoutDupes(
  //     itemList,
  //     prevBill?.bill_list || [],
  //     'proc_cli_id'
  //   );
  let billList = [];
  if (prevBill?.bill_list?.length) {
    billList = prevBill?.bill_list || [];
  } else {
    billList = itemList;
  }

  const acc = (prev, next) =>
    parseInt(prev) +
    parseInt(Number(next?.procedure_price) || Number(next?.item_price) || 0);
  const dicountAcc = (prev, next) =>
    parseInt(prev) + parseInt(next?.discounted_price || 0);
  const netBillAmount = billList?.reduce(acc, 0);
  const totalBillDiscount = billList?.reduce(dicountAcc, 0);
  const total_paid = transactions?.reduce(
    (sum, current) =>
      current.action_type === 'payment' ? sum + current.bill_amount : sum,
    0
  );

  const total_refund = transactions?.reduce(
    (sum, current) =>
      current.action_type === 'refund' ? sum + current.bill_amount : sum,
    0
  );
  const net_paid = appointmentData?.NET_PAID || total_paid - total_refund;
  const rxOnlyAmount = billList
    .filter((e) => e.type === 'rx')
    .reduce(
      (acc, next) =>
        acc + parseInt((next?.item_price ?? next?.procedure_price) || 0),
      0
    );
  const txOnlyAmount = billList
    .filter((e) => e.type === 'px')
    .reduce(
      (acc, next) =>
        acc +
        (next?.item_price
          ? Number(next.item_price)
          : next?.procedure_price
          ? Number(next.procedure_price)
          : 0),
      0
    );

  if (ALLOW_PREV_DUE_PAY_ONLY && showDueOnly) {
    return {
      payableAmount:
        appointmentData?.patient?.payment_due || appointmentData?.payment_due,
      rxOnlyAmount,
      txOnlyAmount,
      net_paid: 0,
    };
  }

  return {
    payableAmount: netBillAmount - totalBillDiscount - (net_paid || 0),
    rxOnlyAmount,
    txOnlyAmount,
    net_paid,
  };
};

export default getPayableBillAmount;

export const get_amounts = (row) => {
  const consultation_fee = row.is_tatkal
    ? row.tatkal_consult_fee
    : row.normal_consult_fee;

  const { payableAmount, rxOnlyAmount, txOnlyAmount } = getPayableBillAmount(
    row,
    consultation_fee
  );
  const transactions = row.transactions || null;

  const total_paid =
    transactions?.reduce(
      (sum, current) =>
        current.action_type === 'payment' ? sum + current.bill_amount : sum,
      0
    ) || 0;
  const total_refund =
    transactions?.reduce(
      (sum, current) =>
        current.action_type === 'refund' ? sum + current.bill_amount : sum,
      0
    ) || 0;

  const isBillGenerated = !!transactions;
  const net_paid = total_paid - total_refund;
  return {
    payableAmount,
    total_paid,
    total_refund,
    net_paid,
    rxOnlyAmount,
    txOnlyAmount,

    isBillGenerated,
  };
};

export const calculateStatitcs = (appoinments) =>
  appoinments
    .map((e) => {
      const {
        net_paid,
        payableAmount,
        rxOnlyAmount,
        txOnlyAmount,
        isBillGenerated,
      } = get_amounts(e);

      // console.log({
      //   name: e.name,
      //   isBillGenerated,
      //   e,
      //   net_paid,
      //   payableAmount,
      //   rxOnlyAmount,
      //   txOnlyAmount,
      // });
      if (!isBillGenerated)
        return {
          paidAmount: 0,
          partialPaid: 0,
          due: 0,
          partialDue: 0,
          rxOnlyAmount: 0,
          txOnlyAmount: 0,
          rxPaid: 0,
          pxPaid: 0,
        };
      let pendingAmount = payableAmount;
      if (payableAmount < 0) pendingAmount = 0;
      const isPending = pendingAmount > 0;
      const isFullPaid = !isPending;
      const isPartialPaid = net_paid > 0 && isPending;
      const paidAmount = isFullPaid ? net_paid : 0;
      const partialPaid = isPartialPaid ? net_paid : 0;
      const due = isPending && !isPartialPaid ? pendingAmount : 0;
      const partialDue = isPartialPaid ? pendingAmount : 0;
      const rxPaid = isFullPaid ? rxOnlyAmount || 0 : 0;
      const pxPaid = isFullPaid ? txOnlyAmount || 0 : 0;
      const isOp = e.consultation_type !== 'walkin';
      return {
        paidAmount,
        partialPaid,
        due,
        partialDue,
        rxOnlyAmount,
        txOnlyAmount,
        rxPaid,
        pxPaid,
        isOp,
      };
    })
    .reduce(
      (acc, curr) => {
        acc.paidAmount += curr.paidAmount;
        acc.partialPaid += curr.partialPaid;
        acc.due += curr.due;
        acc.partialDue += curr.partialDue;

        if (curr.paidAmount > 0) acc.paidCount += 1;
        if (curr.partialPaid > 0) acc.partialPaidCount += 1;
        if (curr.due > 0) acc.dueCount += 1;
        //   if (curr.partialDue > 0) acc.partialDueCount += 1;
        acc.rxOnlyAmount += curr.rxOnlyAmount;
        acc.txOnlyAmount += curr.txOnlyAmount;
        acc.rxPaid += curr.rxPaid;
        acc.pxPaid += curr.pxPaid;
        if (curr.rxPaid > 0) acc.rxPaidCount += 1;
        if (curr.pxPaid > 0) acc.pxPaidCount += 1;
        acc.totalCount += 1;
        const isWalkin = !curr.isOp;
        if (curr.isOp) acc.opCount += 1;
        if (isWalkin) acc.walkinCount += 1;
        return acc;
      },
      {
        paidAmount: 0,
        partialPaid: 0,
        due: 0,
        partialDue: 0,
        paidCount: 0,
        partialPaidCount: 0,
        dueCount: 0,
        rxOnlyAmount: 0,
        txOnlyAmount: 0,
        rxPaid: 0,
        pxPaid: 0,
        rxPaidCount: 0,
        pxPaidCount: 0,
        totalCount: 0,
        walkinCount: 0,
        opCount: 0,
        //   partialDueCount: 0,
      }
    );
