import React, { useState, useEffect } from 'react';
import {
  GetBase64FromCropped,
  DataURLtoBlob,
} from '../../_helpers/image_handler';
import ImageCropModal from '../common/image-crop-modal';

/*
	Only allowing JPG / PNG files
*/

const ImageUpload = ({
  uploadImageFn,
  newImgWidth = 250,
  newImgHeight = 250,
  name = 'image',
  thumbnail_img = '',
}) => {
  const [thumbnailImg, setThumbnailImg] = useState('');
  const [showCrop, setShowCrop] = useState();
  const [imgSrc, setImgSrc] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileNameWithExtension, setFileNameWithExtension] = useState('');

  useEffect(() => {
    if (imgSrc) {
      setShowCrop(true);
    }
  }, [imgSrc]);

  const readFile = (files) => {
    const random_string = Math.random().toString(16).substring(2, 10);
    const file = files?.[0];
    if (file) {
      setFileType(file.type);
      const fileExtension = file.type.indexOf('png') !== -1 ? 'png' : 'jpg';
      // To avoid overwriting files with same name, we are adding a random string to the file_name
      const _fileNameWithExtension = `${
        file.name.split('.')[0]
      }_${random_string}.${fileExtension}`;
      setFileNameWithExtension(_fileNameWithExtension);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      );
      reader.readAsDataURL(file);
    }
    // GetBase64(files[0], newImgWidth, newImgHeight).then((imageDataUrl) => {
    //   thumbnail_img = '';
    //   setThumbnailImg(imageDataUrl);
    //   const imageToUpload = DataURLtoBlob(imageDataUrl);
    //   uploadImageFn({
    //     imgBlob: imageToUpload,
    //     _fileNameWithExtension,
    //   });
    // });
  };

  const onCrop = async (cropedImgDimensions, outImgRef) => {
    thumbnail_img = '';
    const imageDataUrl = await GetBase64FromCropped(
      outImgRef,
      cropedImgDimensions,
      fileType
    );
    setThumbnailImg(imageDataUrl);
    const imageToUpload = DataURLtoBlob(imageDataUrl);
    uploadImageFn({
      imgBlob: imageToUpload,
      fileNameWithExtension,
    });
    setShowCrop(false);
  };

  return (
    <div className="form-group hex-image !_mt-4 xl:!_h-[220px] !_h-8">
      <div className="hex-file jFiler jFiler-theme-default">
        <input
          className="form-control"
          name={name}
          type="file"
          accept="image/x-png,image/jpeg"
          multiple={false}
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={(event) => {
            readFile(event.target.files);
          }}
        />
        <div className="jFiler-input" style={{ margin: '0px' }}>
          <div className="_hidden xl:_block">
            {(thumbnailImg || thumbnail_img) && (
              <img
                src={thumbnailImg || thumbnail_img}
                alt="File Thumbnail"
                width="160px"
              />
            )}
          </div>
          <div
            className="btn btn-info btn-sm jFiler-input-button"
            onClick={() => document.querySelector(`[name="${name}"]`).click()}
          >
            <i className="fa fa-camera" />
            Choose Image
          </div>
        </div>
      </div>
      <div className="messages" style={{ position: 'absolute', top: '20px' }} />
      <ImageCropModal
        onCrop={onCrop}
        inputImage={imgSrc}
        setShow={setShowCrop}
        show={showCrop}
      />
    </div>
  );
};

export default ImageUpload;
