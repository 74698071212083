import React from 'react';

const LoadingBox = ({ show, opacity = 1 }) =>
  show && (
    <div className="hex-loading-box" style={{ opacity }}>
      <div className="preloader4">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </div>
  );

export default LoadingBox;
