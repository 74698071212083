import React from 'react';

const typeInfo = {
  alert: {
    color: '#f1c40f',
    fontColor: '#fff',
    icon: 'ion-alert',
    title: 'Alert',
  },
  error: {
    color: '#e74c3c',
    fontColor: '#fff',
    icon: 'ion-close',
    title: 'Error',
  },
  success: {
    color: '#2ecc71',
    fontColor: '#fff',
    icon: 'ion-checkmark',
    title: 'Success',
  },
};

const Alert = ({
  action,
  msg,
  setShow,
  show,
  type = 'alert',
  icon = true,
  closeButton = false,
  label = 'close',
}) => {
  const closeHandler = () => {
    setShow(false);
    if (action) action();
  };

  return (
    <div
      aria-hidden="true"
      className={show ? 'modal fade show' : 'modal fade'}
      style={{ display: show ? 'block' : 'none' }}
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-sm modal-center-content"
        role="document"
      >
        <div
          className="modal-content"
          style={{
            minWidth: '350px',
            borderTop: `5px solid ${typeInfo[type].color}`,
            borderRadius: '10px',
          }}
        >
          {icon && (
            <div
              className="cursor-pointer"
              onClick={closeHandler}
              style={{
                position: 'absolute',
                right: '12px',
                top: '5px',
                fontSize: '20px',
                zIndex: '9999',
              }}
            >
              <i className="ion-close" />
            </div>
          )}
          <div
            className="modal-body p-20 mt-3"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                alignItems: 'center',
                borderRadius: '50%',
                display: 'flex',
                fontSize: '20px',
                justifyContent: 'center',
                height: '40px',
                width: '40px',
                fontWeight: 100,
                backgroundColor: typeInfo[type].color,
                color: typeInfo[type].fontColor,
              }}
            >
              <i className={typeInfo[type].icon} />
            </span>
            <h6 className="modal-title text-center mt-3">{msg}</h6>
          </div>
          {closeButton && (
            <div className="modal-footer">
              <button className="btn btn-sm btn-inverse" onClick={closeHandler}>
                {label}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
