import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import Select from 'react-select';
import Calendar from 'react-calendar';
import CreatableSelect from 'react-select/creatable';
import useSWRImmutable from 'swr/immutable';

import SingleSelect from '../../../form/Select';
import Alert from '../../../layout/Alert';
import LoadingBox from '../../../layout/LoadingBox';
import { API_ENDPOINT, STORAGE_DOMAIN } from '../../../../config/api';
import 'react-circular-progressbar/dist/styles.css';
import '../OPSession.css';
import OPPreviewPrescriptionModal from '../OPPreviewPrescriptionModal';
import PrescriptionUpload from '../../../form/PrescriptionUpload';
import { GetBase64, DataURLtoBlob } from '../../../../_helpers/image_handler';
import {
  dosageList,
  durationList,
  bloodTypes,
} from '../../../../_helpers/constants';
import DoctorSelectionModal from '../DoctorSelectionModal';
import viewIcon from '../assets/viewIcon.svg';
import closeIcon from '../assets/Icon.Close.svg';
import transferIcon from '../assets/transfer.png';
import { getFetcher } from '../../../../_helpers/swr';
import PrintButton from '../../../common/PrintButton';
import { calculateAge } from '../../../../_helpers/misc';
import CasesheetDetails from '../../_partials/CasesheetDetails';

const _date = moment().format('YYYY-MM-DD');

const initVitalForm = () => ({
  vital_sign_id: null,
  bp_sys: '',
  bp_diastolic: '',
  bmi: '',
  height_cm: '',
  weight_kg: '',
  pulse_rate: '',
  allergies: '',
  habits: '',
  medical_conditions: '',
  insurance_provider: '',
  policy_number: '',
  policy_expiry_date: '',
  blood_group: '',
});
const initCaseSheetForm = (degreeName) => ({
  casesheet_id: null,
  pdf_id: '',
  prescription: degreeName === 'MBBS' || degreeName === 'BDS' ? {} : '',
  prescription_details: '',
  special_instruction: '',
  validity_in_days: '',
  attachment_id: '',
  attachment_url: '',
});

let doctor_info = {};
let provider_id = '';
let drSignImage = '';
let current_appointment_start_time;

const ConsultEdit = () => {
  const navigate = useNavigate();
  const { appointment_id } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);
  const [currentAppointment, setCurrentAppointment] = useState({
    appointment_id,
    resetPatKey: Math.random(),
    resetPaymentKey: Math.random(),
    resetCaseSheetKey: Math.random(),
  });
  const [patientVitals, setPatientVitals] = useState([]);
  const [patientCaseSheet, setPatientCaseSheet] = useState([]);
  const [patient_eRecords, setPatient_eRecords] = useState([]);
  const [vital_form_data, setVitalFormData] = useState(initVitalForm());
  const [casesheet_form_data, setCasesheetFormData] = useState(
    initCaseSheetForm(doctor_info?.degree_name)
  );
  const [schedule_info, setSchedule_info] = useState({});
  const [clinic_logo_url, setClinicLogoUrl] = useState('');
  const [provider_sign_url, setProviderSignUrl] = useState(drSignImage);
  const [vitalsEdited, setVitalsEdited] = useState(false);
  const [caseSheetEdited, setCaseSheetEdited] = useState(false);
  const [medList, setMedList] = useState([]);
  const [consultType, setConsultType] = useState('');
  const prescriptionTextRef = useRef(null);
  const detailsInputRef = useRef(null);
  const specialInstructionsRef = useRef(null);

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const vital_form_input_handler = ({ target: { name, value, type } }) => {
    vital_form_data[name] = type === 'number' ? parseFloat(value) : value;
    setVitalFormData(vital_form_data);
    const _patVitals = patientVitals?.length ? patientVitals[0] : {};
    patientVitals[0] = { ..._patVitals, ...vital_form_data };
    setPatientVitals(patientVitals);
    setVitalsEdited(true);
  };

  const casesheet_form_input_handler = ({ target: { name, value, type } }) => {
    const splited_name = name ? name.split('_') : undefined;
    if (splited_name && splited_name[0] === 'medObj') {
      const prescription_no = splited_name[1];
      const prescription_prop = splited_name[2];

      if (casesheet_form_data.prescription[prescription_no]) {
        if (value === null) {
          delete casesheet_form_data.prescription[prescription_no][
            prescription_prop
          ];
          if (
            Object.values(
              casesheet_form_data.prescription[prescription_no]
            ).every((e) => e === null)
          ) {
            delete casesheet_form_data.prescription[prescription_no];
          }
        } else {
          casesheet_form_data.prescription[prescription_no][prescription_prop] =
            value;
        }
      } else {
        // check casesheet_form_data.prescription is array
        if (typeof casesheet_form_data.prescription !== 'object') {
          casesheet_form_data.prescription = {};
        }
        casesheet_form_data.prescription[prescription_no] = {
          [prescription_prop]: value,
        };
      }
      const inputElements = document.getElementsByName(
        `medObj_${prescription_no}_${prescription_prop}`
      );
      if (inputElements && inputElements.length) {
        // inputElements[0].value = value;
        inputElements.forEach((element) => {
          element.value = value;
        });
      }
    } else {
      casesheet_form_data[name] = type === 'number' ? parseInt(value) : value;
    }
    setCasesheetFormData(casesheet_form_data);
    setCaseSheetEdited(true);
  };

  const resetCaseSheetForm = (casesheet, resetAll) => {
    if (casesheet.prescription && Object.keys(casesheet.prescription)?.length) {
      const prescriptionNumbers = Object.keys(casesheet.prescription);
      for (let i = 0; i < prescriptionNumbers?.length; i += 1) {
        const prescriptionNumber = prescriptionNumbers[i];
        const medInputs = document.getElementsByName(
          `medObj_${prescriptionNumber}_med`
        );
        const dosageInputs = document.getElementsByName(
          `medObj_${prescriptionNumber}_dosage`
        );
        const durationInputs = document.getElementsByName(
          `medObj_${prescriptionNumber}_duration`
        );
        if (medInputs && medInputs.length) {
          medInputs.forEach((element) => {
            element.value = '';
          });
        }
        if (dosageInputs && dosageInputs.length) {
          dosageInputs.forEach((element) => {
            element.value = '';
          });
        }
        if (durationInputs && durationInputs.length) {
          durationInputs.forEach((element) => {
            element.value = '';
          });
        }
      }
    }

    setVitalFormData({ ...initVitalForm() });

    if (resetAll) {
      setCasesheetFormData({
        ...initCaseSheetForm(doctor_info?.degree_name),
        ...{
          attachment_id: '',
          attachment_url: '',
          prescription_details: '',
          special_instruction: '',
        },
      });
    } else {
      setCasesheetFormData({ ...initCaseSheetForm(doctor_info?.degree_name) });
    }

    setTimeout(() => {
      setCurrentAppointment({
        ...currentAppointment,
        resetCaseSheetKey: Math.random(),
      });
    }, 100);
  };

  const uploadCasesheetAttachment = (files) => {
    if (!provider_id) return;
    const random_string = Math.random().toString(16).substring(2, 10);
    const file = files[0];
    const fileExtension = file.type === 'image/jpeg' ? 'jpg' : 'png';
    // To avoid overwriting files with same name, we are adding a random string to the file_name
    const fileNameWithExtension = `${
      file.name.split('.')[0]
    }_${random_string}.${fileExtension}`;
    GetBase64(files[0], 800, 800).then((imageDataUrl) => {
      const imageToUpload = DataURLtoBlob(imageDataUrl);
      const fd = new FormData();
      fd.append('file', imageToUpload, fileNameWithExtension);
      fd.append('upload_use_case', 'casesheet_attachment');
      fd.append('clinic_uid', schedule_info.clinic_no);
      fd.append('person_uin', currentAppointment.patient.person_no);
      Axios({
        data: fd,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'post',
        url: `${API_ENDPOINT}/File/auth_upload`,
      })
        .then(({ data: { info, file_id, file_url } }) => {
          if (file_id && file_url) {
            setCaseSheetEdited(true);
            setCasesheetFormData({
              ...casesheet_form_data,
              ...{ attachment_id: file_id, attachment_url: file_url },
            });
          }
          showAlert('Attachment uploaded successfully');
        })
        .catch((err) =>
          showAlert('Uploading failed please try again', 'error')
        );
    });
  };

  const removeCasesheetAttachment = () => {
    if (!provider_id) return;
    setCaseSheetEdited(true);
    setCasesheetFormData({
      ...casesheet_form_data,
      ...{ attachment_id: '', attachment_url: '' },
    });
    showAlert('Attachment removed successfully');
  };

  const uploadDoctorSignature = (files) => {
    if (!provider_id) return;
    const random_string = Math.random().toString(16).substring(2, 10);
    const file = files[0];
    const fileExtension = file.type === 'image/jpeg' ? 'jpg' : 'png';
    // To avoid overwriting files with same name, we are adding a random string to the file_name
    const fileNameWithExtension = `${
      file.name.split('.')[0]
    }_${random_string}.${fileExtension}`;
    const fd = new FormData();
    fd.append('file', file, fileNameWithExtension);
    fd.append('upload_use_case', 'dr_signature');
    fd.append('provider_id', provider_id);
    Axios({
      data: fd,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: `${API_ENDPOINT}/File/upload_signature`,
    })
      .then(({ data: { info, file_id, file_url } }) => {
        setProviderSignUrl(file_url ? STORAGE_DOMAIN + file_url : '');
        showAlert('Image uploaded successfully');
      })
      .catch((err) => showAlert('Uploading failed please try again', 'error'));
  };

  const saveConsultation = async (_action) => {
    const currentPatientData = currentAppointment.patient
      ? currentAppointment.patient
      : {};
    //   _action === 'absent' ||

    if (
      !currentAppointment ||
      !currentAppointment.appointment_id ||
      !currentPatientData.person_id
    ) {
      showAlert('Invalid patient profile loaded.');
      navigate('/');
      return;
    }

    const postData = {
      appointment_id: currentAppointment.appointment_id,
      op_id: 0,
      clinic_id: schedule_info.clinic_id,
      provider_id,
      patient_id: currentPatientData.person_id || 0,
      patient_uin: currentPatientData.person_no || 'default',
      token_no: 0,
      clinic_logo_url,
      dr_sign_url: provider_sign_url,
      consultation_date: moment().format('DD-MM-YYYY'),
      start_time: moment(current_appointment_start_time).format('HH:mm:ss'),
      end_time: moment().format('HH:mm:ss'),
      consultation_type: consultType,
      consultation_summary: '',
      dr_patient_info: {
        doctor_info,
        clinic_info: {
          clinic_uid: schedule_info.clinic_no,
          name: schedule_info.clinic_name,
          phone: schedule_info.clinic_phone_no,
          logo: schedule_info.clinic_logo,
          clinic_place: schedule_info.clinic_place,
          clinic_pin_code: schedule_info.clinic_pin_code,
          clinic_working_time: `${
            schedule_info.slot_start
              ? moment(`${_date} ${schedule_info.slot_start}`).format('hh:mm A')
              : '00:00 XX'
          }  ${
            schedule_info.slot_end
              ? ` to ${moment(`${_date} ${schedule_info.slot_end}`).format(
                  'hh:mm A'
                )}`
              : ' to 00:00 XX'
          }`,
          clinic_working_days: `${schedule_info.mon ? 'M ' : '_ '}
      				${schedule_info.tue ? 'T ' : '_ '}${schedule_info.wed ? 'W ' : '_ '}
      				${schedule_info.thu ? 'T ' : '_ '}${schedule_info.fri ? 'F ' : '_ '}
      				${schedule_info.sat ? 'S ' : '_ '}${schedule_info.sun ? 'S ' : '_ '}`,
        },
        patient_info: {
          uin: currentPatientData.person_no,
          name: currentPatientData.name,
          phone: currentPatientData.phone,
          gender: currentPatientData.gender,
          age: currentPatientData.age,
          place: currentPatientData.place,
        },
      },
      patient_absent: _action === 'absent',
    };
    if (vitalsEdited && _action !== 'absent')
      postData.patient_vitals = vital_form_data;
    if (caseSheetEdited && _action !== 'absent')
      postData.casesheet = casesheet_form_data;
    setShowLoading(true);
    Axios.post(
      `${API_ENDPOINT}/ProviderPatientConsultation/save_consultation`,
      postData
    )
      .then(({ data: resData }) => {
        showAlert('Consutation saved successfully', 'success');
        current_appointment_start_time = Date.now();
        //   setCasesheetFormData(initCaseSheetForm());

        if (
          casesheet_form_data.attachment_id ||
          casesheet_form_data.attachment_url ||
          casesheet_form_data.prescription_details ||
          casesheet_form_data.special_instruction
        ) {
          setCasesheetFormData({
            ...casesheet_form_data,
            ...{
              attachment_id: '',
              attachment_url: '',
              prescription_details: '',
              special_instruction: '',
            },
          });
        }

        // CLEAR case sheet exra and special details
        const extraDetailsInput = document.getElementById(
          'casesheet-extra-details'
        );
        if (extraDetailsInput) extraDetailsInput.value = '';
        const specialInstructionsInput = document.getElementById(
          'casesheet-special-instructions'
        );
        if (specialInstructionsInput) specialInstructionsInput.value = '';
        if (detailsInputRef?.current) {
          detailsInputRef.current.value = '';
          specialInstructionsRef.current.value = '';
        }
        setTimeout(() => {
          navigate('/consult/recentconsultations');
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        showAlert('Saving consultation failed', 'error');
      })
      .finally(() => setShowLoading(false));
  };

  const getConsultDetails = () => {
    setShowLoading(true);
    Axios.get(
      `${API_ENDPOINT}/ProviderPatientConsultation/get_consult_details?appointment_id=${appointment_id}`
    )
      .then(({ data: resData }) => {
        const _patient = resData.patient?.[0] || {};
        const _vitals = resData.vitals || [];
        const _case_sheets = resData.case_sheets || [];
        const _schedule = resData.schedule_info?.[0] || {};
        setSchedule_info(_schedule);
        setPatientVitals(_vitals || []);
        setPatientCaseSheet(_case_sheets || []);
        setPatient_eRecords(resData.e_records || []);
        setConsultType(resData.consultation_type || '');
        const medicines = resData.medicines || [];
        const doctorData = resData.doctor_info?.[0] || {};
        setCurrentAppointment({
          ...currentAppointment,
          patient: _patient,
          resetPatKey: Math.random(),
          resetPaymentKey: Math.random(),
          resetCaseSheetKey: Math.random(),
        });
        setMedList(
          medicines?.map((row) => ({
            ...row,
            label: `${row.medicine_name} ${row.medicine_unit || ''}`,
            value: `${row.medicine_name} ${row.medicine_unit || ''}`,
          }))
        );

        provider_id = doctorData.provider_id;
        drSignImage = doctorData.sign_image
          ? STORAGE_DOMAIN + doctorData.sign_image
          : '';
        doctor_info = {
          upn: doctorData.provider_no,
          name: `${doctorData.first_name} ${doctorData.last_name}`,
          speciality: doctorData.speciality,
          dr_reg_no: doctorData.reg_no,
          degree_name: doctorData.degree_name,
          degree_id: doctorData.degree_id,
          provider_id: doctorData.provider_id,
          add_qualification: doctorData.add_qualification,
        };
        setProviderSignUrl(drSignImage);
        setClinicLogoUrl(
          _schedule.clinic_logo ? STORAGE_DOMAIN + _schedule.clinic_logo : ''
        );

        if (
          _vitals[0] &&
          typeof (_vitals[0] === 'object') &&
          Object.keys(_vitals[0])?.length
        ) {
          Object.keys(_vitals[0]).forEach((vital_prop) => {
            vital_form_data[vital_prop] = _vitals[0][vital_prop];
            setVitalFormData(vital_form_data);
          });
        }
        // Pre loading the latest casesheet
        if (
          _case_sheets[0] &&
          typeof (_case_sheets[0] === 'object') &&
          Object.keys(_case_sheets[0])?.length
        ) {
          Object.keys(_case_sheets[0]).forEach((casesheet_prop) => {
            if (
              casesheet_prop === 'attachment_url' &&
              _case_sheets[0][casesheet_prop]
            ) {
              _case_sheets[0][casesheet_prop] =
                STORAGE_DOMAIN + _case_sheets[0][casesheet_prop];
            }
            casesheet_form_data[casesheet_prop] =
              _case_sheets[0][casesheet_prop];
            setCasesheetFormData(casesheet_form_data);
          });
        }
      })
      .catch((err) => showAlert('Loading Casesheet failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const deleteERecord = (prescripton) => {
    if (
      !prescripton ||
      !prescripton.e_record_id ||
      !currentAppointment.patient.person_id
    )
      return;
    setShowLoading(true);
    Axios.put(`${API_ENDPOINT}/eRecord/web_admin/update_e_record`, {
      ...prescripton,
      person_id: currentAppointment.patient.person_id,
      delete_ind: true,
    })
      .then(({ data: { info } }) => {
        showAlert(info);
        getConsultDetails();
      })
      .catch((err) => showAlert('Submit failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    getConsultDetails();
  }, []);

  return (
    <div>
      <ConsultEditComponent
        currentAppointment={currentAppointment || {}}
        schedule_info={schedule_info || {}}
        patientVitals={patientVitals || []}
        patientCaseSheet={patientCaseSheet || []}
        procedures_list={[]} // procedures_list
        procedure_time_change_handler={() => {
          console.log('procedure_time_change_handler');
        }}
        vital_form_input_handler={vital_form_input_handler}
        casesheet_form_input_handler={casesheet_form_input_handler}
        casesheet_form_data={casesheet_form_data}
        patient_eRecords={patient_eRecords}
        deleteERecord={deleteERecord}
        provider_sign_url={provider_sign_url}
        uploadDoctorSignature={uploadDoctorSignature}
        uploadCasesheetAttachment={uploadCasesheetAttachment}
        removeCasesheetAttachment={removeCasesheetAttachment}
        medList={medList}
        prescriptionTextRef={prescriptionTextRef}
        detailsInputRef={detailsInputRef}
        specialInstructionsRef={specialInstructionsRef}
        getConsultDetails={getConsultDetails}
        navigate={navigate}
        saveConsultation={saveConsultation}
        resetCaseSheetForm={resetCaseSheetForm}
        consultType={consultType}
      />
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </div>
  );
};

export default ConsultEdit;

const ConsultEditComponent = ({
  currentAppointment,
  schedule_info,
  patientVitals,
  patientCaseSheet,
  procedures_list,
  procedure_time_change_handler,
  vital_form_input_handler,
  casesheet_form_input_handler,
  casesheet_form_data,
  patient_eRecords,
  deleteERecord,
  provider_sign_url,
  uploadDoctorSignature,
  uploadCasesheetAttachment,
  removeCasesheetAttachment,
  medList,
  prescriptionTextRef,
  detailsInputRef,
  specialInstructionsRef,
  getConsultDetails,
  navigate,
  saveConsultation,
  resetCaseSheetForm,
  consultType,
}) => {
  const [isOPPreviewPrescriptionModal, setIsOPPreviewPrescriptionModal] =
    useState(false);
  useEffect(() => {
    setIsOPPreviewPrescriptionModal(false);
  }, []);
  return (
    <>
      <div className="d-flex align-items-center my-3">
        <button
          className="btn ml-2 px-3"
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/consult/recentconsultations')}
        >
          Cancel
        </button>
        <button
          className="btn btn-info mx-5 px-3"
          onClick={() => {
            saveConsultation?.('absent');
          }}
        >
          Absent
        </button>
        <button
          className="btn btn-success px-4"
          onClick={() => {
            saveConsultation?.();
          }}
        >
          Save
        </button>
      </div>
      <div className="op-session-wrapper">
        <div
          className="op-session-right-section"
          // style={is_op_paused ? { opacity: '0.5', cursor: 'not-allowed' } : {}}
        >
          <div className="op-session-right-inner-section consult-edit-section">
            <div className="op-session-right-inner-left-section">
              <CaseSheet
                key={currentAppointment.resetCaseSheetKey}
                patientCaseSheet={patientCaseSheet}
                schedule_info={schedule_info || {}}
                patient_info={currentAppointment.patient || {}}
                casesheet_form_input_handler={casesheet_form_input_handler}
                setIsOPPreviewPrescriptionModal={
                  setIsOPPreviewPrescriptionModal
                }
                casesheet_form_data={casesheet_form_data}
                provider_sign_url={provider_sign_url}
                uploadDoctorSignature={uploadDoctorSignature}
                uploadCasesheetAttachment={uploadCasesheetAttachment}
                removeCasesheetAttachment={removeCasesheetAttachment}
                medList={medList}
                prescriptionTextRef={prescriptionTextRef}
                detailsInputRef={detailsInputRef}
                specialInstructionsRef={specialInstructionsRef}
                resetCaseSheetForm={resetCaseSheetForm}
                appointment_id={currentAppointment.appointment_id}
                consultType={consultType}
              />
            </div>
            <div className="op-session-right-inner-right-section">
              <PatientDetailedInfo
                refreshKey={currentAppointment.resetCaseSheetKey}
                patientVitals={patientVitals}
                vital_form_input_handler={vital_form_input_handler}
                patient_eRecords={patient_eRecords}
                deleteERecord={deleteERecord}
                patient_id={
                  currentAppointment.patient
                    ? currentAppointment.patient.person_id
                    : null
                }
                getConsultDetails={getConsultDetails}
              />
            </div>
          </div>
        </div>
        {isOPPreviewPrescriptionModal && (
          <OPPreviewPrescriptionModal
            setIsOPPreviewPrescriptionModal={setIsOPPreviewPrescriptionModal}
            casesheet_form_data={casesheet_form_data}
            casesheet_form_input_handler={casesheet_form_input_handler}
          />
        )}
      </div>
    </>
  );
};

const PatientDetailedInfo = ({
  refreshKey,
  patientVitals,
  vital_form_input_handler,
  patient_eRecords,
  deleteERecord,
  patient_id,
  getConsultDetails,
  is_op_paused,
}) => {
  const [view, setView] = useState('personal');
  const pat_vital_info = patientVitals?.length ? patientVitals[0] : {};
  Object.keys(pat_vital_info).forEach((vitalKey) => {
    pat_vital_info[vitalKey] =
      pat_vital_info[vitalKey] === 'NULL' ? '' : pat_vital_info[vitalKey];
  });

  return (
    <div className="op-session-details-chooser-container">
      <div className="op-session-details-chooser">
        <p className="op-session-details-chooser-title">EHR</p>

        <div className="op-session-details-chooser-selector">
          <p
            className={
              view === 'personal' ? 'op-session-details-chooser-active' : ''
            }
            onClick={() => {
              setView('personal');
            }}
          >
            Vitals
          </p>
          <p
            className={
              view === 'e-record' ? 'op-session-details-chooser-active' : ''
            }
            onClick={() => {
              !is_op_paused && setView('e-record');
            }}
            style={{
              color:
                patient_eRecords && patient_eRecords.length ? 'initial' : 'red',
            }}
          >
            e-Record
          </p>
        </div>
      </div>

      {view === 'personal' ? (
        <PersonalDetailsTab
          key={pat_vital_info.vital_time || refreshKey}
          pat_vital_info={pat_vital_info}
          vital_form_input_handler={vital_form_input_handler}
          is_op_paused={is_op_paused}
        />
      ) : (
        ''
      )}
      {view === 'e-record' ? (
        <ErecordTab
          key={refreshKey}
          patient_eRecords={patient_eRecords}
          deleteERecord={deleteERecord}
          patient_id={patient_id}
          getConsultDetails={getConsultDetails}
        />
      ) : (
        ''
      )}
    </div>
  );
};

const PersonalDetailsTab = ({
  pat_vital_info,
  vital_form_input_handler,
  is_op_paused,
}) => {
  const [bmiElem, setBmiElem] = useState('');
  const [patHeight, setPatHeight] = useState(pat_vital_info?.height_cm || '');
  const [patWeight, setPatWeight] = useState(pat_vital_info?.weight_kg || '');

  const calcBmi = (inputName, value) => {
    if (inputName && inputName === 'height_cm') setPatHeight(value);
    if (inputName && inputName === 'weight_kg') setPatWeight(value);

    const height_in_cm = inputName === 'height_cm' ? value : patHeight;
    const wheight_in_kg = inputName === 'weight_kg' ? value : patWeight;
    if (height_in_cm && wheight_in_kg) {
      const height_in_meters =
        height_in_cm && height_in_cm > 0 ? height_in_cm / 100 : 0;
      const bmi = wheight_in_kg
        ? wheight_in_kg / (height_in_meters * height_in_meters)
        : null;
      if (bmi < 18.5) {
        setBmiElem(
          <span className="personal-details-form-bpm-under_weight">
            Under Weight
          </span>
        );
      } else if (bmi >= 18.5 && bmi < 25.0) {
        setBmiElem(
          <span className="personal-details-form-bpm-ideal">Ideal</span>
        );
      } else if (bmi >= 25.0 && bmi < 30.0) {
        setBmiElem(
          <span className="personal-details-form-bpm-over_weight">
            Over Weight
          </span>
        );
      } else if (bmi >= 30.0) {
        setBmiElem(
          <span className="personal-details-form-bpm-obese">Obese</span>
        );
      } else {
        setBmiElem('');
      }
    }
  };

  useEffect(() => {
    if (patHeight && patWeight) {
      calcBmi();
    }
  }, []);

  return (
    <div>
      <div className="personal-details-form">
        <div className="personal-details-form-field">
          <p>Ht</p>
          <input
            type="number"
            name="height_cm"
            defaultValue={pat_vital_info?.height_cm}
            onChange={({ target: { name, value, type } }) => {
              vital_form_input_handler({ target: { name, value, type } });
              calcBmi(name, value);
            }}
            disabled={is_op_paused}
          />
          <p className="personal-details-form-units">CM</p>
          <p className="personal-details-form-dates">
            {pat_vital_info?.height_cm && pat_vital_info?.vital_time
              ? moment(pat_vital_info?.vital_time).format('DD-MM-YYYY')
              : ''}
          </p>
        </div>
        <div className="personal-details-form-field">
          <p>Wt</p>
          <input
            type="number"
            name="weight_kg"
            defaultValue={pat_vital_info?.weight_kg}
            onChange={({ target: { name, value, type } }) => {
              vital_form_input_handler({ target: { name, value, type } });
              calcBmi(name, value);
            }}
            disabled={is_op_paused}
          />
          <p className="personal-details-form-units">KG</p>
          <p className="personal-details-form-dates">
            {pat_vital_info?.weight_kg && pat_vital_info?.vital_time
              ? moment(pat_vital_info?.vital_time).format('DD-MM-YYYY')
              : ''}
          </p>
        </div>
        <div className="personal-details-form-field">
          <p>BG</p>
          <select
            name="blood_group"
            defaultValue={pat_vital_info?.blood_group || ''}
            onBlur={vital_form_input_handler}
            disabled={is_op_paused}
          >
            <option value="" className="text-muted">
              Select
            </option>
            {bloodTypes.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <p className="personal-details-form-dates">
            {pat_vital_info?.blood_group && pat_vital_info?.vital_time
              ? moment(pat_vital_info?.vital_time).format('DD-MM-YYYY')
              : ''}
          </p>
        </div>
        <div className="personal-details-form-field">
          <p>PR</p>
          <input
            type="number"
            name="pulse_rate"
            defaultValue={pat_vital_info?.pulse_rate}
            onBlur={vital_form_input_handler}
            disabled={is_op_paused}
          />
          <p className="personal-details-form-units">BPM</p>
          <p className="personal-details-form-dates">
            {pat_vital_info?.pulse_rate && pat_vital_info?.vital_time
              ? moment(pat_vital_info?.vital_time).format('DD-MM-YYYY')
              : ''}
          </p>
        </div>
        <div className="personal-details-form-field">
          <p>S-BP:</p>
          <input
            type="number"
            name="bp_sys"
            defaultValue={pat_vital_info?.bp_sys}
            onBlur={vital_form_input_handler}
            disabled={is_op_paused}
          />
          <p className="personal-details-form-units">mmHg</p>
          <p className="personal-details-form-dates">
            {pat_vital_info?.bp_sys && pat_vital_info?.vital_time
              ? moment(pat_vital_info?.vital_time).format('DD-MM-YYYY')
              : ''}
          </p>
        </div>
        <div className="personal-details-form-field">
          <p>D-BP:</p>
          <input
            type="number"
            name="bp_diastolic"
            defaultValue={pat_vital_info?.bp_diastolic}
            onBlur={vital_form_input_handler}
            disabled={is_op_paused}
          />
          <p className="personal-details-form-units">mmHg</p>
          <p className="personal-details-form-dates">
            {pat_vital_info?.bp_diastolic && pat_vital_info?.vital_time
              ? moment(pat_vital_info?.vital_time).format('DD-MM-YYYY')
              : ''}
          </p>
        </div>
        <div className="personal-details-form-bpm">BMI: {bmiElem}</div>
      </div>
      <div className="basic-medical-deatails-container">
        <input
          type="text"
          placeholder="Allergies"
          name="allergies"
          defaultValue={
            !pat_vital_info?.allergies || pat_vital_info?.allergies === 'NULL'
              ? ''
              : pat_vital_info?.allergies
          }
          onBlur={vital_form_input_handler}
          disabled={is_op_paused}
        />
        <input
          type="text"
          placeholder="Medical Conditions"
          name="medical_conditions"
          defaultValue={
            !pat_vital_info?.medical_conditions ||
            pat_vital_info?.medical_conditions === 'NULL'
              ? ''
              : pat_vital_info?.medical_conditions
          }
          onBlur={vital_form_input_handler}
          disabled={is_op_paused}
        />
        <input
          type="text"
          placeholder="habits"
          name="habits"
          defaultValue={
            !pat_vital_info?.habits || pat_vital_info?.habits === 'NULL'
              ? ''
              : pat_vital_info?.habits
          }
          onBlur={vital_form_input_handler}
          disabled={is_op_paused}
        />
      </div>
      <div className="insurance-deatails-container">
        <input
          type="text"
          placeholder="Insurance Provider"
          name="insurance_provider"
          defaultValue={
            !pat_vital_info?.insurance_provider ||
            pat_vital_info?.insurance_provider === 'NULL'
              ? ''
              : pat_vital_info?.insurance_provider
          }
          onBlur={vital_form_input_handler}
          disabled={is_op_paused}
        />
        <input
          type="text"
          placeholder="Policy Number"
          name="policy_number"
          defaultValue={
            !pat_vital_info?.policy_number ||
            pat_vital_info?.policy_number === 'NULL'
              ? ''
              : pat_vital_info?.policy_number
          }
          onBlur={vital_form_input_handler}
          disabled={is_op_paused}
        />
        <input
          type="date"
          placeholder="Policy Expiry Date"
          name="policy_expiry_date"
          defaultValue={
            !pat_vital_info?.policy_expiry_date ||
            pat_vital_info?.policy_expiry_date === 'NULL'
              ? ''
              : moment(pat_vital_info?.policy_expiry_date).format('YYYY-MM-DD')
          }
          onBlur={vital_form_input_handler}
          disabled={is_op_paused}
        />
      </div>
    </div>
  );
};

const ErecordTab = ({
  patient_eRecords,
  deleteERecord,
  patient_id,
  getConsultDetails,
}) => {
  const showPrescrionUploader = () => {
    window.$('#eRecord-modal').modal('show');
  };

  return (
    <div className="e-record-container">
      {patient_eRecords && patient_eRecords.length
        ? patient_eRecords.map((record, idx) => (
            <div className="e-record-item" key={idx}>
              <div>
                <p>{record.e_record_date}</p>
                <p>{record.title}</p>
              </div>
              <div>
                <a
                  href={STORAGE_DOMAIN + record.file_location}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={viewIcon} alt="View" />
                </a>
                <a onClick={() => deleteERecord(record)}>
                  <img src={closeIcon} alt="ownload" />
                </a>
              </div>
            </div>
          ))
        : ''}
      <div
        className="fab"
        title="Add Prescription"
        style={{ position: 'relative', top: '15px', left: '15px' }}
      >
        <span className="btn btn-icon btn-fab__outline">
          <i className="ion-plus-round" onClick={showPrescrionUploader} />
        </span>
      </div>
      <PrescriptionUpload
        patient_id={patient_id}
        refreshData={() => getConsultDetails()}
        key={(patient_eRecords?.length || 0) + 1}
      />
    </div>
  );
};

const CaseSheet = ({
  patientCaseSheet,
  schedule_info,
  patient_info,
  casesheet_form_input_handler,
  setIsOPPreviewPrescriptionModal,
  casesheet_form_data,
  provider_sign_url,
  uploadDoctorSignature,
  uploadCasesheetAttachment,
  removeCasesheetAttachment,
  medList,
  is_op_paused,
  prescriptionTextRef,
  detailsInputRef,
  specialInstructionsRef,
  resetCaseSheetForm,
  appointment_id,

  consultType,
}) => {
  const customMedNameRef = useRef();
  const customDosageRef = useRef();
  const customDurationRef = useRef();
  const caseSheet_info = patientCaseSheet?.length ? patientCaseSheet[0] : {};
  const [activePrescriptionTab, setActivePrescriptionTab] = useState(1);
  const [prescription, setPrescription] = useState(
    casesheet_form_data.prescription || {}
  );
  const [medicineList, setMedicineList] = useState(medList);
  const [showNextApmntCalendar, setShowNextApmntCalendar] = useState(false);
  const [selectedNextApmntDate, setSelectedNextApmntDate] = useState(undefined);

  const nextApmntDateChangeHandler = (date) => {
    if (date && date !== selectedNextApmntDate) {
      setShowNextApmntCalendar(false);
      setSelectedNextApmntDate(date);
    }
  };

  const addToSpecialIinstructions = (appendText) => {
    const _instruction = casesheet_form_data?.special_instruction?.length
      ? `${casesheet_form_data?.special_instruction}, ${appendText}`
      : appendText;
    casesheet_form_data.special_instruction = _instruction;
    if (specialInstructionsRef?.current) {
      specialInstructionsRef.current.value = _instruction;
    }
    casesheet_form_input_handler({
      target: {
        name: 'special_instruction',
        value: _instruction,
        type: 'text',
      },
    });
    setSelectedNextApmntDate(undefined);
  };

  useEffect(() => {
    if (selectedNextApmntDate) {
      const nxtAppointmentInfo = `Next appointment on ${moment(
        selectedNextApmntDate
      ).format('DD/MM/YYYY')}`;
      addToSpecialIinstructions(nxtAppointmentInfo);
    }
  }, [selectedNextApmntDate]);

  useEffect(() => {
    setPrescription(casesheet_form_data.prescription || {});
    const prescribedMeds = Object.values(
      casesheet_form_data?.prescription
    )?.map((m) => m.med);
    if (prescribedMeds?.length) {
      setMedicineList(
        medList?.filter((m) => !prescribedMeds?.includes(m?.label))
      );
    }
  }, [casesheet_form_data.prescription]);

  const { data: dosages, mutate: mutateDosages } = useSWRImmutable(
    `${API_ENDPOINT}/provider/get-dosages`,
    getFetcher
  );
  const { data: duration, mutate: mutateDuration } = useSWRImmutable(
    `${API_ENDPOINT}/provider/get-durations`,
    getFetcher
  );
  const { data: medicinesList, mutate: medicineMutate } = useSWRImmutable(
    `${API_ENDPOINT}/Medicine/?degree_id=${doctor_info.degree_id}`,
    getFetcher
  );

  const updateMedObj = (value, idx1, idx2, type) => {
    casesheet_form_input_handler({
      target: {
        name: `medObj_${(idx1 + 1) * 3 - 3 + (idx2 + 1)}_${type}`,
        value,
        type: 'text',
      },
    });
  };

  return (
    <div className="case-sheet-container casesheet-edit-wrapper">
      <div className="case-sheet-header">
        <div>
          <p>Case Sheet Edit</p>
        </div>
        <div>
          {/* <button>Upload</button> */}
          <div
            className="file-upload btn"
            style={{
              padding: '1px 8px',
              background: '#343C44',
              color: '#fff',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          >
            Upload
            <input
              type="file"
              name="file"
              accept="image/x-png,image/jpeg,application/pdf"
              multiple={false}
              disabled={is_op_paused || casesheet_form_data?.attachment_url}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(event) => {
                uploadCasesheetAttachment(event.target.files);
              }}
            />
          </div>
          {/* <button>Writing Pad</button>
          <button>Email</button>
          <button>Sent to Pharmacy</button> */}
        </div>
      </div>
      <div className="clinic-doctor-details-container">
        <div className="clinic-doctor-details">
          <p>{doctor_info.name}</p>
          <p>{doctor_info.speciality} Specialist</p>
          <p className="clinic-name">{schedule_info.clinic_name}</p>
        </div>
        <div className="d-flex align-items-center">
          <img
            className="op-session-patient-info-item-icon"
            src={transferIcon}
            alt=""
            width={30}
            height={30}
            onClick={() => window.$('#dr-selection-modal')?.modal('show')}
          />
          {/* <img src={printIcon} alt="print" />
          <img src={downloadIcon} alt="download" /> */}
          <div className="popup-calendar-wrapper">
            <button
              type="button"
              onClick={() => setShowNextApmntCalendar(true)}
              className="btn pl-0 pr-3 py-1 no-border"
            >
              <img
                src="/img/icons/calendar-icon.png"
                alt="Add next appointment date"
                style={{ width: '30px', height: '30px' }}
              />
            </button>
            <Calendar
              minDate={new Date()}
              className={showNextApmntCalendar ? '' : 'hide'}
              value={
                typeof selectedNextApmntDate === 'string'
                  ? new Date(selectedNextApmntDate)
                  : selectedNextApmntDate
              }
              onChange={(date) =>
                nextApmntDateChangeHandler(moment(date).format('YYYY-MM-DD'))
              }
              tileClassName={(view) =>
                view === 'month' ? 'hex-date-blocked' : ''
              }
            />
          </div>
        </div>
        <DoctorSelectionModal
          clinic_id={schedule_info?.clinic_id}
          onClose={(selectedDrObj) => {
            window.$('#dr-selection-modal')?.modal('hide');
            const transferInstruction = `Transfer to ${selectedDrObj?.name}`;
            addToSpecialIinstructions(transferInstruction);
          }}
        />
      </div>
      <div className="prescription-container">
        <div className="prescription-patient-details mx-2">
          <div>
            <p>{patient_info.name}</p>
            <p>{patient_info.gender}</p>
            <p>
              {' '}
              {patient_info.date_of_birth
                ? calculateAge(patient_info?.date_of_birth)
                : patient_info.age}
            </p>
          </div>
          <div>
            {patient_info.visit_status ? (
              <div className="new-patient-status-box text-capitalize">
                {patient_info.visit_status}
              </div>
            ) : null}
            {patient_info.is_free_visit ? (
              <div className="new-patient-status-box text-capitalize">
                Re-Visit
              </div>
            ) : null}
            <PrintButton
              schedule_id={schedule_info.schedule_id}
              appointmentId={appointment_id}
              info={{ patient:patient_info, doctor_info, schedule_info }}
              casesheet={casesheet_form_data}
              provider_sign_url={provider_sign_url}
              //   selectedProcedures={selectedProcedures}
              isWalkin={consultType === 'walkin'}
            />
            {/* <DownloadButton
              onClick={() =>
                generatePrescriptionSheet(
                  appointment_id,
                  row.proc_data,
                  row.appointment_date,
                  row.consult_end_time
                )
              }
              appointmentId={appointment_id}
            /> */}
          </div>
        </div>
        {doctor_info?.degree_name === 'MBBS' ||
        doctor_info?.degree_name === 'BDS' ? (
          <div className="op-session-prescription">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <span className="op-session-prescription-title ">
                Prescription
              </span>{' '}
              <button
                className="btn align-self-end py-1"
                onClick={() => resetCaseSheetForm?.(casesheet_form_data)}
              >
                Clear
              </button>
            </div>
            <div className="op-session-prescription-form">
              {[...Array(5)].map((key, idx1) => (
                <div
                  key={idx1 + 1}
                  style={{
                    display:
                      activePrescriptionTab === idx1 + 1 ? 'block' : 'none',
                  }}
                >
                  {[...Array(3)].map((key, idx2) => (
                    <div className="medRow" key={idx2}>
                      <CreatableSelect
                        ref={customMedNameRef}
                        isClearable
                        placeholder="Drug Name"
                        name={`medObj_${(idx1 + 1) * 3 - 3 + (idx2 + 1)}_med`}
                        defaultValue={() => {
                          const _med =
                            prescription?.[`${(idx1 + 1) * 3 - 3 + (idx2 + 1)}`]
                              ?.med;
                          return _med
                            ? { name: _med, label: _med, value: _med }
                            : undefined;
                        }}
                        onChange={(newData, actionMeta) => {
                          if (actionMeta.action === 'clear') {
                            customMedNameRef?.current?.select?.select?.clearValue();
                            updateMedObj(null, idx1, idx2, 'med');
                          } else {
                            const value =
                              actionMeta.action === 'create-option'
                                ? newData.label
                                : `${newData.label} ${
                                    newData.medicine_unit || ''
                                  }`;
                            updateMedObj(value, idx1, idx2, 'med');
                            if (actionMeta.action === 'create-option') {
                              Axios.post(
                                `${API_ENDPOINT}/provider/new-medicine`,
                                {
                                  medicine_name: newData.label,
                                  user_no: doctor_info.upn,
                                  degree_id: doctor_info.degree_id,
                                }
                              ).then(() => medicineMutate());
                            }
                          }
                        }}
                        options={medicinesList?.map((item) => ({
                          label: item.medicine_name,
                          value: item.medicine_id,
                          medicine_note: item.medicine_note,
                          medicine_unit: item.medicine_unit,
                        }))}
                        className="basic-multi-select text-left"
                        classNamePrefix="react-select"
                        id="react-select2"
                        isDisabled={!patient_info?.person_no}
                      />
                      <CreatableSelect
                        ref={customDosageRef}
                        isClearable
                        name={`medObj_${
                          (idx1 + 1) * 3 - 3 + (idx2 + 1)
                        }_dosage`}
                        placeholder="Dosage"
                        defaultValue={() => {
                          const _dosage =
                            prescription?.[`${(idx1 + 1) * 3 - 3 + (idx2 + 1)}`]
                              ?.dosage;
                          return _dosage
                            ? { name: _dosage, label: _dosage, value: _dosage }
                            : undefined;
                        }}
                        optionStyle={{ textTransform: 'none' }}
                        onChange={(newData, actionMeta) => {
                          if (actionMeta.action === 'clear') {
                            customDosageRef?.current?.select?.select?.clearValue();
                            updateMedObj(null, idx1, idx2, 'dosage');
                          } else {
                            const value =
                              actionMeta.action === 'create-option'
                                ? newData.label
                                : newData.value;

                            updateMedObj(value, idx1, idx2, 'dosage');
                            if (actionMeta.action === 'create-option') {
                              Axios.post(
                                `${API_ENDPOINT}/provider/new-dosage`,
                                {
                                  name: newData.label,
                                  user_no: doctor_info.upn,
                                }
                              ).then((e) => mutateDosages());
                            }
                          }
                        }}
                        options={dosages?.map((d) => ({
                          label: d.name,
                          value: d.name,
                          name: d.name,
                          id: d.id,
                        }))}
                        isDisabled={!patient_info?.person_no}
                        className="basic-multi-select text-left op-dosage-input"
                        classNamePrefix="react-select"
                        id="react-select2"
                      />
                      <CreatableSelect
                        ref={customDurationRef}
                        isClearable
                        name={`medObj_${
                          (idx1 + 1) * 3 - 3 + (idx2 + 1)
                        }_duration`}
                        placeholder="Duration"
                        defaultValue={() => {
                          const _duration =
                            prescription?.[`${(idx1 + 1) * 3 - 3 + (idx2 + 1)}`]
                              ?.dosage;
                          return _duration
                            ? {
                                name: _duration,
                                label: _duration,
                                value: _duration,
                              }
                            : undefined;
                        }}
                        optionStyle={{ textTransform: 'none' }}
                        onChange={(newData, actionMeta) => {
                          if (actionMeta.action === 'clear') {
                            customDurationRef?.current?.select?.select?.clearValue();
                            updateMedObj(null, idx1, idx2, 'duration');
                          } else {
                            const value =
                              actionMeta.action === 'create-option'
                                ? newData.label
                                : newData.value;

                            updateMedObj(value, idx1, idx2, 'duration');

                            if (actionMeta.action === 'create-option') {
                              Axios.post(
                                `${API_ENDPOINT}/provider/new-duration`,
                                {
                                  name: newData.label,
                                  user_no: doctor_info.upn,
                                }
                              ).then((e) => mutateDuration());
                            }
                          }
                        }}
                        options={duration?.map((d) => ({
                          label: d.name,
                          value: d.name,
                          name: d.name,
                          id: d.id,
                        }))}
                        isDisabled={!patient_info?.person_no}
                        className="basic-multi-select text-left op-dosage-input"
                        classNamePrefix="react-select"
                        id="react-select2"
                      />
                    </div>
                  ))}
                </div>
              ))}
              <div className="op-session-wizard-container">
                <div>
                  <button
                    onClick={() => {
                      setIsOPPreviewPrescriptionModal(true);
                    }}
                    disabled={is_op_paused}
                  >
                    Preview
                  </button>
                  {/* <button className="op-session-wizard-bulid-button">
									Build
								</button> */}
                </div>
                <div>
                  <button
                    onClick={() =>
                      setActivePrescriptionTab(
                        activePrescriptionTab > 1
                          ? activePrescriptionTab - 1
                          : 1
                      )
                    }
                    disabled={is_op_paused}
                  >
                    Previous
                  </button>
                  {[...Array(5)].map((item, index) => (
                    <button
                      key={index}
                      className={
                        activePrescriptionTab === index + 1
                          ? 'op-session-wizard-blue-button'
                          : ''
                      }
                      onClick={() => setActivePrescriptionTab(index + 1)}
                      disabled={is_op_paused}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() =>
                      setActivePrescriptionTab(
                        activePrescriptionTab < 5
                          ? activePrescriptionTab + 1
                          : 5
                      )
                    }
                    className="op-session-wizard-blue-button"
                    disabled={is_op_paused}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="op-session-prescription-extra-details">
            <div className="px-2 w-100 d-flex align-items-center justify-content-between mb-1">
              <h5>Prescription</h5>
              <button
                className="btn align-self-end py-1"
                onClick={() => resetCaseSheetForm?.(casesheet_form_data)}
              >
                Clear
              </button>
            </div>
            <textarea
              className="op-session-prescription-extra-details-textarea"
              id="casesheet-extra-details"
              cols="30"
              rows="10"
              name="prescription"
              ref={prescriptionTextRef}
              defaultValue={
                typeof casesheet_form_data.prescription === 'string'
                  ? casesheet_form_data.prescription
                  : ''
              }
              onChange={casesheet_form_input_handler}
              disabled={is_op_paused}
            />
          </div>
        )}

        <CasesheetDetails
          value={casesheet_form_data.prescription_details || ''}
          handleChange={casesheet_form_input_handler}
          disabled={is_op_paused}
        />

        <div className="op-session-prescription-extra-details">
          <h5>Instructions</h5>
          <textarea
            className="op-session-special-instruction"
            id="casesheet-special-instructions"
            cols="30"
            rows="10"
            name="special_instruction"
            ref={specialInstructionsRef}
            defaultValue={casesheet_form_data.special_instruction || ''}
            onChange={casesheet_form_input_handler}
            disabled={is_op_paused}
          />
          <div className="op-sesssion-signature">
            {provider_sign_url ? (
              <img src={provider_sign_url} alt="Signature" />
            ) : (
              ''
            )}
            {/* <p className="op-sesssion-signature-name">( {doctor_info.name} )</p> */}
            <div
              className="file-upload btn btn-primary btn-sm"
              style={{ padding: '2px 8px', background: '#3498db' }}
            >
              Upload
              <input
                type="file"
                name="file"
                disabled={is_op_paused}
                accept="image/x-png,image/jpeg,application/pdf"
                multiple={false}
                onClick={(event) => {
                  event.target.value = null;
                }}
                onChange={(event) => {
                  uploadDoctorSignature(event.target.files);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row attachment-wrapper" style={{ textAlign: 'left' }}>
          {casesheet_form_data && casesheet_form_data.attachment_url ? (
            <div className="col-12 px-4 d-flex justify-content-between">
              <div style={{ paddingLeft: '10px', fontSize: '14px' }}>
                Attachment:{' '}
                <a
                  className="btn btn-sm text-info py-0 px-2 ml-2"
                  href={casesheet_form_data.attachment_url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: '14px' }}
                >
                  View
                </a>
                <button
                  className="btn btn-sm text-danger py-0 px-2 ml-2"
                  style={{ fontSize: '14px' }}
                  onClick={() => removeCasesheetAttachment()}
                >
                  Delete
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
