export const PATIENT_TYPES = {
  adult: 'adult',
  pedo: 'pedo',
};

export const TEETH_QUADRANTS = {
  upperRight: 'upper-right',
  upperLeft: 'upper-left',
  lowerRight: 'lower-right',
  lowerLeft: 'lower-left',
};

export const FILLING_TYPES = {
  p: 'Palatal',
  m: 'Mesial',
  b: 'Buccal',
  d: 'Distal',
  o: 'Occulusal',
  la: 'Labial',
  i: 'Incisal',
  li: 'Lingual',
};
const { p, m, b, d, o, la, i, li } = FILLING_TYPES;

export const TEETH_FILLING_TYPES = {
  PMBDO: [p, m, b, d, o],
  PMLaDI: [p, m, la, d, i],
  PDLaMI: [p, d, la, m, i],
  PDBMO: [p, d, m, b, o],
  LiDBMO: [li, d, b, m, o],
  LiDLaMI: [li, d, la, m, i],
  LiMLaDI: [li, m, la, d, i],
  LiMBDO: [li, m, b, d, o],
};

const { PMBDO, PMLaDI, PDLaMI, PDBMO, LiMBDO, LiMLaDI, LiDLaMI, LiDBMO } =
  TEETH_FILLING_TYPES;

export const ALL_TEETHS = {
  adult: {
    upperLeft: [
      { code: 18, fillingTypes: PMBDO },
      { code: 17, fillingTypes: PMBDO },
      { code: 16, fillingTypes: PMBDO },
      { code: 15, fillingTypes: PMBDO },
      { code: 14, fillingTypes: PMBDO },
      { code: 13, fillingTypes: PMLaDI },
      { code: 12, fillingTypes: PMLaDI },
      { code: 11, fillingTypes: PMLaDI },
    ],
    upperRight: [
      { code: 21, fillingTypes: PDLaMI },
      { code: 22, fillingTypes: PDLaMI },
      { code: 23, fillingTypes: PDLaMI },
      { code: 24, fillingTypes: PDBMO },
      { code: 25, fillingTypes: PDBMO },
      { code: 26, fillingTypes: PDBMO },
      { code: 27, fillingTypes: PDBMO },
      { code: 28, fillingTypes: PDBMO },
    ],
    lowerLeft: [
      { code: 48, fillingTypes: LiMBDO },
      { code: 47, fillingTypes: LiMBDO },
      { code: 46, fillingTypes: LiMBDO },
      { code: 45, fillingTypes: LiMBDO },
      { code: 44, fillingTypes: LiMBDO },
      { code: 43, fillingTypes: LiMLaDI },
      { code: 42, fillingTypes: LiMLaDI },
      { code: 41, fillingTypes: LiMLaDI },
    ],
    lowerRight: [
      { code: 31, fillingTypes: LiDLaMI },
      { code: 32, fillingTypes: LiDLaMI },
      { code: 33, fillingTypes: LiDLaMI },
      { code: 34, fillingTypes: LiDBMO },
      { code: 35, fillingTypes: LiDBMO },
      { code: 36, fillingTypes: LiDBMO },
      { code: 37, fillingTypes: LiDBMO },
      { code: 38, fillingTypes: LiDBMO },
    ],
  },
  pedo: {
    upperLeft: [
      { code: 55, fillingTypes: PMBDO },
      { code: 54, fillingTypes: PMBDO },
      { code: 53, fillingTypes: PMLaDI },
      { code: 52, fillingTypes: PMLaDI },
      { code: 51, fillingTypes: PMLaDI },
    ],
    upperRight: [
      { code: 61, fillingTypes: PDLaMI },
      { code: 62, fillingTypes: PDLaMI },
      { code: 63, fillingTypes: PDLaMI },
      { code: 64, fillingTypes: PDBMO },
      { code: 65, fillingTypes: PDBMO },
    ],
    lowerLeft: [
      { code: 85, fillingTypes: LiMBDO },
      { code: 84, fillingTypes: LiMBDO },
      { code: 83, fillingTypes: LiMLaDI },
      { code: 82, fillingTypes: LiMLaDI },
      { code: 81, fillingTypes: LiMLaDI },
    ],
    lowerRight: [
      { code: 71, fillingTypes: LiDLaMI },
      { code: 72, fillingTypes: LiDLaMI },
      { code: 73, fillingTypes: LiDLaMI },
      { code: 74, fillingTypes: LiDBMO },
      { code: 75, fillingTypes: LiDBMO },
    ],
  },
};

export const FPD_TYPES = ['Abutment', 'Pontic'];
export const OTHER_TREATMENTS = [
  { label: 'Extraction', image: './extraction.png' },
  { label: 'Implant', image: '/assets/images/dental/model_teeth/implant.png' },
  { label: 'RCT', image: '/assets/images/dental/model_teeth/rct.png' },
  {
    label: 'Post & Core',
    image: '/assets/images/dental/model_teeth/postncore.png',
  },
];
export const CROWN_TYPES = [
  { label: 'Veneers', image: '/assets/images/dental/model_teeth/veneers.png' },
  {
    label: 'Laminates',
    image: '/assets/images/dental/model_teeth/laminates.png',
  },
  {
    label: 'Full Crowns',
    image: '/assets/images/dental/model_teeth/full-crowns.png',
  },
  { label: 'Onlay', image: '/assets/images/dental/model_teeth/onlay.png' },
  { label: 'Inlay', image: '/assets/images/dental/model_teeth/inlay.png' },
];

export const OBSERVATION_TYPES = [
  { label: 'Caries', image: '/assets/images/dental/model_teeth/caries.png' },
  {
    label: 'Deep Caries',
    image: '/assets/images/dental/model_teeth/deep-caries.png',
  },
  {
    label: 'Caries Exposure',
    image: '/assets/images/dental/model_teeth/caries-exposure.png',
  },
];

export const TREATMENT_PERIOD = {
  history: 'history',
  plan: 'plan',
  current: 'current',
};
