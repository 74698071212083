import React from 'react';

const DeleteConfirm = ({ message = 'Confirm Delete', onConfirm }) => (
  <div
    aria-hidden="true"
    className="modal fade"
    id="delete-Modal"
    role="dialog"
    tabIndex="-1"
  >
    <div className="modal-dialog modal-lg modal-center-content" role="document">
      <div className="modal-content">
        <div className="modal-body">
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-12 m-b-20">
              <h4 className="modal-title text-center">{message}</h4>
            </div>
            <div className="col-6 text-center">
              <button
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={onConfirm}
              >
                Confirm
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className="btn"
                data-dismiss="modal"
                style={{ cursor: 'pointer' }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DeleteConfirm;
