import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useFullUser } from '@/hooks/useUser';

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

const tabNames = [
  'History',
  'Observations',
  'Diagnosis',
  'Treatments',
] as const;

type ValueType = {
  [K in ElementType<typeof tabNames>]?: string;
} & {
  provider_id?: number;
  historyUpdatedAt?: string;
  observationupdatedAt?: string;
};

function CasesheetDetails({
  disabled,
  handleChange,
  value: defaultValue = '',
}: {
  disabled: boolean;
  value: string;
  handleChange: ({
    target,
  }: {
    target: { name: string; value: string; type: string };
  }) => void;
}) {
  const [tab, settab] = useState<ElementType<typeof tabNames>>(tabNames[2]);
  const val = useMemo(() => encodePrescription(defaultValue), [defaultValue]);
  const [valueObj, setvalue] = useState<ValueType>(val || {});
  const touched = useRef<Partial<Record<keyof ValueType, boolean>>>({});

  const handleInput: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const { name, value, type } = e.target;
    touched.current[tab] = true;

    const _data = {
      ...valueObj,
      [tab]: value,
      historyUpdatedAt:
        tab === 'History'
          ? new Date().toISOString()
          : valueObj?.historyUpdatedAt || undefined,
      observationupdatedAt:
        tab === 'Observations'
          ? new Date().toISOString()
          : valueObj?.observationupdatedAt || undefined,
    };
    setvalue(_data);
    const data = decodePrescription(_data);
    handleChange({ target: { name, value: data, type } });
  };

  useEffect(() => {
    setvalue(val);
  }, [val]);

  return (
    <div className="_border _border-solid _border-slate-200 _bg-stone-100 _m-2 _px-4 _pb-3 _rounded-lg">
      <div className="_flex _justify-between _items-center">
        <h5 className="_my-2 _font-bold _text-gray-600 _text-xs">{tab}</h5>
        <div className="_flex _items-center" role="tablist">
          {tabNames.map((name) => {
            const value = valueObj?.[name];
            const hasValue = value && value?.length > 0;
            const active = tab === name;
            const isTouched = !!touched.current[name];

            return (
              <button
                key={name}
                role="tab"
                id={`tab-${name}`}
                aria-selected={active}
                tabIndex={active ? 0 : -1}
                aria-controls={`panel-${name}`}
                className={classNames(
                  '_px-3 !_py-0 _text-[13px] !_mb-0 hover:_bg-fuchsia-300 _cursor-pointer _duration-500 _text-fuchsia-700 _bg-fuchsia-100 _rounded-full _mx-1 _border _border-solid _border-fuchsia-400',
                  {
                    '!_bg-fuchsia-700 !_text-white _font-bold':
                      active && !hasValue && !isTouched,
                    '!_bg-sky-700 !_text-sky-100 _font-bold hover:!_bg-sky-600 !_border-sky-400':
                      hasValue && active && !isTouched,
                    '!_bg-sky-100 !_text-sky-700 _font-bold hover:!_bg-sky-200 !_border-sky-400':
                      hasValue && !active && !isTouched,
                    '!_bg-lime-100 !_text-lime-700 _font-bold hover:!_bg-lime-200 !_border-lime-400':
                      isTouched && !active,
                    '!_bg-lime-700 !_text-lime-100 _font-bold hover:!_bg-lime-600 !_border-lime-400':
                      isTouched && active,
                  }
                )}
                onClick={() => settab(name)}
              >
                {name}
              </button>
            );
          })}
          <p
            className={classNames(
              '_px-3 !_py-0 _text-[13px] !_mb-0  _cursor-not-allowed _duration-500 _text-slate-400 _bg-slate-200 _rounded-full _mx-1 _border _border-solid _border-slate-400'
            )}
          >
            Lab tests
          </p>
        </div>
      </div>
      {tabNames.map((k, i) => (
        <div
          key={k}
          aria-labelledby={`tab-${k}`}
          role="tabpanel"
          id={`panel-${k}`}
          hidden={k !== tab}
          className={classNames({
            _hidden: k !== tab,
          })}
        >
          <textarea
            className="form-control"
            id=""
            cols={30}
            rows={5}
            name="prescription_details"
            value={valueObj[k] || ''}
            onChange={handleInput}
            disabled={disabled}
            onKeyDown={(l) => {
              if (l.key === 'Tab') {
                if (i >= 0 && i < tabNames.length - 1) {
                  l.preventDefault();
                  settab(tabNames[i + 1]);
                }
              }
            }}
          />
          {k === 'Observations' && valueObj.observationupdatedAt && (
            <p className="_text-xs _text-gray-500 _text-right _-mt-5 _mr-3">
              Updated at:{' '}
              {moment(valueObj.observationupdatedAt).format(
                'DD-MM-YYYY hh:mm:ss A'
              )}
            </p>
          )}
          {k === 'History' && valueObj.historyUpdatedAt && (
            <p className="_text-xs _text-gray-500 _text-right _-mt-5 _mr-3">
              Updated at:{' '}
              {moment(valueObj.historyUpdatedAt).format(
                'DD-MM-YYYY hh:mm:ss A'
              )}
            </p>
          )}
        </div>
      ))}
    </div>
  );
}

export default CasesheetDetails;


export const encodePrescription = (
  data: string,
  safe = false,
  print = false,
  _forced = false // Used to force all details - Used in Clear button in PrescriptionSheet.jsx
): ValueType => {
  const provider_id = useFullUser.getState().user?.user_data.user_id;
  try {
    const val = JSON.parse(data) as ValueType | string;
    if (typeof val === 'string') {
      return {
        Diagnosis: val,
        provider_id,
      };
    }
    if (print || safe) {
      return {
        Diagnosis: val.Diagnosis,
        Treatments: val.Treatments,
      };
    }
    return { ...val, provider_id };
  } catch (error) {
    return {
      Diagnosis: data,
      provider_id,
    };
  }
};

export const decodePrescription = (data: ValueType) => {
  if (typeof data === 'string') {
    return JSON.stringify({ Diagnosis: data });
  }
  return JSON.stringify(data);
};

export const clearPrescripton = (data: string) => {
  const prescription = encodePrescription(data, false, false, true);
  return decodePrescription({
    History: prescription.History,
    Observations: prescription.Observations,
    historyUpdatedAt: prescription.historyUpdatedAt,
    observationupdatedAt: prescription.observationupdatedAt,
  });
};

export const replacePrescription = (data: string, replace: string) => {
  const prescription = encodePrescription(data, false, false, true);
  const replacePrescription = encodePrescription(replace, false, false, true);
  return decodePrescription({
    ...prescription,
    History: replacePrescription.History,
    Observations: replacePrescription.Observations,
    historyUpdatedAt: replacePrescription.historyUpdatedAt,
    observationupdatedAt: replacePrescription.observationupdatedAt,
  });
};
