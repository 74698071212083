import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import moment from 'moment';
import useSWRImmutable from 'swr/immutable';
import { useNavigate } from 'react-router';
import Table from '../layout/Table';
import Alert from '../layout/Alert';
import FormWizard from '../form/FormWizard';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import ImageUpload from '../form/ImageUpload';
import { API_ENDPOINT } from '../../config/api';
import PhoneInput from '../common/phoneInput';
import getAreaFromPincode from '../common/fetchAreaFromPostalCode';
import LocationInput from '../common/LocationInput';
import { ethnicityList, bloodTypes } from '../../_helpers/constants';
import ConsultLinksModal from '../modals/ConsultLinksModal';
import AppointmentSelectModal from '../modals/AppointmentSelectModal';
import { getFetcher } from '../../_helpers/swr';
import PaymentModal from '../layout/PaymentModal';
import DOBInput from '../common/DOBInput';
import { Link } from 'react-router-dom';
import { usePageDetails } from '@/hooks/useUser';

const formHeaders = [
  'General Info',
  'Family Member (optional)',
  'Additional Info (optional)',
];

const ExistingMemberListHeaders = [
  'UIN',
  'Name',
  'Relation',
  'Profile',
  'OP Booking',
  'WK Booking',
];

const doubleDigits = (num) => (num < 10 ? `0${num}` : num);

const getYear = (year) => {
  year = parseInt(year);

  if (year < 10) return `000${year}`;
  if (year < 100) return `00${year}`;
  if (year < 1000) return `0${year}`;
  return `${year}`;
};

const PatientAdd = () => {
  const {
    preferences,
    user_data,
    clinic_data,
    doctors,
    schedules,
    procedures,
    navigate,
    pathname,
    user,
  } = usePageDetails();
  let ClinicData = clinic_data || {};
  if (
    preferences?.selectedClinicId &&
    user_data.user_role === 'provider' &&
    clinic_data.length
  ) {
    ClinicData = clinic_data.find(
      (clinic) => clinic.clinic_id === preferences?.selectedClinicId
    );
  }

  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [isWalkinOnly, setIsWalkinOnly] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    phone_no: '',
    date_of_birth: moment().format('MM-DD-YYYY'),
    sex: '',
    blood_group: '',
    pin_code: '',
    area: '',
    member_status: false,
    member_first_name: '',
    member_last_name: '',
    member_phone_no: '',
    member_phone_code: '91',
    member_date_of_birth: moment().format('MM-DD-YYYY'),
    member_sex: '',
    member_blood_group: '',
    member_relation_id: '',
    email_id: '',
    em_contact_name: '',
    prefix: '',
    em_contact_no: '',
    family_name: '',
    landmark: '',
    image_id: null,
    file_no: '',
    member_file_no: '',
    country_id: ClinicData.country_id,
    phone_code: '91',
    province: ClinicData.province,
    street_address: '',
    regional_name: '',
    id_proof: '',
    id_proof_type: '',
    id_proof_image: '',
    health_card_id: '',
    ethnicity: '',
  });
  const [showLoading, setShowLoading] = useState(true);
  const [areaList, setAreaList] = useState([]);
  const [imgUploadStatus, setImgUploadStatus] = useState({
    message: 'Upload your image.',
    success: false,
  });
  const [relationTypes, setRelationTypes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('IN');
  const [countries, setCountries] = useState([]);
  const [dialCodeList, setDialCodeList] = useState([]);
  const [lastFileNo, setLastFileNo] = useState('');
  const [selectedPatient, setSelectedPatient] = useState({});
  const [payInfo, setPayInfo] = useState({
    appointmentId: '',
    patient: {},
    schedule: {},
    doctor: {},
  });
  const [existingPatData, setExistingPatData] = useState({});

  const _dotorsList =
    doctors?.map((doc) => ({
      name:
        doc.is_rotation === true
          ? `${doc.rotation_name} - ${doc.name}`
          : doc.name,
      value: doc.provider_id,
      speciality_id: doc.speciality_id,
    })) || [];

  const _date = moment().format('YYYY-MM-DD');

  const _scheduleFullList = schedules.map((schdl) => ({
    ...schdl,
    name: `${moment(`${_date} ${schdl.slot_start}`).format(
      'hh:mm A'
    )} to ${moment(`${_date} ${schdl.slot_end}`).format('hh:mm A')}`,
    value: schdl.schedule_id,
  }));

  const changeHandler = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
    if (
      name === 'pin_code' &&
      value &&
      value.length === 6 &&
      !Number.isNaN(value)
    ) {
      getAreaFromPincode(value);
    }
  };

  const constraints = [
    {
      file_no: {
        format: {
          pattern: /^[a-z0-9]+$/i,
          message: 'should contain only alpha numeric values',
        },
      },
      id_proof: {
        presence: false,
      },
      first_name: {
        format: {
          pattern: /[a-z /.]+/i,
          message: 'should contain only alphabets',
        },
        presence: true,
      },
      last_name: {
        format: {
          pattern: /[a-z /.]+/i,
          message: 'should contain only alphabets',
        },
        presence: false,
      },
      phone_no: {
        format: {
          pattern: /(\d+){4,15}/,
          message: 'invalid',
        },
        presence: !isWalkinOnly,
      },
      date_of_birth: {
        presence: true,
      },
      sex: { presence: true },
      blood_group: { presence: false },
      country_id: { presence: true },
      province: { presence: true },
      street_address: { presence: false },
      pin_code: { presence: false },
      area: { presence: true },
      landmark: { presence: false },
      ethnicity: { presence: false },
    },
    {
      member_status: { presence: false },
      member_file_no(value, attributes) {
        if (!attributes.member_status) return null;
        return {
          format: {
            pattern: /^[a-z0-9]+$/i,
            message: 'should contain only alpha numeric values',
          },
        };
      },
      member_first_name(value, attributes) {
        if (!attributes.member_status) return null;
        return {
          format: {
            pattern: /[a-z /.]+/i,
            message: 'should contain only alphabets',
          },
          presence: true,
        };
      },
      member_last_name(value, attributes) {
        if (!attributes.member_status) return null;
        return {
          format: {
            pattern: /[a-z /.]+/i,
            message: 'should contain only alphabets',
          },
          presence: false,
        };
      },
      member_phone_no(value, attributes) {
        if (!attributes.member_status) return null;
        return {
          format: {
            pattern: /(\d+){4,15}/,
            message: 'invalid',
          },
          presence: false,
        };
      },
      member_date_of_birth(value, attributes) {
        if (!attributes.member_status) return null;
        return { presence: true };
      },
      member_sex(value, attributes) {
        if (!attributes.member_status) return null;
        return { presence: true };
      },
      member_blood_group: { presence: false },
      member_relation_id(value, attributes) {
        if (!attributes.member_status) return null;
        return { presence: true };
      },
    },
    {
      email_id: { email: true },
      em_contact_name: { presence: false },
      em_contact_no: {
        format: {
          pattern: /(\d+){4,15}/,
          message: 'invalid',
        },
        presence: false,
      },
      family_name: { presence: false },
      landmark: { presence: false },
    },
  ];

  const getDateValue = (value) => {
    if (!value) return value;
    const newDate = value.split('-');
    return `${getYear(newDate[2])}-${newDate[0]}-${newDate[1]}`;
  };

  const sexChangeHandler = ({ target: { value: sex } }) =>
    setData({ ...data, sex });

  const uploadImage = ({ imgBlob, fileNameWithExtension }) => {
    const fd = new FormData();
    fd.append('file', imgBlob, fileNameWithExtension);
    fd.append('upload_use_case', 'patient_img');
    setShowLoading(true);
    Axios({
      data: fd,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: `${API_ENDPOINT}/File/upload`,
    })
      .then(({ data: { info, file_id } }) => {
        data.image_id = file_id;
        setImgUploadStatus({
          success: true,
          message: 'Image uploaded successfully',
        });
      })
      .catch((err) =>
        setImgUploadStatus({
          success: false,
          message: 'Uploading failed please try again',
        })
      )
      .finally(() => setShowLoading(false));
  };

  const getCountries = () => {
    setCountries([]);
    Axios.get(`${API_ENDPOINT}/StaticData/country-list`)
      .then((res) => {
        if (res.data) {
          //   setCountries(formatSelectOptionData(res.data, 'name', 'id'));
          setCountries(
            res?.data?.map((item) => ({
              name: item?.name,
              value: item?.id,
              country_code: item?.iso2,
              phone_code: item?.phonecode,
            }))
          );
          setDialCodeList(
            res?.data
              ?.map((item) => ({
                name: `+${item?.phonecode}`,
                value: item?.phonecode,
                country_code: item?.iso2,
                country_name: item?.name,
              }))
              .sort((a, b) => (a?.value?.[0] || 0) - (b?.value?.[0] || 0))
          );
          const _country = res?.data?.find(
            (item) => item.id === ClinicData?.country_id
          );
          if (_country?.id) {
            setSelectedCountryCode(_country.iso2);
            setData({
              ...data,
              phone_code: _country.phonecode,
              member_phone_code: _country.phonecode,
            });
          }
        }
      })
      .catch((err) => console.log('Fetching countries failed', 'error'));
  };

  const getRelationTypes = () => {
    setShowLoading(true);
    Axios.get(`${API_ENDPOINT}/RelationshipName/relation_types`)
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData && resData.length) {
          setRelationTypes(
            resData.map((item) => ({
              name: item.relationship_name,
              value: item.relation_id,
            }))
          );
        }
      })
      .catch((err) => showAlert('Getting relation types failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const getLastFileNo = () => {
    setShowLoading(true);
    Axios.get(
      `${API_ENDPOINT}/PatientClinicMapping/web_admin/get_last_file_no/${preferences?.selectedClinicId}`
    )
      .then(({ data: resData }) => {
        setShowLoading(false);
        setLastFileNo(resData?.file_no || '');
      })
      .catch((err) => showAlert('Getting relation types failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const isPatientExist = async (_phone_no, _phone_code) =>
    Axios.post(
      `${API_ENDPOINT}/UserAccount/web_admin/is_user_exist_for_phone_no`,
      { user_type: 'patient', phone_no: _phone_no, phone_code: _phone_code }
    )
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData.user_exists) {
          showAlert('User already exists with this phone number');
          return true;
        }
        return false;
      })
      .catch((err) => {
        setShowLoading(false);
        console.log(err);
      });

  const isFileNoExists = async (file_no) => {
    if (!file_no) return;
    Axios.post(
      `${API_ENDPOINT}/PatientClinicMapping/web_admin/is_user_exist_by_file_no`,
      { clinic_id: preferences?.selectedClinicId, file_no }
    )
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData.user_exists) {
          showAlert('User already exists with this file number');
          return true;
        }
        return false;
      })
      .catch((err) => {
        setShowLoading(false);
      });
  };

  const isPhoneNoExists = (phone_no) => {
    if (!phone_no) return;
    let API_PATH = `patient_by_upn_or_phone?query_val=${phone_no}`;
    if (!Number.isNaN(phone_no)) {
      API_PATH = `${API_PATH}&phone_code=${data.phone_code}`;
    }
    setShowLoading(true);
    Axios.get(`${API_ENDPOINT}/Patients/${API_PATH}`)
      .then((res) => {
        if (res.data) {
          setExistingPatData(res.data);
          setTimeout(() => {
            window.$('#existing-members-modal').modal('show');
          }, 100);
        }
      })
      .finally(() => setShowLoading(false));
  };

  const submitHandler = async () => {
    setSelectedPatient({});
    setShowLoading(true);
    const _phone_no =
      data.phone_no && data.phone_no !== '' ? `${data.phone_no}` : undefined;

    if (_phone_no) {
      const is_exists = await isPatientExist(_phone_no, data.phone_code);
      if (is_exists) return;
    }

    const postData = {
      ...data,
      first_name: `${data.prefix} ${data.first_name}`,
      phone_code: _phone_no ? data.phone_code : undefined,
      phone_no: _phone_no,
      clinic_id: preferences?.selectedClinicId,
    };

    Axios({
      data: postData,
      method: 'post',
      url: `${API_ENDPOINT}/Patients/web_admin/reg_pat`,
    })
      .then(({ data: { info, person_no, person_id } }) => {
        if (info.includes('added')) {
          setSelectedPatient({
            ...postData,
            person_no,
            person_id,
          });
          window.$('#consult-links-modal').modal('show');
        } else showAlert(info);
      })
      .catch((err) => showAlert('Submit failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const goToPatientsList = () => {
    navigate('/patient');
  };

  const goToWalkinList = () => {
    navigate('/consult/walkins');
  };
  const clinic_id = ClinicData?.clinic_id || user_data?.clinic_id;
  const { data: fields } = useSWRImmutable(
    clinic_id
      ? `${API_ENDPOINT}/clinic/registrationFields?clinic_id=${clinic_id}`
      : null,
    getFetcher
  );

  const allowedFields = fields || {
    fileNo: true,
    idCard: true,
    nativeName: true,
    blood_group: true,
    healthCardNo: true,
    email: true,
    ethinicity: true,
  };

  useEffect(() => {
    if (!payInfo.appointmentId) return;
    setTimeout(() => {
      window.$('#payment-modal').modal('show');
    }, 300);
    window.$('#provider-schedule-modal').modal('hide');
    // eslint-disable-next-line
  }, [payInfo]);

  useEffect(() => {
    setShowLoading(false);
    getCountries();
    getRelationTypes();
    getLastFileNo();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FormWizard
        constraints={constraints}
        headers={formHeaders}
        onSubmit={submitHandler}
        onCancel={goToPatientsList}
      >
        <div>
          <div className="row">
            <div className="col-xl-2 col-12 text-center">
              <ImageUpload
                uploadImageFn={uploadImage}
                newImgWidth={250}
                newImgHeight={250}
                name="image_id"
              />
              <span
                style={{
                  color: imgUploadStatus.success === true ? 'green' : 'black',
                  //   imgUploadStatus.success === false
                  //   ? 'red'
                  //   : 'black',
                }}
              >
                {imgUploadStatus.message}
              </span>
            </div>
            <div className="col-xl-10 col-12">
              <div className="_grid _grid-cols-1 md:_grid-cols-2 _gap-4 lg:_grid-cols-3">
                <div className="form-group !_mb-0">
                  <PhoneInput
                    selectedCountryCode={selectedCountryCode}
                    selectedPhoneCode={data.phone_code}
                    phoneCodeList={dialCodeList}
                    onChangeHandler={changeHandler}
                    defaultPhoneNumber={data.phone_no}
                    onBlur={({ target: { value } }) => {
                      setExistingPatData({ person_id: 0 });
                      isPhoneNoExists(value);
                    }}
                    inputName="phone_no"
                    inputLabel="Phone Number *"
                    // isCountryLocked
                    onPhoneCodeChange={(phoneCode) => {
                      setData((prevData) => ({
                        ...prevData,
                        phone_code: phoneCode,
                      }));
                    }}
                  />
                  <div className="col-12 d-flex justify-content-end">
                    <div>
                      <div className="form-check">
                        <input
                          className="form-check-input d-inline-block"
                          type="checkbox"
                          checked={isWalkinOnly}
                          onChange={(e) => setIsWalkinOnly(e.target.checked)}
                          id="walinOnly"
                        />
                        <label
                          className="form-check-label !_mb-0"
                          htmlFor="walinOnly"
                        >
                          Patient doesnot have a phone no.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group !_mb-0  _flex">
                  <div className="_mr-1 _shrink-0">
                    <select
                      name="prefix"
                      onChange={changeHandler}
                      value={data.prefix}
                      className="form-control"
                    >
                      <option>Prefix</option>
                      <option value="S/O">S/O</option>
                      <option value="D/O">D/O</option>
                      <option value="B/O">B/O</option>
                      <option value="W/O">W/O</option>
                      <option value="H/O">H/O</option>
                    </select>
                  </div>
                  <div className="_grow">
                    <input
                      className="form-control"
                      name="first_name"
                      onChange={changeHandler}
                      placeholder="First Name *"
                      type="text"
                      value={data.first_name}
                    />
                    <div className="messages" />
                  </div>
                </div>
                <div className="form-group !_mb-0">
                  <input
                    className="form-control"
                    name="last_name"
                    onChange={changeHandler}
                    placeholder="Last Name"
                    type="text"
                    value={data.last_name}
                  />
                  <div className="messages" />
                </div>
                <LocationInput
                  countries={countries}
                  areaList={areaList}
                  setAreaList={setAreaList}
                  data={data}
                  setData={setData}
                  changeHandler={changeHandler}
                  biasLat={ClinicData.location_lat}
                  biasLon={ClinicData.location_lon}
                  // setSelectedCountryCode={setSelectedCountryCode}
                />

                {allowedFields.fileNo && (
                  <div className="form-group !_mb-0">
                    <input
                      className="form-control"
                      name="file_no"
                      onChange={changeHandler}
                      onBlur={({ target: { value } }) => isFileNoExists(value)}
                      placeholder="File Number"
                      type="text"
                      value={data.file_no}
                    />
                    {lastFileNo ? (
                      <span>Last File No: {lastFileNo}</span>
                    ) : null}
                    <div className="messages" />
                  </div>
                )}
                {allowedFields.idCard && (
                  <div className="form-group !_mb-0">
                    <input
                      className="form-control"
                      name="id_proof"
                      onChange={changeHandler}
                      placeholder="ID Card"
                      type="text"
                      value={data.id_proof}
                    />
                    <div className="messages" />
                  </div>
                )}

                {allowedFields.nativeName && (
                  <div className="form-group !_mb-0">
                    <input
                      className="form-control"
                      name="regional_name"
                      onChange={changeHandler}
                      placeholder="Name in Native Language"
                      type="text"
                      value={data.regional_name}
                    />
                    <div className="messages" />
                  </div>
                )}
                {allowedFields.bloodGroup && (
                  <div className="form-group !_mb-0">
                    <Select
                      name="blood_group"
                      value={data.blood_group}
                      onChange={changeHandler}
                      placeholder="Blood Group"
                      values={bloodTypes.map((option, index) => ({
                        name: option,
                        value: option,
                      }))}
                    />
                    <div className="messages" />
                  </div>
                )}
                {allowedFields.healthCardNo && (
                  <div className="form-group !_mb-0">
                    <input
                      className="form-control"
                      name="health_card_id"
                      onChange={changeHandler}
                      placeholder="Health Card No"
                      type="text"
                      value={data.health_card_id}
                    />
                    <div className="messages" />
                  </div>
                )}
                <div className="form-group !_mb-0">
                  <div className=" _flex _items-center">
                    <div className="text-muted _shrink-0 _mr-4">Gender *</div>
                    <label className="!_mb-0">
                      <div className="form-radio radio radiofill radio-info radio-inline">
                        <label className="!_mb-0">
                          <input
                            checked={data.sex === 'M'}
                            name="sex"
                            onChange={changeHandler}
                            type="radio"
                            value="M"
                          />
                          <i className="helper" />
                          <span className="radio-label">Male</span>
                        </label>
                      </div>
                    </label>
                    <label className="!_mb-0">
                      <div className="form-radio radio radiofill radio-info radio-inline">
                        <label className="!_mb-0">
                          <input
                            checked={data.sex === 'F'}
                            name="sex"
                            onChange={changeHandler}
                            type="radio"
                            value="F"
                          />
                          <i className="helper" />
                          <span className="radio-label">Female</span>
                        </label>
                      </div>
                    </label>
                  </div>
                  <div className=" messages" />
                </div>

                {allowedFields.ethinicity && (
                  <div className="form-group !_mb-0">
                    <Select
                      name="ethnicity"
                      value={data.ethnicity}
                      onChange={changeHandler}
                      placeholder="Ethnicity/Race"
                      values={ethnicityList}
                    />
                    <div className="messages" />
                  </div>
                )}

                {allowedFields.email && (
                  <div className="form-group !_mb-0">
                    <input
                      className="form-control"
                      name="email_id"
                      onChange={changeHandler}
                      placeholder="Email ID"
                      type="text"
                      value={data.email_id}
                    />
                    <div className="messages" />
                  </div>
                )}
              </div>
              <DOBInput
                input_name="date_of_birth"
                value={new Date(moment(data.date_of_birth, 'MM-DD-YYYY'))}
                onChange={(e) => {
                  setData({
                    ...data,
                    date_of_birth: moment(e).format('MM-DD-YYYY'),
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="_w-full">
            <div className="col-12 checkbox-fade fade-in-info form-group !_mb-0">
              <label>
                <input
                  checked={data.member_status}
                  name="member_status"
                  onChange={() =>
                    setData({ ...data, member_status: !data.member_status })
                  }
                  type="checkbox"
                  value={data.member_status}
                />
                <span className="cr">
                  <i className="cr-icon ion-checkmark txt-primary" />
                </span>
                <span>Add a family member:</span>
              </label>
              <div className="messages" />
            </div>
            {data.member_status ? (
              <div className="_grid _grid-cols-1 md:_grid-cols-2 lg:_grid-cols-3 _gap-4">
                <div className=" form-group !_mb-0">
                  <input
                    className="form-control"
                    name="member_file_no"
                    onChange={changeHandler}
                    placeholder="File Number"
                    type="text"
                    value={data.member_file_no}
                  />
                  <div className="messages" />
                </div>

                <div className=" form-group !_mb-0">
                  <input
                    className="form-control"
                    name="member_first_name"
                    onChange={changeHandler}
                    placeholder="First Name *"
                    type="text"
                    value={data.member_first_name}
                  />
                  <div className="messages" />
                </div>
                <div className=" form-group !_mb-0">
                  <input
                    className="form-control"
                    name="member_last_name"
                    onChange={changeHandler}
                    placeholder="Last Name"
                    type="text"
                    value={data.member_last_name}
                  />
                  <div className="messages" />
                </div>

                <div className=" form-group !_mb-0">
                  <PhoneInput
                    selectedCountryCode={selectedCountryCode}
                    selectedPhoneCode={data.member_phone_code}
                    phoneCodeList={dialCodeList}
                    onChangeHandler={changeHandler}
                    defaultPhoneNumber={data.member_phone_no}
                    onBlur={({ target: { value } }) => {
                      isPhoneNoExists(value);
                    }}
                    inputName="member_phone_no"
                    inputLabel="Phone Number"
                    // isCountryLocked
                    onPhoneCodeChange={(phoneCode) => {
                      setData((prevData) => ({
                        ...prevData,
                        member_phone_code: phoneCode,
                      }));
                    }}
                  />
                </div>
                <div className=" form-group !_mb-0">
                  <Select
                    name="member_blood_group"
                    value={data.member_blood_group}
                    onChange={changeHandler}
                    placeholder="Blood Group"
                    values={bloodTypes.map((option, index) => ({
                      name: option,
                      value: option,
                    }))}
                  />
                  <div className="messages" />
                </div>
                <div className="md:_col-span-2 lg:_col-span-3">
                  <DOBInput
                    input_name="member_date_of_birth"
                    value={
                      new Date(moment(data.member_date_of_birth, 'MM-DD-YYYY'))
                    }
                    onChange={(e) => {
                      setData({
                        ...data,
                        member_date_of_birth: moment(e).format('MM-DD-YYYY'),
                      });
                    }}
                  />
                </div>

                <div className="">
                  <div className="form-group">
                    <div className=" _flex _items-center">
                      <div className="text-muted _shrink-0 _mr-4">Sex *</div>
                      <label className="!_mb-0">
                        <div className="form-radio radio radiofill radio-info radio-inline">
                          <label>
                            <input
                              checked={data.member_sex === 'M'}
                              name="member_sex"
                              onChange={changeHandler}
                              type="radio"
                              value="M"
                            />
                            <i className="helper" />
                            <span className="radio-label">Male</span>
                          </label>
                        </div>
                      </label>
                      <label className="!_mb-0">
                        <div className="form-radio radio radiofill radio-info radio-inline">
                          <label>
                            <input
                              checked={data.member_sex === 'F'}
                              name="member_sex"
                              onChange={changeHandler}
                              type="radio"
                              value="F"
                            />
                            <i className="helper" />
                            <span className="radio-label">Female</span>
                          </label>
                        </div>
                      </label>
                    </div>

                    <div className="col-12 messages text-center" />
                  </div>
                </div>
                <div className="form-group">
                  <Select
                    name="member_relation_id"
                    onChange={changeHandler}
                    placeholder="Relationship *"
                    value={data.member_relation_id}
                    values={relationTypes}
                  />
                  <div className="messages" />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div>
          <div className="_grid _grid-cols-1 md:_grid-cols-2 lg:_grid-cols-3 _gap-4">
            <div className=" form-group">
              <input
                className="form-control"
                name="family_name"
                onChange={changeHandler}
                placeholder="Family Name"
                type="text"
                value={data.family_name}
              />
              <div className="messages" />
            </div>
            <div className=" form-group">
              <input
                className="form-control"
                name="email_id"
                onChange={changeHandler}
                placeholder="Email"
                type="text"
                value={data.email_id}
              />
              <div className="messages" />
            </div>

            <div className=" form-group">
              <input
                className="form-control"
                name="em_contact_name"
                onChange={changeHandler}
                placeholder="Emergency Contact Name"
                type="text"
                value={data.em_contact_name}
              />
              <div className="messages" />
            </div>
            <div className=" form-group">
              <input
                className="form-control"
                name="em_contact_no"
                onChange={changeHandler}
                placeholder="Emergency Contact Number"
                type="text"
                maxLength={10}
                value={data.em_contact_no}
              />
              <div className="messages" />
            </div>
          </div>
        </div>
      </FormWizard>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
      <ConsultLinksModal
        patientData={selectedPatient}
        userRole={user_data.user_role}
        onClose={goToPatientsList}
        showNewWalkinForm={() => {
          setTimeout(() => {
            window.$('#provider-schedule-modal').modal('show');
          }, 500);
        }}
      />
      <AppointmentSelectModal
        patientData={selectedPatient}
        clinic_id={preferences?.selectedClinicId}
        providerList={_dotorsList}
        scheduleList={_scheduleFullList}
        setShowLoading={setShowLoading}
        showAlert={showAlert}
        closeAlert={() => setAlertShow(false)}
        onClose={() => {
          setSelectedPatient({});
          goToPatientsList();
        }}
        // clinicSettings={clinicSettings}
        collectPayment={(
          appointmentId,
          patientInfo,
          scheduleInfo,
          doctorInfo
        ) => {
          setPayInfo({
            appointmentId,
            patient: patientInfo,
            schedule: scheduleInfo,
            doctor: doctorInfo,
          });
        }}
      />
      <ExistingPatientModal
        navigate={navigate}
        userRole={user_data.user_role}
        setSelectedPatient={setSelectedPatient}
        key={existingPatData?.person_id}
        patientId={existingPatData?.person_id}
        membersData={[
          ...[
            {
              person_no: existingPatData?.person_no,
              person_id: existingPatData?.person_id,
              email_id: existingPatData?.email_id,
              first_name: existingPatData?.first_name,
              last_name: existingPatData?.last_name,
              phone_no: existingPatData?.phone_no,
              sex: existingPatData?.sex,
              date_of_birth: existingPatData?.date_of_birth,
              relationship_name: '',
              age: existingPatData?.age,
            },
          ],
          ...(existingPatData?.family_members || []),
        ]}
      />
      <PaymentModal
        key={payInfo?.appointmentId}
        appointment={{
          appointment_id: payInfo?.appointmentId,
          appointment_date: moment().format('YYYY-MM-DD'),
          provider_id: payInfo?.doctor?.id,
          provider_name: payInfo?.doctor?.name || '',
          patient: payInfo?.patient,
          proc_data: [],
          is_tatkal: false,
          tatkal_consult_fee: 0,
          normal_consult_fee: payInfo?.schedule?.normal_consult_fee || 0,
        }}
        user_data={user_data}
        isTransactionComplete={() => {
          setSelectedPatient({});
          window.$('#provider-schedule-modal').modal('hide');
          window.$('#payment-modal').modal('hide');
          setTimeout(goToPatientsList, 300);
        }}
        procedures={procedures}
        clinic_data={clinic_data}
        site_preferences={preferences}
      />
    </>
  );
};

export const ExistingPatientModal = ({
  navigate,
  membersData,
  userRole,
  setSelectedPatient,
  patientId,
}) => {
  const [data, setData] = useState(membersData);

  const loadData = (rows) =>
    rows
      .filter((row) => row.display)
      .map((row) => [
        row.person_no,
        <span>
          {`${row.first_name} ${row.last_name}`}{' '}
          <span className="_mx-1 _text-slate-600">&#8226;</span>
          <span className="_text-purple-600 _text-xs">
            {row.date_of_birth
              ? moment().diff(moment(row.date_of_birth, 'YYYY-MM-DD'), 'years')
              : ''}
          </span>
          <span className="_mx-1 _text-slate-600">&#8226;</span> {row.sex}
        </span>,
        row.relationship_name,
        <button
          className="btn btn-outline-info btn-sm"
          data-dismiss="modal"
          onClick={() => {
            window.$('#transfer-modal').modal('hide');
            navigate(`/patient/edit/${row?.person_id}`);
          }}
        >
          View/Edit
        </button>,

        <button
          className="btn btn-outline-info btn-sm"
          data-dismiss="modal"
          onClick={() => {
            window.$('#transfer-modal').modal('hide');
            navigate(`/booking/availability?uin=${row?.person_no}`);
          }}
        >
          Book
        </button>,

        <button
          className="btn btn-outline-info btn-sm"
          data-dismiss="modal"
          onClick={() => {
            window.$('#transfer-modal').modal('hide');
            if (userRole === 'provider') {
              navigate(`/consult/walk-in?uin=${row?.person_no}`);
            } else {
              setSelectedPatient({ ...row });
              setTimeout(() => {
                window.$('#provider-schedule-modal').modal('show');
              }, 500);
            }
          }}
        >
          Book
        </button>,
      ]);

  useEffect(() => {
    if (membersData && membersData.length) {
      setData(membersData.map((row) => ({ ...row, display: true })));
    }
    // eslint-disable-next-line
  }, [membersData]);

  const handleRemovePhone = () => {
    Axios.post(`${API_ENDPOINT}/provider/patient/remove-phone`, {
      patient_id: patientId,
    }).then((res) => {
      window.$('#existing-members-modal').modal('hide');
    });
  };
  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="existing-members-modal"
      role="dialog"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {data?.find((e) => e?.phone_no)?.phone_no} Already Exists!
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row m-b-40" style={{ alignItems: 'center' }}>
              <div className="col-12">
                {data?.length ? (
                  <Table
                    data={loadData(data)}
                    headings={ExistingMemberListHeaders}
                    number={7}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="mb-3">
              <h4 className="_text-center">OR</h4>
              <p className="text-danger _text-center _font-bold">
                The user is not related with the listed profile?
              </p>
            </div>
            <div className="_flex _justify-between">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  window.$('#existing-members-modal').modal('hide');
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={handleRemovePhone}
              >
                Remove this number from above profile and continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientAdd;
