import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import { API_ENDPOINT, PG_DOMAIN } from '../../config/api';
import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import { showCurrency, showCurrencyCode } from '../common/showCurrency';
import { useUser } from '@/hooks/useUser';

const Checkout = () => {
  const location = useLocation();
  const { user_data, clinic_data } = useUser();
  if (user_data.user_role !== 'Admin' || user_data.user_role !== 'PRO') {
    // eslint-disable-next-line no-alert
    alert('Un Authorized');
    return;
  }
  const { invoice_id } = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [pgOrderId, setPgOrderId] = useState('');
  const [pgAppId, setPgAppId] = useState('');
  const [pgSecret, setPgSecret] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const data = {};
  data.orderAmount = '1';
  data.customerName = 'Rashid';
  data.customerPhone = '9562253295';
  data.customerEmail = 'rashid@unq.ai';
  data.notifyUrl = '';
  data.orderNote = 'Test';
  data.returnURL = `${API_ENDPOINT}/SubscriptionPlan/web_admin/pg_payment_status`;
  data.orderCurrency = showCurrencyCode();

  const makePayment = () => {
    setShowLoading(true);
    const reqData = {
      clinic_id: clinic_data.clinic_id,
      clinic_no: clinic_data.clinic_no,
      order_price: data.orderAmount,
      order_note: data.orderNote,
      user_name: data.customerName,
      user_email_id: data.customerEmail,
      user_phone: data.customerPhone,
      return_url: data.returnURL,
    };
    Axios.post(
      `${API_ENDPOINT}/SubscriptionPlan/web_admin/init_payment`,
      reqData
    )
      .then(({ data: resData }) => {
        setShowLoading(false);
        setPgOrderId(resData.order_no);
        setPgAppId(resData.pay_app_id);
        setPgSecret(resData.pay_token);
      })
      .catch((err) => showAlert('Adding appointment failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const getInvoiceData = () => {
    Axios.get(
      `${API_ENDPOINT}/Invoice/web_admin/get_invoice_by_id?invoice_id=${invoice_id}&clinic_no=${clinic_data.clinic_no}`
    )
      .then(({ data: resData }) => {
        setShowLoading(false);
        setInvoiceData(resData);
      })
      .catch((err) => showAlert('Adding appointment failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  useEffect(() => {
    if (pgSecret) {
      document.getElementById('redirectForm').submit();
    }
  }, [pgSecret]);

  return (
    <div className="container">
      <div id="payment-div" />
      <div className="row">
        <div className="col-md-4 order-md-2 mb-4">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">Your cart</span>
            {/* <span className="badge badge-secondary badge-pill">3</span> */}
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Subscription Charge</h6>
                <small className="text-muted">Monthly Subscription Fee</small>
              </div>
              <span className="text-muted">
                {showCurrency()}
                {invoiceData.subscription_chrg || 0}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Additional Consultation Charge</h6>
                <small className="text-muted">
                  Consultation above the Subscription package limit.
                </small>
              </div>
              <span className="text-muted">
                {showCurrency()}
                {invoiceData.extra_consult_chrg || 0}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Priority Booking Charge</h6>
              </div>
              <span className="text-muted">
                {showCurrency()}
                {invoiceData.tkl_service_chrg || 0}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Third Party Booking Charge</h6>
              </div>
              <span className="text-muted">
                {showCurrency()}
                {invoiceData.tp_service_chrg || 0}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">SMS Charge</h6>
              </div>
              <span className="text-muted">
                {showCurrency()}
                {invoiceData.sms_chrg || 0}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between bg-light">
              <div className="text-success">
                <h6 className="my-0">Promo Discount</h6>
                {/* <small>USEDPROMOCODE</small> */}
              </div>
              <span className="text-success">
                -{showCurrency()}
                {invoiceData.discount_amount || 0}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>Total (Incl Tax)</span>
              <strong>
                {showCurrency()}
                {invoiceData.payable_amount || 0}
              </strong>
            </li>
          </ul>

          <form className="card p-2">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Promo code"
              />
              <div className="input-group-append">
                <button type="submit" className="btn btn-secondary">
                  Redeem
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-8 order-md-1">
          <h4 className="mb-3">Billing details</h4>
          <form className="needs-validation" noValidate>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="firstName">First name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder=""
                  defaultValue={user_data.first_name}
                  required
                />
                <div className="invalid-feedback">
                  Valid first name is required.
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="lastName">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder=""
                  defaultValue={user_data.last_name}
                  required
                />
                <div className="invalid-feedback">
                  Valid last name is required.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="you@example.com"
                  defaultValue={clinic_data.email_id}
                />
                <div className="invalid-feedback">
                  Please enter a valid email address.
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="phone_no">Phone No</label>
                <input
                  type="phone_no"
                  className="form-control"
                  id="phone_no"
                  placeholder="Phone No"
                  defaultValue={clinic_data.clinic_phone_no}
                />
                <div className="invalid-feedback">
                  Please enter a valid number.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="gstin">GSTIN (optional)</label>
                <input
                  type="gstin"
                  className="form-control"
                  id="gstin"
                  placeholder="GSTIN"
                  defaultValue={clinic_data.gstin_no}
                />
                <div className="invalid-feedback">
                  Please enter a valid GSTIN number.
                </div>
              </div>
            </div>
          </form>

          <div className="card">
            <form id="redirectForm" method="post" action={PG_DOMAIN}>
              <input type="hidden" name="appId" defaultValue={pgAppId} />
              <input type="hidden" name="orderId" defaultValue={pgOrderId} />
              <input
                type="hidden"
                name="orderAmount"
                defaultValue={data.orderAmount}
              />
              <input
                type="hidden"
                name="orderCurrency"
                defaultValue={data.orderCurrency}
              />
              <input
                type="hidden"
                name="orderNote"
                defaultValue={data.orderNote}
              />
              <input
                type="hidden"
                name="customerName"
                defaultValue={data.customerName}
              />
              <input
                type="hidden"
                name="customerEmail"
                defaultValue={data.customerEmail}
              />
              <input
                type="hidden"
                name="customerPhone"
                defaultValue={data.customerPhone}
              />
              <input
                type="hidden"
                name="returnUrl"
                defaultValue={data.returnURL}
              />
              <input type="hidden" name="signature" defaultValue={pgSecret} />
            </form>
            <br />
            <button
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
              type="button"
              onClick={() => makePayment()}
            >
              Continue to payment
            </button>
          </div>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </div>
  );
};

export default Checkout;
