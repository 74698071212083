/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import { Link } from 'react-router-dom';

import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import { API_ENDPOINT } from '../../config/api';
import Select from '../form/Select';
import Table from '../layout/Table';
import useInterval from '../../_helpers/use_interval_hook';
import NoResults from '../common/NoResults';
import { usePageDetails } from '@/hooks/useUser';

const _date = moment().format('YYYY-MM-DD');

const _currentDayName = moment().format('ddd').toLowerCase();

const initialFilters = { search_term: '', transferType: 'incoming' };

const ConsultTransfers = () => {
  const {
    preferences,
    user_data,
    clinic_data,
    doctors,
    schedules,
    procedures,
    navigate,
    pathname,
    user,
  } = usePageDetails();
  const tempSpecialityList =
    doctors?.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.speciality_id)) {
        unique.push({ name: o.speciality_name, value: o.speciality_id });
      }
      return unique;
    }, []) || [];
  tempSpecialityList.unshift({ name: 'All Departments', value: '' });
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);
  const _dotorsFullList =
    doctors?.map((doc) => ({
      name:
        doc.is_rotation === true
          ? `${doc.rotation_name} - ${doc.name}`
          : doc.name,
      value: doc.provider_id,
      speciality_id: doc.speciality_id,
    })) || [];
  const tempDoctorList = _dotorsFullList.filter((doc) => {
    const shdl = schedules.find(
      (shdl) => shdl.provider_id === doc.value && shdl[_currentDayName] === true
    );
    // return (shdl && (shdl[_currentDayName] === true) && (moment().isBetween(moment(shdl.slot_start, _timeFormat), moment(shdl.slot_end, _timeFormat))))
    return shdl;
  });
  tempDoctorList.unshift({ name: 'Choose Doctor', value: '' });
  const _scheduleFullList = schedules.map((schdl) => ({
    ...schdl,
    name: `${moment(`${_date} ${schdl.slot_start}`).format(
      'hh:mm A'
    )} to ${moment(`${_date} ${schdl.slot_end}`).format('hh:mm A')}`,
    value: schdl.schedule_id,
  }));

  const clinic_id = preferences?.selectedClinicId;
  const [specialityList, setSpecialityList] = useState(tempSpecialityList);
  const [doctorList, setDoctorList] = useState(tempDoctorList);
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    user_data.user_role === 'provider' ? user_data.speciality_id : undefined
  );
  const [selectedDoctor, setSelectedDoctor] = useState(
    user_data.user_role === 'provider' ? user_data.user_id : undefined
  );
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [transferData, setTransferData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [lastRefreshedTime, setLastRefreshedTime] = useState(null);
  const [refreshtimeFromNow, setRefreshtimeFromNow] = useState(null);
  const tableHeadings = [
    'UIN',
    'File No',
    <>
      Patient <i className="fa fa-sort-amount-asc" />
    </>,
    'Phone No',
    filters.transferType === 'outgoing' ? 'Transfer To' : 'Transfer From',
    'Notes',
    'Date',
    'Status',
    'Type',
    'Actions',
  ];

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const specialityChangeHandler = async ({ target: { name, value } }) => {
    await Axios.storage.remove('get-transfer-list');
    setDoctorList([]);
    setSelectedDoctor(undefined);
    setSelectedSpecialty(parseInt(value));
    if (value) {
      const doctors = tempDoctorList.filter(
        (obj) => obj.speciality_id === parseInt(value)
      );
      setDoctorList(doctors);
      if (doctors[0]) setSelectedDoctor(doctors[0].value);
    } else {
      setDoctorList(tempDoctorList);
      setSelectedSchedule({});
    }
  };

  const loadTransferTable = (data) =>
    data
      .filter((row) => row.display)
      ?.sort(
        (a, b) =>
          moment(b.transferred_on).format('YYYYMMDD') -
          moment(a.transferred_on).format('YYYYMMDD')
      )
      .map((row, index) => {
        const tableRow = [
          <span>
            {row.patient.uin}{' '}
            {row.appointment_note ? (
              <i
                className="ion-chatbox cursor-pointer"
                onClick={() => showAppointmentNote(row.appointment_note)}
              />
            ) : (
              ''
            )}
          </span>,
          row.patient.file_no,
          <span>
            <Link to={`/patient/edit/${row.patient?.id}`}>
              {row.patient?.name}
            </Link>{' '}
            {row.patient?.age} {row.patient?.gender}
          </span>,
          row.patient.phone,
          filters.transferType === 'outgoing'
            ? row.transferred_to_name
            : row.transferred_from_name,
          row.proc_data?.map((proc) => proc?.procedure_name)?.join(','),
          row.transferred_on
            ? moment(row.transferred_on).format('YYYY-MM-DD')
            : '',
          row.is_walkin ? '' : row.op_status === 'done' ? 'Done' : 'Pending',
          row.is_walkin ? (
            user_data.user_role === 'provider' ? (
              <a
                className="txt-underline"
                href={`/consult/walk-in?uin=${row.patient?.uin}`}
              >
                Walkin
              </a>
            ) : (
              'Walkin'
            )
          ) : (
            'OP'
          ),
          '',
        ];
        return tableRow;
      });

  const getTransfers = (schedule_id, date = moment().format('YYYY-MM-DD')) => {
    if (!selectedDoctor) return;
    // setShowLoading(true);
    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/get_op_transfers?clinic_id=${clinic_id}&transfer_date=${date}&provider_id=${selectedDoctor}`,
      {
        id: 'get-transfer-list',
      }
    )
      .then(({ data: resData }) => {
        if (resData && resData.length) {
          setTransferData(
            resData.map((row) => {
              if (filters.search_term || filters.transferType) {
                if (searchRow(row, filters.transferType)) {
                  return { ...row, display: true };
                }
              } else {
                return { ...row, display: true };
              }
              return { ...row, display: false };
            })
          );
        } else {
          setTransferData([]);
        }
        setLastRefreshedTime(moment());
      })
      .catch((err) => {
        setTransferData([]);
        showAlert('Fetching appointments failed', 'error');
      })
      .finally(() => setShowLoading(false));
  };

  const searchRow = (row, transferType) => {
    const keys = Object.keys(row);
    for (const i in keys) {
      const key = keys[i];
      const value =
        typeof row[key] === 'string' ? row[key] : JSON.stringify(row[key]);
      if (!transferType) {
        if (value.toLowerCase().includes(filters.search_term.toLowerCase())) {
          return true;
        }
      } else if (transferType === 'incoming' && !filters.search_term) {
        if (parseInt(row.transferred_to) === parseInt(selectedDoctor)) {
          return true;
        }
      } else if (transferType === 'incoming' && filters.search_term) {
        if (
          parseInt(row.transferred_to) === parseInt(selectedDoctor) &&
          value.toLowerCase().includes(filters.search_term.toLowerCase())
        ) {
          return true;
        }
      } else if (transferType === 'outgoing' && !filters.search_term) {
        if (parseInt(row.transferred_to) !== parseInt(selectedDoctor)) {
          return true;
        }
      } else if (transferType === 'outgoing' && filters.search_term) {
        if (
          parseInt(row.transferred_to) !== parseInt(selectedDoctor) &&
          value.toLowerCase().includes(filters.search_term.toLowerCase())
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const filter = () => {
    const copy = transferData.map((row) => {
      if (!filters.transferType && filters.search_term === '')
        return { ...row, display: true };
      if (searchRow(row, filters.transferType))
        return { ...row, display: true };
      return { ...row, display: false };
    });
    setTransferData(copy);
  };

  const filterChangeHandler = ({ target: { name, value } }) => {
    setFilters({ ...filters, [name]: value });
  };

  const showAppointmentNote = (note) => {
    showAlert(note, 'alert');
  };

  useEffect(() => {
    getTransfers(selectedSchedule.schedule_id);
  }, [selectedDoctor, preferences?.selectedClinicId]);

  const forceRefresh = () => {
    getTransfers(selectedSchedule.schedule_id);
  };

  useInterval(() => {
    setRefreshtimeFromNow(lastRefreshedTime?.fromNow() || '');
  }, 1000);

  useEffect(() => {
    filter();
    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <div className="card hex-sm-card-border-top p-t-20 p-b-20">
        <div className="row">
          <div className="col-md-8 col-lg-9">
            {user_data.user_role !== 'provider' ? (
              <div className="row m-b-20">
                <div className="col-md-4 m-b-10">
                  <Select
                    className="outlined"
                    values={specialityList}
                    value={selectedSpecialty}
                    onChange={specialityChangeHandler}
                  />
                </div>
                <div className="col-md-4 m-b-10">
                  <Select
                    className="outlined"
                    values={doctorList}
                    value={selectedDoctor}
                    onChange={async ({ target: { name, value } }) => {
                      await Axios.storage.remove('get-transfer-list');
                      setFilters(initialFilters);
                      setTransferData([]);
                      setSelectedDoctor(value);
                    }}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-md-4 m-b-10">
                <input
                  className="form-control outlined"
                  name="search_term"
                  onChange={filterChangeHandler}
                  placeholder="Search here"
                  tabIndex={1}
                  type="text"
                  value={filters.search_term}
                />
              </div>
              <div className="col-md-6 m-b-10">
                <div
                  className="form-radio radio radiofill radio-info radio-inline"
                  style={{ display: 'inline-flex' }}
                >
                  <label>
                    <input
                      checked={filters.transferType === 'incoming'}
                      type="radio"
                      onChange={() =>
                        setFilters({ ...filters, transferType: 'incoming' })
                      }
                    />
                    <i className="helper" />
                    <span className="radio-label">Incoming</span>
                  </label>
                </div>
                <div
                  className="form-radio radio radiofill radio-info radio-inline"
                  style={{ display: 'inline-flex' }}
                >
                  <label>
                    <input
                      checked={filters.transferType === 'outgoing'}
                      type="radio"
                      onChange={() =>
                        setFilters({ ...filters, transferType: 'outgoing' })
                      }
                    />
                    <i className="helper" />
                    <span className="radio-label">Outgoing</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedSchedule && selectedSchedule.schedule_id && (
        <p>
          {refreshtimeFromNow ? (
            <span className="text-danger font-weight-bold">
              Last updated {refreshtimeFromNow}{' '}
            </span>
          ) : null}
          <span
            className="bg-inverse"
            style={{
              borderRadius: '3px',
              margin: '0px 6px 0px 3px',
              padding: '3px 6px',
              cursor: 'pointer',
            }}
            onClick={() => forceRefresh()}
          >
            <i className="fa fa-refresh" /> Refresh List
          </span>
        </p>
      )}
      {transferData.length ? (
        <Table
          headings={tableHeadings}
          data={loadTransferTable(transferData)}
          number={10}
        />
      ) : (
        <div className="w-100">
          <NoResults message="No appointments found" />
        </div>
      )}
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default ConsultTransfers;
