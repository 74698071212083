import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ link, name }) => (
  <Link className="hex-menubox hex-account-type" to={link}>
    <div className="bg-primary card group-widget text-center">{name}</div>
  </Link>
);

const accountTypes = [
  { link: '/clinic', name: 'Clinic' },
  { link: '/provider', name: 'Provider' },
  { link: '/medicalstore', name: 'Medical Store' },
];

const CreateAccount = () => (
  <div style={{ paddingTop: '50px' }}>
    <div>
      <Link style={{ color: '#212121', fontSize: '1.3rem' }} to="/">
        <i className="fa fa-chevron-left m-r-10" /> Back
      </Link>
    </div>
    <div className="j-wrapper j-wrapper-400">
      <form
        onSubmit={(ev) => ev.preventDefault()}
        className="j-pro"
        id="sign-in"
        noValidate
      >
        <div className="j-content">
          {accountTypes.map((accountType, index) => (
            <Card {...accountType} key={index} />
          ))}
        </div>
        <div className="j-footer">
          <div className="j-unit">
            <div
              className="g-recaptcha"
              data-sitekey={import.meta.env.VITE_CAPTCHA_SITE_KEY}
            />
          </div>
          <div className="j-response" />
        </div>
      </form>
    </div>
  </div>
);

export default CreateAccount;
