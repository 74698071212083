/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import Alert from '../layout/Alert';
import BackButton from '../layout/BackButton';
import DeleteConfirm from '../layout/DeleteConfirm';
import FormWizard from '../form/FormWizard';
import ImageUpload from '../form/ImageUpload';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import Table from '../layout/Table';
import { API_ENDPOINT } from '../../config/api';
import { formatSelectOptionData } from '../../_helpers/common_utils';
import PhoneInput from '../common/phoneInput';
import getAreaFromPincode from '../common/fetchAreaFromPostalCode';
import LocationInput from '../common/LocationInput';
import { usePageDetails } from '@/hooks/useUser';

const clinicHeadings = ['#', 'Name', 'Contact', 'Location', 'Action'];

const ReferralTypes = [
  { name: 'Doctor', value: 'Doctor' },
  { name: 'Medical Rep', value: 'Medical Rep' },
  { name: 'Software Consultant', value: 'Software Consultant' },
  { name: 'Agent', value: 'Agent' },
];

let otherDegreeId = 0;

const constraints = [
  {
    email_id: { email: true, presence: true },
    otp: {
      format: {
        pattern: /^\d{6}$/,
        message: 'should be a 6-digit number',
      },
      presence: true,
    },
  },
  {
    first_name: {
      format: {
        pattern: /[a-z .]+/i,
        message: 'should contain only alphabets',
      },
      presence: true,
    },
    last_name: {
      format: {
        pattern: /[a-z .]+/i,
        message: 'should contain only alphabets',
      },
      presence: true,
    },
    sex: { presence: true },
    date_of_birth: {
      datetime: {
        latest: moment.utc().subtract(18, 'years'),
        message: '^You need to be at least 18 years old',
      },
      presence: true,
    },
  },
  {
    degree_title: { presence: true },
    speciality(value, attributes) {
      if (
        attributes.degree_title &&
        parseInt(attributes.degree_title) === otherDegreeId
      )
        return null;
      return { presence: true };
    },
    provider_type: { presence: true },
    consult_startdate: { presence: true },
    additional_qualification(value, attributes) {
      if (
        attributes.degree_title &&
        parseInt(attributes.degree_title) !== otherDegreeId
      )
        return null;
      return { presence: { message: ' or Other details required' } };
    },
    reg_no: { presence: true },
    experience: {
      format: {
        pattern: /^\d{0,2}(\.([1-9]|10|11))?$/,
        message: 'should be a number(Months in the decimal place)',
      },
      presence: true,
    },
  },
  {
    // primary_clinic_id: { presence: true },
    pin_code: { presence: true },
    area: { presence: true },
    create_new_clinic: { presence: false },
    new_clinic_name(value, attributes) {
      if (!attributes.create_new_clinic) return null;
      return {
        format: {
          pattern: /[ a-zA-Z0-9_.&-]+/i,
          message: 'should contain only letters, numbers and _ . & -',
        },
        presence: true,
      };
    },
    phone_no: {
      format: {
        pattern: /(\d+){4,15}/,
        message: 'invalid',
      },
      presence: true,
    },
    user_name: { presence: true },
    user_password: { presence: true },
    confirm_password: {
      equality: {
        attribute: 'user_password',
        message: '^The passwords do not match',
      },
      presence: true,
    },
  },
  {
    affliateStatus: { presence: false },
    referrer_type(value, attributes) {
      if (!attributes.affliateStatus) return null;
      return {
        presence: true,
      };
    },
    referrer_first_name(value, attributes) {
      if (!attributes.affliateStatus) return null;
      return {
        format: {
          pattern: /[a-z ]+/i,
          message: 'should be only alphabets',
        },
        presence: true,
      };
    },
    referrer_last_name(value, attributes) {
      if (!attributes.affliateStatus) return null;
      return {
        format: {
          pattern: /[a-z ]+/i,
          message: 'should contain only alphabets',
        },
        presence: true,
      };
    },
    referrer_phone_no(value, attributes) {
      if (!attributes.affliateStatus) return null;
      return {
        format: {
          pattern: /(\d+){4,15}/,
          message: 'invalid',
        },
        presence: true,
      };
    },
    referrer_email_id(value, attributes) {
      if (!attributes.affliateStatus) return null;
      return { email: true, presence: true };
    },
    terms_accepted: {
      presence: {
        message:
          '^You have to accept the Terms & Conditions and Privacy Policy',
      },
    },
  },
];

const formHeaders = [
  'Verification',
  'Basic Info',
  'Professional Info',
  'Profile Info',
  'Referred By',
];

const ProviderAdd = () => {
  const {
    preferences,
    user_data,
    clinic_data,
    doctors,
    schedules,
    procedures,
    navigate,
    pathname,
    user,
  } = usePageDetails();
  const subscription_plan = user?.subscription_plan;
  let ClinicData = clinic_data || {};
  if (
    preferences?.selectedClinicId &&
    user_data.user_role === 'provider' &&
    clinic_data.length
  ) {
    ClinicData = clinic_data.find(
      (clinic) => clinic.clinic_id === preferences?.selectedClinicId
    );
  }
  let userExistsInDB = false;
  const { clinic_id, clinic_name, provider_id } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [alertBackMsg, setAlertBackMsg] = useState('');
  const [alertBackShow, setAlertBackShow] = useState(false);
  const [alertHomeMsg, setAlertHomeMsg] = useState('');
  const [alertHomeShow, setAlertHomeShow] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [currentEmailId, setCurrentEmailId] = useState('');
  const [data, setData] = useState({
    phoneChangeStatus: false,
    phone_no: '',
    otp: '',
    first_name: '',
    last_name: '',
    sex: '',
    date_of_birth: '',
    degree_title: '',
    degree_id: '',
    speciality_name: '',
    speciality_id: '',
    additional_qualification: '',
    provider_type: '',
    provider_type_id: '',
    consult_startdate: '',
    create_new_clinic: false,
    new_clinic_name: '',
    reg_no: '',
    experience: '',
    pin_code: '',
    area: '',
    email_id: '',
    user_name: '',
    user_password: '',
    confirm_password: '',
    affliateStatus: true,
    referrer_type: '',
    referrer_first_name: '',
    referrer_last_name: '',
    referrer_phone_no: '',
    referrer_email_id: '',
    is_active: true,
    image_id: null,
    created_by: null,
    terms_accepted: false,
    country_id: undefined,
    phone_code: '91',
    referrer_phone_code: '91',
    province: '',
    street_address: '',
    regional_name: '',
    primary_clinic_id:
      clinic_id ||
      (clinic_data && clinic_data.clinic_id ? clinic_data.clinic_id : null),
  });
  const [degrees, setDegrees] = useState([]);
  const [providerTypes, setProviderTypes] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [specializations, setSpecializations] = useState([]);
  const [clinicAreaList, setClinicAreaList] = useState([]);
  const [primaryClinicName, setPrimaryClinicName] = useState('');
  const [otpSendStatus, setOtpSendStatus] = useState({});
  const [otpVerifyStatus, setOtpVerifyStatus] = useState({});
  const [imgUploadStatus, setImgUploadStatus] = useState({
    message: 'Upload your picture.',
    success: false,
  });
  const [clinicTypes, setClinicTypes] = useState([]);
  const [profilePic, setProfilePic] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('IN');
  const [referrerCountryCode, setReferrerCountryCode] = useState('IN');
  const [countries, setCountries] = useState([]);
  const [dialCodeList, setDialCodeList] = useState([]);

  if (provider_id) {
    constraints[0].phoneChangeStatus = { presence: false };
    constraints[0].email_id = (value, attributes) => {
      if (!attributes.phoneChangeStatus) return null;
      return {
        email: true,
        presence: true,
      };
    };
    constraints[0].otp = (value, attributes) => {
      if (!attributes.phoneChangeStatus) return null;
      return {
        format: {
          pattern: /^\d{6}$/,
          message: 'should be a 6-digit number',
        },
        presence: true,
      };
    };
    constraints[3].user_password = { presence: false };
    constraints[3].confirm_password = { presence: false };
    constraints[4].referrer_type = { presence: false };
    constraints[4].referrer_first_name = { presence: false };
    constraints[4].referrer_last_name = { presence: false };
    constraints[4].referrer_phone_no = { presence: false };
    constraints[4].referrer_email_id = { presence: false };
  }

  const changeHandler = ({ target: { name, type, value } }) => {
    if (name === 'clinic_type_id') {
      console.log(value);
    }
    setData({ ...data, [name]: type === 'number' ? parseInt(value) : value });
    if (
      name === 'pin_code' &&
      value &&
      value.length === 6 &&
      !Number.isNaN(value)
    ) {
      getAreaFromPincode(value);
    }
    if (name === 'phone_code' || name === 'referrer_phone_code') {
      const _dialOption = dialCodeList?.find((item) => item.value === value);
      if (name === 'referrer_phone_code') {
        setReferrerCountryCode(_dialOption?.country_code);
      } else {
        setSelectedCountryCode(_dialOption?.country_code);
      }
    }
  };

  const primaryClinicChangeHandler = ({ name, id }) => {
    setPrimaryClinicName(name);
    setData({
      ...data,
      primary_clinic_id: id,
    });
  };

  const dateChangeHandler = ({ target: { name, value } }) => {
    if (Number.isNaN(Date.parse(value))) return;
    const newDate = new Date(value).toISOString().split('T')[0];
    setData({
      ...data,
      [name]: newDate,
    });
  };

  const getDegrees = (provider_type_id) => {
    setDegrees([]);
    Axios.get(
      `${API_ENDPOINT}/Degree/?provider_type_id=${provider_type_id || ''}`
    )
      .then((res) => {
        if (res.data) {
          const otherDegreeObj = res.data.find((x) =>
            x.degree_name.includes('Other ')
          );
          if (otherDegreeObj && otherDegreeObj.degree_id)
            otherDegreeId = otherDegreeObj.degree_id;
          setDegrees(
            formatSelectOptionData(res.data, 'degree_name', 'degree_id')
          );
        }
      })
      .catch((err) => console.log('Fetching degrees failed', 'error'));
  };

  const getSpecializations = (degree_id) => {
    setSpecializations([]);
    Axios.get(
      `${API_ENDPOINT}/Speciality/specialitzation?degree_id=${degree_id || ''}`
    )
      .then((res) => {
        if (res.data) {
          const _specialities = res.data.length
            ? res.data.sort((a, b) => a.speciality_id - b.speciality_id)
            : [];
          setSpecializations(
            formatSelectOptionData(
              _specialities,
              'speciality_name',
              'speciality_id'
            )
          );
        }
      })
      .catch((err) => console.log('Fetching degrees failed', 'error'));
  };

  const getCountries = () => {
    setCountries([]);
    Axios.get(`${API_ENDPOINT}/StaticData/country-list`)
      .then((res) => {
        if (res.data) {
          //   setCountries(formatSelectOptionData(res.data, 'name', 'id'));
          setCountries(
            res?.data?.map((item) => ({
              name: item?.name,
              value: item?.id,
              country_code: item?.iso2,
              phone_code: item?.phonecode,
            }))
          );
          setDialCodeList(
            res?.data
              ?.map((item) => ({
                name: `+${item?.phonecode}`,
                value: item?.phonecode,
                country_code: item?.iso2,
                country_name: item?.name,
              }))
              .sort((a, b) => (a?.value?.[0] || 0) - (b?.value?.[0] || 0))
          );
          const _country = res?.data?.find(
            (item) => item.id === ClinicData?.country_id
          );
          if (_country?.id) {
            setSelectedCountryCode(_country.iso2);
            setReferrerCountryCode(_country.iso2);
            setData({
              ...data,
              phone_code: _country.phonecode,
              referrer_phone_code: _country.phonecode,
            });
          }
        }
      })
      .catch((err) => console.log('Fetching countries failed', 'error'));
  };

  const sendOtp = async () => {
    await checkIfUserExists({
      target: { name: 'email_id', value: data.email_id },
    });
    if (userExistsInDB || !data.email_id) return;
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Otp/email-otp`, { otp_email: data.email_id })
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData && resData.otp_sent === false) {
          setOtpSendStatus({
            success: false,
            message:
              resData.message ||
              "Couldn't sent OTP, plese check the email_id and try again.",
          });
        } else {
          setOtpSendStatus({
            success: true,
            message: 'OTP sent to your email.',
          });
        }
      })
      .catch((err) => {
        setOtpSendStatus({
          success: false,
          message: 'OTP creation failed',
        });
      })
      .finally(() => setShowLoading(false));
  };

  const verifyOtp = () => {
    if (!data.otp || data.otp.length !== 6 || Number.isNaN(data.otp)) {
      setData({ ...data, otp: '' });
      return;
    }
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Otp/verify-email-otp`, {
      otp: data.otp,
      otp_email: data.email_id,
    })
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData && resData.otp_verified === false) {
          setData({ ...data, otp: '' });
          setOtpVerifyStatus({
            success: false,
            message: 'OTP verifcation failed',
          });
        } else {
          setOtpVerifyStatus({
            success: true,
            message: 'OTP verified successfully',
          });
        }
      })
      .catch((err) => {
        setOtpVerifyStatus({
          success: false,
          message: `OTP verifcation failed:${err}`,
        });
      })
      .finally(() => setShowLoading(false));
  };

  const deleteAccount = () => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Providers/web_admin/delete_pro`, {
      provider_id,
      delete_ind: true,
      modified_by: 'any name',
    })
      .then((res) => {
        if (res.data.message === 'deleted successfully')
          showAlertBack(res.data.message);
        else showAlert(res.data.message);
      })
      .catch((err) => showAlert(`Delete failed: ${err.message}`, 'error'))
      .finally(() => setShowLoading(false));
  };

  const getDateValue = (value) => {
    if (!value) return value;
    return moment(value).format('YYYY-MM-DD');
  };

  const checkboxChangeHandler = ({ target: { checked, value } }) => {
    const copy = { ...data };
    copy[value] = checked;
    setData(copy);
  };

  const phoneNumberChangeHandler = ({ target: { checked, value } }) => {
    if (checked === false) {
      setData({
        ...data,
        phoneChangeStatus: !data.phoneChangeStatus,
        email_id: currentEmailId,
      });
    } else {
      setData({ ...data, phoneChangeStatus: !data.phoneChangeStatus });
    }
  };

  const nameIdChangeHandler = ({ target: { name, value } }) => {
    let selectData = {};
    switch (name) {
      case 'provider_type':
        setDegrees([]);
        setSpecializations([]);
        selectData = providerTypes.find((obj) => obj.value === parseInt(value));
        setData({
          ...data,
          provider_type: selectData.name,
          provider_type_id: selectData.value,
          degree_title: '',
          degree_id: undefined,
          speciality_name: '',
          speciality_id: undefined,
        });
        getDegrees(value);
        break;
      case 'degree_title':
        selectData = degrees.find((obj) => obj.value === parseInt(value));
        setData({
          ...data,
          degree_title: selectData.name,
          degree_id: selectData.value,
          speciality_name: '',
          speciality_id: undefined,
        });
        getSpecializations(value);
        break;
      case 'speciality':
        selectData = specializations.find(
          (obj) => obj.value === parseInt(value)
        );
        setData({
          ...data,
          speciality_name: selectData.name,
          speciality_id: selectData.value,
        });
        break;
      default:
        break;
    }
  };

  const sexChangeHandler = ({ target: { value: sex } }) =>
    setData({ ...data, sex });

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const showAlertBack = (msg) => {
    setAlertBackMsg(msg);
    setAlertBackShow(true);
  };

  const showAlertHome = (msg) => {
    setAlertHomeMsg(msg);
    setAlertHomeShow(true);
  };

  const cancelhandler = () => {
    navigate(-1);
  };

  const checkIfUserExists = async ({ target: { name, value } }) => {
    userExistsInDB = false;
    if (!value) return;
    setShowLoading(true);

    let api_route = '';
    let postData = {};
    if (name === 'reg_no') {
      api_route = 'is_user_exist_by_reg_no';
      postData = { reg_no: value };
    }
    if (name === 'phone_no') {
      api_route = 'is_user_exist_for_phone_no';
      postData = { phone_no: value };
    }
    if (name === 'email_id') {
      api_route = 'is_user_exist_by_email';
      postData = { email: value };
    }
    if (name === 'user_name') {
      api_route = 'is_user_exist_by_username';
      postData = { username: value };
    }
    postData.user_type = 'provider';
    await Axios.post(
      `${API_ENDPOINT}/UserAccount/web_admin/${api_route}`,
      postData
    )
      .then(({ data: resData }) => {
        if (resData.user_exists) {
          userExistsInDB = true;
          showAlert(
            `User already exists with the ${name
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}`
          );
          setData({
            ...data,
            [name]: '',
          });
        }
        setShowLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setShowLoading(false));
  };

  const submitHandler = () => {
    setShowLoading(true);
    const postData = { ...data };
    // console.log(data.date_of_birth);
    // consult_startdate
    if (postData.date_of_birth) {
      postData.date_of_birth = moment(postData.date_of_birth).format(
        'MM-DD-YYYY'
      );
    }
    Axios({
      data: provider_id
        ? {
            ...postData,
            provider_id,
            admin_modified_by: user_data.user_no,
            modified_by: user_data.user_no,
            is_active: true,
          }
        : {
            ...postData,
            created_by: user_data.user_no || 'myself',
            is_active: true,
          },
      headers: { 'Content-Type': 'application/json' },
      method: provider_id ? 'put' : 'post',
      url: provider_id
        ? `${API_ENDPOINT}/Providers/web_admin/edit_pro`
        : `${API_ENDPOINT}/Providers/web_admin/${
            postData.new_clinic_name ? 'reg_provider_with_clinic' : 'reg_pro'
          }`,
    })
      .then(({ data: { info } }) => {
        if (provider_id && info === 'provider updated successfully')
          showAlertBack(info);
        else if (
          info === 'provider added successfully' ||
          info ===
            'provider added successfully and connected with the home clinic' ||
          info ===
            'provider added successfully and connected with the choosen clinic'
        ) {
          if (pathname === '/provider') showAlertHome(info);
          else showAlertBack(info);
        } else showAlert(info);
      })
      .catch((err) => showAlert(`Submit failed${err.message}`, 'error'))
      .finally(() => setShowLoading(false));
  };

  const uploadImage = ({ imgBlob, fileNameWithExtension }) => {
    const fd = new FormData();
    fd.append('file', imgBlob, fileNameWithExtension);
    fd.append('upload_use_case', 'provider_img');
    setShowLoading(true);
    Axios({
      data: fd,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: `${API_ENDPOINT}/File/upload`,
    })
      .then(({ data: { info, file_id } }) => {
        data.image_id = file_id;
        setImgUploadStatus({
          success: true,
          message: 'Image uploaded successfully',
        });
      })
      .catch((err) =>
        setImgUploadStatus({
          success: false,
          message: 'Uploading failed please try again',
        })
      )
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    Axios.get(`${API_ENDPOINT}/ProviderTypeMaster/get-all`)
      .then((res) => {
        setProviderTypes(
          formatSelectOptionData(res.data, 'provider_type', 'provider_type_id')
        );
        return Axios.get(`${API_ENDPOINT}/Clinics/web_admin/clinic_dir`);
      })
      .then((res) => {
        setClinics(res.data);
        Axios.get(`${API_ENDPOINT}/Clinic_types/get-all`).then((res) => {
          setClinicTypes(
            res.data.map((item) => ({
              name: item.clinic_type,
              value: item.clinic_type_id,
            }))
          );
        });
        getCountries();
        if (provider_id) {
          setData({ ...data, provider_id, clinic_id });
          return Axios.get(
            `${API_ENDPOINT}/Providers/web_admin/get_for_edit/${provider_id}`
          ).then((res) => {
            const newData = { ...res.data[0] };
            if (newData.provider_type_id) getDegrees(newData.provider_type_id);
            if (newData && newData.email_id)
              setCurrentEmailId(newData.email_id);
            if (newData.degree_id) getSpecializations(newData.degree_id);
            if (newData.pin_code) getAreaFromPincode(newData.pin_code);
            newData.affliateStatus = !!newData.referrer_email_id;
            if (newData && newData.profile_pic) {
              setProfilePic(newData.profile_pic);
              setImgUploadStatus({ message: 'Click to update' });
            }
            setTimeout(() => {
              setData({ ...data, ...newData });
            }, 500);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        showAlert(`Loading Info failed: ${err.message}`, 'error');
      })
      .finally(() => setShowLoading(false));
    if (clinic_data && clinic_data.clinic_name)
      setPrimaryClinicName(clinic_data.clinic_name);
    // eslint-disable-next-line
  }, [provider_id]);

  return (
    <div style={{ paddingTop: '50px' }} className="container">
      {pathname === '/provider' && <BackButton to="/" />}
      <FormWizard
        constraints={constraints}
        headers={formHeaders}
        onSubmit={submitHandler}
        onCancel={cancelhandler}
        otpVerifyStatus={otpVerifyStatus}
        phoneChangeStatus={data.phoneChangeStatus}
        finishBtnName={provider_id ? 'Update' : 'Finish'}
        isEditMode={!!provider_id}
      >
        <div>
          {provider_id && (
            <div className="row">
              <div
                className="col-sm-6 checkbox-fade fade-in-info form-group"
                style={{ marginRight: '0px' }}
              >
                <label>
                  <input
                    checked={data.phoneChangeStatus}
                    name="phoneChangeStatus"
                    onChange={phoneNumberChangeHandler}
                    type="checkbox"
                    value={data.phoneChangeStatus}
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                  <span>
                    Change <b>{currentEmailId}</b> email address
                  </span>
                </label>
                <div className="messages" />
              </div>
            </div>
          )}
          {(data.phoneChangeStatus || !provider_id) && (
            <div className="row">
              <div className="col-md-6 form-group">
                <div
                  className="input-group input-group-button"
                  style={{ margin: '0px' }}
                >
                  <input
                    autoFocus
                    className="form-control"
                    name="email_id"
                    onChange={changeHandler}
                    placeholder="Email Address *"
                    type="text"
                    value={data.email_id}
                    disabled={provider_id && !data.phoneChangeStatus}
                  />
                  <span
                    className="bg-info input-group-addon"
                    id="basic-addon10"
                    onMouseDown={sendOtp}
                  >
                    Send OTP
                  </span>
                </div>
                <div className="messages" />
                <div style={{ color: otpSendStatus.success ? 'green' : 'red' }}>
                  {otpSendStatus.message}
                </div>
              </div>
              <div className="col-md-6 form-group">
                <div
                  className="input-group input-group-button m-b-20"
                  style={{ margin: '0px' }}
                >
                  <input
                    className="form-control"
                    name="otp"
                    onChange={changeHandler}
                    placeholder="OTP"
                    type="text"
                    value={data.otp}
                  />
                  <span
                    className="bg-info input-group-addon"
                    id="basic-addon10"
                    onClick={verifyOtp}
                  >
                    Verify
                  </span>
                </div>
                <div
                  className="messages"
                  style={{ color: otpVerifyStatus.success ? 'green' : 'red' }}
                >
                  {' '}
                  {otpVerifyStatus.message}{' '}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="first_name"
                onChange={changeHandler}
                placeholder="First Name *"
                type="text"
                value={data.first_name}
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="last_name"
                onChange={changeHandler}
                placeholder="Last Name *"
                type="text"
                value={data.last_name}
              />
              <div className="messages" />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div
                className="form-group row"
                style={{ justifyContent: 'center' }}
              >
                <div className="col-md-12 m-b-5 text-center text-muted">
                  Sex *
                </div>
                <label className="col-4 col-sm-2">
                  <div className="form-radio radio radiofill radio-info radio-inline">
                    <label>
                      <input
                        checked={data.sex === 'M'}
                        name="sex"
                        onChange={sexChangeHandler}
                        type="radio"
                        value="M"
                      />
                      <i className="helper" />
                      <span className="radio-label">Male</span>
                    </label>
                  </div>
                </label>
                <label className="col-4 col-sm-2">
                  <div className="form-radio radio radiofill radio-info radio-inline">
                    <label>
                      <input
                        checked={data.sex === 'F'}
                        name="sex"
                        onChange={sexChangeHandler}
                        type="radio"
                        value="F"
                      />
                      <i className="helper" />
                      <span className="radio-label">Female</span>
                    </label>
                  </div>
                </label>
                <div className="col-12 messages text-center" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row form-group">
                <div className="col-12 m-b-5 text-center text-muted">DOB</div>
                <div className="col-12">
                  <input
                    className="form-control"
                    name="date_of_birth"
                    onChange={dateChangeHandler}
                    placeholder="DOB"
                    type="date"
                    value={getDateValue(data.date_of_birth)}
                  />
                </div>
                <div className="col-12 messages" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <Select
                name="provider_type"
                onChange={nameIdChangeHandler}
                placeholder="Provider Type *"
                value={data.provider_type_id}
                values={providerTypes}
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <Select
                name="degree_title"
                onChange={nameIdChangeHandler}
                placeholder="Degree Title *"
                value={data.degree_id}
                values={degrees}
              />
              <div className="messages" />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <Select
                name="speciality"
                onChange={nameIdChangeHandler}
                placeholder="Specialization"
                value={data.speciality_id}
                values={specializations}
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <div className="m-b-5 text-center text-muted">
                Consult Start Date *
              </div>
              <input
                className="form-control"
                name="consult_startdate"
                onChange={dateChangeHandler}
                type="date"
                value={getDateValue(data.consult_startdate)}
              />
              <div className="messages" />
            </div>
          </div>

          <div className="row">
            <div className="col-12 form-group">
              <input
                className="form-control"
                name="additional_qualification"
                onChange={changeHandler}
                placeholder="Other / Additional Qualifications"
                type="text"
                value={data.additional_qualification}
              />
              <div className="messages" />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="reg_no"
                onChange={changeHandler}
                onBlur={checkIfUserExists}
                placeholder="Registration Number *"
                type="text"
                value={data.reg_no}
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="experience"
                onChange={changeHandler}
                placeholder="Years of Experience *"
                type="text"
                value={data.experience}
              />
              <div className="messages" />
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-2 text-center m-b-20">
              <ImageUpload
                uploadImageFn={uploadImage}
                newImgWidth={250}
                newImgHeight={250}
                name="image_id"
                thumbnail_img={profilePic}
              />
              <span
                style={{
                  color:
                    imgUploadStatus.success === true
                      ? 'green'
                      : imgUploadStatus.success === false
                      ? 'red'
                      : 'black',
                }}
              >
                {imgUploadStatus.message}
              </span>
              {/* {provider_id && (
                <button className="btn btn-danger m-b-10 m-t-10" type="button">
                  Delete Account
                </button>
              )} */}
            </div>
            <div className="col-md-10">
              <div className="row form-group" style={{ alignItems: 'center' }}>
                {(clinic_data && clinic_data.clinic_name) || clinic_name ? (
                  <div
                    className="col-sm-12 checkbox-fade fade-in-info form-group"
                    style={{ marginRight: '0px' }}
                  >
                    <label>
                      <input
                        checked
                        disabled
                        name="default_clinic"
                        type="checkbox"
                      />
                      <span className="cr">
                        <i className="cr-icon ion-checkmark txt-primary" />
                      </span>
                      <span>{`Connect with '${
                        clinic_name || clinic_data.clinic_name
                      }'`}</span>
                    </label>
                  </div>
                ) : (
                  <div className="col-12 input-group input-group-button">
                    <input
                      className="form-control"
                      name="clinic"
                      placeholder="My Connected Clinic *"
                      readOnly
                      type="text"
                      value={primaryClinicName}
                    />
                    <span
                      className="bg-info input-group-addon"
                      id="basic-addon10"
                      data-toggle="modal"
                      data-target="#clinics-modal"
                    >
                      <i className="ion-plus-round" style={{ margin: 0 }} />
                    </span>
                  </div>
                )}
                <div className="col-12 messages" />
              </div>

              {((provider_id && data.new_clinic_name) || !provider_id) && (
                <div className="row">
                  <div
                    className="col-sm-3 checkbox-fade fade-in-info form-group"
                    style={{ marginRight: '0px' }}
                  >
                    <label>
                      <input
                        checked={
                          data.create_new_clinic ||
                          (provider_id && data.new_clinic_name)
                        }
                        name="create_new_clinic"
                        onChange={checkboxChangeHandler}
                        type="checkbox"
                        value="create_new_clinic"
                        disabled={provider_id}
                      />
                      <span className="cr">
                        <i className="cr-icon ion-checkmark txt-primary" />
                      </span>
                      <span>I consult from home</span>
                    </label>
                    <div className="messages" />
                  </div>
                  {(data.create_new_clinic ||
                    (provider_id && data.new_clinic_name)) && (
                    <>
                      <div className="col-sm-9 form-group">
                        <input
                          className="form-control"
                          name="new_clinic_name"
                          onChange={changeHandler}
                          placeholder="Home Consult Name"
                          type="text"
                          value={data.new_clinic_name}
                          disabled={provider_id}
                        />
                        {!provider_id && (
                          <div>
                            Note: A new clinic will be created with the details
                            provided in this form.
                          </div>
                        )}
                        <div className="messages" />
                      </div>

                      {!provider_id && (
                        <div className="col-sm-6 form-group">
                          <Select
                            name="clinic_type_id"
                            onChange={changeHandler}
                            placeholder="Clinic Type *"
                            value={data.clinic_type}
                            values={clinicTypes}
                          />
                          <div className="messages" />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              <div>
                <LocationInput
                  countries={countries}
                  clinicAreaList={clinicAreaList}
                  setClinicAreaList={setClinicAreaList}
                  data={data}
                  setData={setData}
                  changeHandler={changeHandler}
                  setSelectedCountryCode={setSelectedCountryCode}
                />
              </div>

              {/* <div className="row">
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="pin_code"
                    onChange={changeHandler}
                    placeholder="Pincode *"
                    type="text"
                    value={data.pin_code}
                    autoComplete="none"
                  />
                  <div className="messages" />
                </div>
                <div className="col-sm-6 form-group">
                  <Select
                    name="area"
                    onChange={changeHandler}
                    placeholder="Choose Your Area *"
                    value={data.area}
                    values={clinicAreaList}
                  />
                  <div className="messages" />
                </div>
              </div> */}

              <div className="row">
                <div className="col-sm-6 form-group">
                  <PhoneInput
                    selectedCountryCode={selectedCountryCode}
                    selectedPhoneCode={data.phone_code}
                    phoneCodeList={dialCodeList}
                    onChangeHandler={changeHandler}
                    defaultPhoneNumber={data.phone_no}
                    onBlurHandler={checkIfUserExists}
                    phoneCodeInputName="phone_code"
                    inputName="phone_no"
                    inputLabel="Mobile No *"
                    onPhoneCodeChange={(phoneCode) => {
                      setData((prevData) => ({
                        ...prevData,
                        phone_code: phoneCode,
                      }));
                    }}
                  />
                  <div className="messages" />
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="user_name"
                    onChange={changeHandler}
                    onBlur={checkIfUserExists}
                    placeholder="Username *"
                    type="text"
                    value={data.user_name}
                    disabled={provider_id}
                  />
                  <div className="messages" />
                </div>
              </div>

              {!provider_id && (
                <div className="row">
                  <div className="col-sm-6 form-group">
                    <input
                      className="form-control"
                      name="user_password"
                      onChange={changeHandler}
                      placeholder="Password"
                      type="password"
                      value={data.user_password}
                    />
                    <div className="messages" />
                  </div>
                  <div className="col-sm-6 form-group">
                    <input
                      className="form-control"
                      name="confirm_password"
                      onChange={changeHandler}
                      placeholder="Confirm Password"
                      type="password"
                      value={data.confirm_password}
                    />
                    <div className="messages" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <p className="p-l-20">
              Note: <br />
              ** For the best result please upload an image(.png or .jpg) of
              width 250px and height 250px
            </p>
          </div>
        </div>
        <div>
          {(!provider_id || (provider_id && data.affliateStatus)) && (
            <div className="row">
              <div className="col-12 checkbox-fade fade-in-info form-group">
                <label>
                  <input
                    checked={data.affliateStatus}
                    name="affliateStatus"
                    onChange={() =>
                      setData({ ...data, affliateStatus: !data.affliateStatus })
                    }
                    type="checkbox"
                    value={data.affliateStatus}
                    disabled={provider_id}
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                  <span>Been referred by:</span>
                </label>
                <div className="messages" />
              </div>
            </div>
          )}
          {data.affliateStatus && (
            <>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <Select
                    name="referrer_type"
                    onChange={changeHandler}
                    placeholder="User Type *"
                    value={data.referrer_type}
                    values={ReferralTypes}
                    disabled={provider_id}
                  />
                  <div className="messages" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="referrer_first_name"
                    onChange={changeHandler}
                    placeholder="First Name *"
                    value={data.referrer_first_name}
                    type="text"
                    disabled={provider_id}
                  />
                  <div className="messages" />
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="referrer_last_name"
                    onChange={changeHandler}
                    placeholder="Last Name *"
                    value={data.referrer_last_name}
                    type="text"
                    disabled={provider_id}
                  />
                  <div className="messages" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 form-group">
                  <PhoneInput
                    selectedCountryCode={referrerCountryCode}
                    phoneCodeInputName="referrer_phone_code"
                    selectedPhoneCode={data.referrer_phone_code}
                    phoneCodeList={dialCodeList}
                    onChangeHandler={changeHandler}
                    defaultPhoneNumber={data.referrer_phone_no}
                    inputName="referrer_phone_no"
                    inputLabel="Mobile Number *"
                    maxLength={10}
                    disabled={provider_id}
                    onPhoneCodeChange={(phoneCode) => {
                      setData((prevData) => ({
                        ...prevData,
                        referrer_phone_code: phoneCode,
                      }));
                    }}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="referrer_email_id"
                    onChange={changeHandler}
                    placeholder="Email ID *"
                    value={data.referrer_email_id}
                    type="text"
                    disabled={provider_id}
                  />
                  <div className="messages" />
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-12 checkbox-fade fade-in-info form-group">
              <label>
                <input
                  checked={data.terms_accepted}
                  name="terms_accepted"
                  onChange={() =>
                    setData({ ...data, terms_accepted: !data.terms_accepted })
                  }
                  type="checkbox"
                  value={data.terms_accepted}
                />
                <span className="cr">
                  <i className="cr-icon ion-checkmark txt-primary" />
                </span>
                <span>
                  I agree to the{' '}
                  <a
                    href="/legal/terms.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms & conditions
                  </a>{' '}
                  and{' '}
                  <a
                    href="/legal/privacy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>
                </span>
              </label>
              <div className="messages" />
            </div>
          </div>
        </div>
      </FormWizard>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <Alert
        action={() => {
          navigate(-1);
        }}
        msg={alertBackMsg}
        setShow={setAlertBackShow}
        show={alertBackShow}
        type="success"
      />
      <Alert
        action={() => {
          navigate('/');
        }}
        msg={alertHomeMsg}
        setShow={setAlertHomeShow}
        show={alertHomeShow}
        type="success"
      />
      <DeleteConfirm onConfirm={deleteAccount} />
      <ModalBox
        changeHandler={primaryClinicChangeHandler}
        clinicData={clinics}
      />
      <LoadingBox show={showLoading} />
    </div>
  );
};

const ModalBox = ({ changeHandler, clinicData }) => {
  const [data, setData] = useState(clinicData);
  const [filters, setFilters] = useState({ clinic_type: '', search: '' });

  const filter = () => {
    const copy = data.map((row) => {
      if (
        (filters.clinic_type === '' ||
          row.clinic_type === filters.clinic_type) &&
        (filters.search === '' || searchRow(row))
      )
        return { ...row, display: true };
      return { ...row, display: false };
    });
    setData(copy);
  };

  const filterChangeHandler = ({ target: { name, value } }) =>
    setFilters({ ...filters, [name]: value });

  const filterData = (name) =>
    data
      .map((row) => row[name])
      .reduce((col, item) => (col.includes(item) ? col : [...col, item]), [])
      .map((item) => ({ name: item, value: item }));

  const loadData = (rows) =>
    rows
      .filter((row) => row.display)
      .map((row, index) => [
        // row.clinic_id,
        index + 1,
        <span className="text-capitalize">
          {row.clinic_name?.toLowerCase()}
        </span>,
        row.contact,
        `${row.area}${row.country ? `, ${row.country}` : ''}`,
        <button
          className="btn btn-outline-info btn-sm"
          data-dismiss="modal"
          onClick={() =>
            changeHandler({ name: row.clinic_name, id: row.clinic_id })
          }
        >
          Connect
        </button>,
      ]);

  const searchRow = (row) => {
    const keys = Object.keys(row);
    for (const i in keys) {
      const key = keys[i];
      const value = typeof row[key] === 'string' ? row[key] : `${row[key]}`;
      if (value.toLowerCase().includes(filters.search.toLowerCase()))
        return true;
    }
    return false;
  };

  useEffect(() => {
    setData(clinicData.map((row) => ({ ...row, display: true })));
    // eslint-disable-next-line
  }, [clinicData]);

  useEffect(() => {
    filter();
    // eslint-disable-next-line
  }, [filters]);

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="clinics-modal"
      role="dialog"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Clinic Directory</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row m-b-40" style={{ alignItems: 'center' }}>
              <div className="col-md-4">
                <Select
                  className="outlined"
                  name="clinic_type"
                  onChange={filterChangeHandler}
                  placeholder="Type"
                  values={filterData('clinic_type')}
                />
              </div>
              <div className="col-md-4">
                <input
                  className="form-control outlined"
                  name="search"
                  onChange={filterChangeHandler}
                  placeholder="Search"
                  type="text"
                  value={filters.search}
                />
              </div>
              <div className="col-12">
                <Table
                  data={loadData(data)}
                  headings={clinicHeadings}
                  number={7}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderAdd;
