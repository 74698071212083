/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import { Link } from 'react-router-dom';

import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import { API_ENDPOINT } from '../../config/api';
import Select from '../form/Select';
import Table from '../layout/Table';
import useInterval from '../../_helpers/use_interval_hook';
import { calculateAge } from '../../_helpers/misc';
import { usePageDetails } from '@/hooks/useUser';

let lastAppntTime = null;
let lastUpdatedTime = null;

const week = {
  mon: 'M',
  tue: 'T',
  wed: 'W',
  thu: 'T',
  fri: 'F',
  sat: 'S',
  sun: 'S',
};

const initialFilters = { search_term: '', showPending: false };

const _date = moment().format('YYYY-MM-DD');

const _timeFormat = 'HH:mm:ss';

const _localDateTimeFormat = 'DD-MM-YYYY hh:mm A';

const CheckboxData = ({
  checkInType,
  is_disabled = false,
  appointment = {},
  checkinTime,
  checkinChangeFn,
}) => (
  <div style={{ display: 'flex' }}>
    <div
      className="j-pro"
      style={{
        background: 'transparent',
        border: 'none',
        display: 'inline-flex',
        minWidth: 22,
        paddingLeft: '15px',
      }}
    >
      <div
        className="checkbox-fade fade-in-primary"
        style={{ background: '#fff', margin: 0 }}
      >
        <label>
          <input
            disabled={is_disabled}
            checked={checkinTime}
            name="terms"
            type="checkbox"
            onChange={({ target: { checked } }) =>
              checkinChangeFn(checked, checkInType, appointment.appointment_id)
            }
          />
          <span className="cr" style={{ background: '#fff', margin: 0 }}>
            <i className="cr-icon ion-checkmark txt-primary" />
          </span>
        </label>
      </div>
    </div>
    <span style={{ paddingLeft: '15px' }}>
      {checkinTime ? moment(`${_date} ${checkinTime}`).format('hh:mm A') : ''}
    </span>
  </div>
);

const _tableHeadings = [
  'UIN',
  <>
    Token No <i className="fa fa-sort-amount-asc" />
  </>,
  <>
    Patient <i className="fa fa-sort-amount-asc" />
  </>,
  'Phone No',
  'Booking Date',
  'OP_Booking',
  //   'OP',
];

const CheckIn = () => {
  const {
    preferences,
    user_data,
    clinic_data,
    doctors,
    schedules,
    procedures,
    navigate,
    pathname,
    user,
  } = usePageDetails();
  const tempSpecialityList =
    doctors?.reduce((unique, o) => {
      if (!unique.some((obj) => obj.value === o.speciality_id)) {
        unique.push({ name: o.speciality_name, value: o.speciality_id });
      }
      return unique;
    }, []) || [];
  tempSpecialityList.unshift({ name: 'All Departments', value: '' });
  const _dotorsFullList = doctors?.map((doc) => ({
    name:
      doc.is_rotation === true
        ? `${doc.rotation_name} - ${doc.name}`
        : doc.name,
    value: doc.provider_id,
    speciality_id: doc.speciality_id,
  }));
  const getTempDoctorsList = (date) => {
    if (!doctors) return [];
    const dr_list = _dotorsFullList.filter((doc) => {
      const shdl = schedules.find((shdl) => shdl.provider_id === doc.value);
      const _dayName = moment(date).format('ddd').toLowerCase();
      return shdl && shdl[_dayName] === true;
    });
    dr_list.unshift({ name: 'Choose Doctor', value: '' });
    return dr_list;
  };
  let tempDoctorList = getTempDoctorsList(_date);

  const tempDateList = [...Array(3)].map((val, index) => {
    const date = moment().add(index, 'days');
    return {
      name: date.format('DD-MM-YYYY'),
      value: date.format('YYYY-MM-DD'),
    };
  });
  const _scheduleFullList = schedules.map((schdl) => ({
    ...schdl,
    name: `${moment(`${_date} ${schdl.slot_start}`).format(
      'hh:mm A'
    )} to ${moment(`${_date} ${schdl.slot_end}`).format('hh:mm A')}`,
    value: schdl.schedule_id,
  }));

  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);
  const [dateList, setDateList] = useState(tempDateList);
  const [specialityList, setSpecialityList] = useState(tempSpecialityList);
  const [doctorList, setDoctorList] = useState(tempDoctorList);
  const [selectedDate, setSelectedDate] = useState(_date);
  const [selectedSpecialty, setSelectedSpecialty] = useState(undefined);
  const [selectedDoctor, setSelectedDoctor] = useState(undefined);
  const [scheduleList, setScheduleList] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [tableHeadings, setTableHeadings] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [specialitySummary, setSpecialitySummary] = useState({});
  const [totalTokens, setTotalTokens] = useState(0);
  const [totalBookedTokens, setTotalBookedTokens] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [lastRefreshedTime, setLastRefreshedTime] = useState(null);
  const [refreshtimeFromNow, setRefreshtimeFromNow] = useState(null);

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const dateChangeHandler = ({ target: { name, value } }) => {
    lastAppntTime = null;
    lastUpdatedTime = null;
    if (Number.isNaN(Date.parse(value))) return;
    tempDoctorList = getTempDoctorsList(value);
    setDoctorList(tempDoctorList);
    setSelectedDate(value);
    const headings = [..._tableHeadings];
    if (selectedSchedule && selectedSchedule.has_web_checkin) {
      headings.push(
        <>
          W-Check in <i className="fa fa-sort-amount-asc" />
        </>
      );
    }
    // if (
    //   selectedSchedule &&
    //   selectedSchedule.has_clinic_checkin &&
    //   value === _date
    // ) {
    //   headings.push('C-Check in');
    // }
    setTableHeadings(headings);
    if (selectedSchedule && selectedSchedule.schedule_id) {
      getAppointments(selectedSchedule.schedule_id, value);
    }
  };

  const specialityChangeHandler = ({ target: { name, value } }) => {
    lastAppntTime = null;
    lastUpdatedTime = null;
    setDoctorList([]);
    setSelectedDoctor(undefined);
    setSelectedSpecialty(parseInt(value));
    if (value) {
      const doctors = tempDoctorList.filter(
        (obj) => obj.speciality_id === parseInt(value)
      );
      setDoctorList(doctors);
    } else {
      setDoctorList(tempDoctorList);
    }
  };

  const providerChangeHandler = ({ target: { name, value } }) => {
    lastAppntTime = null;
    lastUpdatedTime = null;
    const _dayName = moment(selectedDate).format('ddd').toLowerCase();
    const _schedules = _scheduleFullList.filter((obj) => {
      // let time = moment(),
      // 	_schedule_start = moment(obj.slot_start, _timeFormat),
      // 	_schedule_end = moment(obj.slot_end, _timeFormat);
      // return ((obj.provider_id === parseInt(value)) && (obj[_dayName] === true) && (time.isBetween(_schedule_start, _schedule_end)))
      // obj.provider_id === parseInt(value) && obj[_dayName] === true
      if (user_data.user_role === 'provider') {
        return (
          obj.clinic_id === parseInt(preferences?.selectedClinicId) &&
          obj[_dayName] === true
        );
      }
      return obj.provider_id === parseInt(value) && obj[_dayName] === true;
    });
    setSelectedDoctor(value);
    setScheduleList(_schedules);
    if (_schedules && _schedules.length) {
      const time = moment();
      const recentSchedules = _schedules.filter((schdl) => {
        const _schedule_start = moment(schdl.slot_start, _timeFormat);
        const _schedule_end = moment(schdl.slot_end, _timeFormat);
        return time.isBetween(_schedule_start, _schedule_end);
      });
      setSelectedSchedule(recentSchedules?.[0] || _schedules[0]);
    } else {
      setSelectedSchedule({});
    }
  };

  const scheduleChangeHandler = ({ target: { value } }) => {
    lastAppntTime = null;
    lastUpdatedTime = null;
    setAppointmentData([]);
    const _shchedule = scheduleList.find(
      (schdl) => schdl.schedule_id === parseInt(value)
    );
    setSelectedSchedule(_shchedule || {});
  };

  const checkinChangeHandler = (
    check_in_status,
    check_in_type,
    appointment_id
  ) => {
    if (
      typeof check_in_status !== 'boolean' ||
      !check_in_type ||
      !appointment_id
    )
      return;
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/PatientAppointments/do_checkin`, {
      appointment_id,
      check_in_type, // web || clinic
      check_in_time: check_in_status ? moment().format('HH:mm:ss') : null,
      user_no: user_data.user_no || 'byme',
    })
      .then(({ data: resData }) => {
        if (selectedSchedule && selectedSchedule.schedule_id) {
          getAppointments(selectedSchedule.schedule_id, selectedDate);
        }
      })
      .catch((err) => showAlert('Updating checkin status failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const loadAppointmentTable = (data) =>
    data
      .filter((row) => row.display)
      .map((row, index) => {
        const tableRow = [
          <span>
            {row.patient.uin}{' '}
            {row.appointment_note ? (
              <i
                className="ion-chatbox cursor-pointer"
                onClick={() => showAppointmentNote(row.appointment_note)}
              />
            ) : (
              ''
            )}
          </span>,
          row.token_number,
          <span>
            <Link to={`/patient/edit/${row.patient.id}`}>
              {row.patient.name}
            </Link>{' '}
            <span className="_mx-1 _text-slate-600">&#8226;</span>
            <span className="_text-purple-600 _text-xs">
              {calculateAge(row.patient?.date_of_birth)}
            </span>
            <span className="_mx-1 _text-slate-600">&#8226;</span>{' '}
            {row.patient.gender}
          </span>,
          row.patient.phone,
          row.appointment_date,
          row.booking_status === 'cancelled' ? (
            <div>
              Cancelled By <br />
              {row.modify_by}
            </div>
          ) : row.initial_op_time ? (
            moment(`${_date} ${row.initial_op_time}`).format('hh:mm A')
          ) : (
            '00:00 XX'
          ),
          //   row.booking_status === 'cancelled'
          //     ? 'Cancelled'
          //     : row.op_time
          //     ? getCurrentOPTime(row.op_time, index, row.is_op_active)
          //     : '00:00 XX',
        ];
        if (selectedSchedule && selectedSchedule.has_web_checkin)
          tableRow.push(
            // row.web_checkin_time ? moment(_date + ' ' + row.web_checkin_time).format('hh:mm A') : 'Not Done'
            row.booking_status === 'cancelled' ? (
              'Cancelled'
            ) : (
              <CheckboxData
                checkInType="web"
                appointment={row}
                checkinTime={row.web_checkin_time || ''}
                checkinChangeFn={checkinChangeHandler}
                is_disabled={isWebCheckinAllowed(selectedSchedule.slot_start)}
              />
            )
          );
        // if (
        //   selectedSchedule &&
        //   selectedSchedule.has_clinic_checkin &&
        //   selectedDate === _date
        // )
        //   tableRow.push(
        //     <CheckboxData
        //       is_disabled={
        //         selectedSchedule.has_web_checkin &&
        //         (!row.web_checkin_time || row.web_checkin_time.length < 1)
        //       }
        //       checkInType="clinic"
        //       appointment={row}
        //       checkinTime={row.clinic_checkin_time || ''}
        //       checkinChangeFn={checkinChangeHandler}
        //     />
        //   );
        return tableRow;
      });

  const getBookingSummary = (clinic_id, date = _date) => {
    setShowLoading(true);
    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/get_booking_summary?clinic_id=${clinic_id}&booking_date=${date}`
    )
      .then(({ data: resData }) => {
        if (resData && resData.appointments) {
          setSpecialitySummary(resData.appointments || {});
          setTotalTokens(resData.total_tokens || 0);
          setTotalBookedTokens(resData.booked_tokens || 0);
        }
      })
      .catch((err) => showAlert('Fetching summary failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const getBookingsCountForSpeciality = (bookingList) => {
    const totalTokenCount = bookingList.reduce(
      (prev, cur) => prev + parseInt(cur.total_token_number),
      0
    );
    const bookedTokenCount = bookingList.reduce(
      (prev, cur) => prev + parseInt(cur.booked_count),
      0
    );
    return `${bookedTokenCount}/${totalTokenCount}`;
  };

  const getAppointments = (schedule_id, date = _date) => {
    setAppointmentData(
      appointmentData.map((app) => {
        app.initial_op_time = null;
        app.op_time = null;
        return app;
      })
    );
    // setShowLoading(true);
    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/load_new_bookings_for_schedule?schedule_id=${schedule_id}&appointment_date=${date}`
    )
      .then(({ data: resData }) => {
        if (resData && resData.length) {
          setAppointmentData(resData.map((row) => ({ ...row, display: true })));
          setTimeout(() => {
            setFilters({ ...filters, showPending: filters.showPending });
          }, 100);
        } else {
          setAppointmentData([]);
        }
        setLastRefreshedTime(moment());
      })
      .catch((err) => {
        setAppointmentData([]);
        showAlert('Fetching appointments failed', 'error');
      })
      .finally(() => setShowLoading(false));
  };

  const getCheckinTimeRange = (scheduleStartsAt, scheduleEndsAt) => {
    const webCheckinEndsBefore = moment.duration('12:00:00');
    const startsAt = moment(`${_date} ${scheduleStartsAt}`)
      .subtract(3, 'days')
      .format(_localDateTimeFormat);
    const endsAt = moment(`${_date} ${scheduleStartsAt}`)
      .subtract(webCheckinEndsBefore)
      .format(_localDateTimeFormat);
    return (
      <>
        {startsAt}
        <span className="h5 font-weight-bold">-</span>
        {endsAt}
      </>
    );
  };

  const isWebCheckinAllowed = (scheduleStartsAt) => {
    const _now = moment(new Date());
    const scheduleStart = moment(`${_date} ${scheduleStartsAt}`);
    const durationToOP = moment.duration(scheduleStart.diff(_now));
    return durationToOP.asHours() > 12;
  };

  const searchRow = (row, showPending) => {
    const keys = Object.keys(row);
    for (const i in keys) {
      const key = keys[i];
      const value =
        typeof row[key] === 'string' ? row[key] : JSON.stringify(row[key]);
      if (!showPending) {
        if (value.toLowerCase().includes(filters.search_term.toLowerCase())) {
          return true;
        }
      } else if (showPending === true && !filters.search_term) {
        if (!row.web_checkin_time || !row.web_checkin_time.length) {
          return true;
        }
      } else if (showPending === true && filters.search_term) {
        if (
          (!row.web_checkin_time || !row.web_checkin_time.length) &&
          value.toLowerCase().includes(filters.search_term.toLowerCase())
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const filter = () => {
    const copy = appointmentData.map((row) => {
      if (filters.showPending === false && filters.search_term === '')
        return { ...row, display: true };
      if (searchRow(row, filters.showPending)) return { ...row, display: true };
      return { ...row, display: false };
    });
    setAppointmentData(copy);
  };

  const filterChangeHandler = ({ target: { name, value } }) => {
    setFilters({ ...filters, [name]: value });
  };

  const showAppointmentNote = (note) => {
    showAlert(note, 'alert');
  };

  const forceRefresh = () => {
    getAppointments(selectedSchedule.schedule_id, selectedDate);
  };

  useInterval(() => {
    setRefreshtimeFromNow(lastRefreshedTime?.fromNow() || '');
  }, 1000);

  useEffect(() => {
    const headings = [..._tableHeadings];
    if (selectedSchedule && selectedSchedule.has_web_checkin) {
      headings.push(
        <>
          W-Check in <i className="fa fa-sort-amount-asc" />
        </>
      );
    }
    // if (
    //   selectedSchedule &&
    //   selectedSchedule.has_clinic_checkin &&
    //   selectedDate === _date
    // ) {
    //   headings.push('C-Check in');
    // }
    setTableHeadings(headings);
    if (selectedSchedule && selectedSchedule.schedule_id) {
      getAppointments(selectedSchedule.schedule_id, selectedDate);
    }
  }, [selectedSchedule]);

  useEffect(() => {
    filter();
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    getBookingSummary(preferences?.selectedClinicId, selectedDate);
    // eslint-disable-next-line
  }, [preferences?.selectedClinicId]);

  return (
    <>
      <div className="row">
        <div
          className="col-12"
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        >
          <div className="panel panel-info">
            <div className="panel-heading bg-info rounded">
              <h5>OP Summary</h5>
            </div>
            <div className="m-10 panel-body">
              <div className="row m-b-10">
                <div className="col-8 col-md-2">
                  <b>Total Appointments</b>
                </div>
                <div className="col-4 col-md-2">
                  {totalBookedTokens}/{totalTokens}
                </div>
                {specialitySummary && Object.keys(specialitySummary).length
                  ? Object.keys(specialitySummary).map((speciality, index) => (
                      <div className="col-6 col-md-2">
                        {speciality}:{' '}
                        {getBookingsCountForSpeciality(
                          specialitySummary[speciality]
                        )}
                      </div>
                    ))
                  : ''}
              </div>
              {/* <div className='row m-b-10'>
								<div className='col-8 col-md-2'>
									<b>Total Paid Online</b>
								</div>
								<div className='col-4 col-md-2'>175/250</div>
								<div className='col-6 col-md-2'>Dept 1: 8/50</div>
								<div className='col-6 col-md-2'>Dept 2: 8/50 </div>
								<div className='col-6 col-md-2'>Dept 3: 8/50</div>
								<div className='col-6 col-md-2'>Dept 4: 8/50</div>
							</div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card hex-sm-card-border-top p-20">
        <div className="row">
          <div className="col-md-8 col-lg-9">
            <div className="row m-b-20">
              <div className="col-md-4 m-b-10 text-center">
                {/* <input className='form-control outlined'
									onChange={dateChangeHandler}
									placeholder='Choose Date'
									type='date'
									value={selectedDate} /> */}
                <Select
                  className="outlined"
                  values={dateList}
                  value={selectedDate}
                  onChange={dateChangeHandler}
                />
              </div>
              <div className="col-md-4 m-b-10">
                <Select
                  className="outlined"
                  values={specialityList}
                  value={selectedSpecialty}
                  onChange={specialityChangeHandler}
                />
              </div>
              <div className="col-md-4 m-b-10">
                <Select
                  className="outlined"
                  values={doctorList}
                  value={selectedDoctor}
                  onChange={providerChangeHandler}
                />
              </div>
            </div>
            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-md-4 m-b-10">
                <input
                  className="form-control outlined"
                  name="search_term"
                  onChange={filterChangeHandler}
                  placeholder="Search here"
                  tabIndex={1}
                  type="text"
                  value={filters.search_term}
                />
              </div>
              <div className="col-md-6 m-b-10">
                <span className="m-r-20">Web Checkin:-</span>
                <div
                  className="form-radio radio radiofill radio-info radio-inline"
                  style={{ display: 'inline-flex' }}
                >
                  <label>
                    <input
                      checked={!filters.showPending}
                      type="radio"
                      onChange={() =>
                        setFilters({ ...filters, showPending: false })
                      }
                    />
                    <i className="helper" />
                    <span className="radio-label">All</span>
                  </label>
                </div>
                <div
                  className="form-radio radio radiofill radio-info radio-inline"
                  style={{ display: 'inline-flex' }}
                >
                  <label>
                    <input
                      checked={filters.showPending}
                      type="radio"
                      onChange={() =>
                        setFilters({ ...filters, showPending: true })
                      }
                    />
                    <i className="helper" />
                    <span className="radio-label">Pending</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {selectedSchedule && selectedSchedule.schedule_id ? (
            <div className="col-md-4 col-lg-3 text-center">
              <div
                className="bg-default w-100"
                style={{
                  borderRadius: '5px',
                  color: 'rgb(0, 0, 0)',
                  display: 'inline-block',
                  padding: '6px',
                }}
              >
                <Select
                  className="outlined"
                  values={scheduleList}
                  value={selectedSchedule.schedule_id}
                  onChange={scheduleChangeHandler}
                />
              </div>
              <div
                className="rounded border pb-2"
                style={{ marginTop: '-8px' }}
              >
                <p
                  className="m-t-10"
                  style={{ marginBottom: '0px', fontSize: '12px' }}
                >
                  *Web Checkin Window
                  <br />
                  {getCheckinTimeRange(
                    selectedSchedule.slot_start,
                    selectedSchedule.slot_end
                  )}
                </p>
                <div className="m-t-10">
                  {Object.keys(week).map((key, index) => (
                    <div
                      className={
                        selectedSchedule[key] ? 'bg-inverse' : 'bg-default'
                      }
                      key={index}
                      style={{
                        alignItems: 'center',
                        borderRadius: '50%',
                        display: 'inline-flex',
                        fontSize: '11px',
                        justifyContent: 'center',
                        height: '20px',
                        width: '20px',
                        marginRight: '10px',
                      }}
                    >
                      {week[key]}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {selectedSchedule && selectedSchedule.schedule_id && (
        <p>
          {refreshtimeFromNow ? (
            <span className="text-danger font-weight-bold">
              Last updated {refreshtimeFromNow}{' '}
            </span>
          ) : null}
          <span
            className="bg-inverse"
            style={{
              borderRadius: '3px',
              margin: '0px 6px 0px 3px',
              padding: '3px 6px',
              cursor: 'pointer',
            }}
            onClick={() => forceRefresh()}
          >
            <i className="fa fa-refresh" /> Refresh List
          </span>
        </p>
      )}
      {selectedSchedule &&
      selectedSchedule.schedule_id &&
      appointmentData.length ? (
        <Table
          headings={tableHeadings}
          data={loadAppointmentTable(appointmentData)}
          number={15}
        />
      ) : (
        'No appointments found'
      )}
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default CheckIn;
