/* eslint-disable import/prefer-default-export */
import React from 'react';
import { showCurrencyCode } from '../../common/showCurrency';

export const BillingSheet = ({
  drName,
  drCode,
  drSpecialization,
  drAddQualification,
  drdegree,
  clinicLogo,
  weekdays,
  clinicTime,
  clinicPlace,
  clinicPhone,
  patientName,
  patientGender,
  patientAge,
  patientMobile,
  patientPlace,
  patientUPN,
  fileNo,
  paymentMode,
  billDate,
  tokenNo,
  consultType,
  billInfo,
  totalAmount,
  userName,
  freevisit,
  billNo,
  currency = showCurrencyCode(),
  print_token_display,
  print_provider_header,
  print_clinic_header,
  message,
}) => (
  <div
    style={{
      height: '841.89px',
      width: '595.28px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#666',
      fontSize: 14,
      fontWeight: 400,
    }}
  >
    <div
      style={{
        height: '130px',
        width: '100%',
        backgroundColor: '#f9fafe',
        display: 'flex',
        padding: '20px 20px 5px 20px',
        justifyContent: 'space-between',
      }}
    >
      {print_provider_header ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: '50%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: 18,
                fontWeight: 600,
                alignItems: 'flex-end',
              }}
            >
              <span>{drName}</span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                fontSize: 12,
              }}
            >
              {drdegree}, {drSpecialization}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                fontSize: 12,
              }}
            >
              {drAddQualification}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                fontSize: 12,
              }}
            >
              {weekdays}
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: 10,
              }}
            >
              {clinicTime}
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {print_clinic_header && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            fontSize: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              // borderWidth: 2,
              // borderColor: "#000",
              overflow: 'hidden',
            }}
          >
            <img
              src={clinicLogo}
              style={{
                width: '70px',
                height: '70px',
              }}
              alt="logo"
              width="70px"
              height="70px"
            />
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            {clinicPlace}
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            {clinicPhone}
          </div>
        </div>
      )}
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#fff',
        width: '100%',
        flexGrow: 1,
        padding: '10px 30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '0 40px',
          marginBottom: '20px',
        }}
      >
        {/* General details */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderWidth: 1,
            borderColor: '#94a3b8',
            padding: '10px',
            borderRadius: '10px',
            width: '100%',
            backgroundColor: '#f9fafe',
            fontSize: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              {patientName}
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              Age: {patientAge} Sex :{' '}
              {patientGender === 'F' ? 'Female' : 'Male'}
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              Ph: {patientMobile}
            </div>

            {patientPlace && (
              <div
                style={{
                  display: 'flex',
                }}
              >
                Place: {patientPlace}
              </div>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
            }}
          >
            {fileNo && (
              <div
                style={{
                  display: 'flex',
                }}
              >
                File No: {fileNo}
              </div>
            )}
            {patientUPN && (
              <div
                style={{
                  display: 'flex',
                }}
              >
                EMR No: {patientUPN}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: 12,
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          {billNo && (
            <div
              style={{
                display: 'flex',
              }}
            >
              Reciept: #{billNo}
            </div>
          )}
          {paymentMode && (
            <div
              style={{
                display: 'flex',
              }}
            >
              MOP: {paymentMode}
            </div>
          )}
          <div
            style={{
              display: 'flex',
            }}
          >
            Dr. Code: {drCode}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
          }}
        >
          {tokenNo && print_token_display && (
            <div
              style={{
                display: 'flex',
              }}
            >
              {consultType} Token No: #{tokenNo}
            </div>
          )}
          <div
            style={{
              display: 'flex',
            }}
          >
            Date: {billDate}
          </div>

          {/* <div
            style={{
              display: 'flex',
            }}
          >
            OP Latest Issue: 08:56 AM
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            OP Arrival: 08:59 AM
          </div> */}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '15px',
          fontSize: 12,
          lineHeight: 1.2,
        }}
      >
        {/*  Treatments */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '5px',
          }}
        >
          {billInfo.length > 0 && (
            <table
              style={{
                width: '100%',
                borderColor: '#b9c1cc',
                borderWidth: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <thead
                style={{
                  display: 'flex',
                  padding: '5px',
                  width: '100%',
                  fontSize: 10,
                  backgroundColor: '#b9c1cc',
                  fontWeight: 600,
                }}
              >
                <th
                  style={{
                    width: '10%',
                    padding: '0 3px',
                  }}
                >
                  Type
                </th>
                <th
                  style={{
                    width: '15%',
                    padding: '0 3px',
                  }}
                >
                  SL
                </th>
                <th
                  style={{
                    flexGrow: 1,
                  }}
                >
                  Description
                </th>

                <th
                  style={{
                    width: '15%',
                    padding: '0 7px',
                  }}
                >
                  Price
                </th>
              </thead>
              <tbody
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: 10,
                }}
              >
                {billInfo.map((e, i) => (
                  <tr
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    key={e.name}
                  >
                    <td
                      style={{
                        borderColor: '#b9c1cc',
                        borderWidth: 0.5,
                        padding: '5px',
                        width: '10%',
                      }}
                    >
                      {e.type}
                    </td>
                    <td
                      style={{
                        borderColor: '#b9c1cc',
                        borderWidth: 0.5,
                        padding: '5px',
                        width: '15%',
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        flexGrow: 1,
                        borderColor: '#b9c1cc',
                        borderWidth: 0.5,
                        padding: '5px',
                      }}
                    >
                      {e.name}
                    </td>

                    <td
                      style={{
                        width: '15%',
                        borderColor: '#b9c1cc',
                        borderWidth: 0.5,
                        padding: '5px',
                      }}
                    >
                      {e.final || 0} {currency}
                    </td>
                  </tr>
                ))}

                <tr
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <td
                    style={{
                      flexGrow: 1,
                      borderColor: '#b9c1cc',
                      borderWidth: 0.5,
                      padding: '5px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    Grand Total
                  </td>

                  <td
                    style={{
                      width: '15%',
                      borderColor: '#b9c1cc',
                      borderWidth: 0.5,
                      padding: '5px',
                      fontWeight: 600,
                    }}
                  >
                    {totalAmount} {currency}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        {message && (
          <div
            style={{
              margin: '10px 0',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              fontSize: 12,
              whiteSpace: 'pre-line',
            }}
          >
            {message}
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '15px',
          fontSize: 12,
          lineHeight: 1.2,
        }}
      >
        {/*  Instructions */}

        {freevisit ? (
          <span
            style={{
              color: '#229954',
            }}
          >
            Free revisit upto: {freevisit}
          </span>
        ) : (
          <span />
        )}
        {userName && <span>Posted by: {userName}</span>}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '10px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {/*  Sign */}
        {
          //      <img
          //         src="https://iili.io/J13TVs4.md.png"
          //         style={{
          //           width: '100px',
          //           height: '50px',
          //         }}
          //         alt="sign"
          //       />
        }
        <div
          style={{
            fontSize: 12,
          }}
        >
          Patient Signature
        </div>
        <div
          style={{
            fontSize: 12,
          }}
        >
          Authorized Signature
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
        }}
      />
    </div>
    {/* <div
      style={{
        display: 'flex',
        backgroundColor: '#f9fafe',
        height: '25px',
        width: '100%',
        fontSize: 8,
        alignItems: 'center',
        padding: '0 20px',
      }}
    >
    
      This is a digital receipt generated from www.unqscs.com
    </div> */}
  </div>
);
