import React, { useEffect, useState } from 'react';
// import Calendar from 'react-calendar';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import useSWRImmutable from 'swr/immutable';
import PaymentModal from './PaymentModal';

import Alert from './Alert';
import LoadingBox from './LoadingBox';
import { API_ENDPOINT } from '../../config/api';
import { postFetcher } from '../../_helpers/swr';
import { useCalculateFreeVisitPeriod } from '../common/func';
import showCurrency from '../common/showCurrency';
import BlankSheetPrintButton from '../pages/pdf/BlankSheet';
import { usePageDetails } from '@/hooks/useUser';

let clinic_id;
let clinic_no;
let new_appointment_id;
const _date = moment().format('YYYY-MM-DD');
let _durationToOP;

const ModalBox = ({
  patient_details,
  date,
  slot,
  checkin_time,
  consultation_charge,
  provider_id,
  is_tatkal,
  cancelAppointment,
  previous_apmnt_id,
}) => {
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);
  const [appointmentNote, setAppointmentNote] = useState('');

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const confirmAppointment = () => {
    if (showLoading) return;
    const appointment_data = {
      clinic_no,
      clinic_id,
      appointment_id: new_appointment_id,
      person_id: patient_details.person_id,
      appointment_note: appointmentNote,
      booking_status: 'confirmed',
      provider_id,
      doAutoCheckin:
        is_tatkal || (_durationToOP && _durationToOP.asHours() <= 18), // Auto web checkin state
      autoWebCheckinTime: moment().format('HH:mm:ss'),
    };
    setShowLoading(true);
    Axios.post(
      `${API_ENDPOINT}/MedicalStoreAppointment/update_patient_appointment`,
      appointment_data
    )
      .then(() => {
        if (previous_apmnt_id && new_appointment_id) {
          Axios.post(
            `${API_ENDPOINT}/MedicalStoreAppointment/add_transferred_apmnt_id`,
            {
              previous_apmnt_id,
              new_appointment_id,
            }
          );
        }
      })
      .catch((err) => showAlert('Confirming appointment failed', 'error'))
      .finally(() => {
        setShowLoading(false);
        // getSlots(schedule_id, booking_date)  INVOKE THE FUNCTION OF PARENT
      });
  };

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="slot-modal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
      style={{ backgroundColor: '#000000aa' }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm details</h5>
          </div>
          <div className="modal-body text-center">
            <div className="row m-b-40">
              <div className="col-md-4 m-t-10">
                Date: {date ? moment(date).format('DD-MM-YYYY') : ''}
              </div>
              <div className="col-md-4 m-t-10">Slot: {slot}</div>
              <div className="col-md-4 m-t-10">
                {checkin_time ? `Clinic Check In: ${checkin_time}` : ''}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 m-b-10">
                <input
                  autoFocus
                  className="form-control outlined"
                  name="patient"
                  placeholder="Patient *"
                  type="text"
                  defaultValue={`${patient_details.first_name} ${patient_details.last_name}`}
                />
              </div>
              <div className="col-md-6 m-b-10">
                <input
                  className="form-control outlined"
                  name="mobile"
                  placeholder="Mobile *"
                  type="text"
                  defaultValue={
                    patient_details.phone_no ||
                    patient_details.booked_by_fmly_person_phone
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 m-b-10">
                <input
                  className="form-control outlined"
                  name="email-address"
                  placeholder="Email Address *"
                  type="text"
                  defaultValue={patient_details.email_id}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 m-b-10">
                <input
                  className="form-control outlined"
                  name="blood-group"
                  placeholder="Blood Group *"
                  type="text"
                  defaultValue={patient_details.blood_group}
                />
              </div>
              <div className="col-md-6 m-b-10">
                <input
                  className="form-control outlined"
                  name="family-name"
                  placeholder="Family Name *"
                  type="text"
                  defaultValue={patient_details.family_name}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 m-b-10">
                <input
                  className="form-control outlined"
                  name="blood-group"
                  placeholder="Emergency Contact Name *"
                  type="text"
                  defaultValue={patient_details.em_contact_name}
                />
              </div>
              <div className="col-md-6 m-b-20">
                <input
                  className="form-control outlined"
                  name="family-name"
                  placeholder="Emergency Contact Number *"
                  type="text"
                  defaultValue={patient_details.em_contact_no}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 m-b-10">
                <textarea
                  className="form-control outlined"
                  placeholder="Leave a note to the Doctor. E.g.:- I have a 6 month baby with me."
                  onChange={({ target: { value } }) =>
                    setAppointmentNote(value)
                  }
                />
              </div>
            </div>
            <div className="row text-left">
              <div className="col-md-6 m-b-10">
                <span>Pay at clinic</span>
                <input disabled checked className="m-l-10" type="checkbox" />
              </div>
              <div className="col-md-6 m-b-20">
                {/* <div>Booking charges: </div> */}
                <div>
                  Consultation charges: {showCurrency()}
                  {consultation_charge}
                </div>
              </div>
            </div>
            <div className="row m-b-10">
              <div className="col-12 text-right">
                <button
                  className="btn btn-default m-r-10"
                  data-dismiss="modal"
                  onClick={() => cancelAppointment()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-info"
                  data-dismiss="modal"
                  data-target="#confirm-modal"
                  data-toggle="modal"
                  onClick={() => confirmAppointment()}
                >
                  Book
                </button>
              </div>
              {is_tatkal && (
                <div className="col-12 text-left">
                  <p>Priority Booking Notes:</p>
                  <p>
                    1. Advance payment required for Priority booking and no
                    refund on cancellation
                    <br />
                    2. Clinic should collect payment at the time of Priority
                    Booking
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </div>
  );
};
const ModalConfirm = ({
  navigate,
  patient_details,
  clinic_id,
  appointment_id,
  free_visit_period,
  free_visit_enabled,
  slot,
  checkin_time,
  date,
  is_tatkal,
  consultation_charge,
}) => {
  const checkAppointmentBefore = moment.duration('12:00:00');
  const checkApntTime = moment(
    `${moment(date).format('YYYY-MM-DD')} ${checkin_time}`
  ).subtract(checkAppointmentBefore);

  const { data: lastAppoinmentData } = useSWRImmutable(
    [
      `${API_ENDPOINT}/appointments/get-last-appoinment-data`,
      {
        clinic_id,
        person_id: patient_details.person_id,
      },
    ],
    postFetcher
  );

  const lastAppoinmentDate = moment(lastAppoinmentData?.appointment_date);
  // const eligibility = isEligibleForFreeVisit();

  const status = useCalculateFreeVisitPeriod(
    lastAppoinmentData?.appointment_id
  );

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="confirm-modal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
      style={{ backgroundColor: '#000000aa' }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Booking confirmed</h5>
          </div>
          <div className="modal-body text-center">
            <div className="row">
              <div className="col-12 m-b-10">
                Patient:{' '}
                {`${patient_details.first_name} ${patient_details.last_name}`}
              </div>
              <div className="col-12 m-b-10">
                Mobile:{' '}
                {patient_details.phone_no ||
                  patient_details.booked_by_fmly_person_phone}
              </div>
              <div className="col-12 m-b-10">Slot: {slot}</div>
              <div className="col-12 m-b-10">
                {checkin_time ? `Clinic Check In: ${checkin_time}` : ''}
              </div>
              <div className="col-12 m-b-10">
                {date ? `Date: ${moment(date).format('DD-MM-YYYY')}` : ''}
              </div>
              {!is_tatkal && (
                <div className="col-12 m-b-10">
                  Please check your appointment time after{' '}
                  {moment(checkApntTime).format('DD-MM-YYYY')} on{' '}
                  {moment(checkApntTime).format('hh:mm A')}
                </div>
              )}
              <div className="col-12 m-b-30">Thank you</div>
              <div className="mx-auto mb-3">
                <BlankSheetPrintButton
                  appointment_id={appointment_id}
                  label="Print Casesheet"
                />
              </div>

              <div className="d-flex justify-content-center w-100 flex-column">
                <p
                  className="text-warning mb-1 cursor-pointer"
                  data-dismiss="modal"
                  onClick={() => {
                    setTimeout(() => {
                      window.$('#payment-modal').modal('show');
                    }, 300);
                    window.$('#confirm-modal').modal('hide');
                  }}
                >
                  Collect Payment{' '}
                  <strong className="text-danger">
                    {showCurrency()}
                    {Number(consultation_charge || 0).toFixed(2)}
                  </strong>
                </p>
                {lastAppoinmentData?.appointment_date && (
                  <p className="text-info mb-1">
                    Patient last visited on{' '}
                    {lastAppoinmentDate.format('DD-MM-YYYY')}
                  </p>
                )}
                <p
                  className={`${
                    status?.status ? 'text-success' : 'text-secondary'
                  }`}
                >
                  {
                    // calculate eligibility for free revisit -> last_appointment_date + revisit_period < today
                    // revisit_period is in days
                    // revisit_period is set in clinic settings
                    // if eligible, show message
                    // else show message with revisit_period

                    status?.status
                      ? `Patient is eligible for free revisit. \n Eligible for ${status.diff} days.`
                      : 'Patient is not eligible for free revisit.'
                  }
                </p>
                {!status?.status && (
                  <p>
                    {status.diff &&
                      status.diff > 0 &&
                      `Eligiblity expired before ${
                        status.diff - free_visit_period
                      } days.`}
                  </p>
                )}
              </div>
              <div className="col-12 justify-content-between">
                <button
                  onClick={() => {
                    window.$('#confirm-modal').modal('hide');
                    navigate('/patient');
                  }}
                  className="btn btn-info ml-3"
                >
                  Back
                </button>
                <button
                  onClick={() => {
                    setTimeout(() => {
                      window.$('#payment-modal').modal('show');
                    }, 300);
                    window.$('#confirm-modal').modal('hide');
                  }}
                  className="btn btn-info ml-5 my-4"
                >
                  Bill Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LotCard = ({
  index,
  booking_disabled,
  booked,
  fee,
  lot_start_time,
  title,
  total,
  type,
  slot_id_list,
  slotSelectionhandle,
  disable_all_lots,
}) => {
  const getBGColor = () => {
    if (booked === total) return '#e74c3c';
    if (booking_disabled || disable_all_lots) return '#e0e0e0';
    if (booked === 0) return '#2ecc71';
    return '#f1c40f';
  };

  const getColor = () => {
    if (booked === total) return '#f0f0f0';
    if (booked === 0) return '#424242';
    if (type === 'regular') return '#424242';
    return '#f0f0f0';
  };

  const lotPosition = title.split('L')[1];

  return (
    <div
      className="hex-slot-card-wrap"
      style={{
        background: type === 'regular' ? 'none' : '#b8b8b8',
      }}
    >
      <LotMarker marker_text={lot_start_time} />
      <div
        className="bg-inverse hex-slot-card p-10 text-center"
        style={{ borderTop: `6px solid ${getBGColor()}`, cursor: 'pointer' }}
        onClick={() => {
          if (booking_disabled || booked === total || disable_all_lots) return;
          slotSelectionhandle(
            lotPosition,
            lot_start_time,
            fee,
            type,
            booked,
            slot_id_list
          );
        }}
      >
        <h5 className="m-b-40 m-t-10">{title}</h5>
        <p>
          {showCurrency()}
          {fee}
        </p>
        {booking_disabled || booked === total || disable_all_lots ? (
          <div
            className="m-t-20 hex-slot-card-box"
            style={{
              background: getBGColor(),
              color: getColor(),
              cursor: 'not-allowed',
            }}
          >
            {' '}
            {booking_disabled && !booked ? (
              <div>&nbsp;</div>
            ) : (
              `${booked}/${total}`
            )}
          </div>
        ) : (
          <div
            className="m-t-20 hex-slot-card-box"
            style={{
              background: getBGColor(),
              color: getColor(),
            }}
          >
            {booked}/{total}
          </div>
        )}
      </div>
    </div>
  );
};

const LotMarker = ({ marker_text, type }) => (
  <div
    className={
      type === 'bottomRight'
        ? 'hex-slot-marker-wrap --bottomRight'
        : type === 'right'
        ? 'hex-slot-marker-wrap --right'
        : 'hex-slot-marker-wrap'
    }
  >
    <div className="hex-slot-time">{marker_text}</div>
    <div className="bg-danger hex-slot-marker" />
  </div>
);

const SlotTitle = ({ tatkal, style }) => (
  <div
    className={`text-center hex-slot-title hex-slot-title hex-slot-title-${
      tatkal ? 'tatkal' : 'regular'
    }`}
    style={style}
  >
    {tatkal ? 'Priority Slot' : 'General Slots'}
  </div>
);

const SlotView = ({ noHeader = false, bookingData = {} }) => {
  const { preferences, user_data, clinic_data, procedures, navigate, user } =
    usePageDetails();
  const userData = user.user_data;
  let restrictedLotsCsv = '';
  clinic_id = preferences?.selectedClinicId;
  clinic_no = preferences.selectedClinicNo;
  const { schedule_id } = bookingData.selected_schedule;
  const scheduleStartTime = bookingData.selected_schedule.slot_start || null;
  const totalTokenNumber = bookingData.selected_schedule.total_token_no || null;
  const [consultStartTime, setConsultStartTime] = useState(null);
  const { booking_date, previous_apmnt_id, provider_id, provider_name } =
    bookingData;
  const checkin_enabled = true;
  const { proc_id_list } = bookingData.selected_procedures;
  const patient_details = bookingData.patientDetails;
  const procedure_minutes = bookingData.selected_procedures.procedure_time || 0;

  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);

  const [slots_data, setSlotsData] = useState([]);
  const [selected_slot_data, setSelectedSlotData] = useState({});
  const [disable_all_lots, setDisableAllLots] = useState(false);
  const [resume_booking, setResumeBooking] = useState(false);
  const [hasTatkalSlots, setHasTatkalSlots] = useState(false);

  const hasTimeExpired = (lotStartTime, lotEndTime) => {
    if (booking_date !== moment().format('MM-DD-YYYY')) return false;
    const format = 'hh:mm A';
    const time = moment();
    const _lotStartTime = moment(lotStartTime, format);
    const _lotEndTime = moment(lotEndTime, format);
    return time.isAfter(_lotEndTime);
  };

  const getSlots = (
    _schedule_id,
    _booking_date,
    showPrevBookingAlert = true
  ) => {
    setShowLoading(true);
    const date_diff = moment().diff(_booking_date, 'days');
    if (date_diff > 0) {
      setSlotsData([]);
      setShowLoading(false);
      return;
    }
    Axios.get(
      `${API_ENDPOINT}/MedicalStoreAppointment/get_slots_for_booking?schedule_id=${_schedule_id}&procedure_minutes=${procedure_minutes}&booking_date=${_booking_date}&person_id=${patient_details.person_id}&restricted_lots=${restrictedLotsCsv}`,
      {
        id: 'get_slots_for_booking',
      }
    )
      .then((slots_result) => {
        const _slotsData =
          slots_result.data && slots_result.data.slots_data
            ? slots_result.data.slots_data
            : [];
        if (_slotsData) {
          const slotWithTatkal = _slotsData.find(
            (item) => item.type === 'tatkal'
          );
          if (slotWithTatkal && slotWithTatkal.type === 'tatkal') {
            setHasTatkalSlots(true);
          } else {
            setHasTatkalSlots(false);
          }
          if (_slotsData.length) {
            const _consultStartTime =
              _slotsData && _slotsData.length
                ? _slotsData[0].lot_start_time
                : null;
            setConsultStartTime(_consultStartTime);
            _slotsData.forEach((lot) => {
              if (hasTimeExpired(lot.lot_start_time, lot.lot_end_time)) {
                lot.booking_disabled = true;
              }
            });
          }
          return Axios.get(
            `${API_ENDPOINT}/MedicalStoreAppointment/get_patient_appointment?provider_id=${provider_id}&clinic_id=${clinic_id}&person_id=${patient_details.person_id}&appointment_date=${_booking_date}`
          ).then((patient_appointments) => {
            if (
              patient_appointments &&
              Array.isArray(patient_appointments.data) &&
              patient_appointments.data.length
            ) {
              patient_appointments.data.forEach((item) => {
                const lot_title = `L${parseInt(item.lot)}`;
                const booked_lot = _slotsData.find(
                  (obj) => obj.title === lot_title
                );
                if (booked_lot) {
                  booked_lot.booking_disabled = true;
                }

                if (item.booking_status === 'confirmed') {
                  setDisableAllLots(true);
                  showPrevBookingAlert &&
                    showAlert(
                      <>
                        <span>A booking exists for this patient</span>
                        <br />
                        <span>in OP or Walk-in</span>
                      </>
                    );
                }
                if (item.resume_booking) {
                  setDisableAllLots(true);
                  setResumeBooking(true);
                  showPrevBookingAlert &&
                    showAlert('Please continue with the previous booking');
                }
              });
            }
            setSlotsData(_slotsData);
          });
        }
      })
      .catch((err) => showAlert('Status change failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const getCheckinTime = (appointment_time, checkin_period_in_minutes = 15) => {
    const _time = moment(appointment_time, 'hh:mm A');
    _time.subtract(checkin_period_in_minutes, 'minutes');
    return _time.format('hh:mm A');
  };

  const slotSelectionhandle = (
    lotPosition,
    lot_starts_at,
    fee,
    lot_type,
    booked_slot_no,
    slot_id_list
  ) => {
    if (showLoading) return;
    setShowLoading(true);
    const is_tatkal = lot_type !== 'regular';
    const _slot_id =
      booked_slot_no > 0 ? slot_id_list[booked_slot_no] : slot_id_list[0];
    const _lot_title = `L${String(lotPosition).padStart(2, '0')}`;
    const new_appointment_data = {
      person_id: patient_details.person_id,
      selected_lot_number: parseInt(lotPosition),
      slot_id: _slot_id,
      is_tatkal,
      appointment_date: booking_date,
      clinic_id,
      proc_id_list,
      schedule_id,
      provider_id,
      procedure_minutes,
      slot_position: (booked_slot_no || 0) + 1,
      slots_per_lot: slot_id_list?.length || 1,
    };
    if (patient_details.booked_by_fmly_person_id) {
      new_appointment_data.booked_by_fmly_person_id =
        patient_details.booked_by_fmly_person_id;
      new_appointment_data.booked_by_fmly_person_phone =
        patient_details.booked_by_fmly_person_phone;
    }
    Axios.post(
      `${API_ENDPOINT}/MedicalStoreAppointment/add_patient_appointment`,
      new_appointment_data,
      {
        cache: {
          update: {
            get_slots_for_booking: 'delete',
          },
        },
      }
    )
      .then((res) => {
        new_appointment_id =
          res.data && res.data.new_appointment_id
            ? res.data.new_appointment_id
            : null;
        window.$('#slot-modal').modal('show');
      })
      .catch((err) => showAlert('Adding appointment failed', 'error'))
      .finally(() => {
        computeRestrictedDays();
        getSlots(schedule_id, booking_date, false);
        setShowLoading(false);
      });
    setSelectedSlotData({
      date: booking_date,
      lot_name: _lot_title?.toLowerCase() || '',
      slot_id: _slot_id,
      slot: `${_lot_title}: ${lot_starts_at}`,
      checkin_time: checkin_enabled ? getCheckinTime(lot_starts_at) : '',
      consultation_charge: fee,
      is_tatkal,
    });
  };

  const computeRestrictedDays = () => {
    if (booking_date && scheduleStartTime) {
      const _now = moment(new Date());
      const scheduleStart = moment(`${booking_date} ${scheduleStartTime}`);
      _durationToOP = moment.duration(scheduleStart.diff(_now));
      const daysToOP = _durationToOP.asDays();
      const hoursToOP = _durationToOP.asHours();
      if (totalTokenNumber <= 45) {
        if (daysToOP > 3) restrictedLotsCsv = 'l01,l02';
        if (daysToOP < 3 && hoursToOP > 12) restrictedLotsCsv = 'l02';
      } else if (totalTokenNumber > 45) {
        if (daysToOP > 3) restrictedLotsCsv = 'l01,l02,l06,l07';
        if (daysToOP < 3 && hoursToOP > 12) restrictedLotsCsv = 'l02,l07';
      }
    }
  };

  useEffect(() => {
    computeRestrictedDays();
    getSlots(schedule_id, booking_date, true);
    Axios.post(`${API_ENDPOINT}/appointments/get-last-appoinment-data`, {
      clinic_id,
      person_id: patient_details.person_id,
    });
  }, []);

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const cancelAppointment = (slotData) => {
    Axios.post(
      `${API_ENDPOINT}/PatientAppointments/web_admin/cancel_booking_by_id_without_sms_alert`,
      {
        appointment_id: new_appointment_id,
        chart_date: booking_date,
        schedule_id,
      },
      {
        cache: {
          update: {
            get_slots_for_booking: 'delete',
          },
        },
      }
    )
      .then(() => {
        setDisableAllLots(false);
      })
      .catch((err) => showAlert('Cancelling appointment failed', 'error'))
      .finally(() => {
        // setShowLoading(false);
        computeRestrictedDays();
        getSlots(schedule_id, booking_date, true);
      });
  };

  const isTransactionComplete = () => {
    navigate('/patient');
  };

  return (
    <div>
      {/* {disable_all_lots ? <h6>A booking exist for this patient with same procedure</h6> : ''} */}
      {/* {resume_booking && new_appointment_id ? <h6>Do you want to continue with the previous booking?</h6> : ''} */}
      <div className="row">
        <p className="pl-3 font-weight-bold">
          Booking For:{' '}
          {booking_date
            ? moment(booking_date, 'MM-DD-YYYY').format('DD-MM-YYYY')
            : ''}
        </p>
        {/* <div className="col-md-3 hex-sm-hide m-b-20">
          <Calendar
            // onChange={onChange}
            value={new Date(booking_date)}
            minDate={new Date()}
          />
        </div>
		<div className="col-md-9" style={{ padding: '0px' }}>
		*/}
        <div className="offset-md-1 col-md-9">
          <div className="panel">
            <div className="panel-body p-20">
              {slots_data.length ? (
                <div
                  className="table"
                  style={{
                    width: '750px',
                    maxWidth: '750px',
                    paddingLeft: '18px',
                  }}
                >
                  <div
                    className="hex-slot-wrap"
                    style={{
                      display: 'inline-block',
                      width: `${130 * 5}px`,
                    }}
                  >
                    {slots_data.slice(0, 5).map((item, index) => (
                      <LotCard
                        key={index}
                        index={index}
                        slotSelectionhandle={slotSelectionhandle}
                        disable_all_lots={disable_all_lots}
                        {...item}
                      />
                    ))}
                  </div>
                  <LotMarker
                    marker_text={slots_data[5 - 1].lot_end_time}
                    type="right"
                  />
                  <div className="hex-slot-titles">
                    {hasTatkalSlots && <SlotTitle tatkal />}
                    <SlotTitle
                      style={{ width: `${130 * (hasTatkalSlots ? 4 : 5)}px` }}
                    />
                  </div>
                  {slots_data.length > 5 ? (
                    <div>
                      <div
                        className="hex-slot-wrap hex-slots-bottom"
                        style={{
                          display: 'inline-block',
                          width: `${130 * 5}px`,
                        }}
                      >
                        {slots_data
                          .slice(5, slots_data.length)
                          .map((item, index) => (
                            <LotCard
                              key={index}
                              index={index}
                              slotSelectionhandle={slotSelectionhandle}
                              disable_all_lots={disable_all_lots}
                              {...item}
                            />
                          ))}
                      </div>
                      <LotMarker
                        marker_text={
                          slots_data[slots_data.length - 1].lot_end_time
                        }
                        type="bottomRight"
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              ) : (
                <div>No bookings available for the date.</div>
              )}
            </div>
          </div>
        </div>

        <div className="fab hex-sm-show" title="Add Appointment">
          <button
            className="btn btn-inverse btn-icon"
            data-target="#calendar-modal"
            data-toggle="modal"
          >
            <i className="fa fa-calendar" />
          </button>
        </div>
        <ModalBox
          patient_details={patient_details}
          {...selected_slot_data}
          provider_id={provider_id}
          cancelAppointment={() => cancelAppointment(selected_slot_data)}
          previous_apmnt_id={previous_apmnt_id}
        />
        <ModalConfirm
          navigate={navigate}
          appointment_id={new_appointment_id}
          patient_details={patient_details}
          clinic_id={clinic_id}
          free_visit_period={bookingData.selected_schedule.free_visit_period}
          free_visit_enabled={bookingData.selected_schedule.free_visit_enabled}
          {...selected_slot_data}
        />
        <Alert
          msg={alertMsg}
          setShow={setAlertShow}
          show={alertShow}
          type={alertType}
        />
        <LoadingBox show={showLoading} />

        <PaymentModal
          key={new_appointment_id}
          appointment={{
            appointment_id: new_appointment_id,
            appointment_date: booking_date,
            provider_id,
            provider_name,
            patient: patient_details,
            proc_data: [],
            is_tatkal: selected_slot_data?.is_tatkal,
            tatkal_consult_fee: selected_slot_data?.is_tatkal
              ? selected_slot_data?.consultation_charge
              : 0,
            normal_consult_fee: !selected_slot_data?.is_tatkal
              ? selected_slot_data?.consultation_charge
              : 0,
          }}
          user_data={user_data}
          isTransactionComplete={isTransactionComplete}
          procedures={procedures}
          clinic_data={clinic_data}
          site_preferences={preferences}
          onCancelFn={() => navigate('/patient')}
        />
      </div>
    </div>
  );
};

export default SlotView;
