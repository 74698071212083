import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AVATAR_API, API_ENDPOINT } from '../../config/api';

import { useOnlineStatus } from '../../_helpers/use_online_status_hook';
import { useClinic, useFullUser } from '@/hooks/useUser';
import { useNavBar } from '@/hooks/states';
import { cn } from '@/lib/utils';
import useSWRImmutable from 'swr/immutable';
import { getFetcher } from '@/_helpers/swr';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { ArrowRightToLine, ChevronDown, Menu, Pause, Play } from 'lucide-react';
import { NavBarWalkin } from '../pages/WalkIn/_partials/session-info';
import { AppoinmentDateLab, NavBarLabSelect } from '../pages/lab/navbarItem';

/**
 *
 * Displays the navigation bar
 * Signed in users only
 */

const Navbar = () => {
  const isOnline = useOnlineStatus();
  const { setSelectedClinic, isProvider, getAllClinics, user } = useFullUser();

  const toggleNavBar = useNavBar((state) => state.toggleShow);
  const show = useNavBar((state) => state.show);
  const selectedClinic = useClinic();

  const clinicList = useMemo(
    () =>
      getAllClinics().map((c) => ({
        name: c.clinic_name,
        value: c.clinic_id.toString(),
        clinic_no: c.clinic_no,
      })),
    [getAllClinics]
  );

  if (!user) return;

  const { user_data } = user;

  const query =
    user_data.user_role === 'provider'
      ? `provider_id=${user_data.user_id}&clinic_id=${selectedClinic}`
      : `clinic_id=${selectedClinic}`;

  const { data: consultQueueData } = useSWRImmutable(
    `${API_ENDPOINT}/PatientAppointments/get_consult_queue_count?${query}&date=${moment().format(
      'YYYY-MM-DD'
    )}`,
    getFetcher
  );

  const has_paused_op =
    user.type !== 'pharmacy' &&
    user.preferences?.paused_op &&
    user.preferences?.paused_op.provider_id === user_data.user_id &&
    moment(user.preferences.paused_op.paused_at).format('DD-MM-YYYY') ===
      moment().format('DD-MM-YYYY');

  return (
    <>
      {!isOnline && (
        <div className="_text-rose-600 _w-full _bg-gradient-to-r _from-teal-100 _via-fuchsia-100 _to-sky-200 _flex _justify-center _font-bold _text-sm">
          No network connection
        </div>
      )}

      {isOnline && (
        <div className="_text-rose-600 _w-full _bg-gradient-to-r _from-teal-100 _via-fuchsia-100 _to-sky-200 _flex _justify-center _font-bold _text-sm _space-x-4">
          <Link to="/consult/op-session" className="_text-rose-600">
            {has_paused_op ? (
              <>
                <Pause className="_size-3 _inline-block _mr-1" /> You have paused the OP
              </>
            ) : isProvider() &&
              consultQueueData?.op_count &&
              consultQueueData.op_count > 0 ? (
              <>
                <Play className="_size-3 _inline-block _mr-1"></Play>You have{' '}
                {consultQueueData?.op_count} pending patient in OP.
              </>
            ) : (
              ''
            )}
          </Link>
          {selectedClinic &&
            !isProvider &&
            selectedClinic.wallet_balance < 5 && (
              <div className="_text-rose-600">
                <i className="fa fa-bullhorn _mr-1" /> Note: Your wallet balance
                is critically low. Please top-up for uninterrupted VAS
              </div>
            )}
        </div>
      )}

      <div
        className={cn(
          '_bg-sky-900 _text-white !_relative _flex _group _py-1 _duration-300 _w-full _items-center'
        )}
      >
        <div className="_flex _mx-4">
          <div className="_flex _items-center">
            <div className="">
              {show ? (
                <Menu onClick={toggleNavBar} />
              ) : (
                <ArrowRightToLine onClick={toggleNavBar} />
              )}
            </div>

            <img
              alt="UnQ SCS"
              className="_ml-3  !_h-[17px] lg:!_h-[30px] _duration-300"
              src="/assets/images/logo-transparant.png"
            />
          </div>
        </div>
        <div className="_grow" />
        <div className="_flex _items-center _space-x-2 _w-fit">
          {isProvider() && <NavBarWalkin />}

		  {!isProvider() && <NavBarLabSelect />}
		  {!isProvider() && <AppoinmentDateLab />}


          {isProvider() && (
            <Select
              value={selectedClinic?.clinic_id.toString()}
              onValueChange={(value) => setSelectedClinic(value)}
            >
              <SelectTrigger
                className="_w-[180px] !_bg-white _text-foreground !_py-0 !_px-2 sm:_px-3 sm:_py-2 _leading-3 sm:_leading-normal"
                value={selectedClinic?.clinic_id}
              >
                <SelectValue placeholder="Select Clinic" />
              </SelectTrigger>
              <SelectContent>
                {clinicList.map((c) => (
                  <SelectItem key={c.value} value={c.value}>
                    {c.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          <div className="_mx-3 _hidden sm:_flex _items-center">
            <span className="_text-nowrap _mx-5 _font-bold _text-xl">
              {isProvider() ? 'PROVIDER' : 'PRACTICE'}
            </span>
            <span className="_text-center">
              <span className="_leading-3">
                <i className="fa fa-user _mr-1" />
                {user_data.user_type === 'clinic_owner' ||
                user_data.user_type === 'clinic_staff'
                  ? selectedClinic?.clinic_no
                  : user_data.user_no}
              </span>
            </span>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="_px-0 _py-0 hover:_bg-sky-800 _flex _items-center _mr-2 _shrink-0">
                <img
                  className="!_size-[32px] _my-0 !_p-0 _overflow-hidden _rounded-full"
                  src={`${AVATAR_API}${user_data.first_name} ${user_data.last_name}&rounded=true`}
                  alt="User-Profile"
                />
                <ChevronDown className="_size-4" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>
                {`${user_data.first_name} ${user_data.last_name}`}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <Link
                  to={`/${user_data?.user_role?.toLowerCase()}/edit/${
                    user_data.user_id
                  }`}
                >
                  <i className="icon-user" /> Profile
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to="/logout">
                  <i className="icon-logout" /> Logout
                </Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </>
  );
};

export default Navbar;
