import React from 'react';
import './OPSession.css';
import CloseIcon from './assets/Icon.Close.svg';

const OPPreviewPrescriptionModal = ({
  setIsOPPreviewPrescriptionModal,
  casesheet_form_data,
  casesheet_form_input_handler,
}) => {
  const prescription = casesheet_form_data.prescription || {};

  const removeFromPrescriptions = (prescriptionNumber) => {
    if (casesheet_form_data.prescription) {
      const medInputs = document.getElementsByName(
        `medObj_${prescriptionNumber}_med`
      );
      const dosageInputs = document.getElementsByName(
        `medObj_${prescriptionNumber}_dosage`
      );
      const durationInputs = document.getElementsByName(
        `medObj_${prescriptionNumber}_duration`
      );
      if (medInputs && medInputs.length) {
        medInputs.forEach((element) => {
          element.value = '';
        });
      }

      if (dosageInputs && dosageInputs.length) {
        dosageInputs.forEach((element) => {
          element.value = '';
        });
      }

      if (durationInputs && durationInputs.length) {
        durationInputs.forEach((element) => {
          element.value = '';
        });
      }
      delete casesheet_form_data.prescription[prescriptionNumber];
    }
  };

  return (
    <div className="op-list-modal">
      <div className="op-list-modal-header">
        <p>OP List</p>
        <img
          src={CloseIcon}
          alt="Close Icon"
          onClick={() => {
            setIsOPPreviewPrescriptionModal(false);
          }}
        />
      </div>
      <table className="op-list-modal-table">
        <thead>
          <tr>
            <th>Drug Name</th>
            <th>Dosage</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(15)].map((key, index) => (
            <tr key={index + 1}>
              <td>
                <div className="op-list-modal-table-item">
                  <input
                    type="text"
                    placeholder="Drug Name"
                    name={`medObj_${index + 1}_med`}
                    defaultValue={
                      prescription[index + 1] ? prescription[index + 1].med : ''
                    }
                    onChange={casesheet_form_input_handler}
                  />
                </div>
              </td>
              <td>
                <div className="op-list-modal-table-item">
                  <input
                    type="text"
                    placeholder="Dosage"
                    name={`medObj_${index + 1}_dosage`}
                    defaultValue={
                      prescription[index + 1]
                        ? prescription[index + 1].dosage
                        : ''
                    }
                    onChange={casesheet_form_input_handler}
                  />
                </div>
              </td>
              <td>
                <div className="op-list-modal-table-item">
                  <input
                    type="text"
                    placeholder="Duration"
                    name={`medObj_${index + 1}_duration`}
                    defaultValue={
                      prescription[index + 1]
                        ? prescription[index + 1].duration
                        : ''
                    }
                    onChange={casesheet_form_input_handler}
                  />
                  <button
                    className="op-list-modal-table-item-x-btn"
                    onClick={() => removeFromPrescriptions(index + 1)}
                  >
                    X
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OPPreviewPrescriptionModal;
