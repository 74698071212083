import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import Select from '../form/Select';
import { API_ENDPOINT } from '../../config/api';
import { useCalculateFreeVisitPeriod } from '../common/func';
import showCurrency from '../common/showCurrency';
import BlankSheetPrintButton from '../pages/pdf/BlankSheet';
import { setInitialSchedule } from '@/hooks/useUser';

const AppointmentSelectModal = ({
  patientData,
  clinic_id,
  providerList,
  scheduleList,
  setShowLoading,
  //   clinicSettings,
  showAlert,
  onClose,
  closeAlert,
  collectPayment,
}) => {
  const [selectedDoctor, setSelectedDoctor] = useState(undefined);
  const [familyMemberList, setFamilyMemberList] = useState([]);
  const [selectedContact, setSelectedContact] = useState(undefined);
  const [schedule_info, setSchedule_info] = useState({});
  const schedules = useMemo(() => {
    if (!selectedDoctor) return [];
    const _dayName = moment().format('ddd').toLowerCase();
    const _schedules =
      scheduleList.filter(
        (obj) =>
          obj.provider_id === parseInt(selectedDoctor) && obj[_dayName] === true
      ) || [];
    const selectedShdl =
      _schedules.sort((a, b) =>
        a.slot_start.localeCompare(b.slot_start)
      )?.[0] || {};
    setSchedule_info(selectedShdl);

    return _schedules .sort((a, b) => {
		const format = 'HH:mm:ss';
        const a_schedule_start = moment(a.slot_start, format);
        const b_schedule_start = moment(b.slot_start, format);
        return a_schedule_start.isAfter(b_schedule_start) ? 1 : -1;
      });
  }, [selectedDoctor]);

  useEffect(() => {
	const _dayName = moment().format('ddd').toLowerCase();
	const _schedules =
	scheduleList.filter(
	  (obj) =>
		obj.provider_id === parseInt(selectedDoctor) && obj[_dayName] === true
	) || [];
    setSchedule_info(setInitialSchedule(_schedules) || {});
  }, [selectedDoctor]);

  const closeModal = (config) => {
    setSelectedDoctor(undefined);
    setSchedule_info({});
    setFamilyMemberList([]);
    setSelectedContact(undefined);
    window.$('#provider-schedule-modal').modal('hide');
    !config?.redirect && onClose && onClose();
    if (config?.alertmsg) showAlert(config.alertmsg);
  };

  const handleSubmit = () => {
    if (!patientData || !patientData.person_id) return;
    const postData = {
      person_id: patientData.person_id,
      schedule_id: schedule_info.schedule_id,
      clinic_id,
      provider_id: selectedDoctor,
      procedure_id: null,
      appointment_date: moment().format('YYYY-MM-DD'),
      appointment_note: '',
      procedure_minutes: schedule_info.avg_pat_time || 0,
      proc_id_list: '{}',
      initial_op_time: moment().format('HH:mm'),
    };

    if (!patientData?.phone_no && selectedContact) {
      postData.booked_by_fmly_person_id = selectedContact;
    }
    setShowLoading(true);
    Axios.post(
      `${API_ENDPOINT}/PatientAppointments/add_walkin_appointment`,
      postData
    )
      .then(({ data }) => {
        const newAppId = data?.new_appointment_id;
        showAlert(
          <SuccessAlertComponent
            appoinmentId={newAppId}
            last_appointment_date={patientData.last_appointment_date}
            free_revisit_enabled={schedule_info.free_revisit_enabled}
            revisit_period={schedule_info?.free_revisit_period}
            normal_consult_fee={schedule_info.normal_consult_fee}
            closeModal={() => {
              closeAlert();
              closeModal();
            }}
            showPayModal={() => {
              window.$('#provider-schedule-modal').modal('hide');
              closeAlert?.();
              collectPayment?.(newAppId, patientData, schedule_info, {
                id: selectedDoctor,
                name:
                  providerList?.find?.(
                    (dr) => dr.value === parseInt(selectedDoctor)
                  )?.name || '',
              });
            }}
          />,
          'success'
        );
      })
      .catch((err) => {
        console.log(err);
        showAlert(
          <>
            <span>New walkin booking failed!</span>
            <br />
            <span>A booking already exists in OP or Walkin</span>
          </>,
          'error'
        );
      })
      .finally(() => {
        setShowLoading(false);
        closeModal({ redirect: true });
      });
  };

  const getPatientDetails = (person_no) => {
    if (!person_no) return;
    setShowLoading(true);
    Axios.get(
      `${API_ENDPOINT}/Patients/patient_by_upn_or_phone?query_val=${person_no}`
    )
      .then(({ data }) => {
        if (data) {
          //   if (!data.family_members) {
          //     // setShowLoading(false);
          //     // setTimeout(() => {
          //     //   closeModal(
          //     //     "The profile loaded doesn't have a valid phone number or connected family member."
          //     //   );
          //     // }, 500);
          //     closeModal(
          //       "The profile loaded doesn't have a valid phone number or connected family member."
          //     );
          //     return;
          //   }
          const members = data?.family_members?.map((member) => ({
            name: `${member?.first_name} ${member?.last_name} (${member?.relationship_name})`,
            value: member.person_id,
          }));
          setFamilyMemberList(members || []);
          setSelectedContact(members?.[0]?.value);
        } else {
          setShowLoading(false);
          setTimeout(() => {
            closeModal({ alertmsg: 'No user exist for the given details.' });
          }, 500);
        }
      })
      .catch((err) => {
        setFamilyMemberList([]);
        showAlert('Fetching patient family members failed', 'error');
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    setSelectedDoctor(providerList?.[0]?.value);
    if (patientData.person_no && !patientData.phone_no) {
      getPatientDetails(patientData.person_no);
    }
  }, [patientData.person_no]);

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="provider-schedule-modal"
      role="dialog"
      tabIndex="-1"
      data-backdrop="static"
    >
      <div
        className="modal-dialog modal-sm modal-center-content"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">Walk in Appointment</h6>
            <button
              aria-label="Close"
              className="btn btn-light"
              style={{
                padding: '0px 2px 0px 5px',
                border: 'none',
                background: '#fff',
              }}
              type="button"
              onClick={() => closeModal()}
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div
            className="modal-body px-4"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span>
              <Link to={`/patient/edit/${patientData.person_id}`}>
                {patientData.first_name} {patientData.last_name}
              </Link>{' '}
              {patientData.age} {patientData.sex}
            </span>
            {patientData?.phone_no ? (
              <span>Contact: {patientData.phone_no}</span>
            ) : null}

            {familyMemberList?.length ? (
              <div className="m-t-10 m-b-10">
                <span>Select Contact Person</span>
                <Select
                  className="outlined"
                  values={familyMemberList}
                  value={selectedContact}
                  onChange={({ target: { name, value } }) => {
                    setSelectedContact(value);
                  }}
                />
              </div>
            ) : null}

            <div className="m-t-10 m-b-10">
              <Select
                className="outlined"
                values={providerList}
                value={selectedDoctor}
                onChange={({ target: { name, value } }) => {
                  setSelectedDoctor(value);
                }}
              />
            </div>
            <div className="m-b-15">
              <Select
                className="outlined"
                values={schedules.sort((a, b) =>
                  a.slot_start.localeCompare(b.slot_start)
                )}
                value={schedule_info.schedule_id}
                onChange={({ target: { name, value } }) => {
                  const selectedShdl = schedules.find(
                    (obj) => obj.schedule_id === parseInt(value)
                  );
                  setSchedule_info(selectedShdl);
                }}
              />
            </div>
            <div className="d-flex w-full justify-content-between">
              <button
                className="btn ml-3"
                onClick={() => closeModal()}
                style={{ cursor: 'pointer' }}
              >
                Cancel
              </button>
              <button
                className="btn btn-info ml-3"
                onClick={() => handleSubmit()}
                disabled={!schedule_info.schedule_id}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentSelectModal;

const SuccessAlertComponent = ({
  last_appointment_date,
  free_revisit_enabled,
  revisit_period,
  normal_consult_fee,
  appoinmentId,
  closeModal,
  showPayModal,
}) => {
  const status = useCalculateFreeVisitPeriod(appoinmentId);

  return (
    <div>
      <h4>New walkin Appoinment added</h4>
      {status?.lastVisit && (
        <p className="text-info">Patient last visited on {status.lastVisit}</p>
      )}

      <BlankSheetPrintButton
        appointment_id={appoinmentId}
        label="Print casesheet"
      />
      {free_revisit_enabled && (
        <div
          className={`mt-2 ${
            status?.status ? 'text-success' : 'text-secondary'
          }`}
        >
          <p>
            {
              // calculate eligibility for free revisit -> last_appointment_date + revisit_period < today
              // revisit_period is in days
              // revisit_period is set in clinic settings
              // if eligible, show message
              // else show message with revisit_period

              status?.status
                ? `Patient is eligible for free revisit. \n Eligible upto ${
                    revisit_period - status.diff
                  } days.`
                : 'Patient is not eligible for free revisit.'
            }
          </p>
          {!status?.status && (
            <p>
              {status?.diff &&
                status.diff > 0 &&
                `Eligiblity expired before ${
                  status.diff - revisit_period
                } days.`}
            </p>
          )}
        </div>
      )}
      <p
        style={{ color: '#c39c00', cursor: 'pointer' }}
        data-dismiss="modal"
        onClick={() => showPayModal()}
      >
        Collect payment of{' '}
        <span className="mr-1 text-danger">
          {showCurrency()}
          {Number(normal_consult_fee).toFixed(2)}
        </span>
        from patient.
      </p>
      <div className="d-flex w-full justify-content-between my-3">
        <button onClick={closeModal} className="btn btn-info">
          Back
        </button>
        <button onClick={showPayModal} className="btn btn-info">
          Bill Payment
        </button>
      </div>
    </div>
  );
};
