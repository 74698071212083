import { ALL_TEETHS, TEETH_QUADRANTS } from './constants';
import { useDentalChart } from './DentalChartContext';
import TeethContainer from './TeethContainer';

function AllTeeths({ timeline }) {
  const { patientType } = useDentalChart();
  return (
    <>
      <div className={`dental-chart dental-chart--${patientType}`}>
        <div className="dental-chart__upper">
          <div className="dental-chart__upper-left">
            {ALL_TEETHS[patientType].upperLeft.map((data) => (
              <TeethContainer
                key={data.code}
                quadrant={TEETH_QUADRANTS.upperLeft}
                {...data}
              />
            ))}
          </div>
          <div className="dental-chart__upper-right">
            {ALL_TEETHS[patientType].upperRight.map((data) => (
              <TeethContainer
                key={data.code}
                quadrant={TEETH_QUADRANTS.upperRight}
                {...data}
              />
            ))}
          </div>
        </div>
        <div className="dental-chart__lower">
          <div className="dental-chart__lower-left">
            {ALL_TEETHS[patientType].lowerLeft.map((data) => (
              <TeethContainer
                key={data.code}
                quadrant={TEETH_QUADRANTS.lowerLeft}
                {...data}
              />
            ))}
          </div>
          <div className="dental-chart__lower-right">
            {ALL_TEETHS[patientType].lowerRight.map((data) => (
              <TeethContainer
                key={data.code}
                quadrant={TEETH_QUADRANTS.lowerRight}
                {...data}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`dental-chart__marker dental-chart__marker-${timeline}`}
      ></div>
    </>
  );
}

export default AllTeeths;
