/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import axios from '@/_helpers/axios_interceptor';
import moment from 'moment';
import React from 'react';
import { API_ENDPOINT, STORAGE_DOMAIN } from '../../../config/api';
import { genratePdfFromElement } from './generatePdf';
import { calculateAge } from '../../../_helpers/misc';
import { encodePrescription } from '../_partials/CasesheetDetails';
import { useUser } from '@/hooks/useUser';
import { getPrescriptionFromString } from '../WalkIn/CasesheetDetails';

/** @typedef {object} BasicDetails
 * @property {object} patient_info
 * @property {string} patient_info.person_no
 * @property {number} patient_info.person_id
 * @property {string} patient_info.email_id
 * @property {string} patient_info.first_name
 * @property {string} patient_info.last_name
 * @property {string} patient_info.phone_no
 * @property {string} patient_info.sex
 * @property {string} patient_info.date_of_birth
 * @property {string} patient_info.blood_group
 * @property {string} patient_info.family_name
 * @property {string} patient_info.em_contact_name
 * @property {string} patient_info.em_contact_no
 * @property {string} patient_info.file_no
 * @property {string} patient_info.place
 * @property {string} patient_info.visit_status
 * @property {object[]} patient_info.family_members
 * @property {string} patient_info.family_members.person_no
 * @property {number} patient_info.family_members.person_id
 * @property {string} patient_info.family_members.email_id
 * @property {string} patient_info.family_members.first_name
 * @property {string} patient_info.family_members.last_name
 * @property {string} patient_info.family_members.phone_no
 * @property {string} patient_info.family_members.sex
 * @property {string} patient_info.family_members.date_of_birth
 * @property {string} patient_info.family_members.relationship_name
 * @property {number} patient_info.family_members.age
 * @property {number} patient_info.age
 * @property {object} doctor_info
 * @property {string} doctor_info.upn
 * @property {string} doctor_info.name
 * @property {string} doctor_info.speciality
 * @property {string} doctor_info.dr_reg_no
 * @property {string} doctor_info.degree_name
 * @property {string} doctor_info.add_qualification
 * @property {object} schedule_info
 * @property {number} schedule_info.schedule_id
 * @property {number} schedule_info.clinic_id
 * @property {string} schedule_info.clinic_no
 * @property {string} schedule_info.slot_start
 * @property {string} schedule_info.slot_end
 * @property {string} schedule_info.normal_consult_fee
 * @property {string} schedule_info.tatkal_consult_fee
 * @property {string} schedule_info.clinic_name
 * @property {string} schedule_info.clinic_phone_no
 * @property {string} schedule_info.clinic_place
 * @property {string} schedule_info.clinic_pin_code
 * @property {string} schedule_info.clinic_logo
 * @property {boolean} schedule_info.has_clinic_checkin
 * @property {boolean} schedule_info.has_web_checkin
 * @property {boolean} schedule_info.free_revisit_enabled
 * @property {string} schedule_info.free_revisit_period
 * @property {boolean} schedule_info.mon
 * @property {boolean} schedule_info.tue
 * @property {boolean} schedule_info.wed
 * @property {boolean} schedule_info.thu
 * @property {boolean} schedule_info.fri
 * @property {boolean} schedule_info.sat
 * @property {boolean} schedule_info.sun
 * @property {number} schedule_info.avg_pat_time
 * @property {string} schedule_info.name
 * @property {number} schedule_info.value
 */

/** @typedef {object} CasesheetRes
 * @property {object[]} case_sheets
 * @property {null} case_sheets.attachment_id
 * @property {null} case_sheets.attachment_url
 * @property {number} case_sheets.casesheet_id
 * @property {number} case_sheets.consultation_id
 * @property {object} case_sheets.prescription
 * @property {object} case_sheets.prescription.1
 * @property {string} case_sheets.prescription.1.dosage
 * @property {string} case_sheets.prescription.1.duration
 * @property {string} case_sheets.prescription.1.med
 * @property {object} case_sheets.prescription.2
 * @property {string} case_sheets.prescription.2.dosage
 * @property {string} case_sheets.prescription.2.duration
 * @property {string} case_sheets.prescription.2.med
 * @property {object} case_sheets.prescription.3
 * @property {string} case_sheets.prescription.3.dosage
 * @property {string} case_sheets.prescription.3.duration
 * @property {string} case_sheets.prescription.3.med
 * @property {string} case_sheets.prescription_details
 * @property {string} case_sheets.special_instruction
 * @property {string} consultation_type
 * @property {object[]} doctor_info
 * @property {string} doctor_info.degree_name
 * @property {string} doctor_info.first_name
 * @property {string} doctor_info.last_name
 * @property {string} doctor_info.profile_pic
 * @property {number} doctor_info.provider_id
 * @property {string} doctor_info.provider_no
 * @property {string} doctor_info.reg_no
 * @property {string} doctor_info.sign_image
 * @property {string} doctor_info.speciality
 * @property {null} e_records
 * @property {object[]} medicines
 * @property {string|null} medicines.create_time
 * @property {string|null} medicines.created_by
 * @property {number} medicines.degree_id
 * @property {boolean} medicines.delete_ind
 * @property {null} medicines.medicine_code
 * @property {number} medicines.medicine_id
 * @property {string} medicines.medicine_name
 * @property {null|string} medicines.medicine_note
 * @property {null} medicines.medicine_unit
 * @property {null|string} medicines.modify_by
 * @property {string|null} medicines.modify_time
 * @property {object[]} patient
 * @property {number} patient.age
 * @property {string} patient.file_no
 * @property {null} patient.gender
 * @property {string} patient.name
 * @property {number} patient.payment_due
 * @property {number} patient.person_id
 * @property {string} patient.person_no
 * @property {string} patient.phone
 * @property {string} patient.place
 * @property {object[]} schedule_info
 * @property {number} schedule_info.avg_pat_time
 * @property {number} schedule_info.clinic_id
 * @property {string} schedule_info.clinic_logo
 * @property {string} schedule_info.clinic_name
 * @property {string} schedule_info.clinic_no
 * @property {string} schedule_info.clinic_phone_no
 * @property {string} schedule_info.clinic_pin_code
 * @property {string} schedule_info.clinic_place
 * @property {boolean} schedule_info.free_revisit_enabled
 * @property {number} schedule_info.free_revisit_period
 * @property {boolean} schedule_info.fri
 * @property {boolean} schedule_info.has_clinic_checkin
 * @property {boolean} schedule_info.has_web_checkin
 * @property {boolean} schedule_info.mon
 * @property {number} schedule_info.normal_consult_fee
 * @property {number} schedule_info.provider_id
 * @property {boolean} schedule_info.sat
 * @property {number} schedule_info.schedule_id
 * @property {string} schedule_info.slot_end
 * @property {string} schedule_info.slot_start
 * @property {boolean} schedule_info.sun
 * @property {number} schedule_info.tatkal_consult_fee
 * @property {boolean} schedule_info.thu
 * @property {boolean} schedule_info.tue
 * @property {boolean} schedule_info.wed
 * @property {object[]} vitals
 * @property {string} vitals.allergies
 * @property {string} vitals.blood_group
 * @property {null} vitals.bmi
 * @property {null} vitals.bp_diastolic
 * @property {null} vitals.bp_sys
 * @property {number} vitals.clinic_id
 * @property {string} vitals.habits
 * @property {null} vitals.height_cm
 * @property {string} vitals.insurance_provider
 * @property {string} vitals.medical_conditions
 * @property {null} vitals.policy_expiry_date
 * @property {string} vitals.policy_number
 * @property {null} vitals.pulse_rate
 * @property {string} vitals.vital_time
 * @property {null} vitals.weight_kg
 */

/** @typedef {object} CaseSheet
 * @property {string} casesheet_id
 * @property {string} pdf_id
 * @property {{[key:string]: {
 * med: string,
 * dosage: string,
 * duration: string
 * }}} prescription
 * @property {string|undefined} prescription_details
 * @property {string} special_instruction
 * @property {string} validity_in_days
 */

/**
 * @typedef {object} PrescriptionSheetProps
 * @property {BasicDetails} info
 * @property {CaseSheet} casesheet
 * @property {string} provider_sign_url
 * @property {number} appointmentId
 * @property {boolean} isWalkin
 *
 * @param {PrescriptionSheetProps} props
 */
const generatePrescriptionSheetPages = async ({
  info,
  casesheet,
  provider_sign_url,
  date,
  selectedProcedures,
  isWalkin,
  time,
}) => {
  const prescriptions =
    (casesheet && Object.values(casesheet.prescription)) || [];

  const treatments =
    selectedProcedures?.filter((e) => e.category === 'Procedure') || [];

  const mapping = (
    await axios.get(
      `${API_ENDPOINT}/provider/mapping/${info.doctor_info.provider_id}/${info?.schedule_info?.clinic_id}`
    )
  ).data;
  const patient = info.patient_info || info.patient;
  console.log({
    info,
  });

  const {
    print_clinic_header,
    print_provider_header,
    print_token_display,
    settings,
  } = mapping;
  let schedules = null;

  if (settings?.print?.showMultipleSchedule)
    schedules = (
      await axios.get(
        `${API_ENDPOINT}/provider/clinic-provider-schedule-mapping/${info?.schedule_info?.clinic_id}/${info.doctor_info.provider_id}`
      )
    ).data;
  if (!casesheet || (prescriptions.length < 1 && treatments?.length < 1)) {
    return (
      <PrescriptionSheetLayout
        print_clinic_header={print_clinic_header}
        print_provider_header={print_provider_header}
        doctorName={info.doctor_info.name}
        doctorUpn={info.doctor_info.upn}
        doctorSpeciality={info.doctor_info.speciality}
        doctorDegreeName={info.doctor_info.degree_name}
        doctorAddQualification={info.doctor_info.add_qualification}
        doctorRegNo={info.doctor_info.dr_reg_no}
        slot_start={info.schedule_info.slot_start}
        slot_end={info.schedule_info.slot_end}
        clinic_logo={info.schedule_info.clinic_logo}
        clinic_place={info.schedule_info.clinic_place}
        clinic_phone_no={info.schedule_info.clinic_phone_no}
        weekdays={info.schedule_info}
        settings={settings?.print}
        schedules={schedules}
      >
        <PrescriptionSheetItem
          patient_info={info.patient}
          file_no={info.patient?.file_no}
          isWalkin={isWalkin}
          provider_sign_url={provider_sign_url}
          doctorName={info.doctor_info.name}
          doctorRegNo={info.doctor_info.dr_reg_no}
          casesheet={casesheet}
          settings={settings?.print}
          date={date}
          print_token_display={print_token_display}
          time={time}
        />
      </PrescriptionSheetLayout>
    );
  }

  // if treatments array length + prescriptions array length should be less that 10 in single page.
  // so if multiple page exists return as element arrays by spliting contents. First split prescriptions, then treatments
  const itemsCountInSinglePage = 20;
  const itemsCountInFirstPage = 15;
  const pages = [];
  let prescriptionIndex = 0;
  let treatmentIndex = 0;
  let p_serialNumber = 1;
  let t_serialNumber = 1;

  // Calculate the number of lines in casesheet.prescription_details
  let prescriptionDetailsLines = 0;
  if (casesheet?.prescription_details) {
    prescriptionDetailsLines = Math.ceil(
      casesheet.prescription_details.split('\n').length / 2
    );
  }

  while (
    prescriptionIndex < prescriptions?.length ||
    treatmentIndex < treatments?.length
  ) {
    const itemsCountInCurrentPage =
      pages.length === 0
        ? itemsCountInFirstPage - prescriptionDetailsLines
        : itemsCountInSinglePage;

    const prescriptionsArray =
      prescriptions
        ?.slice(prescriptionIndex, prescriptionIndex + itemsCountInCurrentPage)
        .map((item) => ({ ...item, serialNumber: p_serialNumber++ })) || [];

    prescriptionIndex += prescriptionsArray.length;

    let treatmentsArray = [];
    if (prescriptionIndex >= prescriptions?.length) {
      treatmentsArray =
        treatments
          ?.slice(
            treatmentIndex,
            treatmentIndex + itemsCountInCurrentPage - prescriptionsArray.length
          )
          .map((item) => ({ ...item, serialNumber: t_serialNumber++ })) || [];

      treatmentIndex += treatmentsArray.length;
    }

    pages.push(
      <PrescriptionSheetLayout
        print_clinic_header={print_clinic_header}
        print_provider_header={print_provider_header}
        doctorName={info.doctor_info.name}
        doctorUpn={info.doctor_info.upn}
        doctorSpeciality={info.doctor_info.speciality}
        doctorDegreeName={info.doctor_info.degree_name}
        doctorAddQualification={info.doctor_info.add_qualification}
        doctorRegNo={info.doctor_info.dr_reg_no}
        slot_start={info.schedule_info.slot_start}
        slot_end={info.schedule_info.slot_end}
        clinic_logo={info.schedule_info.clinic_logo}
        clinic_place={info.schedule_info.clinic_place}
        clinic_phone_no={info.schedule_info.clinic_phone_no}
        weekdays={info.schedule_info}
        settings={settings?.print}
        schedules={schedules}
      >
        <PrescriptionSheetItem
          patient_info={pages.length > 0 ? undefined : info.patient}
          file_no={info.patient?.file_no}
          isWalkin={isWalkin}
          provider_sign_url={provider_sign_url}
          prescriptions={prescriptionsArray}
          doctorName={info.doctor_info.name}
          date={date}
          time={time}
          settings={settings?.print}
          doctorRegNo={info.doctor_info.dr_reg_no}
          treatments={treatmentsArray}
          casesheet={pages.length > 0 ? undefined : casesheet}
          print_token_display={print_token_display}
        />
      </PrescriptionSheetLayout>
    );
  }

  return pages;
};

export const generatePrescriptionSheet = async (
  appointment_id,
  procdure_data,
  date,
  time
) => {
  const { data } = await axios.get(
    `${API_ENDPOINT}/ProviderPatientConsultation/get_consult_details?appointment_id=${appointment_id}`
  );

  const casesheet = data;
  const doctor = casesheet.doctor_info[0];
  const patient = casesheet.patient[0];
  const clinic = casesheet.schedule_info[0];

  const _casesheet = casesheet.case_sheets?.[0];
  const treatments = procdure_data?.map((e) => ({
    label: e.procedure_name,
  }));

  const pages = await generatePrescriptionSheetPages({
    info: {
      doctor_info: {
        ...doctor,
        name: `${doctor?.first_name} ${doctor?.last_name}`,
        upn: doctor?.provider_no,
        dr_reg_no: doctor?.reg_no,
      },
      patient,
      schedule_info: clinic,
    },
    casesheet: _casesheet,
    provider_sign_url: `${STORAGE_DOMAIN}${doctor?.sign_image}`,
    date: moment(date).format('DD-MM-YYYY'),
    selectedProcedures: treatments,
    isWalkin: casesheet?.consultation_type !== 'op',
    time: moment(`${date} ${time}`).format('hh:mm A'),
  });
  await genratePdfFromElement(pages)();
};

export const generatePDFElement = async (
  info,
  casesheet,
  provider_sign_url,
  appointmentId,
  isWalkin,
  selectedProcedures
) => {
  await genratePdfFromElement(
    await generatePrescriptionSheetPages({
      info,
      casesheet,
      provider_sign_url,
      appointmentId,
      isWalkin,
      selectedProcedures,
      time: moment().format('hh:mm A'),
      date: moment().format('DD-MM-yyyy'),
    })
  )();
};

export const PrescriptionSheetItem = ({
  doctorName,
  doctorRegNo,
  patient_info,
  file_no,
  isWalkin,
  provider_sign_url,
  date,
  prescriptions,
  treatments,
  casesheet,
  settings,
  time,
}) => {
  const preDetails = casesheet?.prescription_details
    ? getPrescriptionFromString(casesheet.prescription_details)
    : {};
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#fff',
        width: '100%',
        flexGrow: 1,
        padding: '10px 30px',
      }}
    >
      {patient_info && Object.keys(patient_info).length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0 40px',
            marginBottom: '20px',
          }}
        >
          {/* General details */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderWidth: 1,
              borderColor: '#94a3b8',
              padding: '10px',
              borderRadius: '10px',
              width: '100%',
              backgroundColor: '#f9fafe',
              fontSize: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  textTransform: 'uppercase',
                }}
              >
                {patient_info.first_name} {patient_info.last_name}
                {patient_info.name}
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                Age:{' '}
                {patient_info.date_of_birth
                  ? calculateAge(patient_info?.date_of_birth)
                  : patient_info.age}{' '}
                Sex: {patient_info.sex ?? patient_info.gender}
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                Ph: {patient_info.phone_no ?? patient_info.phone}
              </div>

              <div
                style={{
                  display: 'flex',
                }}
              >
                Place: {patient_info.place}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
            >
              {file_no && (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  File No: {file_no}
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                }}
              >
                {isWalkin ? 'WK' : 'OP'} #
                {(settings?.showPatientUIN && patient_info.person_no) ??
                  patient_info.uin}
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                {date}
              </div>
              {time && (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  {time}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {preDetails &&
        Object.keys(preDetails).map((k) => {
          const v = preDetails[k];
          if (!v || v === '') return;
          if (!['Diagnosis', 'Treatments'].includes(k)) return;
          return (
            <div
              key={k}
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: 12,
                lineHeight: 1.2,
                marginBottom: '15px',
              }}
            >
              {/*  Diagnosis */}
              <span
                style={{
                  fontWeight: 600,
                  marginRight: '5px',
                  display: 'flex',
                }}
              >
                {k}:
              </span>
              <span
                style={{
                  display: 'flex',
                  whiteSpace: 'pre-line',
                }}
              >
                {preDetails[k]}
              </span>
            </div>
          );
        })}
      {/* {casesheet?.special_instruction && (
		<div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '15px',
              fontSize: 12,
              lineHeight: 1.2,
            }}
          >
        
            <span
              style={{
                fontWeight: 600,
                marginRight: '5px',
                display: 'flex',
              }}
            >
              Diagnosis:
            </span>
            <span
              style={{
                display: 'flex',
              }}
            >
              {casesheet?.special_instruction}
            </span>
          </div>
        )} */}
      {prescriptions?.length > 0 && (
        <PrescriptionTable prescriptions={prescriptions} />
      )}
      {treatments?.length > 0 && <TreatmentsTable treatments={treatments} />}

      {preDetails?.Lab && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: 12,
            lineHeight: 1.2,
            marginBottom: '15px',
          }}
        >
          {/*  Diagnosis */}
          <span
            style={{
              fontWeight: 600,
              marginRight: '5px',
              display: 'flex',
            }}
          >
            Lab:
          </span>
          <span
            style={{
              display: 'flex',
              whiteSpace: 'pre-line',
            }}
          >
            {preDetails.Lab}
          </span>
        </div>
      )}

      {casesheet?.special_instruction && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
            fontSize: 12,
            lineHeight: 1.2,
          }}
        >
          {/*  Instructions */}
          <span
            style={{
              fontWeight: 600,
              marginRight: '5px',
              display: 'flex',
            }}
          >
            Instructions:
          </span>
          <span
            style={{
              display: 'flex',
              whiteSpace: 'pre-line',
            }}
          >
            {casesheet?.special_instruction}
          </span>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          paddingTop: '10px',
          flexDirection: 'column',
          // justifyContent: 'flex-start'
        }}
      >
        {/*  Sign */}
        {provider_sign_url && (
          <img
            src={provider_sign_url}
            style={{
              width: '100px',
              height: '50px',
            }}
            width="100px"
            height="50px"
            alt="sign"
          />
        )}

        <div
          style={{
            fontSize: 10,
            display: 'flex',
          }}
        >
          {doctorName}
        </div>

        {settings?.casesheet?.regNoHide ? (
          <div />
        ) : (
          <div
            style={{
              fontSize: 9,
              display: 'flex',
            }}
          >
            Reg No: {doctorRegNo}
          </div>
        )}
      </div>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
        }}
      />
    </div>
  );
};

export const PrescriptionSheetLayout = ({
  children,
  print_provider_header,
  doctorName,
  doctorUpn,
  doctorRegNo,
  doctorSpeciality,
  doctorDegreeName,
  doctorAddQualification,
  slot_start,
  slot_end,
  print_clinic_header,
  weekdays: { mon, tue, wed, thu, fri, sat, sun },
  clinic_logo,
  clinic_place,
  clinic_phone_no,
  footNote = 'Generated from UNQ Practice Management Software',
  settings,
  schedules,
}) => (
  <div
    style={{
      height: '841.89px',
      width: '595.28px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#666',
      fontSize: 14,
      fontWeight: 400,
    }}
  >
    <div
      style={{
        height: settings?.casesheet?.customHeaderHeight
          ? settings?.casesheet?.headerHeight
          : '130px',
        width: '100%',
        backgroundColor:
          settings?.casesheet?.headerBg === false ? '#fff' : '#f9fafe',
        display: 'flex',
        padding: '20px 20px 5px 20px',
        justifyContent: 'space-between',
      }}
    >
      {print_provider_header ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: '50%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: 16,
                fontWeight: 600,
                alignItems: 'flex-end',
              }}
            >
              <span
                style={{
                  display: 'flex',
                }}
              >
                {doctorName}
              </span>
            </div>
            {`${doctorDegreeName} , ${doctorSpeciality}`
              .split('\\n')
              .map((k, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',
                    fontSize: 9,
                  }}
                >
                  {k}
                </div>
              ))}

            {doctorAddQualification.split('\\n').map((k, i) => (
              <div
                key={i}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                  fontSize: 9,
                }}
              >
                {k}
              </div>
            ))}

            {!settings?.casesheet?.hideHeaderProviderNo && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  fontSize: 9,
                }}
              >
                Reg No:{doctorRegNo}
              </div>
            )}
          </div>
          {!settings?.hideSchedule && !schedules && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  fontSize: 10,
                  display: 'flex',
                }}
              >
                {mon ? 'M' : '_'} {tue ? 'T' : '_'} {wed ? 'W' : '_'}{' '}
                {thu ? 'T' : '_'} {fri ? 'F' : '_'} {sat ? 'S' : '_'}{' '}
                {sun ? 'S' : '_'}
              </div>
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 10,
                  display: 'flex',
                }}
              >
                {`${moment(slot_start, 'HH:mm:ss').format(
                  'hh:mm A'
                )} to ${moment(slot_end, 'HH:mm:ss').format('hh:mm A')}`}
              </div>
            </div>
          )}
          {schedules && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '5px',
              }}
            >
              {schedules
                .sort((a, b) => a.slot_start.localeCompare(b.slot_start))
                .map((s) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '10px',
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        display: 'flex',
                      }}
                    >
                      {s.monday_on ? 'M' : '_'} {s.tuesday_on ? 'T' : '_'}{' '}
                      {s.wednesday_on ? 'W' : '_'} {s.thursday_on ? 'T' : '_'}{' '}
                      {s.friday_on ? 'F' : '_'} {s.saturday_on ? 'S' : '_'}{' '}
                      {s.sunday_on ? 'S' : '_'}
                    </div>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 10,
                        display: 'flex',
                      }}
                    >
                      {`${moment(s.slot_start, 'HH:mm:ss').format(
                        'hh:mm A'
                      )} to ${moment(s.slot_end, 'HH:mm:ss').format(
                        'hh:mm A'
                      )}`}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
      {print_clinic_header && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            fontSize: 12,
          }}
        >
          {clinic_logo && (
            <div
              style={{
                display: 'flex',
                overflow: 'hidden',
              }}
            >
              <img
                src={import.meta.env.VITE_STORAGE_DOMAIN + clinic_logo}
                width="70px"
                height="70px"
                style={{
                  width: '70px',
                  height: '70px',
                }}
                alt="logo"
                // width="70px" height="70px"
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              fontSize: 10,
            }}
          >
            {clinic_place}
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            {clinic_phone_no}
          </div>
        </div>
      )}
    </div>
    {children}
    {settings.bookingNo && (
      <div
        style={{
          width: '100%',
          display: 'flex',
          fontSize: 14,
          fontWeight: 'bolder',
          backgroundColor: '#fff',
          padding: '0 20px',
          paddingLeft: '50px',
          paddingBottom: settings.sectionBookingPosition
            ? settings.sectionbookingPositionHeight
            : '0px',
        }}
      >
        Booking No: {settings.bookingNo1}, {settings.bookingNo2}
      </div>
    )}
    <div
      style={{
        display: 'flex',
        backgroundColor:
          settings?.casesheet?.headerBg === false ? '#fff' : '#f9fafe',
        height: '20px',
        width: '100%',
        fontSize: 8,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
      }}
    >
      {/*  Footer */}
      <span>{footNote}</span>
      <span
        style={{
          fontSize: 8,
          fontWeight: 600,
          display: 'flex',
          color: '#5D6D7E',
          marginRight: '3px',
        }}
      >
        PRO# {doctorUpn}
      </span>
    </div>
  </div>
);

export default "This file doesn't have any default export";

export const TestSheet = () => (
  <div
    style={{
      height: '841.89px',
      width: '595.28px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#666',
      fontSize: 14,
      fontWeight: 400,
    }}
  >
    <div
      style={{
        height: '130px',
        width: '100%',
        backgroundColor: '#f9fafe',
        display: 'flex',
        padding: '20px 20px 5px 20px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              fontSize: 18,
              fontWeight: 600,
              alignItems: 'flex-end',
            }}
          >
            <span>Anna Paul</span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              -UPN071
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              fontSize: 12,
            }}
          >
            Detal Surgeon Specialist
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              fontSize: 12,
            }}
          >
            BDS, MD, Etc
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: 12,
            }}
          >
            M T W _ F _ S
          </div>
          <div
            style={{
              fontWeight: 700,
              fontSize: 10,
            }}
          >
            06:00 AM to 02:30 PM
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          fontSize: 12,
        }}
      >
        <div
          style={{
            display: 'flex',
            // borderWidth: 2,
            // borderColor: "#000",
            overflow: 'hidden',
          }}
        >
          <img
            src="https://iili.io/J12tI5u.png"
            style={{
              width: '70px',
              height: '70px',
            }}
            alt="logo"
            // width="70px" height="70px"
          />
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          Pushpa Junction, 673002
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          clinicemail@gmail.com | +919544599899
        </div>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#fff',
        width: '100%',
        flexGrow: 1,
        padding: '10px 30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '0 40px',
          marginBottom: '20px',
        }}
      >
        {/* General details */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderWidth: 1,
            borderColor: '#94a3b8',
            padding: '10px',
            borderRadius: '10px',
            width: '100%',
            backgroundColor: '#f9fafe',
            fontSize: 12,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              Muhammed Sainudheen Bahadoorsha
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              Age: 12 Sex: Male
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              Ph: 9856287452
            </div>

            <div
              style={{
                display: 'flex',
              }}
            >
              Place: Ramapuram
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              File No: 24-2596
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              OP/WK #UIN32
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              08-12-2024
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              08:56 AM
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: 12,
          lineHeight: 1.2,
          marginBottom: '15px',
        }}
      >
        {/*  Findings */}
        <span
          style={{
            fontWeight: 600,
            marginRight: '5px',
          }}
        >
          Findings:
        </span>
        <span>Cough, Sputum, Vomiting, Sneezing, Tiredness, Sleepness,</span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '15px',
          fontSize: 12,
          lineHeight: 1.2,
        }}
      >
        {/* Diagnosis */}
        <span
          style={{
            fontWeight: 600,
            marginRight: '5px',
          }}
        >
          Diagnosis:
        </span>
        <span>Acute Fever, Infection</span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '15px',
          fontSize: 12,
          lineHeight: 1.2,
        }}
      >
        {/*  Prescription */}
        <span
          style={{
            fontWeight: 600,
            marginRight: '5px',
          }}
        >
          Prescription:
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '5px',
          }}
        >
          <table
            style={{
              width: '100%',
              borderColor: '#b9c1cc',
              borderWidth: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              fontSize: 10,
            }}
          >
            <thead
              style={{
                display: 'flex',
                padding: '5px',
                width: '100%',
                backgroundColor: '#b9c1cc',
                fontWeight: 600,
              }}
            >
              <th
                style={{
                  flexGrow: 1,
                }}
              >
                Drug
              </th>

              <th
                style={{
                  width: '20%',
                  padding: '0 7px',
                }}
              >
                Dosage
              </th>
              <th
                style={{
                  width: '20%',
                  padding: '0 7px',
                }}
              >
                Duration
              </th>
            </thead>
            <tbody
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <tr
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <td
                  style={{
                    flexGrow: 1,
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  1. Paracetamol (500Mg)
                </td>
                <td
                  style={{
                    width: '20%',
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  1 - 0 -1
                </td>
                <td
                  style={{
                    width: '20%',
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  2 Weeks
                </td>
              </tr>
              <tr
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <td
                  style={{
                    flexGrow: 1,
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  1. Paracetamol (500Mg)
                </td>
                <td
                  style={{
                    width: '20%',
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  1 - 0 -1
                </td>
                <td
                  style={{
                    width: '20%',
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  2 Weeks
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '15px',
          fontSize: 12,
          lineHeight: 1.2,
        }}
      >
        {/*  Treatments */}
        <span
          style={{
            fontWeight: 600,
            marginRight: '5px',
          }}
        >
          Treatments:
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '5px',
          }}
        >
          <table
            style={{
              width: '100%',
              borderColor: '#b9c1cc',
              borderWidth: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <thead
              style={{
                display: 'flex',
                padding: '5px',
                width: '100%',
                fontSize: 10,
                backgroundColor: '#b9c1cc',
                fontWeight: 600,
              }}
            >
              <th
                style={{
                  width: '10%',
                  padding: '0 3px',
                }}
              >
                Session
              </th>
              <th
                style={{
                  width: '15%',
                  padding: '0 3px',
                }}
              >
                Tooth No
              </th>
              <th
                style={{
                  flexGrow: 1,
                }}
              >
                Description
              </th>

              <th
                style={{
                  width: '15%',
                  padding: '0 7px',
                }}
              >
                Appoinment
              </th>
              <th
                style={{
                  width: '10%',
                  padding: '0 7px',
                }}
              >
                Status
              </th>
            </thead>
            <tbody
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: 10,
              }}
            >
              <tr
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <td
                  style={{
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                    width: '10%',
                  }}
                >
                  1
                </td>
                <td
                  style={{
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                    width: '15%',
                  }}
                >
                  {' '}
                </td>
                <td
                  style={{
                    flexGrow: 1,
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  Upper Lower Scaling
                </td>
                <td
                  style={{
                    width: '15%',
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  05-02-2024
                </td>
                <td
                  style={{
                    width: '10%',
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  Done
                </td>
              </tr>
              <tr
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <td
                  style={{
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                    width: '10%',
                  }}
                >
                  1
                </td>
                <td
                  style={{
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                    width: '15%',
                  }}
                >
                  {' '}
                </td>
                <td
                  style={{
                    flexGrow: 1,
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  Upper Lower Scaling
                </td>
                <td
                  style={{
                    width: '15%',
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  05-02-2024
                </td>
                <td
                  style={{
                    width: '10%',
                    borderColor: '#b9c1cc',
                    borderWidth: 0.5,
                    padding: '5px',
                  }}
                >
                  Done
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '15px',
          fontSize: 12,
          lineHeight: 1.2,
        }}
      >
        {/*  Instructions */}
        <span
          style={{
            fontWeight: 600,
            marginRight: '5px',
          }}
        >
          Instructions:
        </span>
        <span>
          Next Appoinment on 24/05/2024, Visit if there is difficulty after 5
          days
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          paddingTop: '10px',
          flexDirection: 'column',
          // justifyContent: 'flex-start'
        }}
      >
        {/*  Sign */}
        <img
          src="https://iili.io/J13TVs4.md.png"
          style={{
            width: '100px',
            height: '50px',
          }}
          alt="sign"
        />
        <div
          style={{
            fontSize: 12,
          }}
        >
          Anna Paul
        </div>
        <div
          style={{
            fontSize: 9,
          }}
        >
          Reg No: 25492
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
        }}
      />
    </div>
    <div
      style={{
        display: 'flex',
        backgroundColor: '#f9fafe',
        height: '20px',
        width: '100%',
        fontSize: 8,
        alignItems: 'center',
        padding: '0 20px',
      }}
    >
      {/*  Footer */}
      Generated from UNQ Dashboard
    </div>
  </div>
);

const TreatmentsTable = ({ treatments }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '15px',
      fontSize: 12,
      lineHeight: 1.2,
    }}
  >
    {/*  Treatments */}
    <span
      style={{
        fontWeight: 600,
        display: 'flex',
        marginRight: '5px',
      }}
    >
      Treatments:
    </span>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '5px',
      }}
    >
      <table
        style={{
          width: '100%',
          borderColor: '#b9c1cc',
          borderWidth: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <thead
          style={{
            display: 'flex',
            padding: '5px',
            width: '100%',
            fontSize: 10,
            backgroundColor: '#b9c1cc',
            fontWeight: 600,
          }}
        >
          <th
            style={{
              width: '10%',
              padding: '0 3px',
              display: 'flex',
            }}
          >
            SL
          </th>

          <th
            style={{
              flexGrow: 1,
              display: 'flex',
            }}
          >
            Description
          </th>

          <th
            style={{
              width: '15%',
              padding: '0 7px',
              display: 'flex',
            }}
          >
            Appoinment
          </th>
          <th
            style={{
              width: '10%',
              padding: '0 7px',
              display: 'flex',
            }}
          >
            Status
          </th>
        </thead>
        <tbody
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: 10,
          }}
        >
          {treatments.map((e, i) => (
            <tr
              key={e.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <td
                style={{
                  borderColor: '#b9c1cc',
                  borderWidth: 0.5,
                  padding: '5px',
                  display: 'flex',
                  width: '10%',
                }}
              >
                {e.serialNumber ?? i + 1}
              </td>
              <td
                style={{
                  flexGrow: 1,
                  borderColor: '#b9c1cc',
                  borderWidth: 0.5,
                  padding: '5px',
                  display: 'flex',
                }}
              >
                {e.label}
              </td>
              <td
                style={{
                  width: '15%',
                  borderColor: '#b9c1cc',
                  borderWidth: 0.5,
                  display: 'flex',
                  padding: '5px',
                }}
              >
                {moment().format('DD-MM-YYYY')}
              </td>
              <td
                style={{
                  width: '10%',
                  borderColor: '#b9c1cc',
                  borderWidth: 0.5,
                  padding: '5px',
                  display: 'flex',
                }}
              >
                Done
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

/**
 *
 * @param {Object} props
 * @param {{
 * med: string,
 * dosage: string,
 * duration: string
 * incredients?: string
 * }[]
 * } props.prescriptions
 * @returns
 * @description
 * This component is used to display the prescription details in the prescription sheet
 */
const PrescriptionTable = ({ prescriptions }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '15px',
      fontSize: 12,
      lineHeight: 1.2,
    }}
  >
    {/*  Prescription */}
    <span
      style={{
        fontWeight: 600,
        marginRight: '5px',
        display: 'flex',
      }}
    >
      Prescription:
    </span>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '5px',
      }}
    >
      <table
        style={{
          width: '100%',
          borderColor: '#b9c1cc',
          borderWidth: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          fontSize: 10,
        }}
      >
        <thead
          style={{
            display: 'flex',
            padding: '5px',
            width: '100%',
            backgroundColor: '#b9c1cc',
            fontWeight: 600,
          }}
        >
          <th
            style={{
              flexGrow: 1,
              display: 'flex',
            }}
          >
            Drug
          </th>

          <th
            style={{
              width: '20%',
              padding: '0 7px',
              display: 'flex',
            }}
          >
            Dosage
          </th>
          <th
            style={{
              width: '20%',
              padding: '0 7px',
              display: 'flex',
            }}
          >
            Duration
          </th>
        </thead>
        <tbody
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {prescriptions.map((e, i) => (
            <tr
              key={e.med + e.dosage + e.duration}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <td
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  borderColor: '#b9c1cc',
                  borderWidth: 0.5,
                  flexDirection: 'column',
                  padding: '5px',
                  width: '60%',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {e.serialNumber ?? i + 1}. {e.med}
                </div>
                {e.incredients && (
                  <div
                    style={{
                      display: 'flex',
                      whiteSpace: 'pre-line',
                      fontSize: '9px',
                      color: '#666',
                    }}
                  >
                    {e.incredients}
                  </div>
                )}
                {e.note && (
                  <div
                    style={{
                      display: 'flex',
                      whiteSpace: 'pre-line',
                      fontSize: '9px',
                      color: '#444',
                    }}
                  >
                    {e.note}
                  </div>
                )}
              </td>
              <td
                style={{
                  width: '20%',
                  borderColor: '#b9c1cc',
                  borderWidth: 0.5,
                  flexShrink: 0,
                  padding: '5px',
                  display: 'flex',
                  whiteSpace: 'pre-line',
                }}
              >
                {e.dosage}
              </td>
              <td
                style={{
                  width: '20%',
                  borderColor: '#b9c1cc',
                  borderWidth: 0.5,
                  flexShrink: 0,
                  padding: '5px',
                  display: 'flex',
                  whiteSpace: 'pre-line',
                }}
              >
                {e.duration}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);
