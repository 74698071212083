/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import Table from '../layout/Table';
import { API_ENDPOINT } from '../../config/api';
import { useUser } from '@/hooks/useUser';

const headings = [
  'UMN',
  'Default',
  'Pharmacy',
  'Contact',
  'Mobile No',
  'Action',
];

const pharmacyHeadings = [
  'UMN',
  'Pharmacy',
  'Contact',
  'Mobile No',
  'Email',
  'Action',
];

const ModalBox = ({ medStoreList, connectPharmacyProvider }) => {
  const [data, setData] = useState(medStoreList || []);
  const [filters, setFilters] = useState({ search: '' });

  const filter = () => {
    const copy = data.map((row) => {
      if (filters.search === '' || searchRow(row))
        return { ...row, display: true };
      return { ...row, display: false };
    });
    setData(copy);
  };

  const filterChangeHandler = ({ target: { name, value } }) =>
    setFilters({ ...filters, [name]: value });

  const searchRow = (row) => {
    const keys = Object.keys(row);
    for (const i in keys) {
      const key = keys[i];
      const value = typeof row[key] === 'string' ? row[key] : `${row[key]}`;
      if (value.toLowerCase().includes(filters.search.toLowerCase()))
        return true;
    }
    return false;
  };

  useEffect(() => {
    filter();
    // eslint-disable-next-line
  }, [filters]);

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="pharmacy-Modal"
      role="dialog"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Pharmacy Directory</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row m-l-10 m-r-10">
              <input
                className="form-control"
                name="search"
                onChange={filterChangeHandler}
                tabIndex={3}
                placeholder="Search by name, id, place, email..."
                type="text"
                value={filters.search}
              />
            </div>
            <div className="row m-t-10">
              <div className="col-12">
                <Table
                  data={data
                    .filter((row) => row.display)
                    .map((medStore) => [
                      medStore.medical_store_no,
                      medStore.medical_store_name,
                      medStore.first_name,
                      medStore.phone_no,
                      medStore.email_id,
                      <button
                        className="btn btn-outline-info"
                        data-dismiss="modal"
                        onClick={() =>
                          connectPharmacyProvider(medStore.medical_store_id)
                        }
                      >
                        Connect
                      </button>,
                    ])}
                  headings={pharmacyHeadings}
                  number={7}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SettingsPharmacy = () => {
  const navigate = useNavigate();
  const { user_data, clinic_data } = useUser();
  const { pathname } = useLocation();

  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(true);
  const [connectedMedStoresData, setConnectedMedStoresData] = useState([]);
  const [medStoreList, setMedStoreList] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState('');

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const setDefault = ({ target: { name, value } }) => {
    const api_route =
      user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff'
        ? 'ClinicMedicalConnection/set_default_pharmacy_for_clinic'
        : 'ProviderMedicalStore/set_default_pharmacy_for_provider';
    const postData = {
      [user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff'
        ? 'clinic_id'
        : 'provider_id']:
        user_data.user_type === 'clinic_owner' ||
        user_data.user_type === 'clinic_staff'
          ? clinic_data.clinic_id
          : user_data.user_id,
      medical_store_id: value,
      is_default: true,
      modified_by: user_data.user_no,
    };
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/${api_route}`, postData)
      .catch((err) =>
        showAlert("Couldn't set as default Medical Store", 'error')
      )
      .finally(() => {
        getConnectedPharmacies();
        showAlert('Medical Store set as default successfully', 'success');
      });
  };

  const disconnectPharmacy = (pharmacy_id) => {
    if (user_data.user_role === 'provider' && selectedClinic) return;
    const api_route =
      user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff'
        ? 'ClinicMedicalConnection/toggle_pharmacy_clinic_connection'
        : 'ProviderMedicalStore/toggle_pharmacy_provider_connection';
    const postData = {
      [user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff'
        ? 'clinic_id'
        : 'provider_id']:
        user_data.user_type === 'clinic_owner' ||
        user_data.user_type === 'clinic_staff'
          ? clinic_data.clinic_id
          : user_data.user_id,
      medical_store_id: pharmacy_id,
      is_active: false,
      modified_by: user_data.user_no,
    };
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/${api_route}`, postData)
      .catch((err) => showAlert("Couldn't disconnect Medical Store", 'error'))
      .finally(() => {
        getConnectedPharmacies();
        showAlert('Medical Store disconnected successfully', 'success');
      });
  };

  const connectPharmacyProvider = (pharmacy_id) => {
    setShowLoading(true);
    Axios.post(
      `${API_ENDPOINT}/ProviderMedicalStore/toggle_pharmacy_provider_connection`,
      {
        provider_id: user_data.user_id,
        medical_store_id: pharmacy_id,
        is_active: true,
        modified_by: user_data.user_no,
      }
    )
      .catch((err) => showAlert("Couldn't connect Medical Store", 'error'))
      .finally(() => {
        getConnectedPharmacies();
        showAlert('Medical Store connected successfully', 'success');
      });
  };

  const getConnectedPharmacies = (clinic_id) => {
    setShowLoading(true);
    const api_route =
      user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff' ||
      clinic_id
        ? `ClinicMedicalConnection/conn_med_list/${
            clinic_id || clinic_data.clinic_id
          }`
        : `ProviderMedicalStore/conn_med_list/${user_data.user_id}`;
    Axios.get(`${API_ENDPOINT}/${api_route}`)
      .then((res) => {
        if (res && res.data) {
          loadMedStoresTable(res.data);
        }
      })
      .catch((err) => {
        showAlert('Failed to load info', 'error');
        throw err;
      })
      .finally(() => setShowLoading(false));
  };

  const getAllPharmacies = () => {
    Axios.get(`${API_ENDPOINT}/MedicalStaffMaster/web_admin/med_list`)
      .then(({ data: res }) => {
        setMedStoreList(res.map((row) => ({ ...row, display: true })));
        setShowLoading(false);
      })
      .catch((err) => showAlert('Failed to load info'))
      .finally(() => setShowLoading(false));
  };

  const loadMedStoresTable = (data) => {
    setConnectedMedStoresData(
      data.map((row) => [
        <Link to={`/medicalstores/edit-for-clinic/${row.medical_store_id}`}>
          {row.medical_store_no}
        </Link>,
        <div className="form-radio radio radiofill radio-info radio-inline">
          <label style={{ marginBottom: '15px' }}>
            <input
              name="is_default"
              onChange={setDefault}
              type="radio"
              checked={row.is_default}
              value={row.medical_store_id}
            />
            <i className="helper" />
          </label>
        </div>,
        row.medical_store_name,
        row.contact_name,
        row.phone_no,
        ((user_data.user_role === 'provider' && !selectedClinic) ||
          user_data.user_type === 'clinic_owner' ||
          user_data.user_type === 'clinic_staff') && (
          <img
            alt="Disconnect"
            src={`/img/disconnect_icon.png`}
            onClick={() => disconnectPharmacy(row.medical_store_id)}
            style={{ cursor: 'pointer' }}
          />
        ),
      ])
    );
  };

  const clinicChangeHandler = ({ target: { value } }) => {
    setSelectedClinic(value);
    getConnectedPharmacies(value);
  };

  useEffect(() => {
    if (
      user_data.user_role === 'provider' &&
      clinic_data &&
      clinic_data.length
    ) {
      const _clinicList = clinic_data.map((item) => ({
        name: item.clinic_name,
        value: item.clinic_id,
      }));
      _clinicList.unshift({ name: 'All Clinics', value: '' });
      setClinicList(_clinicList);
    }
    getConnectedPharmacies();
    getAllPharmacies();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* {user_data.user_role === 'provider' && (
        <div className="col-sm-4 m-b-10">
          <Select
            name="clinic_id"
            onChange={clinicChangeHandler}
            // value={clinic}
            values={clinicList}
          />
        </div>
      )} */}
      <Table data={connectedMedStoresData} headings={headings} />
      {medStoreList && medStoreList.length && (
        <ModalBox
          medStoreList={medStoreList}
          connectPharmacyProvider={connectPharmacyProvider}
        />
      )}
      {((user_data.user_role === 'provider' && !selectedClinic) ||
        user_data.user_type === 'clinic_owner' ||
        user_data.user_type === 'clinic_staff') && (
        <div className="fab" title="Setup">
          {(user_data.user_type === 'clinic_owner' ||
            user_data.user_type === 'clinic_staff') && (
            <div className="btn btn-inverse btn-icon">
              <Link to="/medicalstores/add-for-clinic">
                <i className="ion-plus text-white" />
              </Link>
            </div>
          )}

          {user_data.user_role === 'provider' && (
            <div
              className="btn btn-inverse btn-icon"
              data-toggle="modal"
              data-target="#pharmacy-Modal"
            >
              <i className="ion-plus text-white" />
            </div>
          )}
        </div>
      )}
      <LoadingBox show={showLoading} />
    </>
  );
};

export default SettingsPharmacy;
