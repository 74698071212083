import { useLocation } from 'react-router';
import useSWRImmutable from 'swr/immutable';
import { useEffect } from 'react';
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { getFetcher } from '@/_helpers/swr';
import { API_ENDPOINT } from '@/config/api';
import { useClinic } from '@/hooks/useUser';
import { LabType } from '@/types/api';

import { useLab } from './state';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
 
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
 

export const NavBarLabSelect = () => {
  const { pathname } = useLocation();
  if (!pathname.startsWith('/lab')) return null;

  const clinic = useClinic();
  const { labId, setLabId } = useLab();
  const { data } = useSWRImmutable<LabType[]>(
    clinic ? `${API_ENDPOINT}/lab/labs/${clinic.clinic_id}` : null,
    getFetcher
  );

  const selectedLab = data?.find((e) => e.id === labId) || data?.[0];

  const labChangeHandler = (value: string) => {
    setLabId(Number(value));
  };

  useEffect(() => {
    const sltd = data?.find((e) => e.id === labId);
    if (!sltd) {
      const labId = data?.[0]?.id;
      if (labId) setLabId(labId);
    }
  }, [data]);

  return (
    <Select value={selectedLab?.id.toString()} onValueChange={labChangeHandler}>
      <SelectTrigger className="_w-[180px] !_bg-white _text-foreground !_py-0 !_px-2 sm:_px-3 sm:_py-2 _leading-3 sm:_leading-normal">
        <SelectValue placeholder="Select Lab" />
      </SelectTrigger>
      <SelectContent>
        {data?.map((e) => (
          <SelectItem key={e.id} value={e.id.toString()}>{e.name}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};


export const AppoinmentDateLab  = () => {
	const { pathname } = useLocation();
	if (!pathname.startsWith('/lab')) return null;
  
	const [date, setDate] = useLab(s => [s.date, s.setDate])
 
	return (
	  <Popover>
		<PopoverTrigger asChild>
		  <Button
			variant={"outline"}
			className={cn(
			  "_w-[280px] _justify-start _text-left _font-normal _text-black",
			  !date && "_text-muted-foreground"
			)}
		  >
			<CalendarIcon className="_mr-2 _h-4 _w-4" />
			{date ? format(date, "PPP") : <span>Pick a date</span>}
		  </Button>
		</PopoverTrigger>
		<PopoverContent className="_w-auto _p-0">
		  <Calendar
			mode="single"
			selected={date}
			onSelect={setDate as any}
			required
			initialFocus
		  />
		</PopoverContent>
	  </Popover>
	)
}