const CheckboxWithImage = ({ label, id, image, isTitleBold, ...restProps }) => {
  return (
    <div className="form-group form-check mr-5 d-flex align-items-center dental-chart__treatment-selection__checkbox-with-image">
      <input
        {...restProps}
        type="checkbox"
        className="form-check-input"
        id={id}
      />
      <label className="form-check-label d-flex flex-column " htmlFor={id}>
        {isTitleBold ? (
          <b className="mb-2">{label}</b>
        ) : (
          <span className="mb-2">{label}</span>
        )}

        <img src={image} alt={`${label} image`} />
      </label>
    </div>
  );
};

export default CheckboxWithImage;
