import { PATIENT_TYPES, TEETH_QUADRANTS } from './constants';
import { useDentalChart } from './DentalChartContext';
import TeethTop from './TeethTop';

function TeethContainer({ code = '18', quadrant, fillingTypes }) {
  const { setSelectedTeeth, teethData, patientType } = useDentalChart();
  const currentTeethData = teethData[code] || null;

  let teethUrls = {
    base: `/assets/images/dental/teeth/${code}.png`,
    implant: `/assets/images/dental/teeth/${code}_a.png`,
    rct: `/assets/images/dental/teeth/${code}_b.png`,
    postncore: `/assets/images/dental/teeth/${code}_c.png`,
    veneers: `/assets/images/dental/teeth/${code}_d.png`,
    laminates: `/assets/images/dental/teeth/${code}_e.png`,
    fullcrown: `/assets/images/dental/teeth/${code}_f.png`,
    onlays: `/assets/images/dental/teeth/${code}_g.png`,
    inlays: `/assets/images/dental/teeth/${code}_h.png`,
    caries: `/assets/images/dental/teeth/${code}_i.png`,
    deepcaries: `/assets/images/dental/teeth/${code}_j.png`,
    cariesexposure: `/assets/images/dental/teeth/${code}_k.png`,
  };

  if (quadrant === TEETH_QUADRANTS.upperRight) {
    teethUrls = {
      base: `/assets/images/dental/teeth/1${code.toString().slice(1)}.png`,
      implant: `/assets/images/dental/teeth/1${code.toString().slice(1)}_a.png`,
      rct: `/assets/images/dental/teeth/1${code.toString().slice(1)}_b.png`,
      postncore: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_c.png`,
      veneers: `/assets/images/dental/teeth/1${code.toString().slice(1)}_d.png`,
      laminates: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_e.png`,
      fullcrown: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_f.png`,
      onlays: `/assets/images/dental/teeth/1${code.toString().slice(1)}_g.png`,
      inlays: `/assets/images/dental/teeth/1${code.toString().slice(1)}_h.png`,
      caries: `/assets/images/dental/teeth/1${code.toString().slice(1)}_i.png`,
      deepcaries: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_j.png`,
      cariesexposure: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_k.png`,
    };
  } else if (quadrant === TEETH_QUADRANTS.lowerRight) {
    teethUrls = {
      base: `/assets/images/dental/teeth/4${code.toString().slice(1)}.png`,
      implant: `/assets/images/dental/teeth/4${code.toString().slice(1)}_a.png`,
      rct: `/assets/images/dental/teeth/4${code.toString().slice(1)}_b.png`,
      postncore: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_c.png`,
      veneers: `/assets/images/dental/teeth/4${code.toString().slice(1)}_d.png`,
      laminates: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_e.png`,
      fullcrown: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_f.png`,
      onlays: `/assets/images/dental/teeth/4${code.toString().slice(1)}_g.png`,
      inlays: `/assets/images/dental/teeth/4${code.toString().slice(1)}_h.png`,
      caries: `/assets/images/dental/teeth/4${code.toString().slice(1)}_i.png`,
      deepcaries: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_j.png`,
      cariesexposure: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_k.png`,
    };
  } else if (
    quadrant === TEETH_QUADRANTS.upperLeft &&
    patientType === PATIENT_TYPES.pedo
  ) {
    teethUrls = {
      base: `/assets/images/dental/teeth/1${code.toString().slice(1)}.png`,
      implant: `/assets/images/dental/teeth/1${code.toString().slice(1)}_a.png`,
      rct: `/assets/images/dental/teeth/1${code.toString().slice(1)}_b.png`,
      postncore: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_c.png`,
      veneers: `/assets/images/dental/teeth/1${code.toString().slice(1)}_d.png`,
      laminates: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_e.png`,
      fullcrown: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_f.png`,
      onlays: `/assets/images/dental/teeth/1${code.toString().slice(1)}_g.png`,
      inlays: `/assets/images/dental/teeth/1${code.toString().slice(1)}_h.png`,
      caries: `/assets/images/dental/teeth/1${code.toString().slice(1)}_i.png`,
      deepcaries: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_j.png`,
      cariesexposure: `/assets/images/dental/teeth/1${code
        .toString()
        .slice(1)}_k.png`,
    };
  } else if (
    quadrant === TEETH_QUADRANTS.lowerLeft &&
    patientType === PATIENT_TYPES.pedo
  ) {
    teethUrls = {
      base: `/assets/images/dental/teeth/4${code.toString().slice(1)}.png`,
      implant: `/assets/images/dental/teeth/4${code.toString().slice(1)}_a.png`,
      rct: `/assets/images/dental/teeth/4${code.toString().slice(1)}_b.png`,
      postncore: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_c.png`,
      veneers: `/assets/images/dental/teeth/4${code.toString().slice(1)}_d.png`,
      laminates: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_e.png`,
      fullcrown: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_f.png`,
      onlays: `/assets/images/dental/teeth/4${code.toString().slice(1)}_g.png`,
      inlays: `/assets/images/dental/teeth/4${code.toString().slice(1)}_h.png`,
      caries: `/assets/images/dental/teeth/4${code.toString().slice(1)}_i.png`,
      deepcaries: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_j.png`,
      cariesexposure: `/assets/images/dental/teeth/4${code
        .toString()
        .slice(1)}_k.png`,
    };
  }

  const handleTeethClick = () => {
    setSelectedTeeth({ code, quadrant, fillingTypes });
  };

  const shouldShowBaseTeeth = !(
    currentTeethData?.extracted ||
    currentTeethData?.implanted ||
    currentTeethData?.fpdbridgePontic
  );

  return (
    <div className="dental-chart-teeth" onClick={handleTeethClick}>
      {currentTeethData?.extracted && <ExtractedTeeth />}

      {currentTeethData?.fpdbridgePontic && <BridgeTeeth />}

      {currentTeethData?.implanted && (
        <>
          <div className="dental-chart-teeth__side">
            <img
              className="dental-chart-teeth__base"
              src={teethUrls.implant}
              alt=""
            />
          </div>
          <TeethTop
            top={currentTeethData?.filling?.top || false}
            right={currentTeethData?.filling?.right || false}
            bottom={currentTeethData?.filling?.bottom || false}
            left={currentTeethData?.filling?.left || false}
            center={currentTeethData?.filling?.center || false}
          />
        </>
      )}

      {shouldShowBaseTeeth && (
        <>
          <div className="dental-chart-teeth__side">
            <img
              className="dental-chart-teeth__base"
              src={teethUrls.base}
              alt=""
            />

            {currentTeethData?.rct && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.rct}
                alt=""
              />
            )}
            {currentTeethData?.postncore && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.postncore}
                alt=""
              />
            )}
            {currentTeethData?.veneers && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.veneers}
                alt=""
              />
            )}
            {currentTeethData?.laminates && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.laminates}
                alt=""
              />
            )}
            {currentTeethData?.fullcrown && (
              <img
                className="dental-chart-teeth__overlap dental-chart-teeth__crown"
                src={teethUrls.fullcrown}
                alt=""
              />
            )}
            {currentTeethData?.onlays && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.onlays}
                alt=""
              />
            )}
            {currentTeethData?.inlays && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.inlays}
                alt=""
              />
            )}
            {currentTeethData?.caries && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.caries}
                alt=""
              />
            )}
            {currentTeethData?.deepcaries && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.deepcaries}
                alt=""
              />
            )}
            {currentTeethData?.cariesexposure && (
              <img
                className="dental-chart-teeth__overlap"
                src={teethUrls.cariesexposure}
                alt=""
              />
            )}
          </div>
          <TeethTop
            top={currentTeethData?.filling?.top || false}
            right={currentTeethData?.filling?.right || false}
            bottom={currentTeethData?.filling?.bottom || false}
            left={currentTeethData?.filling?.left || false}
            center={currentTeethData?.filling?.center || false}
          />
        </>
      )}

      <h6 className="text-center dental-chart-teeth__code">{code}</h6>
    </div>
  );
}

export default TeethContainer;

const BridgeTeeth = () => (
  <>
    <img
      className="dental-chart-teeth__bridge"
      src="./fpd_bridge_pontic.png"
      alt=""
    />
  </>
);
const ExtractedTeeth = () => (
  <>
    <img
      className="dental-chart-teeth__base--extracted"
      src="./extraction.png"
      alt=""
    />
    <img
      className="dental-chart-teeth__top--extracted my-2"
      src="./extraction.png"
      alt=""
    />
  </>
);
