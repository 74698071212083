const lightColorList = [
  '#fff9d0',
  '#fedfdc',
  '#fde1f0',
  '#f9e9c9',
  '#82cbb2',
  '#fdde6c',
  '#ead2a2',
  '#e0ffff',
  '#a5fbd5',
  '#ddadaf',
  '#b1ff65',
  '#d8f3d7',
  '#b790d4',
  '#d6b4fc',
  '#aac2a1',
  '#bdcaa8',
];
const generateRandomColor = (type) =>
  type === 'light_color'
    ? lightColorList[Math.floor(Math.random() * lightColorList.length)]
    : `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export { generateRandomColor as default };
