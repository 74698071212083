/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { Link, useParams } from 'react-router-dom';

import Alert from '../layout/Alert';
import Select from '../form/Select';
import Table from '../layout/Table';
import LoadingBox from '../layout/LoadingBox';
import { API_ENDPOINT } from '../../config/api';
import showCurrency from '../common/showCurrency';

const contactHeadings = [
  'Contact Person',
  'Phone No',
  'Store Phone No',
  'Email',
  'Pswd',
  'Status',
  'Action',
];

const providerHeadings = [
  'Dr ID',
  'Name',
  'Type',
  'Speciality',
  'Clinic',
  'Phone',
  'Email',
];

const status = [
  { name: 'Active', value: 'Active' },
  { name: 'Inactive', value: 'Inactive' },
];

const MedicalStoreView = () => {
  const { id: medical_store_id } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [contactData, setContactData] = useState([]);
  const [medicalInfo, setMedicalInfo] = useState({});
  const [providerData, setProviderData] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const statusSelectHandler = (status) => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/MedicalStores/web_admin/is_active_status`, {
      medical_store_id,
      is_active: !status,
      modified_by: 'any name',
    })
      .then((res) => {
        showAlert(res.data.message, 'success');
        return Axios.get(
          `${API_ENDPOINT}/MedicalStaffMaster/web_admin/med_list`
        );
      })
      .then((res) => {
        for (const i in res.data) {
          if (`${res.data[i].medical_store_id}` === medical_store_id)
            setMedicalInfo(res.data[i]);
        }
      })
      .catch((err) => showAlert('Status change failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    Axios.get(`${API_ENDPOINT}/MedicalStaffMaster/web_admin/med_list`)
      .then((res) => {
        for (const i in res.data) {
          if (`${res.data[i].medical_store_id}` === medical_store_id) {
            setMedicalInfo(res.data[i]);
          }
        }
      })
      .then(() =>
        Axios.get(
          `${API_ENDPOINT}/ProviderMedicalStore/web_admin/conn_pro_list/${medical_store_id}`
        )
      )
      .then((res) => {
        setProviderData(
          res.data.map((row) => [
            row.provider_id,
            row.provider_name,
            row.provider_type,
            row.speciality,
            row.clinic_name,
            row.phone_no,
            row.email_id,
          ])
        );
      })
      .catch((err) => showAlert('Failed to load info', 'error'))
      .finally(() => setShowLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="hex-info-wrap m-b-20">
        <div className="row">
          <div className="col-md-2 hex-info-box">
            <div className="hex-info-content">
              <h5>
                {`${medicalInfo.medical_store_name} `}
                <small>
                  <Link
                    className="fa fa-edit"
                    to={`/medicalstores/edit/${medicalInfo.medical_store_id}`}
                  />
                </small>
              </h5>
              <h5>{medicalInfo.medical_store_id}</h5>
            </div>
          </div>
          <div className="col-md-2 hex-info-box">
            <div className="hex-info-content">
              <div className="hex-info-line">
                <span>City</span>
                <span>{medicalInfo.city}</span>
              </div>
              <div className="hex-info-line">
                <span>Area</span>
                <span>{medicalInfo.region}</span>
              </div>
            </div>
          </div>
          <div className="col-md-2 hex-info-box">
            <div className="hex-info-content">
              <div className="hex-info-line">
                <span>Subscription</span>
                <span>Smart Pharma Suit Advanced</span>
              </div>
              <div className="hex-info-line">
                <span>Status</span>
                <span>{medicalInfo.is_active ? 'Active' : 'Not Active'}</span>
              </div>
            </div>
          </div>
          <div className="col-md-2 hex-info-box">
            <div className="hex-info-content">
              <div className="hex-info-line">
                <span>Payments</span>
                <span>{showCurrency()}. 500 (monthly)</span>
              </div>
              <div className="hex-info-line">
                <span>Pending</span>
                <span>{showCurrency()}. 0</span>
              </div>
            </div>
          </div>
          <div className="col-md-4 hex-info-box">
            <div className="hex-info-content">
              <div className="hex-info-line">
                <span>Limit</span>
                <span>100 contacts</span>
              </div>
              <div className="hex-info-line">
                <button className="btn">
                  Configure Payment <i className="ti-angle-down" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="j-unit row">
        <span className="col-2 col-sm-1 text-right">Note1:</span>
        <span className="col-10 col-sm-11">
          The amount will be automatically deducted from debit/vredit card on
          1st day of every month. Debit/Credit Card need to be configured to
          activate service.
        </span>
      </div>
      <div className="j-unit row">
        <span className="col-2 col-sm-1 text-right">Note2:</span>
        <span className="col-10 col-sm-11">
          The subscriptionwill be automatically moved to next tier when the
          limit is crossed
        </span>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <Table headings={contactHeadings} data={contactData} />
      <Table headings={providerHeadings} data={providerData} />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default MedicalStoreView;
