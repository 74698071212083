import React, { useState, useEffect } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import moment from 'moment';
import { API_ENDPOINT } from '../../config/api';

const _date = moment().format('YYYY-MM-DD');

const initFormData = () => ({
  e_record_date: _date,
  title: '',
  file_id: '',
  description: '',
});

const PrescriptionUpload = ({ patient_id, refreshData, setShowLoading }) => {
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const [formData, setFormData] = useState(initFormData());

  const input_handler = ({ target: { name, value, type } }) => {
    const copy = { ...formData };
    if (type === 'date') {
      if (Number.isNaN(Date.parse(value))) return;
      const newDate = new Date(value).toISOString().split('T')[0];
      setFormData({ ...copy, [name]: newDate });
    } else {
      setFormData({ ...copy, [name]: value });
    }
  };

  const readFile = (files) => {
    setLoading(true);
    const random_string = Math.random().toString(16).substring(2, 10);
    const file = files[0];
    const fileExtension =
      file.type === 'application/pdf'
        ? 'pdf'
        : file.type === 'image/jpeg'
        ? 'jpg'
        : 'png';
    // To avoid overwriting files with same name, we are adding a random string to the file_name
    const fileNameWithExtension = `${
      file.name.split('.')[0]
    }_${random_string}.${fileExtension}`;
    const fd = new FormData();
    fd.append('file', file, fileNameWithExtension);
    fd.append('upload_use_case', 'e_record');
    Axios({
      data: fd,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: `${API_ENDPOINT}/File/upload`,
    })
      .then(({ data: { info, file_id } }) => {
        setFormData({ ...formData, file_id: parseInt(file_id) });
        setUploadStatus({
          success: true,
          message:
            'File uploaded successfully, click on submit to save details',
        });
      })
      .catch((err) =>
        setUploadStatus({
          success: false,
          message: 'Uploading failed please try again',
        })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    setShowLoading && setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/eRecord/web_admin/new_e_record`, formData)
      .then(({ data: resData }) => {
        setFormData({
          ...initFormData(),
          person_id: 0,
        });
        refreshData();
      })
      .catch((err) => console.log(err))
      .finally(() => setShowLoading && setShowLoading(false));
  };

  useEffect(() => {
    setFormData({ ...initFormData(), person_id: parseInt(patient_id) });
    setUploadStatus({ success: false, message: '' });
  }, [patient_id, formData.person_id]);

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="eRecord-modal"
      role="dialog"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-lg modal-center-content"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-12 m-b-20" key={formData.person_id}>
                {/* <h4 className='modal-title text-center'>{message}</h4> */}
                <div className="row">
                  <h6 className="col-sm-6">Upload e-Record</h6>
                </div>
                <div className="row">
                  <div className="col-sm-6 form-group">
                    <input
                      className="form-control"
                      name="e_record_date"
                      onChange={input_handler}
                      defaultValue={formData.e_record_date}
                      placeholder="Prescription Date *"
                      type="date"
                      max={new Date().toISOString().split('T')[0]}
                    />
                    <div className="messages" />
                  </div>
                  <div className="col-sm-6 form-group">
                    <input
                      className="form-control"
                      name="title"
                      onChange={input_handler}
                      defaultValue={formData.title}
                      placeholder="Title or Dr Name *"
                      type="text"
                    />
                    <div className="messages" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 form-group">
                    <input
                      className="form-control"
                      name="description"
                      onChange={input_handler}
                      defaultValue={formData.description}
                      placeholder="Description *"
                      type="text"
                    />
                    <div className="messages" />
                  </div>
                  <div className="col-sm-6 ">
                    <div className="file-upload btn btn-secondary">
                      Upload File *
                      <input
                        type="file"
                        name="file"
                        accept="image/x-png,image/jpeg,application/pdf"
                        multiple={false}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        onChange={(event) => {
                          readFile(event.target.files);
                        }}
                        readOnly={loading}
                      />
                    </div>
                    <p
                      style={{
                        color: uploadStatus.success === true ? 'green' : 'red',
                      }}
                    >
                      {uploadStatus.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 text-center">
                <button
                  className="btn"
                  data-dismiss="modal"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setFormData({
                      ...initFormData(),
                      person_id: 0,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  className="btn btn-info"
                  data-dismiss="modal"
                  onClick={() => handleSubmit()}
                  disabled={
                    !formData.e_record_date ||
                    !formData.title ||
                    !formData.file_id
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionUpload;
