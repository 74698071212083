import React from 'react';

const NotFound = () => (
  <div
    style={{ height: 'calc(100vh - 200px)' }}
    className="d-flex flex-column align-items-center justify-content-center"
  >
    {/* <h4>404 | Not Found</h4>
    <p>The page you are looking for doesn&apos;t exist</p> */}
    <img
      className="img-fluid"
      src={`/img/icons/404.svg`}
      alt="No Results Found"
      width="400"
    />
    <h6 className="my-5 text-muted">
      The page you are looking for doesn&apos;t exist
    </h6>
  </div>
);

export default NotFound;
