import React from 'react';
import { Link } from 'react-router-dom';

const BackButton = ({ to }) => (
  <div className="m-b-30">
    <Link style={{ color: '#212121', fontSize: '1.3rem' }} to={to}>
      <i className="fa fa-chevron-left m-r-10" /> Back to home
    </Link>
  </div>
);

export default BackButton;
