import React, { useState } from 'react';
import { GEOCODE_API } from '../../config/api';
import Select from '../form/Select';
import { parseAddressComponents } from '../../_helpers/common_utils';
import getAreaFromPincode from './fetchAreaFromPostalCode';
import classNames from 'classnames';
import { debounce } from 'radash';

const TOLERANCE = 0.2;

// Haversine formula to calculate the distance between two latitude/longitude points
function getDistanceFromLatLonInKm(_lat1, _lon1, _lat2, _lon2) {
  const lat1 = Number(_lat1);
  const lon1 = Number(_lon1);
  const lat2 = Number(_lat2);
  const lon2 = Number(_lon2);

  const R = 6371; // Radius of the Earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

const LocationInput = ({
  countries,
  areaList,
  setAreaList,
  data,
  setData,
  changeHandler,
  setSelectedCountryCode,
  biasLat,
  biasLon,
}) => {
  const [locationSearch, setLocationSearch] = useState('');
  const [locations, setLocations] = useState([]);

  const handleLocation = async (value) => {
    const upperRight = `${Number(biasLat) + TOLERANCE},${
      Number(biasLon) + TOLERANCE
    }`;
    const lowerLeft = `${Number(biasLat) - TOLERANCE},${
      Number(biasLon) - TOLERANCE
    }`;

    const response = await fetch(
      `${GEOCODE_API}${value}&bounds=${lowerLeft}|${upperRight}`
    );
    if (response?.status === 200) {
      const respData = await response?.json();
      const resultSet = await respData?.results;
      const res = resultSet?.map((r) => {
        const parsedAddressComponents = parseAddressComponents(r || []);
        const _route = parsedAddressComponents?.route || '';
        const _sublocality = parsedAddressComponents?.sublocality || '';

        return {
          distance: getDistanceFromLatLonInKm(
            r?.geometry.location.lat,
            r?.geometry.location.lng,
            biasLat,
            biasLon
          ),
          lat: r?.geometry.location.lat,
          lon: r?.geometry.location.lng,
          name: r?.formatted_address,
          country: parsedAddressComponents?.country || '',
          province: parsedAddressComponents?.administrative_area_level_1 || '',
          area: parsedAddressComponents?.locality || '',
          street_address: `${_route}${
            _route ? `, ${_sublocality}` : _sublocality || ''
          }`,
          pin_code: parsedAddressComponents?.postal_code || '',
        };
      });

      setLocations(res.sort((a, b) => a.distance - b.distance));
    }
  };
  const deBouncedHandleLocation = debounce({ delay: 500 }, handleLocation);

  const changeLocationHandler = async ({ target: { value } }) => {
    setLocationSearch(value);
    deBouncedHandleLocation(value);
  };

  const savedLocations = JSON.parse(localStorage.getItem('location')) || [];

  const selectResult = (_location, isSaved = false) => {
    if (!_location) return;
    const _country = countries.find((item) => item.name === _location?.country);
    const _data = {
      ...data,
      location_lat: _location.lat,
      location_lon: _location.lon,
      pin_code: _location.pin_code,
      country_id: _country?.value,
      province: _location?.province,
      area: _location?.area,
      street_address: _location?.street_address,
    };
    if (setSelectedCountryCode) {
      setSelectedCountryCode?.(_country?.country_code);
      _data.phone_code = _country?.phone_code;
    }
    setData(_data);
    getAreaFromPincode(_location.pin_code, setAreaList);
    setLocationSearch(_location.name || '');
    setLocations([]);

    // Add count to saved item, and sort by count
    const toSave = savedLocations.filter((e) => e.name !== _location.name);
    if (isSaved) {
      toSave.push({
        ..._location,
        count: _location.count + 1,
      });
    } else {
      _location.count = 1;
      toSave.push({
        ..._location,
      });
    }
    localStorage.setItem(
      'location',
      JSON.stringify(toSave.sort((a, b) => b.count - a.count))
    );
  };

  return (
    <>
      <div
        className={classNames('row', {
          'lg:_col-span-3': savedLocations.length > 4,
        })}
      >
        <div className="col-12 form-group !_mb-0">
          <input
            className="form-control"
            name="location_search"
            onChange={changeLocationHandler}
            placeholder="Search for location *"
            value={locationSearch}
            type="text"
            autoComplete="none"
          />
          <div className="row">
            <div
              className="col-12"
              style={{
                background: '#eee',
                position: 'absolute',
                zIndex: 2,
              }}
            >
              {locations.length
                ? locations.map((item, index) => (
                    <div
                      className="_py-2 _px-4 _text-gray-600 _bg-white _rounded-sm hover:_bg-sky-50 _cursor-pointer _border-solid _border-slate-400 _w-full _border _text-sm _leading-5 _overflow-hidden"
                      key={index}
                      onClick={() => selectResult(item)}
                    >
                      {item.name}{' '}
                      <div className="_text-xs _inline-block _bg-purple-600 _text-white _rounded-full px-2 _font-bold">
                        {Math.round(item.distance)} km
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          </div>
        </div>
        <div className="col-12">
          <p className="_mb-0">
            {savedLocations.slice(0, 15).map((e) => (
              <span
                className="mr-2 text-secondary hover-opacity hover-underline font-weight-bold"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => selectResult(e, true)}
              >
                {`${e.name?.split(',')[0].substr(0, 7)}..`}
              </span>
            ))}
          </p>
        </div>
      </div>

      <div className="form-group">
        <input
          className="form-control"
          name="street_address"
          onChange={changeHandler}
          placeholder="Building #, Street"
          value={data.street_address}
          type="text"
        />
        <div className="messages" />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          name="pin_code"
          onChange={changeHandler}
          placeholder="Postal Code"
          value={data.pin_code}
          type="text"
          autoComplete="none"
        />
        <div className="messages" />
      </div>
      <div className="form-group">
        {data?.country_id === 101 && areaList?.length ? (
          <Select
            name="area"
            onChange={changeHandler}
            placeholder="Choose Area *"
            value={data.area}
            values={areaList}
          />
        ) : (
          <input
            className="form-control"
            name="area"
            onChange={changeHandler}
            placeholder="Area / Locality *"
            value={data.area}
            type="text"
            autoComplete="none"
          />
        )}
        <div className="messages" />
      </div>

      <div className="form-group">
        <input
          className="form-control"
          name="province"
          onChange={changeHandler}
          placeholder="State / Province *"
          value={data.province}
          type="text"
        />
        <div className="messages" />
      </div>

      <div className="form-group">
        <Select
          name="country_id"
          onChange={changeHandler}
          placeholder="Country *"
          value={data.country_id}
          values={countries}
        />
        <div className="messages" />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          name="landmark"
          onChange={changeHandler}
          placeholder="Landmark"
          value={data.landmark}
          type="text"
        />
        <div className="messages" />
      </div>
    </>
  );
};

export { LocationInput as default };
