import React, { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import { API_ENDPOINT, STORAGE_DOMAIN } from '../../config/api';
import Table from './Table';
// import { joinWithoutDupes } from '../../_helpers/common_utils';
import LoadingBox from './LoadingBox';
import { showCurrency, showCurrencyCode } from '../common/showCurrency';
import { genratePdfFromElement } from '../pages/pdf/generatePdf';
import { BillingSheet } from '../pages/pdf/BillingSheet';
import { useUser } from '@/hooks/useUser';

const payModes = [
  { name: 'Cash', value: 'cash' },
  { name: 'UPI', value: 'upi' },
  { name: 'Credit / Debit Card', value: 'card' },
  { name: 'Net Banking', value: 'net_banking' },
  { name: 'Insurance', value: 'insurance' },
];

const billingTableHeadings = [
  'Type',
  'Sl',
  'Items',
  'Price',
  'Discount',
  'Final',
];

const payActions = [
  { name: 'Collect Payment', value: 'payment' },
  { name: 'Create Bill', value: 'initiated' },
  { name: 'Issue Refund', value: 'refund' },
];

const billItemTypes = [
  { name: 'Px', value: 'px' },
  { name: 'Rx', value: 'rx' },
];

const _date = moment().format('YYYY-MM-DD');

/** @typedef {object} ProviderRes
 * @property {string} add_qualification
 * @property {string | null} base_degree
 * @property {string} consult_startdate
 * @property {string} provider_no
 * @property {number} country_id
 * @property {string} created_by
 * @property {string} degree_title
 * @property {string} email_id
 * @property {string} first_name
 * @property {string | null} id_proof
 * @property {string | null} id_proof_image
 * @property {string | null} id_proof_type
 * @property {number} image_id
 * @property {boolean} is_active
 * @property {boolean} is_email_verified
 * @property {boolean} is_terms_accepted
 * @property {string} last_name
 * @property {string} modify_by
 * @property {string} phone_no
 * @property {string | null} primary_clinic_id
 * @property {number} provider_id
 * @property {string} provider_type
 * @property {string} province
 * @property {string} reg_no
 * @property {string} regional_name
 * @property {string} sex
 * @property {string} speciality
 * @property {string} street_address
 * @property {string} user_name
 */

const PaymentModal = ({
  appointment,
  user_data,
  isTransactionComplete,
  fromPayRecords,
  procedures,
  clinic_data,
  site_preferences,
  onCancelFn,
  onSave,
}) => {
  const newCustomItemInput = useRef();
  const [netPaid, setNetPaid] = useState(0);
  const [formData, setFormData] = useState({
    appointment_id: appointment.appointment_id,
    bill_amount: 0,
    action_type: 'payment',
    created_by: user_data.user_no || 'byme',
    pay_mode: 'cash',
    txn_message: '',
  });
  const [billList, setBillList] = useState([]);
  const [prevBillInfo, setPrevBillInfo] = useState({});
  const [payOptions, setPayOptions] = useState(
    fromPayRecords ? payActions.slice(1) : payActions
  );
  const [newDueProcId, setNewDueProcId] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [fetchedTransctions, setFetchedTransctions] = useState(undefined);
  const [medList, setMedList] = useState([]);
  const [procList, setProcList] = useState([]);
  const [itemsLabelList, setItemsLabelList] = useState([]);
  const [customItemCounter, setCustomItemCounter] = useState(0);
  const [isTransactionDone, setIsTransactionDone] = useState(false);

  const calcPaidAmount = (transactions, is_revisit) => {
    if ((is_revisit && !transactions) || !transactions?.length) return;
    const total_paid = transactions.reduce(
      (sum, current) =>
        current.action_type === 'payment' ? sum + current.bill_amount : sum,
      0
    );
    const total_refund = transactions.reduce(
      (sum, current) =>
        current.action_type === 'refund' ? sum + current.bill_amount : sum,
      0
    );
    const net_paid = appointment?.NET_PAID || total_paid - total_refund;
    setNetPaid(net_paid);
  };

  const PREVIOUS_DUE_AMOUNT = useMemo(() => {
    const _data = fetchedTransctions?.[0];
    if (_data?.transaction?.length) {
      const _tData = _data?.transaction?.[0];
      if (_tData?.transaction_id !== _data?.due_data?.due_txn_id) {
        return _data?.due_amount || 0;
      }
      return 0;
    }
    return 0;
  }, [billList, fetchedTransctions]);

  const user = useUser();

  const freeVisitStatus = useMemo(() => {
    const providerId =
      user_data.user_type === 'provider'
        ? user_data.user_id
        : appointment?.provider_id || appointment?.provider?.value;

    const schedule = user?.schedules.find(
      (e) => Number(e.provider_id) === Number(providerId)
    );
    const rd = fetchedTransctions?.[0]?.revisitdata?.[0];

    const revisitdata = {
      free_revisit_enabled: schedule?.free_revisit_enabled,
      free_revisit_period: schedule?.free_revisit_period,
      last_appointment_date: rd?.last_appointment_date,
    };

    const diff = revisitdata?.free_revisit_period;
    const lastVisit = revisitdata?.last_appointment_date
      ? moment(revisitdata.last_appointment_date, 'YYYY-MM-DD').format(
          'DD-MM-YYYY'
        )
      : fetchedTransctions
      ? 'No date available'
      : 'Loading...';

    const r = {
      status: diff && revisitdata.free_revisit_enabled !== false,
      diff,
      lastVisit,
    };

    return r;
  }, [appointment, user_data, fetchedTransctions]);

  //   const PREVIOUS_DUE_AMOUNT = false;

  const totalAmount = useMemo(() => {
    const acc = (prev, next) =>
      parseInt(prev) + parseInt(next?.item_price || next?.procedure_price || 0);
    const total = billList.reduce(acc, 0);
    return total;
  }, [billList]);

  const discountAmount = useMemo(() => {
    const acc = (prev, next) =>
      parseInt(prev) + parseInt(next?.discounted_price || 0);
    const totalDiscount = billList.reduce(acc, 0);
    return totalDiscount;
  }, [billList]);

  const changeProcedurePrice = (proc_cli_id, value) => {
    if (!value) return;
    const copy = [...billList];
    const proc = copy?.find((p) => p.proc_cli_id === proc_cli_id);
    proc.item_price = `${value}`;
    setBillList(copy);
  };

  const changeDiscountedPrice = (proc_cli_id, value) => {
    const copy = [...billList];
    const proc = copy?.find((p) => p.proc_cli_id === proc_cli_id);
    if (
      value > parseInt(proc.item_price) ||
      value > parseInt(proc.procedure_price)
    )
      return;
    proc.discounted_price = `${value}`;
    // Is FULLY discounted
    proc.is_discounted = `${
      parseInt(proc.item_price || proc.procedure_price) <=
      parseInt(proc.discounted_price)
    }`;
    setBillList(copy);
  };

  //   const applyFullDiscount = (proc_cli_id, discounted) => {
  //     const copy = [...billList];
  //     const proc = copy?.find((p) => p.proc_cli_id === proc_cli_id);
  //     proc.is_discounted = discounted;
  //     proc.discounted_price = proc.procedure_price;
  //     setBillList(copy);
  //   };

  const addBillItem = () => {
    const copy = [...billList];
    const itemCounter = !customItemCounter
      ? (billList?.length || 0) + 1
      : customItemCounter + 1;
    setCustomItemCounter(itemCounter);
    copy.push({
      proc_cli_id: `custom_item_${itemCounter}`,
      procedure_id: '',
      item_name: '',
      item_price: '',
      type: 'px',
    });
    setBillList(copy);
  };

  const deleteBillItem = (proc_cli_id) => {
    const copy = [...billList];
    const procIndex = copy?.findIndex((p) => p.proc_cli_id === proc_cli_id);
    const nextItem = copy?.[procIndex + 1];
    if (procIndex > -1) {
      const priceInput = document.getElementById(`price_${proc_cli_id}`);
      const discountInput = document.getElementById(`discount_${proc_cli_id}`);
      if (priceInput)
        priceInput.value =
          nextItem?.item_price || nextItem?.procedure_price || '';
      if (discountInput) discountInput.value = nextItem?.discounted_price || '';
      copy.splice(procIndex, 1);
    }
    setBillList(copy);
  };

  const handleNextInput = (e, currentIndex) => {
    if (e.key === 'Tab') {
      const inputIndex =
        e.target.name === 'item_price' ? currentIndex : currentIndex + 1;
      const nextItem =
        currentIndex < billList.length ? billList[inputIndex] : null;
      const nextInputID = !nextItem
        ? 'add_item_btn'
        : e.target.name === 'item_price'
        ? `discount_${nextItem.proc_cli_id}`
        : `price_${nextItem.proc_cli_id}`;
      // Make sure to be run after the ChangeEvent
      setTimeout(() => {
        const nextSibiling = document.getElementById(nextInputID);
        if (nextSibiling) {
          nextSibiling.focus();
          nextInputID !== 'add_item_btn' && nextSibiling.select();
        }
      }, 100);
    }
  };

  const loadProcTableData = () => {
    const tableBody = billList?.map((row, index) => [
      <select
        name="type"
        defaultValue={row.type || 'px'}
        onChange={({ target: { value } }) => {
          row.type = value;
          setItemsLabelList(value === 'px' ? procList : medList);
        }}
        style={{ maxWidth: '45px' }}
      >
        {billItemTypes.map((option, index) => (
          <option key={index} value={option?.value}>
            {option?.name}
          </option>
        ))}
      </select>,
      <div className="text-center">{index + 1}</div>,
      row.item_name || row.procedure_name ? (
        <span className="text-capitalize">
          {row.item_name || row.procedure_name}{' '}
          {!PREVIOUS_DUE_AMOUNT ? (
            <i
              className="fa fa-trash text-info cursor-pointer"
              onClick={() => deleteBillItem(row.proc_cli_id)}
            />
          ) : null}
        </span>
      ) : (
        <div className="d-flex align-items-center">
          <CreatableSelect
            ref={newCustomItemInput}
            isClearable
            onChange={(newData, actionMeta) => {
              if (actionMeta.action === 'clear') {
                newCustomItemInput?.current?.select?.select?.clearValue();
                row.item_name = '';
                row.item_price = '';
              } else {
                row.item_name = newData?.value;
                row.item_price = newData?.procedure_price;
                newData.procedure_price &&
                  changeProcedurePrice(
                    row.proc_cli_id,
                    newData?.procedure_price
                  );
              }
            }}
            options={itemsLabelList.filter(
              (e) =>
                e.provider_id ===
                  Number(
                    user_data.user_type === 'provider'
                      ? user_data.user_id
                      : appointment?.provider_id || appointment?.provider?.value
                  ) || 'medicine_name' in e
            )}
            className="flex-grow-1"
            placeholder={row.item_price || row.procedure_price} // "Select or Create New"
          />
          <i
            className="fa fa-trash text-info cursor-pointer ml-2"
            onClick={() => deleteBillItem(row.proc_cli_id)}
          />
        </div>
      ),
      <div>
        <input
          className="table_text_input"
          name="item_price"
          //   disabled={PREVIOUS_DUE_AMOUNT && row.proc_cli_id !== newDueProcId}
          id={`price_${row.proc_cli_id}`}
          onBlur={({ target: { value } }) =>
            changeProcedurePrice(row.proc_cli_id, value)
          }
          onKeyDown={(e) => handleNextInput(e, index)}
          style={{
            border: row.error > 0 ? '2px solid red' : '1px solid #ccc',
          }}
          type="text"
          defaultValue={row.item_price || row.procedure_price}
        />
      </div>,
      <div>
        <input
          className="table_text_input"
          name="discount"
          //   disabled={PREVIOUS_DUE_AMOUNT && row.proc_cli_id !== newDueProcId}
          id={`discount_${row.proc_cli_id}`}
          onBlur={({ target: { value } }) =>
            changeDiscountedPrice(row.proc_cli_id, value)
          }
          onKeyDown={(e) => handleNextInput(e, index)}
          style={{
            border: row.error > 0 ? '2px solid red' : '1px solid #ccc',
          }}
          type="text"
          defaultValue={row.discounted_price || ''}
        />
      </div>,
      <span className="text-capitalize">
        {(row.item_price || row.procedure_price || 0) -
          (row.discounted_price || 0)}
      </span>,
    ]);
    // if (!appointment?.patient?.payment_due && !appointment?.payment_due) {
    tableBody.push([
      {
        colSpan: '3',
        value: (
          <div
            className="fab text-info text-center cursor-pointer"
            title="Add Prescription"
            style={{ position: 'relative', top: '0px', left: '15px' }}
            onClick={() => {
              setItemsLabelList(procList);
              addBillItem();
            }}
          >
            <button
              className="btn btn-icon btn-fab__outline text-info"
              id="add_item_btn"
              style={{
                height: '25px',
                width: '25px',
                lineHeight: '10px',
              }}
            >
              <i className="ion-plus-round" />
            </button>{' '}
            <span className="font-weight-bold">Add Item</span>
          </div>
        ),
      },
      {
        value: <span />,
      },
      {
        value: <span />,
      },
      {
        value: <span />,
      },
    ]);
    // }

    tableBody.push([
      {
        colSpan: '3',
        value: 'Total',
        style: { fontWeight: 'bold', textAlign: 'right' },
      },
      {
        value: `${showCurrency()} ${totalAmount}`,
        style: { fontWeight: 'bold' },
      },
      {
        value: `${showCurrency()} ${discountAmount}`,
        style: { fontWeight: 'bold' },
      },
      {
        value: `${showCurrency()} ${totalAmount - discountAmount}`,
        style: { fontWeight: 'bold' },
      },
    ]);
    tableBody.push([
      {
        colSpan: '4',
        value: (
          <textarea
            className="form-control outlined"
            maxLength={120}
            style={{ resize: 'none', overflow: 'hidden' }}
            placeholder="Leave a note.."
            defaultValue={formData?.txn_message || ''}
            onChange={({ target: { value } }) => {
              setFormData({
                ...formData,
                txn_message: value?.substring(0, 120),
              });
            }}
          />
        ),
        style: { textAlign: 'right' },
      },
      {
        colSpan: '2',
        value: prevBillInfo?.created_by ? (
          <span>
            Last Updated By: {prevBillInfo?.created_by} <br />
            {moment(prevBillInfo?.created_at).format('DD-MM-YYYY hh:mm A')}
          </span>
        ) : (
          <span> </span>
        ),
        style: { textAlign: 'right' },
      },
    ]);

    return tableBody;
  };

  const input_handler = ({ target: { name, value, type } }) => {
    if (name === 'bill_amount' && (!value || Number.isNaN(value))) value = '';

    setFormData({
      ...formData,
      [name]: name === 'bill_amount' ? parseInt(value) : value,
    });
  };

  const handleSubmit = () => {
    const isPayInitiated = formData?.action_type === 'initiated';
    formData.patient_id =
      appointment?.patient?.id ||
      appointment?.patient?.person_id ||
      appointment?.person_id;
    const _due = fetchedTransctions?.[0]?.due_data?.[0]?.due_amount;
    const _formatedBillList = billList?.length
      ? billList?.map((item) => {
          item.type = item.type || 'px';
          item.procedure_id = `${item.procedure_id || ''}`;
          item.proc_cli_id = `${item.proc_cli_id || ''}`;
          item.procedure_price = `${item.procedure_price || ''}`;
          item.item_price = `${item.item_price || ''}`;
          item.discounted_price = `${item.discounted_price || ''}`;
          return item;
        })
      : [];
    formData.due_amount = isPayInitiated
      ? _due
      : totalAmount -
        discountAmount -
        (!_due ? netPaid || 0 : 0) -
        formData.bill_amount;
    formData.bill_info = JSON.stringify({
      bill_no: `${appointment?.appointment_id}_${
        (appointment?.transactions?.length ||
          appointment?.transaction_id ||
          0) + 1
      }`,
      bill_list: _formatedBillList,
      total_amount: `${totalAmount}`,
      discount_amount: `${discountAmount}`,
      final_amount: `${totalAmount - discountAmount}`,
      due_amount: `${
        isPayInitiated
          ? _due
          : totalAmount -
            discountAmount -
            (!_due ? netPaid || 0 : 0) -
            formData.bill_amount
      }`,
      created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      created_by: `${user_data?.first_name} ${user_data?.last_name}`,
      creator_no: user_data?.user_no,
      creator_type: user_data?.user_type,
    });
    setShowLoading(true);
    Axios.post(
      `${API_ENDPOINT}/Transaction/web_admin/new_transaction`,
      formData,
      {
        cache: {
          update: {
            'get-apmnt-transactions': 'delete',
          },
        },
      }
    )
      .then(() => {
        Axios.storage?.remove?.('get-apmnt-transactions');
        Axios.storage.remove('get-consult-walkins');
        Axios.storage.remove('get-consult-oplist');
        Axios.storage.remove('get-recent-appmnts');
        Axios.storage.remove('get-master-appmnts');
        Axios.storage.remove('get-wlkin-item');
        Axios.storage.remove('get-consult-appmnts');
        getTransactions();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setShowLoading(false);
        setIsTransactionDone(true);
      });
  };

  const getTransactions = async () => {
    if (!appointment?.appointment_id) return [];
    setShowLoading(true);
    const _transactions = await Axios.get(
      `${API_ENDPOINT}/Transaction/web_admin/get_apmnt_transactions/${appointment?.appointment_id}`,
      {
        id: 'get-apmnt-transactions',
      }
    )
      .then(({ data: resData }) => (resData?.length ? resData : []))
      .catch((err) => [])
      .finally(() => setShowLoading(false));
    setFetchedTransctions(_transactions);
    return _transactions;
  };

  const getMedList = async () => {
    if (!appointment?.appointment_id) return [];
    setShowLoading(true);
    const _medicines = await Axios.get(
      `${API_ENDPOINT}/Medicine/web_admin/get_provider_medicines/${
        user_data.user_type === 'provider'
          ? user_data.user_id
          : appointment?.provider_id || appointment?.provider?.value
      }`,
      {
        id: 'get-medicines',
      }
    )
      .then(({ data: resData }) => {
        const medList = resData?.length ? resData : [];
        return medList?.map((med) => ({
          ...med,
          label: med?.medicine_name
            ? `${med?.medicine_name} ${med?.medicine_unit || ''}`
            : '',
          value: med?.medicine_name
            ? `${med?.medicine_name} ${med?.medicine_unit || ''}`
            : '',
        }));
      })
      .catch((err) => [])
      .finally(() => setShowLoading(false));
    setMedList([
      {
        label: 'Medicines',
        value: 'Medicines',
        medicine_code: null,
        medicine_name: 'Medicines',
      },
      ..._medicines,
    ]);
    return _medicines;
  };

  useEffect(() => {
    const _due = fetchedTransctions?.[0]?.due_data?.due_amount;
    setFormData({
      ...formData,
      bill_amount: totalAmount - discountAmount - (!_due ? netPaid || 0 : 0),
    });
  }, [totalAmount, discountAmount, netPaid]);

  useEffect(() => {
    if (
      appointment &&
      Object.keys(appointment).length &&
      fetchedTransctions !== undefined
    ) {
      const app_data = appointment || {};
      const transactionsAndProcData = fetchedTransctions || [];
      const transactions = transactionsAndProcData?.[0]?.transactions?.sort(
        (a, b) => a.transaction_id - b.transaction_id
      );
      const procData =
        transactionsAndProcData?.[0]?.proc_data || appointment?.proc_data;
      const lastTransaction = transactions?.[transactions.length - 1];
      const prevBill = lastTransaction?.bill_info || app_data?.bill_info || {};
      setFormData({
        ...formData,
        // action_type:
        //   fromPayRecords ||
        //   transactions?.find((item) => item.action_type !== 'initiated')
        //     ? 'payment'
        //     : 'initiated',
        txn_message: lastTransaction?.txn_message,
      });
      setPrevBillInfo(prevBill);
      calcPaidAmount(transactions);
      setPayOptions(
        prevBill?.bill_no
          ? payActions?.filter((item) => item.value !== 'initiated')
          : payActions
      );
      //   const _prevDueProcId = `payment_due${
      //     prevBillInfo?.bill_no ? `_${prevBillInfo?.bill_no}` : ''
      //   }`;
      const _newDueProcId = `payment_due${appointment?.appointment_id}_${
        (appointment?.transactions?.length ||
          appointment?.transaction_id ||
          0) + 1
      }`;
      setNewDueProcId(_newDueProcId);

      let newList = [];

      //   if (!fromPayRecords) {
      const consultation_fee = app_data.is_tatkal
        ? app_data.tatkal_consult_fee
        : app_data.normal_consult_fee;
      const itemList = [
        {
          proc_cli_id: 'c_fee',
          procedure_id: '',
          item_name: `Consultation Fee ${
            app_data.is_tatkal ? '(Priority Slot)' : ''
          }`,
          item_price: `${consultation_fee}`,
          discounted_price: '0',
        },
        ...(procData || []),
      ];

      if (prevBill?.bill_list?.length) {
        newList = prevBill?.bill_list || [];
      } else {
        newList = itemList;
      }

      //   }

      if (PREVIOUS_DUE_AMOUNT) {
        const is_due_exist = newList?.find(
          (item) =>
            item.item_name === 'Previous Due' ||
            item.procedure_name === 'Previous Due'
        );
        if (!is_due_exist) {
          newList = [
            ...newList,
            ...[
              {
                proc_cli_id: `${_newDueProcId}`,
                procedure_id: '',
                item_name: 'Previous Due',
                item_price: `${fetchedTransctions?.[0]?.due_data?.due_amount}`,
              },
            ],
          ];
        }
      }
      setBillList(newList.filter((k) => k.procedure_name !== 'General Visit'));
    }
  }, [fetchedTransctions]);

  useEffect(() => {
    if (procedures?.length) {
      const procs = procedures.map((proc) => ({
        ...proc,
        label: proc?.procedure_type,
        value: proc?.procedure_type,
      }));
      setProcList(procs);
      setItemsLabelList(procs);
    }
    getTransactions();
    getMedList();
  }, [appointment?.appointment_id]);

  const [isPrinting, setIsPrinting] = useState(false);

  const printReceipt = async () => {
    setIsPrinting(true);
    const providerId =
      user_data.user_type === 'provider'
        ? user_data.user_id
        : appointment?.provider_id || appointment?.provider?.value;
    const mapping = Axios.get(
      `${API_ENDPOINT}/provider/mapping/${providerId}/${site_preferences?.selectedClinicId}`
    );
    const appoinmentPromise = Axios.get(
      `${API_ENDPOINT}/provider/appointment/${appointment?.appointment_id}`
    );
    let _clinicData = clinic_data || {};
    if (
      site_preferences?.selectedClinicId &&
      user_data.user_role === 'provider' &&
      clinic_data.length
    ) {
      _clinicData =
        clinic_data.find(
          (clinic) => clinic.clinic_id === site_preferences?.selectedClinicId
        ) || {};
    }
    const billItems = billList?.map((item, index) => ({
      sl: index + 1,
      type: item?.type === 'rx' ? 'RX' : 'PX',
      name: item?.item_name || item?.procedure_name || '',
      price: item?.item_price || item?.procedure_price || '',
      discount: item?.discounted_price || '',
      final:
        (item.item_price || item.procedure_price || 0) -
          (item.discounted_price || 0) || '',
    }));

    const { data } = await Axios.get(`${API_ENDPOINT}/provider/${providerId}`);

    /**
     * @type {ProviderRes} provider
     */
    const provider = data;
    const { print_clinic_header, print_provider_header, print_token_display } =
      (await mapping).data;

    const appt = { ...appointment, ...(await appoinmentPromise).data };

    const exec = genratePdfFromElement(
      <BillingSheet
        drName={`${provider.first_name} ${provider.last_name}`}
        drCode={provider.provider_no}
        drAddQualification={provider.add_qualification}
        drSpecialization={provider.speciality}
        drdegree={provider.degree_title}
        weekdays={`${_clinicData?.monday_on ? 'M ' : '_ '}
				  ${_clinicData?.tuesday_on ? 'T ' : '_ '}${
          _clinicData?.wednesday_on ? 'W ' : '_ '
        }
				  ${_clinicData?.thursday_on ? 'T ' : '_ '}${
          _clinicData?.friday_on ? 'F ' : '_ '
        }
				  ${_clinicData?.saturday_on ? 'S ' : '_ '}${
          _clinicData?.sunday_on ? 'S ' : '_ '
        }`}
        clinicTime={`${
          _clinicData.opening_time
            ? moment(`${_date} ${_clinicData.opening_time}`).format('hh:mm A')
            : '00:00 XX'
        }  ${
          _clinicData.closing_time
            ? ` to ${moment(`${_date} ${_clinicData.closing_time}`).format(
                'hh:mm A'
              )}`
            : ' to 00:00 XX'
        }`}
        clinicPlace={_clinicData?.area}
        clinicLogo={
          _clinicData?.image_url
            ? `${STORAGE_DOMAIN}${_clinicData?.image_url}`
            : ''
        }
        clinicPhone={_clinicData?.clinic_phone_no}
        patientName={
          appt?.patient?.name ||
          appt?.name ||
          `${appt?.patient?.first_name} ${appt?.patient?.last_name}`
        }
        patientGender={
          appt?.patient?.gender || appt?.patient?.sex || appt?.gender || ''
        }
        patientAge={appt?.patient?.age || appt?.age || ''}
        patientMobile={appt?.phone_no || appt?.patient?.phone_no}
        patientPlace={_clinicData.place}
        fileNo={appt?.patient?.file_no || appt?.file_no}
        patientUPN={appt?.patient?.uin || appt?.patient?.person_no || appt?.uin}
        paymentMode={formData.pay_mode}
        billDate={moment(appt.appointment_date).format('DD-MM-YYYY')}
        tokenNo={appt.token_number || appt.token_no}
        consultType={appt.is_walkin ? 'WK' : 'OP'}
        billInfo={billItems || []}
        totalAmount={totalAmount - discountAmount}
        userName={`${user_data.first_name} ${user_data.last_name}`}
        freevisit={
          freeVisitStatus.status
            ? moment().add(freeVisitStatus.diff, 'days').format('DD/MM/YYYY')
            : undefined
        }
        billNo={prevBillInfo?.bill_no}
        currency={showCurrencyCode()}
        message={formData.txn_message}
        print_provider_header={
          print_provider_header || _clinicData.print_provider_header
        }
        print_clinic_header={
          print_clinic_header || _clinicData.print_clinic_header
        }
        print_token_display={
          print_token_display || _clinicData.print_token_display
        }
      />
    );
    await exec();
    setIsPrinting(false);
  };

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="payment-modal"
      role="dialog"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-lg modal-center-content"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-12 m-b-20" id="receipt-container">
                {/* <h4 className='modal-title text-center'>{message}</h4> */}
                <div className="d-flex flex-wrap justify-content-between mx-3">
                  <h6>
                    {appointment?.patient?.uin ||
                      appointment?.patient?.person_no ||
                      appointment?.uin}
                  </h6>
                  <h6>
                    <span className="text-muted">File #:</span>
                    {appointment?.patient?.file_no || appointment?.file_no}
                  </h6>
                  <h6>
                    {`${
                      appointment?.patient?.name ||
                      appointment?.name ||
                      `${appointment?.patient?.first_name} ${appointment?.patient?.last_name}`
                    } ${appointment?.patient?.age || appointment?.age || ''} ${
                      appointment?.patient?.gender ||
                      appointment?.patient?.sex ||
                      appointment?.gender
                    }`}
                  </h6>
                  <h6>{appointment?.appointment_date}</h6>
                  <h6>
                    <span className="text-muted">Bill #:</span>
                    {prevBillInfo?.bill_no}
                  </h6>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between mx-3 border-bottom">
                  <h6>
                    {/* <span className="text-muted">Phone:</span>{' '} */}
                    {appointment?.phone_no || appointment?.patient?.phone_no}
                  </h6>
                  <h6>
                    {user_data.user_type === 'provider'
                      ? `${user_data.first_name} ${user_data.last_name}`
                      : appointment?.provider_name ||
                        appointment?.provider?.name}
                  </h6>
                </div>
                {!fromPayRecords ? (
                  <div className="d-flex m-3 justify-content-between">
                    <div>
                      <h6>
                        <span className="text-muted">Net Paid:</span>
                        <span className="text-danger">
                          {' '}
                          {showCurrency()} {netPaid}/-
                        </span>
                      </h6>
                      <p className="mb-0" style={{ fontSize: '15px' }}>
                        Last Visit:{' '}
                        <span className="font-weight-bold">
                          {freeVisitStatus?.lastVisit}{' '}
                        </span>{' '}
                        <span className="ml-3">
                          {freeVisitStatus.status
                            ? 'Patient is eligible for free revisit'
                            : 'Patient is not eligible for free revisit.'}
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        cursor:
                          prevBillInfo?.bill_no && !isPrinting
                            ? 'pointer'
                            : 'not-allowed',
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                      className={`${
                        prevBillInfo?.bill_no && !isPrinting
                          ? 'text-primary hover-opacity hover-underline hover-bold'
                          : 'text-secondary'
                      } `}
                      onClick={() =>
                        prevBillInfo?.bill_no && !isPrinting && printReceipt()
                      }
                    >
                      <i
                        className={classNames('fa mr-1', {
                          'fa-print': !isPrinting,
                          'fa-spinner fa-spin': isPrinting,
                        })}
                      />
                      {isPrinting ? 'Printing...' : 'Print bill'}
                    </div>
                  </div>
                ) : null}
                <div
                  className="col-12"
                  style={{ maxHeight: '350px', overflowY: 'scroll' }}
                >
                  <Table
                    data={loadProcTableData()}
                    headings={billingTableHeadings}
                    number={50}
                    cellClassNames="py-1"
                    totalCount={false}
                  />
                </div>
                <div className="col-12">Total: {billList.length || 0}</div>
                {/* <div className="col-12">

				</div> */}
                <div className="col-12 m-t-10">
                  <div className="d-flex">
                    Payment Action:{' '}
                    <div className="ml-2">
                      {payOptions &&
                        payOptions.map((action, idx) => (
                          <label key={idx} style={{ display: 'inline-block' }}>
                            <div className="form-radio radio radiofill radio-info radio-inline">
                              <label
                                style={{ display: 'flex' }}
                                className={
                                  action.value === 'initiated' &&
                                  appointment?.transactions?.find(
                                    (item) => item.action_type !== 'initiated'
                                  )
                                    ? 'input-disabled'
                                    : ''
                                }
                              >
                                <input
                                  name="action_type"
                                  onChange={input_handler}
                                  type="radio"
                                  defaultValue={action.value}
                                  checked={
                                    action.value === formData.action_type
                                  }
                                  disabled={
                                    action.value === 'initiated' &&
                                    appointment?.transactions?.find(
                                      (item) => item.action_type !== 'initiated'
                                    )
                                  }
                                />
                                <i className="helper" />
                                <span className="radio-label">
                                  {action.name}
                                </span>
                              </label>
                            </div>
                          </label>
                        ))}
                      <div className="messages" />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {formData.action_type === 'payment' && (
                    <div className="d-flex">
                      Select Payment mode:{' '}
                      <div className="ml-2">
                        {payModes?.map((mode, idx) => (
                          <label key={idx} style={{ display: 'inline-block' }}>
                            <div className="form-radio radio radiofill radio-info radio-inline">
                              <label style={{ display: 'flex' }}>
                                <input
                                  name="pay_mode"
                                  onChange={input_handler}
                                  type="radio"
                                  defaultChecked={
                                    formData.pay_mode === mode.value
                                  }
                                  defaultValue={mode.value}
                                />
                                <i className="helper" />
                                <span className="radio-label">{mode.name}</span>
                              </label>
                            </div>
                          </label>
                        ))}
                        <div className="messages" />
                      </div>
                    </div>
                  )}
                </div>
                {formData.action_type === 'payment' ||
                formData.action_type === 'refund' ? (
                  <>
                    <div className="col-sm-6 form-group">
                      <input
                        className="form-control font-weight-bold"
                        name="bill_amount"
                        onChange={input_handler}
                        placeholder="Amount *"
                        type="text"
                        maxLength={5}
                        value={
                          formData.bill_amount >= 0 ? formData.bill_amount : ''
                        }
                      />
                      <div className="messages" />
                    </div>
                    {formData.action_type === 'payment' && (
                      <h6 className="ml-3">
                        <span className="text-muted">Balance Payable: </span>
                        <span className="text-danger">
                          {showCurrency()}{' '}
                          {netPaid < totalAmount - discountAmount
                            ? totalAmount -
                              discountAmount -
                              (PREVIOUS_DUE_AMOUNT ? 0 : netPaid)
                            : 0}
                        </span>
                      </h6>
                    )}
                  </>
                ) : null}
              </div>
              <div className="col-6 text-center">
                <button
                  className="btn"
                  data-dismiss="modal"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    Axios.storage?.remove?.('get-apmnt-transactions');
                    if (isTransactionDone) {
                      isTransactionComplete?.(formData.bill_amount);
                    }
                    onSave && onSave(appointment.appointment_id);
                    onCancelFn?.();
                  }}
                >
                  Close
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  className="btn btn-info"
                  onClick={handleSubmit}
                  disabled={
                    showLoading ||
                    !formData.appointment_id ||
                    !formData.action_type ||
                    !formData.pay_mode ||
                    Number.isNaN(formData.bill_amount) ||
                    formData.bill_amount < 0
                  }
                >
                  Save
                  {/* TODO: Continue here */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingBox show={showLoading} />
    </div>
  );
};

export default PaymentModal;
