import TreatmentHistoryTab from './TreatmentHistoryTab';
import TreatmentPlanTab from './TreatmentPlanTab';
import AddTreatmentTab from './AddTreatmentTab';
import { useDentalChart } from './DentalChartContext';
import { TREATMENT_PERIOD } from './constants';
import { useEffect } from 'react';

function TreatmentTabs({ onCancel }) {
  const { setTreatmentAddType } = useDentalChart();

  return (
    <div className="mt-3">
      <ul className="nav nav-tabs" id="treatmentTab" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="history-tab"
            data-toggle="tab"
            href="#history"
            role="tab"
            aria-controls="history"
            aria-selected="true"
            onClick={() => setTreatmentAddType(TREATMENT_PERIOD.current)}
          >
            History
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="add-tab"
            data-toggle="tab"
            href="#add"
            role="tab"
            aria-controls="add"
            aria-selected="false"
          >
            Add
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="plan-tab"
            data-toggle="tab"
            href="#plan"
            role="tab"
            aria-controls="plan"
            aria-selected="false"
            onClick={() => setTreatmentAddType(TREATMENT_PERIOD.current)}
          >
            Plan
          </a>
        </li>
      </ul>

      <div className="tab-content" id="treatmentTabs">
        <div
          className="tab-pane fade show active"
          id="history"
          role="tabpanel"
          aria-labelledby="history-tab"
        >
          <TreatmentHistoryTab />
        </div>
        <div
          className="tab-pane fade show"
          id="add"
          role="tabpanel"
          aria-labelledby="add-tab"
        >
          <AddTreatmentTab onCancel={onCancel} />
        </div>
        <div
          className="tab-pane fade"
          id="plan"
          role="tabpanel"
          aria-labelledby="plan-tab"
        >
          <TreatmentPlanTab />
        </div>
      </div>
    </div>
  );
}

export default TreatmentTabs;
