/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/prefer-default-export */
export const generatePdf = async (svg, width, height, callBack) => {
  const PDFDocument = (await import('pdfkit/js/pdfkit.standalone')).default;
  const SVGtoPDF = (await import('svg-to-pdfkit')).default;
  const blobstream = (await import('blob-stream')).default;

  const doc = new PDFDocument({
    compress: false,
    size: 'A4',
  });

  // is svg is array, then loop through it and add to pdf as separate pages
  if (Array.isArray(svg)) {
    svg.forEach((s, i) => {
      if (i > 0) {
        doc.addPage();
      }
      SVGtoPDF(doc, s, 0, 0, {
        width,
        height,
        preserveAspectRatio: 'xMidYMid meet',
      });
    });
  } else {
    SVGtoPDF(doc, svg, 0, 0, {
      width,
      height,
      preserveAspectRatio: 'xMidYMid meet',
    });
  }

  const stream = doc.pipe(blobstream());
  stream.on('finish', () => {
    const blob = stream.toBlob('application/pdf');
    const url = URL.createObjectURL(blob);
    callBack(url);
  });
  doc.end();
};

let geistRegular = null;
let geistMedium = null;
let geistBold = null;

export const genratePdfFromElement = (elm) => {
  const onGenerate = async () => {
    const satori = (await import('satori')).default;

    if (!geistRegular)
      geistRegular = await (
        await fetch('/assets/fonts/Geist-Regular.otf')
      ).arrayBuffer();
    if (!geistMedium)
      geistMedium = await (
        await fetch('/assets/fonts/Geist-Medium.otf')
      ).arrayBuffer();
    if (!geistBold)
      geistBold = await (
        await fetch('/assets/fonts/Geist-Bold.otf')
      ).arrayBuffer();

    // if elm is an array, then loop through it and add to pdf as separate pages
    if (Array.isArray(elm)) {
      const svgs = [];
      for (const e of elm) {
        const svg = await satori(e, {
          height: 841.89,
          width: 595.28,
          fonts: [
            {
              name: 'Geist',
              data: geistRegular,
              weight: 400,
              style: 'normal',
            },
            {
              name: 'Geist',
              data: geistMedium,
              weight: 500,
              style: 'normal',
            },
            {
              name: 'Geist',
              data: geistBold,
              weight: 800,
              style: 'normal',
            },
          ],
        });
        svgs.push(svg);
      }
      await generatePdf(svgs, 595.28, 841.89, (url) => {
        window.open(url, '_blank').focus();
      });
    } else {
      const svg = await satori(elm, {
        height: 841.89,
        width: 595.28,
        fonts: [
          {
            name: 'Geist',
            data: geistRegular,
            weight: 400,
            style: 'normal',
          },
          {
            name: 'Geist',
            data: geistMedium,
            weight: 500,
            style: 'normal',
          },
          {
            name: 'Geist',
            data: geistBold,
            weight: 800,
            style: 'normal',
          },
        ],
      });
      await generatePdf(svg, 595.28, 841.89, (url) => {
        window.open(url, '_blank');
      });
    }
  };
  return onGenerate;
};
