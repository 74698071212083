import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import moment from 'moment';
import validate from 'validate.js';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import Alert from '../layout/Alert';
import ImageUpload from '../form/ImageUpload';
import LoadingBox from '../layout/LoadingBox';
// import Select from '../form/Select'
import { API_ENDPOINT } from '../../config/api';
import PhoneInput from '../common/phoneInput';
import { useUser } from '@/hooks/useUser';

const ClinicFrontdeskAdd = () => {
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  validate.extend(validate.validators.datetime, {
    parse(value, options) {
      return +moment.utc(value);
    },
    format(value, options) {
      const format = options.dateOnly ? 'DD/MM/YYYY' : 'DD/MM/YYYY';
      return moment.utc(value).format(format);
    },
  });
  const clinicData = user?.clinic_data || {};
  let ClinicData = user?.clinic_data || {};
  if (
    user?.preferences?.selectedClinicId &&
    user?.user_data.user_role === 'provider' &&
    user?.clinic_data.length
  ) {
    ClinicData = user?.clinic_data.find(
      (clinic) => clinic.clinic_id === user?.preferences?.selectedClinicId
    );
  }
  const userData = user?.user_data || {};
  const { id: staffId, clinic_id } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertBackMsg, setAlertBackMsg] = useState('');
  const [alertBackShow, setAlertBackShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [data, setData] = useState({
    phoneChangeStatus: false,
    phone_no: '',
    phone_code: '91',
    otp: '',
    first_name: '',
    last_name: '',
    // staff_type: '',
    email_id: '',
    user_password: '',
    confirm_password: '',
    staff_role: '',
    user_name: '',
    image_id: null,
    clinic_id,
    is_active: true,
  });
  const [showLoading, setShowLoading] = useState(true);
  const [staffTypes, setStaffTypes] = useState([]);
  const [otpSendStatus, setOtpSendStatus] = useState({});
  const [otpVerifyStatus, setOtpVerifyStatus] = useState({});
  const [imgUploadStatus, setImgUploadStatus] = useState({
    message: 'Upload your picture.',
    success: false,
  });
  const [profilePic, setProfilePic] = useState('');
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('IN');
  const [selectedPhoneCode, setSelectedPhoneCode] = useState(91);
  const [countries, setCountries] = useState([]);
  const [dialCodeList, setDialCodeList] = useState([]);

  const changeHandler = ({ target: { name, value } }) => {
    const copy = { ...data };
    if (name === 'staff_role') {
      const selectData = staffTypes.find(
        (obj) => obj.clinic_staff_type_id === parseInt(value)
      );
      copy.staff_role = selectData.clinic_staff_type;
    } else {
      copy[name] = value;
    }
    setData(copy);
  };

  const deleteAccount = () => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Clinic_staff/web_admin/delete_front_desk`, {
      staff_id: staffId,
      delete_ind: true,
      modified_by: 'any name',
    })
      .then((res) => {
        if (res.data.message === 'deleted successfully')
          showAlertBack(res.data.message);
        else showAlertBack(res.data.message);
      })
      .catch((err) => showAlert('Delete failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const getCountries = () => {
    setCountries([]);
    Axios.get(`${API_ENDPOINT}/StaticData/country-list`)
      .then((res) => {
        if (res.data) {
          //   setCountries(formatSelectOptionData(res.data, 'name', 'id'));
          setCountries(
            res?.data?.map((item) => ({
              name: item?.name,
              value: item?.id,
              country_code: item?.iso2,
              phone_code: item?.phonecode,
            }))
          );
          setDialCodeList(
            res?.data
              ?.map((item) => ({
                name: `+${item?.phonecode}`,
                value: item?.phonecode,
                country_code: item?.iso2,
                country_name: item?.name,
              }))
              .sort((a, b) => (a?.value?.[0] || 0) - (b?.value?.[0] || 0))
          );
          const _country = res?.data?.find(
            (item) => item.id === ClinicData?.country_id
          );
          if (_country?.id) {
            setSelectedCountryCode(_country.iso2);
            setData({ ...data, phone_code: _country.phonecode });
            setSelectedPhoneCode(_country.phonecode);
          }
        }
      })
      .catch((err) => console.log('Fetching countries failed', 'error'));
  };

  const sendOtp = () => {
    if (
      !data.phone_no ||
      data.phone_no.length < 10 ||
      Number.isNaN(data.phone_no)
    )
      return;
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Otp/new`, {
      otp_phone: data.phone_no,
      phone_code: data.phone_code,
    })
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData && resData.otp_sent === false) {
          setOtpSendStatus({
            success: false,
            message:
              resData.message ||
              "Couldn't sent OTP, plese check the number and try again.",
          });
        } else {
          setOtpSendStatus({
            success: true,
            message: 'OTP sent to your phone.',
          });
        }
      })
      .catch((err) => {
        setOtpSendStatus({
          success: false,
          message: 'OTP creation failed',
        });
      })
      .finally(() => setShowLoading(false));
  };

  const verifyOtp = () => {
    if (!data.otp || data.otp.length !== 6 || Number.isNaN(data.otp)) {
      setData({ ...data, otp: '' });
      return;
    }
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Otp/verify`, {
      otp: data.otp,
      otp_phone: data.phone_no,
      phone_code: data.phone_code,
    })
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData && resData.otp_verified === false) {
          setData({ ...data, otp: '' });
          setOtpVerifyStatus({
            success: false,
            message: 'OTP verifcation failed',
          });
        } else {
          setOtpVerifyStatus({
            success: true,
            message: 'OTP verified successfully',
          });
        }
      })
      .catch((err) => {
        setOtpVerifyStatus({
          success: false,
          message: `OTP verifcation failed:${err}`,
        });
      })
      .finally(() => setShowLoading(false));
  };

  const constraints = {
    phone_no: {
      format: {
        pattern: /(\d+){4,15}/,
        message: 'invalid',
      },
      presence: true,
    },
    otp: {
      format: {
        pattern: /^\d{6}$/,
        message: 'should be a 6-digit number',
      },
    },
    first_name: {
      format: {
        pattern: /[a-z]+/i,
        message: 'should contain only alphabets',
      },
      presence: true,
    },
    last_name: {
      format: {
        pattern: /[a-z]+/i,
        message: 'should contain only alphabets',
      },
      presence: true,
    },
    user_name: { presence: true },
    email_id: { email: true, presence: true },
    user_password: { presence: true },
    confirm_password: {
      equality: {
        attribute: 'user_password',
        message: '^The passwords do not match',
      },
      presence: true,
    },
    staff_role: { presence: true },
  };

  if (staffId) {
    constraints.phoneChangeStatus = { presence: false };
    constraints.phone_no = (value, attributes) => {
      if (!attributes.phoneChangeStatus) return null;
      return {
        format: {
          pattern: /(\d+){4,15}/,
          message: 'invalid',
        },
        presence: true,
      };
    };
    constraints.otp = (value, attributes) => {
      if (!attributes.phoneChangeStatus) return null;
      return {
        format: {
          pattern: /^\d{6}$/,
          message: 'should be a 6-digit number',
        },
        presence: true,
      };
    };
  }

  const form = document.getElementById('form');

  const inputs = document.querySelectorAll('input, textarea, select');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < inputs.length; ++i) {
    inputs.item(i).addEventListener('change', (ev) => {
      const errors = validate(form, constraints) || {};
      showErrorsForInput(inputs.item(i), errors[inputs.item(i).name]);
    });
  }

  const handleFormSubmit = (ev) => {
    ev.preventDefault();
    const errors = validate(form, constraints);
    showErrors(form, errors || {});
    if (otpVerifyStatus && !otpVerifyStatus.success) {
      const otpInput = document.querySelector("form input[name='otp']");
      showErrorsForInput(otpInput, ['OTP is not verified']);
    }
    if (!errors) {
      setShowLoading(true);
      if (!staffId && !clinicData.clinic_no) {
        return;
      }
      Axios({
        data: staffId
          ? { ...data, modified_by: userData.user_no }
          : {
              ...data,
              created_by: userData.user_no,
              clinic_no: clinicData.clinic_no,
            },
        headers: { 'Content-Type': 'application/json' },
        method: staffId ? 'put' : 'post',
        url: staffId
          ? `${API_ENDPOINT}/Clinic_staff/web_admin/edit_front_desk`
          : `${API_ENDPOINT}/Clinic_staff/web_admin/reg_front_desk`,
      })
        .then(({ data: { info } }) => {
          if (
            (staffId && info === 'updated successfully') ||
            info === 'front end user added successfully'
          )
            showAlertBack(info);
          else showAlert(info);
        })
        .catch((err) => showAlert('Submit failed', 'error'))
        .finally(() => setShowLoading(false));
    }
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const showAlertBack = (msg) => {
    setAlertBackMsg(msg);
    setAlertBackShow(true);
  };

  const showErrors = (form, errors) => {
    form.querySelectorAll('input[name], select[name]').forEach((input) => {
      showErrorsForInput(input, errors && errors[input.name]);
    });
  };

  function showErrorsForInput(input, errors) {
    if (!input) return;
    const formGroup = closestParent(input.parentNode, 'form-group');
    const messages = formGroup.querySelector('.messages');
    resetFormGroup(formGroup);
    if (errors) {
      formGroup.classList.add('has-error');
      errors.forEach((error) => {
        addError(messages, error, input);
      });
    } else {
      formGroup.classList.add('has-success');
    }
  }

  function closestParent(child, className) {
    if (!child || child === document) {
      return null;
    }
    if (child.classList.contains(className)) {
      return child;
    }
    return closestParent(child.parentNode, className);
  }

  function resetFormGroup(formGroup) {
    formGroup.classList.remove('has-error');
    formGroup.classList.remove('has-success');
    formGroup.querySelectorAll('.text-danger').forEach((el) => {
      el.parentNode.removeChild(el);
    });
  }

  function addError(messages, error, input) {
    const block = document.createElement('p');
    block.classList.add('text-danger');
    block.classList.add('error');
    block.innerText = error;
    messages.appendChild(block);
    window.$(input).addClass('input-danger');
  }

  const phoneNumberChangeHandler = ({ target: { checked, value } }) => {
    if (checked === false) {
      setData({
        ...data,
        phoneChangeStatus: !data.phoneChangeStatus,
        phone_no: currentPhoneNumber,
      });
    } else {
      setData({ ...data, phoneChangeStatus: !data.phoneChangeStatus });
    }
  };

  const uploadImage = ({ imgBlob, fileNameWithExtension }) => {
    const fd = new FormData();
    fd.append('file', imgBlob, fileNameWithExtension);
    fd.append('upload_use_case', 'frontdesk_user_img');
    setShowLoading(true);
    Axios({
      data: fd,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: `${API_ENDPOINT}/File/upload`,
    })
      .then(({ data: { info, file_id } }) => {
        data.image_id = file_id;
        setImgUploadStatus({
          success: true,
          message: 'Image uploaded successfully',
        });
      })
      .catch((err) =>
        setImgUploadStatus({
          success: false,
          message: 'Uploading failed please try again',
        })
      )
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    Axios.get(`${API_ENDPOINT}/ClinicStaffTypes/`)
      .then((res) => {
        setStaffTypes(res.data);
      })
      .then(() => {
        if (staffId) {
          return Axios.get(
            `${API_ENDPOINT}/Clinic_staff/Web_admin/staff_profile/${staffId}`
          );
        }
        return false;
      })
      .then((res) => {
        if (res && res.data) {
          const resData = res.data[0];
          if (!resData || !Object.keys(resData).length) return;
          if (resData && resData.phone_no)
            setCurrentPhoneNumber(resData.phone_no);
          if (resData && resData.profile_pic) {
            setProfilePic(resData.profile_pic);
            setImgUploadStatus({ message: 'Update the image' });
          }
          setData({
            ...data,
            ...resData,
          });
        }
      })
      .catch((err) => showAlert('Failed to load info', 'error'))
      .finally(() => setShowLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-2 m-t-40 text-center m-b-20">
          <ImageUpload
            uploadImageFn={uploadImage}
            newImgWidth={250}
            newImgHeight={250}
            name="image_id"
            thumbnail_img={profilePic}
          />
          <span
            style={{
              color:
                imgUploadStatus.success === true
                  ? 'green'
                  : imgUploadStatus.success === false
                  ? 'red'
                  : 'black',
            }}
          >
            {imgUploadStatus.message}
          </span>
          {staffId && (
            <button
              className="btn btn-danger m-b-10 m-t-10"
              onClick={deleteAccount}
              type="button"
            >
              Delete Account
            </button>
          )}
        </div>

        <div className="col-md-10 m-t-40">
          <form
            id="form"
            style={{ backgroundColor: 'transparent', border: 'none' }}
            noValidate="novalidate"
            onSubmit={handleFormSubmit}
          >
            {staffId && (
              <div className="row">
                <div
                  className="col-sm-6 checkbox-fade fade-in-info form-group"
                  style={{ marginRight: '0px' }}
                >
                  <label>
                    <input
                      checked={data.phoneChangeStatus}
                      name="phoneChangeStatus"
                      onChange={phoneNumberChangeHandler}
                      type="checkbox"
                      value={data.phoneChangeStatus}
                    />
                    <span className="cr">
                      <i className="cr-icon ion-checkmark txt-primary" />
                    </span>
                    <span>Change {currentPhoneNumber} mobile no</span>
                  </label>
                  <div className="messages" />
                </div>
              </div>
            )}
            {(data.phoneChangeStatus || !staffId) && (
              <div className="row">
                <div className="col-sm-6 form-group">
                  <div
                    className="_flex input-group input-group-button"
                    style={{ margin: '0px' }}
                  >
                    {/* <input
                      className="form-control"
                      name="phone_no"
                      onChange={changeHandler}
                      placeholder="Phone No"
                      type="text"
                      maxLength={10}
                      value={data.phone_no}
                    /> */}
                    <PhoneInput
                      selectedCountryCode={selectedCountryCode}
                      selectedPhoneCode={data.phone_code}
                      phoneCodeList={dialCodeList}
                      onChangeHandler={changeHandler}
                      defaultPhoneNumber={data.phone_no}
                      onBlur={({ target: { value } }) => {
                        isPhoneNoExists(value);
                      }}
                      inputName="phone_no"
                      inputLabel="Phone Number"
                      isCountryLocked
                      onPhoneCodeChange={(phoneCode) => {
                        setMemberData((prevData) => ({
                          ...prevData,
                          phone_code: phoneCode,
                        }));
                      }}
                    />
                    <div
                      className="bg-info input-group-addon"
                      id="basic-addon10"
                      onClick={sendOtp}
                    >
                      Send OTP
                    </div>
                  </div>
                  <div className="messages" />
                  <div
                    style={{ color: otpSendStatus.success ? 'green' : 'red' }}
                  >
                    {otpSendStatus.message}
                  </div>
                </div>
                <div className="col-sm-6 form-group">
                  <div
                    className="input-group input-group-button"
                    style={{ margin: '0px' }}
                  >
                    <input
                      className="form-control"
                      name="otp"
                      onChange={changeHandler}
                      placeholder="OTP"
                      type="text"
                      value={data.otp}
                    />
                    <span
                      className="bg-info input-group-addon"
                      id="basic-addon10"
                      onClick={verifyOtp}
                    >
                      Verify
                    </span>
                  </div>
                  <div
                    className="messages"
                    style={{ color: otpVerifyStatus.success ? 'green' : 'red' }}
                  >
                    {' '}
                    {otpVerifyStatus.message}{' '}
                  </div>
                </div>
              </div>
            )}

            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-sm-6 form-group">
                <input
                  className="form-control"
                  name="first_name"
                  onChange={changeHandler}
                  placeholder="First Name *"
                  type="text"
                  value={data.first_name}
                />
                <div className="messages" />
              </div>
              <div className="col-sm-6 form-group">
                <input
                  className="form-control"
                  name="last_name"
                  onChange={changeHandler}
                  placeholder="Last Name *"
                  type="text"
                  value={data.last_name}
                />
                <div className="messages" />
              </div>
            </div>

            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-sm-6 form-group">
                <input
                  className="form-control"
                  name="user_name"
                  onChange={changeHandler}
                  placeholder="Username *"
                  type="text"
                  value={data.user_name}
                />
                <div className="messages" />
              </div>
              <div className="col-sm-6 form-group">
                <input
                  className="form-control"
                  name="email_id"
                  onChange={changeHandler}
                  placeholder="Email *"
                  type="text"
                  value={data.email_id}
                />
                <div className="messages" />
              </div>
            </div>

            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-sm-6 form-group">
                <input
                  className="form-control"
                  name="user_password"
                  onChange={changeHandler}
                  type="password"
                  placeholder="Password"
                  value={data.user_password}
                />
                <div className="messages" />
              </div>
              <div className="col-sm-6 form-group">
                <input
                  className="form-control"
                  name="confirm_password"
                  onChange={changeHandler}
                  type="password"
                  placeholder="Confirm Password"
                  value={data.confirm_password}
                />
                <div className="messages" />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="m-b-5 text-muted">Category *</div>
                <div className="form-group">
                  {staffTypes &&
                    staffTypes.map((staffType) => (
                      <label style={{ display: 'inline-block' }}>
                        <div className="form-radio radio radiofill radio-info radio-inline">
                          <label style={{ display: 'flex' }}>
                            <input
                              name="staff_role"
                              onChange={changeHandler}
                              type="radio"
                              checked={
                                staffType.clinic_staff_type === data.staff_role
                              }
                              value={staffType.clinic_staff_type_id}
                            />
                            <i className="helper" />
                            <span className="radio-label">
                              {staffType.clinic_staff_type}
                            </span>
                          </label>
                        </div>
                      </label>
                    ))}
                  <div className="messages" />
                </div>
              </div>
            </div>
            <div className="text-right">
              <button
                className="btn btn-info"
                onClick={(ev) => handleFormSubmit(ev)}
              >
                {location?.pathname.split('/').slice(-2)[0] === 'add'
                  ? 'Add'
                  : 'Update'}
              </button>
              <Link
                className="btn btn-default m-l-10"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <Alert
        action={() => {
          navigate(-1);
        }}
        msg={alertBackMsg}
        setShow={setAlertBackShow}
        show={alertBackShow}
        type="success"
      />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default ClinicFrontdeskAdd;
