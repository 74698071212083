import TreatmentSelection from './TreatmentSelection';
import { useDentalChart } from './DentalChartContext';
import { TREATMENT_PERIOD } from './constants';

const AddTreatmentTab = ({ onCancel }) => {
  const { selectedTreatments, treatmentAddType, setTreatmentAddType } =
    useDentalChart();

  const handleAddTypeClick = (e) => {
    setTreatmentAddType(e.target.name);
  };

  const handleSubmit = () => {
    console.log(selectedTreatments);
  };
  return (
    <div className="treatment-add-type">
      <div className="treatment-add-type__field">
        <div>
          <input
            type="checkbox"
            className="btn-check d-none"
            id="add-type-history"
            autoComplete="off"
            onChange={handleAddTypeClick}
            name={TREATMENT_PERIOD.history}
          />
          <label
            className={`btn ${
              treatmentAddType === TREATMENT_PERIOD.history
                ? 'btn-primary'
                : 'btn-outline-primary'
            }`}
            htmlFor="add-type-history"
          >
            History
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            className="btn-check d-none"
            id="add-type-current"
            autoComplete="off"
            onChange={handleAddTypeClick}
            name={TREATMENT_PERIOD.current}
          />
          <label
            className={`btn ${
              treatmentAddType === TREATMENT_PERIOD.current
                ? 'btn-primary'
                : 'btn-outline-primary'
            }`}
            htmlFor="add-type-current"
          >
            Current
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            className="btn-check d-none"
            id="add-type-plan"
            autoComplete="off"
            onChange={handleAddTypeClick}
            name={TREATMENT_PERIOD.plan}
          />
          <label
            className={`btn ${
              treatmentAddType === TREATMENT_PERIOD.plan
                ? 'btn-primary'
                : 'btn-outline-primary'
            }`}
            htmlFor="add-type-plan"
          >
            Plan
          </label>
        </div>
      </div>

      {treatmentAddType !== TREATMENT_PERIOD.current && (
        <form className="row g-3 mt-2 add-treatment-fields">
          {treatmentAddType === TREATMENT_PERIOD.history && (
            <div className="col-auto">
              <label htmlFor="treatments-by">Treatments By</label>
              <input type="text" className="form-control" id="treatments-by" />
            </div>
          )}
          {(treatmentAddType === TREATMENT_PERIOD.plan ||
            treatmentAddType === TREATMENT_PERIOD.history) && (
            <div className="col-auto">
              <label htmlFor="date">Date</label>
              <input type="date" className="form-control" id="date" />
            </div>
          )}
        </form>
      )}

      <hr />

      <TreatmentSelection type="plan" />
      <div className="mt-3 d-flex justify-content-between">
        <button onClick={onCancel} className="btn btn-secondary mr-2">
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          type="submit"
          className="btn btn-primary"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddTreatmentTab;
