const TreatmentsTable = (props) => {
  return (
    <table className="table mt-3">
      <thead>
        <tr>
          <th scope="col" style={{ verticalAlign: 'baseline' }}>
            <input
              style={{ margin: 'auto' }}
              className="form-check-input"
              type="checkbox"
              value=""
            />
          </th>
          <th scope="col">Date</th>
          <th scope="col">Doctor</th>
          <th scope="col">Treatment</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {props.data?.map((item, i) => (
          <tr key={i}>
            <th scope="row">
              <input
                style={{ margin: 'auto' }}
                className="form-check-input"
                type="checkbox"
                value=""
              />
            </th>
            <td>{item.date}</td>
            <td>{item.doctor}</td>
            <td>{item.treatment}</td>
            <td>
              {props.type !== 'history' && (
                <button className="btn btn-sm btn-primary mr-2">Execute</button>
              )}
              <button className="btn btn-sm btn-danger">Remove</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TreatmentsTable;
