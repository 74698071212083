import { Link, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';

export function QuickNav({
  routes,
}: {
  routes: { s?: boolean; l: string; t: string }[];
}) {
  const { pathname } = useLocation();
  return (
    <div className="_w-full _hidden lg:_flex _space-x-2 _justify-end">
      {routes.map((e) => {
        if (pathname === e.l) return null;
        if (e.s) return null;
        return (
          <Button
            asChild
            variant="secondary"
            size="sm"
            className="_bg-slate-100 _border _border-solid _border-slate-300 hover:_bg-slate-200"
          >
            <Link to={e.l} key={e.l}>
              {e.t}
            </Link>
          </Button>
        );
      })}
    </div>
  );
}
