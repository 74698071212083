import React, { Fragment, useState, useMemo } from 'react';
import Select from '../form/Select';
import classNames from 'classnames';

const Checkbox = ({ index, selected, setSelected }) => {
  const changeHandler = ({ target: { value } }) => {
    const copy = [...selected];
    if (copy.includes(value)) copy.splice(copy.indexOf(value), 1);
    else copy.push(value);
    setSelected(copy);
  };

  return (
    <div className="j-pro hex-j-pro">
      <div
        className="checkbox-fade fade-in-primary"
        style={{ background: '#fff', margin: 0 }}
      >
        <label>
          <input
            checked={selected.includes(index)}
            name="terms"
            type="checkbox"
            onChange={changeHandler}
            value={index}
          />
          <span className="cr" style={{ margin: 0 }}>
            <i className="cr-icon ion-checkmark txt-primary" />
          </span>
        </label>
      </div>
    </div>
  );
};

/**
 *
 * @param {Array} data
 * @param {number} number
 * @returns {any[][]} pages
 */
const getPages = (data, number) => {
  if (data.length === 0) return [[[]]];

  const pages = [];
  for (let i = 0; i < data.length; i += number) {
    const page = data.slice(i, i + number);
    pages.push(page);
  }
  return pages;
};

const range = (num) => [...Array(num + 1).keys()].slice(1);

const reduceLength = (value) => {
  if (typeof value !== 'string') return value;
  const chunks = value.match(/.{1,40}/g) || [];
  return (
    <>
      {chunks.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && <br />}
          {item}
        </Fragment>
      ))}
    </>
  );
};

const TBody = ({
  rowClassNames,
  cellClassNames,
  pages,
  fetchTableDatafn,
  curr,
}) => (
  <tbody>
    {pages[fetchTableDatafn ? 0 : curr - 1]?.map((row, index) => (
      <tr className={rowClassNames} key={index}>
        {row.map((cell, cellIndex) => {
          if (typeof cell === 'object' && cell && cell.value) {
            const { value, ...cellProps } = cell;
            return (
              <td
                className={classNames(cellClassNames, '!_p-2')}
                key={`${curr}_${cellIndex}`}
                {...cellProps}
              >
                {reduceLength(value)}
              </td>
            );
          }
          return (
            <td
              key={`${curr}_${cellIndex}`}
              className={classNames(cellClassNames, '!_p-2')}
            >
              {reduceLength(cell)}
            </td>
          );
        })}
      </tr>
    ))}
  </tbody>
);

const Table = ({
  data,
  headings,
  number,
  pageNo = 1,
  tableType = 'regular',
  selectAll = false,
  totalCount,
  fetchTableDatafn,
  rowClassNames,
  cellClassNames,
}) => {
  const [curr, setCurr] = useState(pageNo);
  const [selected, setSelected] = useState([]);
  const [selectedMain, setSelectedMain] = useState(false);
  const getTableClass = () => {
    switch (tableType) {
      case 'regular':
        return 'table table-hex table-styling regular';
      case 'outline':
        return 'table table-hex table-styling outline';
      case 'striped':
        return 'table table table-striped nowrap';
      case 'bare':
      default:
        return 'table';
    }
  };

  const multipleCheck = (start, checked) => {
    setSelectedMain(checked);
    const copy = [...selected];
    if (checked) {
      for (let i = start; i < start + number; i += 1) copy.push(i);
    } else
      for (let i = start; i < start + number; i += 1)
        if (copy.indexOf(i) > -1) copy.splice(copy.indexOf(i), 1);
    setSelected(copy);
  };

  if (!number) number = 10;

  const pageC = (totalCount || data.length) / number;
  const pageCount = pageC === parseInt(pageC) ? pageC : parseInt(pageC) + 1;
  const pages = getPages([...data.filter((n) => n)], number);

  const pageNumberList = useMemo(() => {
    if (!pageCount) return [];
    return range(pageCount).map((page) => ({
      name: page,
      label: page,
      value: page,
    }));
  }, [pageCount]);

  const pageChangeHandler = (page_no, action_type) => {
    multipleCheck((page_no - 1) * (data.length / number), false);
    setSelectedMain(false);
    if (action_type === 'prev_action' && page_no !== 1) page_no -= 1;
    if (action_type === 'next_action' && page_no !== pageCount) page_no += 1;
    setCurr(page_no);

    // onClick={() => {
    // 	multipleCheck((curr - 1) * (data.length / number), false);
    // 	setSelectedMain(false);
    // 	setCurr(page);
    //   }}

    if (fetchTableDatafn) {
      fetchTableDatafn(page_no);
    }
  };

  if (tableType === 'bodyOnly')
    return pages.length === 1 &&
      pages[0].length === 1 &&
      pages[0][0].length === 0 ? (
      <tbody>
        <tr>
          <td colSpan={headings[0].length} style={{ textAlign: 'center' }}>
            No Data Found
          </td>
        </tr>
      </tbody>
    ) : (
      <TBody pages={pages} fetchTableDatafn={fetchTableDatafn} curr={curr} />
    );
  if (selectAll) {
    data = data.map((item, index) => [
      {
        value: (
          <Checkbox
            index={index}
            selected={selected}
            setSelected={setSelected}
          />
        ),
      },
      ...item,
    ]);

    headings = [
      [
        <div
          className="j-pro"
          style={{
            background: 'transparent',
            border: 'none',
            display: 'flex',
            minWidth: 22,
          }}
        >
          <div
            className="checkbox-fade fade-in-primary"
            style={{ background: '#fff', margin: 0 }}
          >
            <label>
              <input
                checked={selectedMain}
                name="terms"
                onChange={({ target: { checked } }) =>
                  multipleCheck((curr - 1) * (data.length / number), checked)
                }
                type="checkbox"
              />
              <span className="cr" style={{ background: '#fff', margin: 0 }}>
                <i className="cr-icon ion-checkmark txt-primary" />
              </span>
            </label>
          </div>
          <i
            className="fa fa-trash"
            style={{ color: '#ddd', fontSize: '20px', marginLeft: '12px' }}
          />
        </div>,
        ...headings,
      ],
    ];
  } else if (!Array.isArray(headings[0])) headings = [headings];

  return (
    <>
      <div className="card-block table-border-style m-b-10 m-t-10">
        <div className="table-responsive">
          <table className={getTableClass()} key={pageNo}>
            <thead>
              {headings &&
                headings.length > 0 &&
                headings.map((heading, index) => (
                  <tr
                    className={tableType === 'regular' ? 'bg-info' : ''}
                    key={index}
                  >
                    {heading.map((head, indexHeading) => {
                      if (typeof head === 'object' && head.value) {
                        const { value, ...headerProps } = head;
                        return (
                          <th {...headerProps} key={indexHeading}>
                            {value}
                          </th>
                        );
                      }
                      return (
                        <th
                          key={indexHeading}
                          style={{ verticalAlign: 'middle' }}
                        >
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                ))}
            </thead>
            {pages.length === 1 &&
            pages[0].length === 1 &&
            pages[0][0].length === 0 ? (
              <tbody>
                <tr>
                  <td
                    className="!_p-2"
                    colSpan={headings[0].length}
                    style={{ textAlign: 'center' }}
                  >
                    No Data Found
                  </td>
                </tr>
              </tbody>
            ) : (
              <TBody
                rowClassNames={rowClassNames}
                cellClassNames={cellClassNames}
                pages={pages}
                fetchTableDatafn={fetchTableDatafn}
                curr={curr}
              />
            )}
          </table>
        </div>
      </div>
      <div className="d-flex  justify-content-between hex-pagination">
        {totalCount !== false ? (
          <div className="pl-0">Total: {totalCount || data.length}</div>
        ) : (
          <div />
        )}
        {(totalCount || data.length) > 10 && (
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={curr === 1}
                  onClick={() => {
                    pageChangeHandler(
                      curr !== 1 ? curr - 1 : 1,
                      'select_action'
                    );
                  }}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">«</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
              {pageNumberList?.length ? (
                <li className="page-item mx-3">
                  <Select
                    style={{ width: '78px' }}
                    name="page_option"
                    onChange={({ target: { value } }) =>
                      pageChangeHandler(Number(value), 'select_action')
                    }
                    value={curr}
                    values={pageNumberList || []}
                  />
                </li>
              ) : null}
              <li className="page-item">
                <button
                  className="page-link"
                  disabled={curr === pageCount}
                  onClick={() => {
                    pageChangeHandler(
                      curr !== pageCount ? curr + 1 : pageCount,
                      'select_action'
                    );
                  }}
                  aria-label="Next"
                >
                  <span aria-hidden="true">»</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  );
};

export default Table;
