import { useEffect } from 'react';
import PlanHistoryTabs from './PlanHistoryTabs';
import { useDentalChart } from './DentalChartContext';
import TreatmentTabs from './TreatmentTabs';
import { PATIENT_TYPES } from './constants';

function DentalChartModal(props) {
  const {
    selectedTeeth,
    setSelectedTeeth,
    setTeethData,
    setPatientType,
    patientType,
  } = useDentalChart();

  useEffect(() => {
    if (props.patientType) {
      setPatientType(props.patientType);
    }

    getData(props.patientType);
  }, []);

  const getData = async () => {
    // need to integrate api data here
    let url = '/assets/images/dental/teeth-data-adult.json';
    if (props.patientType === PATIENT_TYPES.pedo) {
      url = '/assets/images/dental/teeth-data-pedo.json';
    }

    const res = await fetch(url);
    const data = await res.json();
    setTeethData(data);
  };

  const handleGoBack = () => setSelectedTeeth(null);

  const handlePatientTypeChange = (e) => {
    const updatedPatientType =
      patientType === PATIENT_TYPES.pedo
        ? PATIENT_TYPES.adult
        : PATIENT_TYPES.pedo;

    setPatientType(updatedPatientType);
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
      id={props.id}
    >
      <div className="modal-dialog modal-md modal-dialog-centered dental-chart-modal">
        <div className="modal-content _max-w-[900px] _mx-auto">
          <div className="modal-header">
            <h5 className="modal-title">
              Dental Chart{' '}
              {selectedTeeth !== null && (
                <small>- Teeth No:{selectedTeeth?.code}</small>
              )}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() =>
                setTimeout(() => {
                  handleGoBack();
                }, 500)
              }
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-3">
            {selectedTeeth !== null ? (
              <>
                <div className="dental-chart__top">
                  <div
                    role="button"
                    className="dental-chart__go-back"
                    onClick={handleGoBack}
                  >
                    <img src="/assets/images/dental/back-icon.png" alt="" />
                  </div>
                  <span>|</span>
                  <h5>Treatments</h5>
                </div>

                <TreatmentTabs onCancel={handleGoBack} />
              </>
            ) : (
              <>
                <div className="form-check mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="isPediatric"
                    disabled={props.patientType === PATIENT_TYPES.pedo}
                    checked={patientType === PATIENT_TYPES.pedo}
                    onChange={handlePatientTypeChange}
                  />
                  <label className="form-check-label" htmlFor="isPediatric">
                    Pediatric
                  </label>
                </div>
                <PlanHistoryTabs />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DentalChartModal;
