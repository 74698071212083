import React from 'react';

import GridMenu from '../layout/GridMenu';

const pages = [
  { name: 'Clinics', link: '/payments/clinics' },
  { name: 'Medical Stores', link: '/payments/medicalstores' },
];

const PaymentsPage = () => <GridMenu pages={pages} />;

export default PaymentsPage;
