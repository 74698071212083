import Axios from '@/_helpers/axios_interceptor';
import { API_ENDPOINT } from '../../config/api';

const getAreaFromPincode = (pincode, setAreaList) => {
  setAreaList?.([]);
  if (!pincode) return;
  return Axios.post(`${API_ENDPOINT}/PinCode/pincode_no`, { pin_code: pincode })
    .then(({ data: resData }) => {
      resData?.length
        ? setAreaList?.(
            resData.map((clinicArea) => ({
              name: clinicArea.area,
              vlaue: clinicArea.area,
            }))
          )
        : setAreaList?.([{ name: 'Some Area', vlaue: 'Some Area' }]);
    })
    .catch((err) => setAreaList?.([]));
};

export default getAreaFromPincode;
