import moment from 'moment';

export const getBreakTime = (start, duration) => {
  const breakEndTime =
    start && duration
      ? moment(new Date(`${start}Z`)).add(duration, 'minutes')
      : null;
  const remainingBreakTime = breakEndTime
    ? breakEndTime.diff(moment(), 'minutes')
    : null;
  const toAdd = remainingBreakTime
    ? remainingBreakTime < 0
      ? 0
      : remainingBreakTime
    : 0;

  return {
    breakEndTime,
    remainingBreakTime,
    toAdd,
  };
};

export default 'No default export for this';

export const objectify = (array, getKey, getValue = (item) => item) =>
  array.reduce((acc, item) => {
    acc[getKey(item)] = getValue(item);
    return acc;
  }, {});

/**
 * Sorts an array of items into groups. The return value is a map where the keys are
 * the group ids the given getGroupId function produced and the value is an array of
 * each item in that group.
 */
export const group = (array, getGroupId) =>
  array.reduce((acc, item) => {
    const groupId = getGroupId(item);
    if (!acc[groupId]) acc[groupId] = [];
    acc[groupId].push(item);
    return acc;
  }, {});

export const calculateAge = (dt) => {
  if (!dt) return 0;
  let age = moment().diff(dt, 'years');
  if (age === 0) {
    // calculate months and days
    const months = moment().diff(dt, 'months');
    const days = moment().diff(moment(dt).add(months, 'months'), 'days');
    age = `${months > 0 ? `${months} months` : ''} ${
      days > 0 ? `${days} days` : ''
    }`;
  }
  return age;
};
