import { useFullUser } from '@/hooks/useUser';

let clinic;

export function showCurrency(symbol) {
  if (symbol) return symbol;
  if (clinic && clinic.clinic_id) return clinic.currency_symbol || '₹';
  const currentUser = useFullUser.getState().user;
  if (!currentUser) return '₹';
  const { clinic_data } = currentUser;
  if (Array.isArray(clinic_data) && clinic_data.length > 0) {
    clinic = currentUser.clinic_data.find(
      (e) => e.clinic_id === currentUser.preferences.selectedClinicId
    );
    if (!clinic) {
      [clinic] = clinic_data;
    }
  } else {
    clinic = clinic_data;
  }
  return clinic.currency_symbol || '$';
}

export function showCurrencyCode() {
  if (clinic && clinic.clinic_id) return clinic.currency_code || 'INR';
  const currentUser = useFullUser.getState().user;
  if (!currentUser) return 'INR';
  const { clinic_data } = currentUser;
  if (Array.isArray(clinic_data) && clinic_data.length > 0) {
    clinic = currentUser.clinic_data.find(
      (e) => e.clinic_id === currentUser.preferences.selectedClinicId
    );
    if (!clinic) {
      [clinic] = clinic_data;
    }
  } else {
    clinic = clinic_data;
  }
  return clinic?.currency_code || 'INR';
}
export default showCurrency;
