import React from 'react';
import moment from 'moment';

import { AVATAR_API } from '../../config/api';
import SlotView from '../layout/SlotView';
import showCurrency from '../common/showCurrency';
import { usePageDetails, useUser } from '@/hooks/useUser';
import { useLocation } from 'react-router';

const AppointmentBook = () => {
  const { preferences, user_data, clinic_data } = useUser();
  const location = useLocation();
  const new_booking_data = location.state;
  let ClinicData = clinic_data || {};
  if (
    preferences?.selectedClinicId &&
    user_data.user_role === 'provider' &&
    clinic_data.length
  ) {
    ClinicData = clinic_data.find(
      (clinic) => clinic.clinic_id === preferences?.selectedClinicId
    );
  }
  return (
    <>
      <div className="card p-l-10 p-r-10 p-t-10" style={{ borderTop: 'none' }}>
        <div className="row">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-2 m-b-10 m-t-10">
                <img
                  src={`${AVATAR_API}${new_booking_data.provider}&rounded=true`}
                  className="c-avatar-img"
                  alt="avatar"
                />
              </div>
              <div className="col-md-4 m-b-10 m-t-10">
                <h5
                  style={{
                    color: '#000',
                    fontSize: '20px',
                    textTransform: 'none',
                  }}
                >
                  {new_booking_data.provider}
                </h5>
                <div className="m-b-10" style={{ fontSize: '16px' }}>
                  {new_booking_data.speciality}
                </div>
                <div>
                  <div>
                    Fees:{' '}
                    {new_booking_data.selectedSchedule.tatkal_consult_fee
                      ? 'General '
                      : ''}
                    <div
                      className="bg-default"
                      style={{
                        borderRadius: '3px',
                        color: '#000',
                        display: 'inline-block',
                        margin: '0px 6px 6px 3px',
                        padding: '1px',
                      }}
                    >
                      {showCurrency()}
                      {new_booking_data.selectedSchedule.normal_consult_fee}/-
                    </div>
                    {new_booking_data &&
                    new_booking_data.selectedSchedule.tatkal_consult_fee ? (
                      <>
                        Priority Slot
                        <div
                          className="bg-default"
                          style={{
                            borderRadius: '3px',
                            color: '#000',
                            display: 'inline-block',
                            margin: '0px 6px 6px 3px',
                            padding: '1px',
                          }}
                        >
                          {showCurrency()}
                          {new_booking_data.selectedSchedule.tatkal_consult_fee}
                          /-
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <br />
                    {new_booking_data.visits ? (
                      <>
                        Visits:{' '}
                        <div
                          className="bg-default"
                          style={{
                            borderRadius: '3px',
                            color: '#000',
                            display: 'inline-block',
                            margin: '0px 6px 6px 3px',
                            padding: '1px',
                          }}
                        >
                          {new_booking_data.visits}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 m-b-10 m-t-10 text-left">
                <div className="m-b-10">
                  <b
                    className="bg-default"
                    style={{
                      borderRadius: '3px',
                      color: '#000',
                      margin: '0px 6px 0px 3px',
                      padding: '3px',
                    }}
                  >
                    {`${ClinicData.clinic_name}, ${ClinicData.area}`}
                  </b>
                </div>
                <div className="ml-1 m-b-10">
                  Phone:{' '}
                  <span
                    className="bg-default"
                    style={{
                      borderRadius: '3px',
                      margin: '0px 6px 0px 5px',
                      padding: '3px',
                    }}
                  >
                    <a
                      href={`tel:${ClinicData.clinic_phone_no}`}
                      className="normal-link"
                    >
                      {ClinicData.clinic_phone_no}
                    </a>
                  </span>
                </div>
                {ClinicData.location_lat && ClinicData.location_lon ? (
                  <div className="m-b-10">
                    <span
                      className="bg-success"
                      style={{
                        borderRadius: '3px',
                        color: '#000',
                        margin: '0px 6px 0px 3px',
                        padding: '3px',
                      }}
                    >
                      <a
                        className="normal-link"
                        href={`http://maps.google.com/maps?q=${ClinicData.location_lat},${ClinicData.location_lon}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View on map
                      </a>
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* <div className='col-md-2 m-b-10 m-t-10 text-left'>
								<div className='m-b-10'>
									<b>Free Revisit: 7 days</b>
								</div>
								<div
									className='bg-default m-b-10 m-r-10'
									style={{
										borderRadius: '3px',
										color: '#000',
										display: 'inline-block',
										fontSize: '15px',
										padding: '3px 6px',
									}}
								>
									W-checkin
								</div>
								<br />
								<div
									className='bg-default m-b-10'
									style={{
										borderRadius: '3px',
										color: '#000',
										display: 'inline-block',
										fontSize: '15px',
										padding: '3px 6px',
									}}
								>
									C-checkin
								</div>
							</div> */}
            </div>
          </div>
          <div className="col-md-2">
            <div className="row">
              <div className="col-12 hex-sm-border-left-hide m-b-10 m-t-10 text-left">
                <div
                  className="bg-default m-b-10"
                  style={{
                    borderRadius: '3px',
                    color: '#000',
                    display: 'inline-block',
                    margin: '0px 6px 12px 3px',
                    padding: '3px',
                  }}
                >
                  {new_booking_data.patientDetails.person_no}
                </div>
                <div
                  className="bg-default m-b-10"
                  style={{
                    borderRadius: '3px',
                    color: '#000',
                    display: 'inline-block',
                    margin: '0px 6px 12px 3px',
                    padding: '3px',
                  }}
                >
                  {`${new_booking_data.patientDetails.first_name} ${new_booking_data.patientDetails.last_name}`}
                </div>
                <div
                  className="bg-default m-b-10"
                  style={{
                    borderRadius: '3px',
                    color: '#000',
                    display: 'inline-block',
                    margin: '0px 6px 12px 3px',
                    padding: '3px',
                  }}
                >
                  {new_booking_data.patientDetails.date_of_birth
                    ? moment().diff(
                        moment(
                          new_booking_data.patientDetails.date_of_birth,
                          'YYYY-MM-DD'
                        ),
                        'years'
                      )
                    : ''}
                </div>
                <div
                  className="bg-default m-b-10"
                  style={{
                    borderRadius: '3px',
                    color: '#000',
                    display: 'inline-block',
                    margin: '0px 6px 12px 3px',
                    padding: '3px',
                  }}
                >
                  {new_booking_data.patientDetails.sex === 'M'
                    ? 'Male'
                    : 'Female'}
                </div>
                <div
                  className="bg-default m-b-10"
                  style={{
                    borderRadius: '3px',
                    color: '#000',
                    display: 'inline-block',
                    margin: '0px 6px 12px 3px',
                    padding: '3px',
                  }}
                >
                  {new_booking_data.patientDetails.phone_no}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlotView
        noHeader
        bookingData={{
          selected_schedule: new_booking_data.selectedSchedule,
          selected_procedures: new_booking_data.selected_procedures,
          booking_date: new_booking_data.booking_date,
          patientDetails: new_booking_data.patientDetails,
          provider_id: new_booking_data.provider_id,
          previous_apmnt_id: new_booking_data.previous_apmnt_id,
          provider_name: new_booking_data.provider,
        }}
      />
    </>
  );
};

export default AppointmentBook;
