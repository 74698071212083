export const unitList = [
  { name: 'mg', value: 'mg' },
  { name: 'mcg', value: 'mcg' },
  { name: 'g', value: 'g' },
  { name: 'Kg', value: 'Kg' },
  { name: 'ml', value: 'ml' },
  { name: 'L', value: 'L' },
  { name: 'cc', value: 'cc' },
  { name: 'mol', value: 'mol' },
  { name: 'mmol', value: 'mmol' },
];

export const dosageList = [
  //   { name: '1 Tab', label: '1 Tab', value: '1 Tab' },
  //   { name: '2 Tab', label: '2 Tab', value: '2 Tab' },
  //   { name: '3 Tab', label: '3 Tab', value: '3 Tab' },
  //   { name: '4 Tab', label: '4 Tab', value: '4 Tab' },
  //   { name: '5 Tab', label: '5 Tab', value: '5 Tab' },
  //   { name: '6 Tab', label: '6 Tab', value: '6 Tab' },
  { name: '1 ml', label: '1 ml', value: '1 ml' },
  { name: '2 ml', label: '2 ml', value: '2 ml' },
  { name: '3 ml', label: '3 ml', value: '3 ml' },
  { name: '4 ml', label: '4 ml', value: '4 ml' },
  { name: '5 ml', label: '5 ml', value: '5 ml' },
  { name: '6 ml', label: '6 ml', value: '6 ml' },
  { name: '7 ml', label: '7 ml', value: '7 ml' },
  { name: '8 ml', label: '8 ml', value: '8 ml' },
  { name: '9 ml', label: '9 ml', value: '9 ml' },
  { name: '10 ml', label: '10 ml', value: '10 ml' },
  { name: 'Once', label: 'Once', value: 'Once' },
  { name: 'Twice', label: 'Twice', value: 'Twice' },
  { name: 'Thrice', label: 'Thrice', value: 'Thrice' },
  { name: '4 times', label: '4 times', value: '4 times' },
  { name: '5 times', label: '5 times', value: '5 times' },
  { name: '6 times', label: '6 times', value: '6 times' },
  { name: 'when needed', label: 'when needed', value: 'when needed' },
  { name: 'SOS', label: 'SOS', value: 'SOS' },
  { name: 'OD', label: 'OD', value: 'OD' },
  { name: 'BD', label: 'BD', value: 'BD' },
  { name: 'TID', label: 'TID', value: 'TID' },
  { name: 'QID', label: 'QID', value: 'QID' },
  { name: '5x/day', label: '5x/day', value: '5x/day' },
  { name: '6x/day', label: '6x/day', value: '6x/day' },
  { name: 'PRN', label: 'PRN', value: 'PRN' },
  { name: 'Q2H', label: 'Q2H', value: 'Q2H' },
  { name: 'QD', label: 'QD', value: 'QD' },
  { name: 'QH', label: 'QH', value: 'QH' },
  { name: '1-0-0', label: '1-0-0', value: '1-0-0' },
  { name: '0-1-0', label: '0-1-0', value: '0-1-0' },
  { name: '0-0-1', label: '0-0-1', value: '0-0-1' },
  { name: '1-1-0', label: '1-1-0', value: '1-1-0' },
  { name: '0-1-1', label: '0-1-1', value: '0-1-1' },
  { name: '1-0-1', label: '1-0-1', value: '1-0-1' },
  { name: '1-1-1', label: '1-1-1', value: '1-1-1' },
  {
    name: '1-0-1 (after food)',
    label: '1-0-1 (after food)',
    value: '1-0-1 (after food)',
  },
  {
    name: '1-0-1 (with food)',
    label: '1-0-1 (with food)',
    value: '1-0-1 (with food)',
  },
  {
    name: '1-1-1 (with food)',
    label: '1-1-1 (with food)',
    value: '1-1-1 (with food)',
  },
  {
    name: '0-0-1 (at bedtime)',
    label: '0-0-1 (at bedtime)',
    value: '0-0-1 (at bedtime)',
  },
  {
    name: '0-0-1 (at 8 PM)',
    label: '0-0-1 (at 8 PM)',
    value: '0-0-1 (at 8 PM)',
  },
  {
    name: '0-0-1 (1 hr before food)',
    label: '0-0-1 (1 hr before food)',
    value: '0-0-1 (1 hr before food)',
  },
  {
    name: '1-0-0 (1 hr before food)',
    label: '1-0-0 (1 hr before food)',
    value: '1-0-0 (1 hr before food)',
  },
  {
    name: '1-0-0 (1/2 hr before food)',
    label: '1-0-0 (1/2 hr before food)',
    value: '1-0-0 (1/2 hr before food)',
  },
  {
    name: '0-0-1 (1/2 hr before food)',
    label: '0-0-1 (1/2 hr before food)',
    value: '0-0-1 (1/2 hr before food)',
  },
  {
    name: 'Once daily (after food)',
    label: 'Once daily (after food)',
    value: 'Once daily (after food)',
  },
  {
    name: '1-0-1 (1/2 hr before food)',
    label: '1-0-1 (1/2 hr before food)',
    value: '1-0-1 (1/2 hr before food)',
  },
  {
    name: '1-1-1 (with food) x 7days, 1-1-0 (with food) x nxt 7days',
    label: '1-1-1 (with food) x 7days, 1-1-0 (with food) x nxt 7days',
    value: '1-1-1 (with food) x 7days, 1-1-0 (with food) x nxt 7days',
  },
  {
    name: '1-1-1 (with food) x 5days, 1-1-1 (with food) x nxt 5days',
    label: '1-1-1 (with food) x 5days, 1-1-1 (with food) x nxt 5days',
    value: '1-1-1 (with food) x 5days, 1-1-1 (with food) x nxt 5days',
  },
  {
    name: '5 ml thrice daily (after food)',
    label: '5 ml thrice daily (after food)',
    value: '5 ml thrice daily (after food)',
  },
  {
    name: '5 ml twice daily (after food)',
    label: '5 ml twice daily (after food)',
    value: '5 ml twice daily (after food)',
  },
  {
    name: '2.5 ml thrice daily (after food)',
    label: '2.5 ml thrice daily (after food)',
    value: '2.5 ml thrice daily (after food)',
  },
  {
    name: '2.5 ml twice daily (after food)',
    label: '2.5 ml twice daily (after food)',
    value: '2.5 ml twice daily (after food)',
  },
  {
    name: '2.5 ml twice daily (1/2 hr b/f food)',
    label: '2.5 ml twice daily (1/2 hr b/f food)',
    value: '2.5 ml twice daily (1/2 hr b/f food)',
  },
  {
    name: '2.5 ml at bed time',
    label: '2.5 ml at bed time',
    value: '2.5 ml at bed time',
  },
  {
    name: '5 ml at bed time',
    label: '5 ml at bed time',
    value: '5 ml at bed time',
  },
  { name: '1 drop 2 times', label: '1 drop 2 times', value: '1 drop 2 times' },
  { name: '1 drop 3 times', label: '1 drop 3 times', value: '1 drop 3 times' },
  { name: '1 drop 4 times', label: '1 drop 4 times', value: '1 drop 4 times' },
  { name: '1 drop 6 times', label: '1 drop 6 times', value: '1 drop 6 times' },
  {
    name: '2 drops 2 times',
    label: '2 drops 2 times',
    value: '2 drops 2 times',
  },
  {
    name: '2 drops 3 times',
    label: '2 drops 3 times',
    value: '2 drops 3 times',
  },
  {
    name: '2 drops 4 times',
    label: '2 drops 4 times',
    value: '2 drops 4 times',
  },
  {
    name: '2 drops 6 times',
    label: '2 drops 6 times',
    value: '2 drops 6 times',
  },
  {
    name: '2 drops 1 hourly',
    label: '2 drops 1 hourly',
    value: '2 drops 1 hourly',
  },
  {
    name: '2 drops 2 hourly',
    label: '2 drops 2 hourly',
    value: '2 drops 2 hourly',
  },
  { name: 'Everyday', label: 'Everyday', value: 'Everyday' },
  { name: 'Once in 2 days', label: 'Once in 2 days', value: 'Once in 2 days' },
  { name: 'Once in a week', label: 'Once in a week', value: 'Once in a week' },
  {
    name: 'Twice in a week',
    label: 'Twice in a week',
    value: 'Twice in a week',
  },
  {
    name: 'Once in 2 weeks',
    label: 'Once in 2 weeks',
    value: 'Once in 2 weeks',
  },
  {
    name: 'Once in 3 weeks',
    label: 'Once in 3 weeks',
    value: 'Once in 3 weeks',
  },
  {
    name: 'Once in a month',
    label: 'Once in a month',
    value: 'Once in a month',
  },
  {
    name: 'Once in a month',
    label: 'Once in a month',
    value: 'Once in a month',
  },
  {
    name: 'For local application',
    label: 'For local application',
    value: 'For local application',
  },
  {
    name: 'half-half-half (after food)',
    label: 'half-half-half (after food)',
    value: 'half-half-half (after food)',
  },
  {
    name: 'half-0-half (after food)',
    label: 'half-0-half (after food)',
    value: 'half-0-half (after food)',
  },
];

export const durationList = [
  { name: '1 Day', label: '1 Day', value: '1 Day' },
  { name: '2 Days', label: '2 Days', value: '2 Days' },
  { name: '3 Days', label: '3 Days', value: '3 Days' },
  { name: '5 Days', label: '5 Days', value: '5 Days' },
  { name: '1 Week', label: '1 Week', value: '1 Week' },
  { name: '2 Weeks', label: '2 Weeks', value: '2 Weeks' },
  { name: '1 Month', label: '1 Month', value: '1 Month' },
  { name: '2 Months', label: '2 Months', value: '2 Months' },
  { name: '3 Months', label: '3 Months', value: '3 Months' },
  { name: '6 Months', label: '6 Months', value: '6 Months' },
  { name: '1 Year', label: '1 Year', value: '1 Year' },
  //   { name: 'Everyday', label: 'Everyday', value: 'Everyday' },
  //   { name: 'Once in 2 days', label: 'Once in 2 days', value: 'Once in 2 days' },
  //   { name: 'Once in a week', label: 'Once in a week', value: 'Once in a week' },
  //   {
  //     name: 'Twice in a week',
  //     label: 'Twice in a week',
  //     value: 'Twice in a week',
  //   },
  //   {
  //     name: 'Once in 2 weeks',
  //     label: 'Once in 2 weeks',
  //     value: 'Once in 2 weeks',
  //   },
  //   {
  //     name: 'Once in 3 weeks',
  //     label: 'Once in 3 weeks',
  //     value: 'Once in 3 weeks',
  //   },
  //   {
  //     name: 'Once in a month',
  //     label: 'Once in a month',
  //     value: 'Once in a month',
  //   },
];

export const bloodTypes = [
  'O +ve',
  'A +ve',
  'B +ve',
  'AB +ve',
  'O -ve',
  'A -ve',
  'B -ve',
  'AB -ve',
];

export const ethnicityList = [
  { name: 'White', value: 'White' },
  { name: 'Black or African American', value: 'Black or African American' },
  { name: 'Asian', value: 'Asian' },
  {
    name: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
  },
  {
    name: 'Native Hawaiian or Other Pacific Islander',
    value: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    name: 'Multiple Categories Reported',
    value: 'Multiple Categories Reported',
  },
  { name: 'Race Unknown', value: 'Race Unknown' },
];
