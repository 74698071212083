import { useLocation } from 'react-router-dom';
import { useFullUser } from '@/hooks/useUser';
import { QuickNav } from '@/components/common/QuickNav';
import { cn } from '@/lib/utils';
import PageTitle from './page-title';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import React from 'react';

const PageHeader = ({
  title,
  desc,
  showCrumbs = true,
}: {
  title: string;
  desc: string;
  showCrumbs?: boolean;
}) => {
  const { pathname } = useLocation();
  const { user } = useFullUser();
  if (!user) return null;
  const { signedIn } = user;

  if (
    !signedIn ||
    pathname === '/consult/op-session' ||
    pathname === '/consult/walk-in' ||
    pathname?.includes('/consult/edit/')
  )
    return null;

  return (
    <div
      className={cn('_relative _flex _justify-between _items-end _mt-[10px]', {
        _hidden: !title,
      })}
    >
      <PageTitle title={title} desc={desc} />
      <div className="">
        {pathname !== '/' &&
          showCrumbs &&
          !quickNavArray.map((e) => e.l).includes(pathname) && (
            <ul key={pathname} className="list-inline breadcrumb-title">
              <Breadcrumbs />
            </ul>
          )}
        {quickNavArray.map((e) => e.l).includes(pathname) && (
          <QuickNav routes={quickNavArray} />
        )}
      </div>
    </div>
  );
};

const quickNavArray: {
  s?: boolean;
  l: string;
  t: string;
}[] = [
  { t: 'Recent List', l: '/consult/recentconsultations' },
  { t: 'Master List', l: '/consult/master-list' },
  { t: 'WK List', l: '/consult/walkins' },
  { t: 'OP List', l: '/consult/appointments' },
  { t: 'Patient List', l: '/patient' },
  { t: 'Booking Provider', l: '/booking/provider', s: true },
  { t: 'Booking Patient', l: '/booking/patient', s: true },
  { t: 'Booking', l: '/booking', s: true },
  { t: 'New Appoinment', l: '/booking/availability', s: true },
  { t: 'Follow Ups', l: '/reports/followuplist', s: true },
];

export default PageHeader;

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return (
            <React.Fragment key={match.pathname}>
              <BreadcrumbItem key={match.pathname}>
                {!isLast && (
                  <BreadcrumbLink
                    href={match.pathname !== '/' ? match.pathname : '/home'}
                  >
                    {breadcrumb}
                  </BreadcrumbLink>
                )}
                {isLast && <BreadcrumbPage>{breadcrumb}</BreadcrumbPage>}
              </BreadcrumbItem>
              {!isLast && <BreadcrumbSeparator />}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
