import classNames from 'classnames';
import React, { useState } from 'react';
import moment from 'moment';
import { useClinic, useFullUser, useUser } from '@/hooks/useUser';
import { useAppoinment, useCasesheet } from './states';
import { FileType } from 'lucide-react';
import { SettingsRulesType, TemplateType } from '../settings/rules';
import useSWRImmutable from 'swr/immutable';
import { API_ENDPOINT } from '@/config/api';
import { getFetcher } from '@/_helpers/swr';
import { Button } from '@/components/ui/button';

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

const tabNames = [
  'History',
  'Observations',
  'Diagnosis',
  'Treatments',
  'Lab',
] as const;

type ValueType = {
  [K in ElementType<typeof tabNames>]?: string;
} & {
  provider_id?: number;
  historyUpdatedAt?: string;
  observationupdatedAt?: string;
};

function CasesheetDetails() {
  const [tab, settab] = useState<ElementType<typeof tabNames>>(tabNames[2]);
  const { details, onChange } = useCasesheet((s) => ({
    onChange: s.onChangePrescriptionDetails,
    details: s.caseSheet.prescription_details,
  }));
  const disabled = useAppoinment((s) => !s.patient);

  return (
    <div className="_border _border-solid _border-slate-200 _bg-stone-100 _m-2 _px-4 _pb-3 _rounded-lg">
      <div className="_flex _justify-between _items-center">
        <h5 className="_my-2 _font-bold _text-gray-600 _text-xs">{tab}</h5>
        <div className="_flex _items-center" role="tablist">
          {tabNames.map((name) => {
            const value = details?.[name];
            const hasValue = value && value.value.length > 0;
            const active = tab === name;
            const isTouched = !!value?.isTouched;

            return (
              <button
                key={name}
                role="tab"
                id={`tab-${name}`}
                aria-selected={active}
                tabIndex={active ? 0 : -1}
                aria-controls={`panel-${name}`}
                className={classNames(
                  '_px-3 !_py-0 _text-[13px] !_mb-0 hover:_bg-fuchsia-300 _cursor-pointer _duration-500 _text-fuchsia-700 _bg-fuchsia-100 _rounded-full _mx-1 _border _border-solid _border-fuchsia-400',
                  {
                    '!_bg-fuchsia-700 !_text-white _font-bold':
                      active && !hasValue && !isTouched,
                    '!_bg-sky-700 !_text-sky-100 _font-bold hover:!_bg-sky-600 !_border-sky-400':
                      hasValue && active && !isTouched,
                    '!_bg-sky-100 !_text-sky-700 _font-bold hover:!_bg-sky-200 !_border-sky-400':
                      hasValue && !active && !isTouched,
                    '!_bg-lime-100 !_text-lime-700 _font-bold hover:!_bg-lime-200 !_border-lime-400':
                      isTouched && !active,
                    '!_bg-lime-700 !_text-lime-100 _font-bold hover:!_bg-lime-600 !_border-lime-400':
                      isTouched && active,
                  }
                )}
                onClick={() => settab(name)}
              >
                {name}
              </button>
            );
          })}
        </div>
      </div>

      {tabNames.map((k, i) => {
        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChange(k, e.target.value);
        };

        return (
          <div
            key={k}
            aria-labelledby={`tab-${k}`}
            role="tabpanel"
            id={`panel-${k}`}
            hidden={k !== tab}
            className={classNames({
              _hidden: k !== tab,
            })}
          >
            <textarea
              className="form-control"
              id=""
              cols={30}
              rows={5}
              name="prescription_details"
              value={details?.[k]?.value || ''}
              onChange={handleChange}
              disabled={disabled}
              onKeyDown={(l) => {
                if (l.key === 'Tab') {
                  if (i >= 0 && i < tabNames.length - 1) {
                    l.preventDefault();
                    settab(tabNames[i + 1]);
                  }
                }
              }}
            />
            {k === 'Observations' && details?.Observations.updatedAt && (
              <p className="_text-xs _text-gray-500 _text-right _-mt-5 _mr-3">
                Updated at:{' '}
                {moment(details.Observations.updatedAt).format(
                  'DD-MM-YYYY hh:mm:ss A'
                )}
              </p>
            )}
            {k === 'History' && details?.History.updatedAt && (
              <p className="_text-xs _text-gray-500 _text-right _-mt-5 _mr-3">
                Updated at:{' '}
                {moment(details?.History.updatedAt).format(
                  'DD-MM-YYYY hh:mm:ss A'
                )}
              </p>
            )}
          </div>
        );
      })}
      <TemplateModal />
    </div>
  );
}

export default CasesheetDetails;

export const TemplateModalButton = () => (
  <div data-toggle="modal" data-target="#templatePickerModal">
    <FileType className="_size-7 _cursor-pointer" />
  </div>
);

const TemplateModal = () => {
  const clinic = useClinic();
  const user = useUser();
  const [selected, setselected] = useState<TemplateType | null>(null);
  const { data } = useSWRImmutable<SettingsRulesType>(
    `${API_ENDPOINT}/provider/mapping/${user.user_data.user_id}/${clinic?.clinic_id}`,
    getFetcher
  );

  const [insertTemplate, values] = useCasesheet((s) => [
    s.onChangePrescriptionDetails,
    s.caseSheet.prescription_details,
  ]);
  const handleInsert = () => {
    if (!selected) return;
    const existiingVal =
      values && selected.section in values
        ? values[selected.section].value
        : '';
    insertTemplate(
      selected.section as any,
      existiingVal
        ? `${existiingVal} ${selected.description}`
        : selected.description || ''
    );
  };
  return (
    <div
      className="modal fade"
      tabIndex={-1}
      id="templatePickerModal"
      data-backdrop="static"
    >
      <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="_p-3 ">
            <h5>Choose a template</h5>
          </div>
          <div className="_px-3 _grid _grid-cols-1 _gap-4">
            <div>
              <label>Title</label>
              <select
                className="form-control custom-select"
                onChange={(k) => {
                  setselected(
                    data?.settings?.template?.find(
                      (e) => e.title == k.target.value
                    ) || null
                  );
                }}
              >
                <option>Select a template</option>
                {data?.settings.template?.map((t) => (
                  <option key={t.title} value={t.title}>
                    {t.title}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Section</label>
              <input
                type="text"
                className="form-control _capitalize"
                placeholder="Select a template"
                value={selected?.section || ''}
                readOnly
              />
            </div>
            <div>
              <label>Value</label>
              <textarea
                disabled={!selected}
                className="form-control"
                placeholder="Template details"
                value={selected?.description || ''}
                onChange={(k) =>
                  setselected((e) => ({
                    ...e!,
                    description: k.target.value || '',
                  }))
                }
              ></textarea>
            </div>
          </div>
          <div className="_flex _justify-end _p-3 _space-x-2">
            <Button variant="secondary" data-dismiss="modal">
              Close
            </Button>
            <Button onClick={handleInsert} data-dismiss="modal" variant="ghost">
              Insert
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getPrescriptionFromString = (data: string): ValueType => {
  const p = JSON.parse(data) as ValueType | string;
  if (typeof p === 'string') {
    return {
      Diagnosis: p,
    };
  }
  return p;
};

export const encodePrescription = (
  data: string,
  safe = false,
  print = false,
  _forced = false // Used to force all details - Used in Clear button in PrescriptionSheet.jsx
): ValueType => {
  const provider_id = useFullUser.getState().user?.user_data.user_id;
  try {
    const val = JSON.parse(data) as ValueType | string;
    if (typeof val === 'string') {
      return {
        Diagnosis: val,
        provider_id,
      };
    }
    if (print || safe) {
      return {
        Diagnosis: val.Diagnosis,
        Treatments: val.Treatments,
      };
    }
    return { ...val, provider_id };
  } catch (error) {
    return {
      Diagnosis: data,
      provider_id,
    };
  }
};

export const decodePrescription = (data: ValueType) => {
  if (typeof data === 'string') {
    return JSON.stringify({ Diagnosis: data });
  }
  return JSON.stringify(data);
};

export const clearPrescripton = (data: string) => {
  const prescription = encodePrescription(data, false, false, true);
  return decodePrescription({
    History: prescription.History,
    Observations: prescription.Observations,
    historyUpdatedAt: prescription.historyUpdatedAt,
    observationupdatedAt: prescription.observationupdatedAt,
  });
};

export const replacePrescription = (data: string, replace: string) => {
  const prescription = encodePrescription(data, false, false, true);
  const replacePrescription = encodePrescription(replace, false, false, true);
  return decodePrescription({
    ...prescription,
    History: replacePrescription.History,
    Observations: replacePrescription.Observations,
    historyUpdatedAt: replacePrescription.historyUpdatedAt,
    observationupdatedAt: replacePrescription.observationupdatedAt,
  });
};
