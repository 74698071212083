import moment from 'moment';
import React, { useState, useEffect } from 'react';

export default function DOBInput({ value, onChange, input_name }) {
  const [selectedDate, setSelectedDate] = useState(value || new Date());
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [days, setDays] = useState(0);

  const setDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    setSelectedDate(date);
    const now = moment();
    const dob = moment(date);
    const duration = moment.duration(now.diff(dob));
    setYears(duration.years());
    setMonths(duration.months());
    setDays(duration.days());
    onChange(date);
  };

  const handleYearChange = (e) => {
    if (e > 99 || e < 0) return;
    setYears(e);
    const newDate = moment()
      .subtract(e, 'years')
      .subtract(months, 'months')
      .subtract(days, 'days')
      .toDate();
    setSelectedDate(newDate);
    onChange(newDate);
  };

  const handleMonthchange = (e) => {
    if (e > 99 || e < 0) return;
    setMonths(e);
    const newDate = moment()
      .subtract(years, 'years')
      .subtract(e, 'months')
      .subtract(days, 'days')
      .toDate();
    setSelectedDate(newDate);
    onChange(newDate);
  };

  const handleDayChange = (e) => {
    if (e > 99 || e < 0) return;
    setDays(e);
    const newDate = moment()
      .subtract(years, 'years')
      .subtract(months, 'months')
      .subtract(e, 'days')
      .toDate();
    setSelectedDate(newDate);
    onChange(newDate);
  };

  return (
    <div className="row _mt-4">
      <div className="col-xl-6">
        <div className="form-group">
          <div className="col-12 m-b-5 text-center text-muted">
            Date of Birth{' '}
            <span
              style={{
                color: '#FF5733',
              }}
            >
              *
            </span>
          </div>
          <div>
            <DateInput
              value={selectedDate}
              onChange={setDate}
              input_name={input_name}
            />
          </div>
          <div className="messages" />
        </div>
      </div>

      <div className="col-xl-2 d-flex align-items-center justify-content-center">
        OR AGE
      </div>
      <div
        className="col-xl-4 checkbox-fade fade-in-info form-group"
        style={{ marginRight: '0px' }}
      >
        <div className="row">
          <NumberInput
            onChange={handleYearChange}
            value={years}
            label="Years"
          />

          <NumberInput
            onChange={handleMonthchange}
            value={months}
            label="Months"
          />

          <NumberInput onChange={handleDayChange} value={days} label="Days" />
        </div>
      </div>
    </div>
  );
}

function DateInput({
  value: currentValue,
  onChange,
  label,
  input_name,
  max = moment().format('YYYY-MM-DD'),
}) {
  const [value, setValue] = useState(currentValue || new Date());

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const handleBlur = (e) => {
    onChange(e.target.value);
  };

  return (
    <input
      className="form-control no-arrow"
      name={input_name}
      placeholder={label}
      type="date"
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
      value={moment(value).format('YYYY-MM-DD')}
      max={max}
    />
  );
}

function NumberInput({ onChange, value, label }) {
  const handleFocus = (event) => {
    event.target.select();
  };
  return (
    <div className="col-sm-4 my-sm-0 my-2">
      {label}
      <input
        className="form-control no-arrow"
        name={label}
        placeholder={label}
        type="number"
        onFocus={handleFocus}
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
        value={value}
      />
      <div className="messages" />
    </div>
  );
}
