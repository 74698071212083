import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <div
      id="error-page"
      className="_h-screen _flex _items-center _justify-center"
    >
      <div className="_border _border-solid _border-slate-200 _px-10 _py-20 _rounded-lg _shadow-sm">
        <div className="_text-6xl _font-bold">Oops!</div>
        <div className="_text-rose-600 _text-lg">
          Sorry, an unexpected error has occurred.
        </div>
        <div className="_text-sky-600 _italic ">
          {error.statusText || error.message}
        </div>
      </div>
    </div>
  );
}
