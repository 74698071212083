/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { Link, useLocation } from 'react-router-dom';

import moment from 'moment';
import Alert from '../layout/Alert';
import DeleteConfirm from '../layout/DeleteConfirm';
import Select from '../form/Select';
import LoadingBox from '../layout/LoadingBox';
import Table from '../layout/Table';
import { API_ENDPOINT } from '../../config/api';

const headings = [
  'UMN',
  'Name',
  'Created On',
  'Owner',
  'Phone',
  'Email',
  'Status',
  'KYC',
  'Delete',
];

const status = [
  { name: 'Active', value: 'Active' },
  { name: 'Inactive', value: 'Inactive' },
];

const IconButton = ({ icon, onClick, url, ...props }) =>
  url ? (
    <Link to={url} {...props}>
      <i className={`fa fa-${icon} hex-icon`} />
    </Link>
  ) : (
    <i className={`fa fa-${icon} hex-icon`} onClick={onClick} {...props} />
  );

const MedicalStoresPage = () => {
  const { pathname } = useLocation();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [data, setData] = useState([]);
  const [deleteID, setDeleteID] = useState(null);
  const [filters, setFilters] = useState({ city: '', search: '' });
  const [showLoading, setShowLoading] = useState(true);

  const deleteClinic = () => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/MedicalStores/web_admin/delete_med_store`, {
      medical_store_id: deleteID,
      delete_ind: true,
      modified_by: 'any name',
    })
      .then((res) => {
        if (res.data.message === 'deleted successfully') {
          showAlert(res.data.message, 'success');
          downloadData();
        } else showAlert(res.data.message);
      })
      .catch((err) => showAlert('Delete failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const downloadData = () =>
    Axios.get(`${API_ENDPOINT}/MedicalStaffMaster/web_admin/med_list`)
      .then(({ data: res }) => {
        setData(res.map((row) => ({ ...row, display: true })));
        setShowLoading(false);
      })
      .catch((err) => showAlert('Failed to load info'))
      .finally(() => setShowLoading(false));

  const filter = () => {
    const copy = data.map((row) => {
      if (
        (filters.city === '' || row.city === filters.city) &&
        (filters.search === '' || searchRow(row))
      )
        return { ...row, display: true };
      return { ...row, display: false };
    });
    setData(copy);
  };

  const filterChangeHandler = ({ target: { name, value } }) =>
    setFilters({ ...filters, [name]: value });

  const filterData = (name) =>
    data
      .map((row) => row[name])
      .reduce((col, item) => (col.includes(item) ? col : [...col, item]), [])
      .map((item) => ({ name: item, value: item }));

  const searchRow = (row) => {
    const keys = Object.keys(row);
    for (const i in keys) {
      const key = keys[i];
      const value = typeof row[key] === 'string' ? row[key] : `${row[key]}`;
      if (value.toLowerCase().includes(filters.search.toLowerCase()))
        return true;
    }
    return false;
  };
  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const toggleKycStatus = (med_store_id, kyc_verified) => {
    console.log(med_store_id, kyc_verified);
  };

  const loadData = (rows) =>
    rows
      .filter((row) => row.display)
      .map((row) => [
        <Link to={`medicalstores/view/${row.medical_store_id}`}>
          {row.medical_store_no}
        </Link>,
        <span
          title={row.medical_store_name}
        >{`${row.medical_store_name.substring(0, 12)}...`}</span>,
        moment(row.created_time).format('DD-MM-YY'),
        <span
          title={`${row.first_name} ${row.last_name}`}
        >{`${row.first_name.substring(0, 12)}...`}</span>,
        row.phone_no,
        row.email_id,
        // <Link to={'/reset/medicalstore/' + row.medical_store_id}>Reset</Link>,
        <div style={{ minWidth: 72 }}>
          <Select
            className="outlined"
            name="Active"
            onChange={() =>
              statusSelectHandler(row.medical_store_id, row.is_active)
            }
            value={row.is_active ? 'Active' : 'Inactive'}
            values={status}
          />
        </div>,
        <div className="checkbox-fade fade-in-primary">
          <label>
            <input
              checked={row.is_kyc_verified}
              name="kyc_input"
              onChange={() =>
                toggleKycStatus(row.clinic_id, !row.is_kyc_verified)
              }
              type="checkbox"
              defaultValue={row.is_kyc_verified}
            />
            <span className="cr">
              <i className="cr-icon ion-checkmark txt-primary" />
            </span>
          </label>
        </div>,
        // <IconButton
        // 	icon='edit'
        // 	url={`/medicalstores/edit/${row.medical_store_id}/${row.staff_id}`}
        // />,
        <IconButton
          icon="trash"
          onClick={() => setDeleteID(row.medical_store_id)}
        />,
      ]);

  const statusSelectHandler = (medical_store_id, status) => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/MedicalStores/web_admin/is_active_status`, {
      medical_store_id,
      is_active: !status,
      modified_by: 'any name',
    })
      .then((res) => {
        showAlert(res.data.message, 'success');
        downloadData();
        setShowLoading(false);
      })
      .catch((err) => showAlert('Status change failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    downloadData();
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    filter();
    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <div className="row">
        <div className="col-6 col-md-3 m-b-20">
          <Select
            autoFocus
            name="city"
            onChange={filterChangeHandler}
            placeholder="City"
            tabIndex={1}
            value={filters.city}
            values={filterData('city')}
          />
        </div>
        <div className="col-6 col-md-3 m-b-20">
          <Select
            name="subscription_type"
            tabIndex={2}
            placeholder="Subscription Type"
            values={[
              { name: 'Option1', value: 'Option1' },
              { name: 'Option2', value: 'Option2' },
            ]}
          />
        </div>
        <div className="col-md-6 m-b-20">
          <input
            className="form-control"
            name="search"
            onChange={filterChangeHandler}
            tabIndex={3}
            placeholder="Search"
            type="text"
            value={filters.search}
          />
        </div>
      </div>
      <div className="col-12">
        <Table data={loadData(data)} headings={headings} number={10} />
      </div>
      <div className="fab" title="Add Medical Store">
        <Link
          className="btn btn-inverse btn-icon"
          tabIndex={4}
          to="/medicalstores/add"
        >
          <i className="ion-plus-round" />
        </Link>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <DeleteConfirm onConfirm={deleteClinic} />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default MedicalStoresPage;
