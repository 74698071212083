import { useClinic, useFullUser } from '@/hooks/useUser';
import { useEffect, useMemo, useState } from 'react';
import { useAppoinment } from '../states';
import axios from '@/_helpers/axios_interceptor';
import { _date } from '@/_helpers/common_utils';
import ConsultPendingInfo from '@/components/common/ConsultPendingInfo';
import { API_ENDPOINT } from '@/config/api';
import classNames from 'classnames';
import moment from 'moment';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MyVisitsModal from '../MyVisitsModal';

import { useToast } from '@/components/ui/use-toast';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
export const SesstionInfo = () => {
  const [isMyVisitsModalOpen, setIsMyVisitsModalOpen] = useState(false);
  const [providerId] = useFullUser((s) => [s.user?.user_data?.user_id]);
  const clinic = useClinic();
  return <div />;
  return (
    <div className="_flex _items-center _justify-between _bg-slate-50 _mb-2 _py-1  _border-slate-200 _border-solid _border rounded-lg">
      <p className="_mb-0">
        {/* {scheduleList && scheduleList.length && (
          <SingleSelect
            className="outlined mx-2"
            style={{ width: '200px' }}
            values={scheduleListForInput}
            value={schedule?.schedule_id}
            onChange={scheduleChangeHandler}
          />
        )} */}
      </p>
      <div className="_flex _space-x-4 _mr-4">
        <p className="_mb-0">
          <Link to="/consult/op-session" className="font-weight-bold text-dark">
            <span className="_underline hover:_no-underline">
              Switch to OP{' '}
            </span>
            <ConsultPendingInfo
              op
              provider_id={providerId}
              clinic_id={clinic?.clinic_id}
            />
          </Link>
        </p>
        <button
          onClick={() => {
            setIsMyVisitsModalOpen(!isMyVisitsModalOpen);
          }}
          className={classNames(
            '_px-2 !_py-0 _text-[13px] !_mb-0 hover:_bg-purple-300 _cursor-pointer _duration-500 _text-purple-700 _bg-purple-100 _mr-0.5 last:_mr-0 _rounded-full  _border _border-solid _border-purple-400  disabled:_border-slate-400 disabled:_text-slate-700 disabled:_bg-slate-100 disabled:_cursor-not-allowed'
          )}
        >
          <i className="fa fa-id-card-o _mr-1" aria-hidden="true"></i>
          My Visits
        </button>
      </div>
      {isMyVisitsModalOpen && (
        <MyVisitsModal setIsMyVisitsModalOpen={setIsMyVisitsModalOpen} />
      )}
    </div>
  );
};

export const ScheduleSelect = () => {
  const navigate = useNavigate();
  const [clearAppointment, setFee] = useAppoinment((s) => [
    s.clearAppointment,
    s.setFee,
  ]);
  const [providerId, schedule, scheduleList, setSchedule, setScheduleList] =
    useFullUser((s) => [
      s.user?.user_data?.user_id,
      s.schedule,
      s.user?.schedules.filter((e) => {
        if (e.provider_id) return e.provider_id === s.user?.user_data?.user_id;
        else return true;
      }),
      s.setSchedule,
      s.setScheduleList,
    ]);
  const scheduleChangeHandler = (value: string) => {
    setSchedule(value);
    clearAppointment();
    setFee(schedule?.normal_consult_fee.toString() || '0');
  };
  const { toast } = useToast();

  useEffect(() => {
    axios
      .get(
        `${API_ENDPOINT}/ProviderPatientConsultation/get_pro_schedules_for_the_day?provider_id=${providerId}`
      )
      .then(({ data: resData }) => {
        if (!resData || !resData.length) {
          toast({
            title: 'No active schedules available.',
            description:
              'Please try again later. Redirecting to consult page...',
            variant: 'destructive',
          });
          setTimeout(() => {
            navigate('/consult');
          }, 4000);
          return;
        }
        setScheduleList(resData);
      })
      .catch(() =>
        toast({ title: 'Loading schedules failed', variant: 'destructive' })
      );
  }, [providerId]);

  const scheduleListForInput = useMemo(() => {
    return (
      scheduleList?.map((obj) => ({
        name: `${moment(`${_date} ${obj.slot_start}`).format(
          'hh:mm A'
        )} to ${moment(`${_date} ${obj.slot_end}`).format('hh:mm A')}`,
        value: obj.schedule_id.toString(),
      })) || []
    );
  }, [scheduleList]);
  return (
    <>
      {scheduleList && scheduleList.length && (
        <Select
          value={schedule?.schedule_id.toString()}
          onValueChange={scheduleChangeHandler}
        >
          <SelectTrigger className="_w-[180px] !_bg-white _text-foreground !_py-0 !_px-2 sm:_px-3 sm:_py-2 _leading-3 sm:_leading-normal">
            <SelectValue placeholder="Select Schedule" />
          </SelectTrigger>
          <SelectContent>
            {scheduleListForInput.map((e) => (
              <SelectItem value={e.value}>{e.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    </>
  );
};

export const NavBarWalkin = () => {
  const { pathname } = useLocation();
  if (pathname !== '/consult/walk-in') return null;
  const [providerId] = useFullUser((s) => [s.user?.user_data?.user_id]);
  const clinic = useClinic();
  return (
    <>
	<Link to="/consult/op-session">
      <Button variant="ghost" size="sm" className="">
        <span className="_mr-1">Switch to OP</span>
        <ConsultPendingInfo
          op
          provider_id={providerId}
          clinic_id={clinic?.clinic_id}
		  />
      </Button>
		  </Link>
      <ScheduleSelect />
    </>
  );
};
