/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { Link, useLocation } from 'react-router-dom';

import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import Table from '../layout/Table';
import { API_ENDPOINT } from '../../config/api';

const headings = [
  'UPN',
  'Name',
  'Speciality',
  'RegNo',
  'Clinic no',
  'Exp',
  'Phone',
  'Email',
  'Status',
  'Delete',
];

const status = [
  { name: 'Active', value: 'Active' },
  { name: 'Inactive', value: 'Inactive' },
];

const IconButton = ({ icon, onClick, url, ...props }) =>
  url ? (
    <Link to={url} {...props}>
      <i className={`fa fa-${icon} hex-icon`} />
    </Link>
  ) : (
    <i className={`fa fa-${icon} hex-icon`} onClick={onClick} {...props} />
  );

const ProvidersPage = () => {
  const { pathname } = useLocation();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    city: '',
    provider_type: '',
    search: '',
    speciality_name: '',
  });
  const [showLoading, setShowLoading] = useState(true);
  const [deleteID, setDeleteID] = useState(null);

  const downloadData = () =>
    Axios.get(`${API_ENDPOINT}/Providers/web_admin/pro_list`)
      .then(({ data: resData }) =>
        setData(resData.map((row) => ({ ...row, display: true })))
      )
      .catch((err) => showAlert(`Loading Failed: ${err.message}`, 'error'))
      .finally(() => setShowLoading(false));

  const filter = () => {
    const copy = data.map((row) => {
      if (
        (filters.city === '' || row.city === filters.city) &&
        (filters.provider_type === '' ||
          row.provider_type === filters.provider_type) &&
        (filters.search === '' || searchRow(row)) &&
        (filters.speciality_name === '' ||
          row.speciality_name === filters.speciality_name)
      )
        return { ...row, display: true };
      return { ...row, display: false };
    });
    setData(copy);
  };

  const filterChangeHandler = ({ target: { name, value } }) =>
    setFilters({ ...filters, [name]: value });

  const filterData = (name) =>
    data
      .map((row) => row[name])
      .reduce((col, item) => (col.includes(item) ? col : [...col, item]), [])
      .map((item) => ({ name: item, value: item }));

  const loadData = (rows) =>
    rows
      .filter((row) => row.display)
      .map((row) => [
        <Link to={`/provider/edit/${row.provider_id}`}>{row.provider_no}</Link>,
        row.prov_name,
        row.speciality_name,
        row.reg_no,
        row.clinic_no,
        row.work_experience,
        row.phone_no,
        row.email_id,
        <div style={{ minWidth: 72 }}>
          <Select
            name="status"
            onChange={() => statusSelectHandler(row.provider_id, row.is_active)}
            value={row.is_active ? 'Active' : 'Inactive'}
            values={status}
          />
        </div>,
        <IconButton icon="trash" onClick={() => setDeleteID(row.clinic_id)} />,
      ]);

  const searchRow = (row) => {
    const keys = Object.keys(row);
    for (const i in keys) {
      const key = keys[i];
      const value = typeof row[key] === 'string' ? row[key] : `${row[key]}`;
      if (value.toLowerCase().includes(filters.search.toLowerCase()))
        return true;
    }
    return false;
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const statusSelectHandler = (provider_id, status) => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Providers/web_admin/is_active_status`, {
      provider_id,
      is_active: !status,
      modified_by: 'any name',
    })
      .then((res) => {
        showAlert(res.data.message, 'success');
        downloadData();
      })
      .catch((err) =>
        showAlert(`Status Change Failed: ${err.message}`, 'error')
      )
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    downloadData();
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    filter();
    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <div className="form-group row">
        <div className="col-md-2 m-b-10 m-t-10">
          <Select
            autoFocus
            name="city"
            onChange={filterChangeHandler}
            placeholder="City"
            tabIndex={1}
            value={filters.city}
            values={filterData('city')}
          />
        </div>
        <div className="col-md-2 m-b-10 m-t-10">
          <Select
            name="provider_type"
            onChange={filterChangeHandler}
            placeholder="Type"
            tabIndex={2}
            value={filters.provider_type}
            values={filterData('provider_type')}
          />
        </div>
        <div className="col-md-2 m-b-10 m-t-10">
          <Select
            name="speciality_name"
            onChange={filterChangeHandler}
            placeholder="Speciality"
            tabIndex={3}
            value={filters.speciality_name}
            values={filterData('speciality_name')}
          />
        </div>
        <div className="col-12 col-md-6 m-b-10 m-t-10">
          <input
            className="form-control"
            name="search"
            onChange={filterChangeHandler}
            placeholder="Search ..."
            tabIndex={4}
            type="text"
            value={filters.search}
          />
        </div>
        <div className="col-12">
          <Table data={loadData(data)} headings={headings} number={10} />
        </div>
        <div className="fab" title="Add Provider">
          <Link
            className="btn btn-inverse btn-icon"
            tabIndex={5}
            to="/provider/add"
          >
            <i className="ion-plus-round" />
          </Link>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default ProvidersPage;
