import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import moment from 'moment';

import { API_ENDPOINT } from '../../config/api';
import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import { useUser } from '@/hooks/useUser';
import { useNavigate } from 'react-router';

const PaymentStatus = () => {
  const { user_data, clinic_data } = useUser();
  const navigate = useNavigate();

  if (user_data.user_role !== 'Admin' || user_data.user_role !== 'PRO') {
    // eslint-disable-next-line no-alert
    alert('Un Authorized');
    return;
  }
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 mx-auto mt-5">
          <div className="payment">
            <div className="payment_header">
              <div className="payment_check">
                <i className="fa fa-check" aria-hidden="true" />
              </div>
            </div>
            <div className="payment_content">
              <h1>Payment Success !</h1>
              <p>
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs.{' '}
              </p>
              <a href="#">Go to Home</a>
            </div>
          </div>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </div>
  );
};

export default PaymentStatus;
