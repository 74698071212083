import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { useParams } from 'react-router-dom';
import Alert from '../layout/Alert';
import BackButton from '../layout/BackButton';
import DeleteConfirm from '../layout/DeleteConfirm';
import FormWizard from '../form/FormWizard';
import ImageUpload from '../form/ImageUpload';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import { API_ENDPOINT, GEOCODE_API } from '../../config/api';
import { parseAddressComponents } from '../../_helpers/common_utils';
import { usePageDetails } from '@/hooks/useUser';

const constraints = [
  {
    medical_store_name: { presence: true },
    medical_store_type: { presence: true },
    reg_no: { presence: true },
    email_id: { email: true, presence: true },
    opening_time: { presence: true },
    closing_time: { presence: true },
    days: { presence: true },
  },
  {
    location_lat: {
      format: {
        pattern: /^[+-]?\d+(\.\d+)?$/,
        message: 'is invalid',
      },
      presence: true,
    },
    location_lon: {
      format: {
        pattern: /^[+-]?\d+(\.\d+)?$/,
        message: 'is invalid',
      },
      presence: true,
    },
    pin_code: {
      length: {
        maximum: 6,
        minimum: 6,
      },
      numericality: {
        onlyNumeric: true,
      },
      presence: true,
    },
    area: { presence: true },
    landmark: { presence: true },
    pharmacy_phone_no: {
      format: {
        pattern: /(\d+){4,15}/,
        message: 'should be a valid phone number with a minimum of 4 digits',
      },
      presence: true,
    },
  },
  {
    admin_first_name: {
      format: {
        pattern: /[a-z ]+/i,
        message: 'should contain only alphabets',
      },
      presence: true,
    },
    admin_last_name: {
      format: {
        pattern: /[a-z ]+/i,
        message: 'should contain only alphabets',
      },
      presence: true,
    },
    admin_email_id: { email: true, presence: true },
    admin_user_name: { presence: true },
    admin_user_password: { presence: true },
    confirm_password: {
      equality: {
        attribute: 'admin_user_password',
        message: '^The passwords do not match',
      },
      presence: true,
    },
    admin_phone_no: {
      format: {
        pattern: /(\d+){4,15}/,
        message: 'invalid',
      },
      presence: true,
    },
    otp: {
      length: {
        maximum: 6,
        minimum: 6,
      },
      numericality: {
        onlyNumeric: true,
      },
      presence: true,
    },
    terms_accepted: {
      presence: {
        message:
          '^You have to accept the Terms & Conditions and Privacy Policy',
      },
    },
  },
];

const formHeaders = ['Pharmacy Info', 'Address Info', 'Admin Info'];

const MedicalStoreClinicConnect = () => {
  const {
    preferences,
    user_data,
    clinic_data,
    doctors,
    schedules,
    procedures,
    navigate,
    pathname,
    user,
  } = usePageDetails();
  const { medical_store_id } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [alertBackMsg, setAlertBackMsg] = useState('');
  const [alertBackShow, setAlertBackShow] = useState(false);
  const [alertHomeMsg, setAlertHomeMsg] = useState('');
  const [alertHomeShow, setAlertHomeShow] = useState(false);
  const [data, setData] = useState({
    clinic_id: user_data.clinic_id || '',
    medical_store_name: '',
    medical_store_type: '',
    reg_no: '',
    email_id: '',
    opening_time: '06:00:00',
    closing_time: '18:00:00',
    monday_on: false,
    tuesday_on: false,
    wednesday_on: false,
    thursday_on: false,
    friday_on: false,
    saturday_on: false,
    sunday_on: false,
    location_lat: '',
    location_lon: '',
    pin_code: '',
    area: '',
    landmark: '',
    pharmacy_phone_no: '',
    image_id: null,
    created_by: null,
    admin_first_name: '',
    admin_last_name: '',
    admin_email_id: '',
    admin_user_name: '',
    admin_user_password: '',
    confirm_password: '',
    admin_phone_no: '',
    otp: '',
    terms_accepted: false,
  });
  const [locationSearch, setLocationSearch] = useState('');
  const [locations, setLocations] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [storeTypes, setStoreTypes] = useState([]);
  const [clinicAreaList, setClinicAreaList] = useState([]);
  const [imgUploadStatus, setImgUploadStatus] = useState({
    message: 'Upload pharmacy image.',
    success: false,
  });
  const [otpSendStatus, setOtpSendStatus] = useState({});
  const [otpVerifyStatus, setOtpVerifyStatus] = useState({});
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [profilePic, setProfilePic] = useState('');

  if (medical_store_id) {
    constraints[2].phoneChangeStatus = { presence: false };
    constraints[2].admin_phone_no = (value, attributes) => {
      if (!attributes.phoneChangeStatus) return null;
      return {
        format: {
          pattern: /(\d+){4,15}/,
          message: 'invalid',
        },
        presence: true,
      };
    };
    constraints[2].otp = (value, attributes) => {
      if (!attributes.phoneChangeStatus) return null;
      return {
        format: {
          pattern: /^\d{6}$/,
          message: 'should be a 6-digit number',
        },
        presence: true,
      };
    };
    constraints[2].admin_user_password = { presence: false };
    constraints[2].confirm_password = { presence: false };
  }

  const changeHandler = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
    if (
      name === 'pin_code' &&
      value &&
      value.length === 6 &&
      !Number.isNaN(value)
    ) {
      getAreaFromPincode(value);
    }
  };

  const phoneNumberChangeHandler = ({ target: { checked, value } }) => {
    if (checked === false) {
      setData({
        ...data,
        phoneChangeStatus: !data.phoneChangeStatus,
        admin_phone_no: currentPhoneNumber,
      });
    } else {
      setData({ ...data, phoneChangeStatus: !data.phoneChangeStatus });
    }
  };

  const sendOtp = () => {
    if (
      !data.admin_phone_no ||
      data.admin_phone_no.length < 10 ||
      Number.isNaN(data.admin_phone_no)
    )
      return;
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Otp/new`, {
      otp_phone: data.admin_phone_no,
      phone_code: data.phone_code,
    })
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData && resData.otp_sent === false) {
          setOtpSendStatus({
            success: false,
            message:
              resData.message ||
              "Couldn't sent OTP, plese check the number and try again.",
          });
        } else {
          setOtpSendStatus({
            success: true,
            message: 'OTP sent to your phone.',
          });
        }
      })
      .catch((err) => {
        setOtpSendStatus({
          success: false,
          message: 'OTP creation failed',
        });
      })
      .finally(() => setShowLoading(false));
  };

  const verifyOtp = () => {
    if (!data.otp || data.otp.length !== 6 || Number.isNaN(data.otp)) {
      setData({ ...data, otp: '' });
      return;
    }
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Otp/verify`, {
      otp: data.otp,
      otp_phone: data.admin_phone_no,
      phone_code: data.phone_code,
    })
      .then(({ data: resData }) => {
        setShowLoading(false);
        if (resData && resData.otp_verified === false) {
          setData({ ...data, otp: '' });
          setOtpVerifyStatus({
            success: false,
            message: 'OTP verifcation failed',
          });
        } else {
          setOtpVerifyStatus({
            success: true,
            message: 'OTP verified successfully',
          });
        }
      })
      .catch((err) => {
        setOtpVerifyStatus({
          success: false,
          message: `OTP verifcation failed:${err}`,
        });
      })
      .finally(() => setShowLoading(false));
  };

  const getAreaFromPincode = (pincode) => {
    if (!pincode) return;
    Axios.post(`${API_ENDPOINT}/PinCode/pincode_no`, { pin_code: pincode })
      .then(({ data: resData }) => {
        if (resData && resData.length) {
          setClinicAreaList(
            resData.map((clinicArea) => ({
              name: clinicArea.area,
              vlaue: clinicArea.area,
            }))
          );
        } else {
          setClinicAreaList([{ name: 'Some Area', vlaue: 'Some Area' }]);
        }
      })
      .catch((err) => showAlert('Geting PinCode areas failed', 'error'));
  };

  const changeLocationHandler = async ({ target: { value } }) => {
    setLocationSearch(value);
    const response = await fetch(GEOCODE_API + value);
    if (response?.status === 200) {
      const respData = await response?.json();
      const resultSet = await respData?.results;
      const parsedAddressComponents = parseAddressComponents(
        resultSet?.[0] || []
      );
      const _route = parsedAddressComponents?.route || '';
      const _sublocality = parsedAddressComponents?.sublocality || '';
      setLocations([
        {
          lat: resultSet?.[0]?.geometry.location.lat,
          lon: resultSet?.[0]?.geometry.location.lng,
          name: resultSet?.[0]?.formatted_address,
          country: parsedAddressComponents?.country || '',
          province: parsedAddressComponents?.administrative_area_level_1 || '',
          area: parsedAddressComponents?.locality || '',
          street_address: `${_route}${
            _route ? `, ${_sublocality}` : _sublocality || ''
          }`,
          pin_code: parsedAddressComponents?.postal_code || '',
        },
      ]);
    }
  };

  const checkboxChangeHandler = ({ target: { checked, value } }) => {
    const copy = { ...data };
    copy[value] = checked;
    setData(copy);
  };

  const deleteAccount = () => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/MedicalStores/web_admin/delete_med_store`, {
      medical_store_id,
      delete_ind: true,
      modified_by: 'any name',
    })
      .then((res) => {
        if (res.data.message === 'deleted successfully')
          showAlertBack(res.data.message);
        else showAlert(res.data.message);
      })
      .catch((err) => showAlert(`Delete failed: ${err.message}`, 'error'))
      .finally(() => setShowLoading(false));
  };

  const selectResult = (index) => {
    const _pin_code = locations[index].pin_code
      ? Array.isArray(locations[index].pin_code)
        ? locations[index].pin_code[0]
        : locations[index].pin_code
      : '';
    setData({
      ...data,
      location_lat: locations[index].lat,
      location_lon: locations[index].lon,
      pin_code: _pin_code,
    });
    getAreaFromPincode(_pin_code);
    setLocationSearch(locations[index].name || '');
    setLocations([]);
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const showAlertBack = (msg) => {
    setAlertBackMsg(msg);
    setAlertBackShow(true);
  };

  const showAlertHome = (msg) => {
    setAlertHomeMsg(msg);
    setAlertHomeShow(true);
  };

  const cancelhandler = () => {
    navigate(-1);
  };

  const submitHandler = () => {
    setShowLoading(true);
    Axios({
      data: medical_store_id
        ? {
            ...data,
            medical_store_id,
            modified_by: user_data.user_no,
            is_email_changed: data.admin_email_id !== currentEmail,
          }
        : {
            ...data,
            created_by: user_data.user_no,
            attached_by: user_data.user_no,
          },
      headers: { 'Content-Type': 'application/json' },
      method: medical_store_id ? 'put' : 'post',
      url: medical_store_id
        ? `${API_ENDPOINT}/MedicalStores/web_admin/edit_pharmacy_for_clinic`
        : `${API_ENDPOINT}/MedicalStores/web_admin/add_pharmacy_for_clinic`,
    })
      .then(({ data: { info } }) => {
        if (medical_store_id && info === 'medical store edited successfully')
          showAlertBack(info);
        else if (info === 'medical store added successfully') {
          if (pathname === '/medicalstore') showAlertHome(info);
          else showAlertBack(info);
        } else showAlert(info);
      })
      .catch((err) => showAlert(`Submit failed: ${err.message}`, 'error'))
      .finally(() => setShowLoading(false));
  };

  const uploadImage = ({ imgBlob, fileNameWithExtension }) => {
    const fd = new FormData();
    fd.append('file', imgBlob, fileNameWithExtension);
    fd.append('upload_use_case', 'medical_store_img');
    setShowLoading(true);
    Axios({
      data: fd,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url: `${API_ENDPOINT}/File/upload`,
    })
      .then(({ data: { info, file_id } }) => {
        data.image_id = file_id;
        setImgUploadStatus({
          success: true,
          message: 'Image uploaded successfully',
        });
      })
      .catch((err) =>
        setImgUploadStatus({
          success: false,
          message: 'Uploading failed please try again',
        })
      )
      .finally(() => setShowLoading(false));
  };

  const timeChangeHandler = ({ target: { name, value } }) =>
    setData({ ...data, [name]: `${value}:00` });

  useEffect(() => {
    Axios.get(`${API_ENDPOINT}/MedicalStoreTypeMaster/`)
      .then((res) => {
        setStoreTypes(
          res.data.map((item) => ({
            name: item.medical_store_type,
            value: item.medical_store_type_id,
          }))
        );
        if (medical_store_id) {
          setData({ ...data, medical_store_id });
          return Axios.get(
            `${API_ENDPOINT}/MedicalStores/web_admin/medical_store_by_id/${medical_store_id}`
          ).then((res2) => {
            const newData = { ...res2.data[0] };
            if (newData.pin_code) getAreaFromPincode(newData.pin_code);
            if (newData && newData.admin_phone_no)
              setCurrentPhoneNumber(newData.admin_phone_no);
            if (newData && newData.admin_email_id)
              setCurrentEmail(newData.admin_email_id);
            if (newData && newData.profile_pic) {
              setProfilePic(newData.profile_pic);
              setImgUploadStatus({ message: 'Update the image' });
            }
            setData({ ...data, ...newData });
          });
        }
      })
      .catch((err) => showAlert(`Failed to load info: ${err.message}`, 'error'))
      .finally(() => setShowLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ paddingTop: '50px' }}>
      {pathname === '/medicalstore' && <BackButton to="/" />}
      <FormWizard
        constraints={constraints}
        headers={formHeaders}
        onSubmit={submitHandler}
        onCancel={cancelhandler}
        otpVerifyStatus={otpVerifyStatus}
        phoneChangeStatus={data.phoneChangeStatus}
      >
        <div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="medical_store_name"
                onChange={changeHandler}
                placeholder="Medical Store Name *"
                value={data.medical_store_name}
                type="text"
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <Select
                name="medical_store_type"
                onChange={changeHandler}
                placeholder="Type *"
                value={data.medical_store_type}
                values={storeTypes}
              />
              <div className="messages" />
            </div>
          </div>

          <div
            className="form-unit row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="reg_no"
                onChange={changeHandler}
                placeholder="Registeration Number *"
                value={data.reg_no}
                type="text"
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="email_id"
                onChange={changeHandler}
                placeholder="Email Address *"
                value={data.email_id}
                type="text"
              />
              <div className="messages" />
            </div>
          </div>

          <div
            className="form-unit row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="col-sm-6">
              <div className="row">
                <div className="col-12 m-b-5 text-center text-muted">
                  Working Hours *
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="opening_time"
                    onChange={timeChangeHandler}
                    placeholder="Start Time"
                    type="time"
                    value={data.opening_time}
                  />
                  <div className="messages" />
                </div>

                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="closing_time"
                    onChange={timeChangeHandler}
                    placeholder="End Time"
                    type="time"
                    value={data.closing_time}
                  />
                  <div className="messages" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 form-group text-center">
              <div className="m-b-10">Working Days *</div>
              <div className="checkbox-fade fade-in-primary">
                <label>
                  <span>M</span>
                  <input
                    checked={data.monday_on}
                    name="days"
                    onChange={checkboxChangeHandler}
                    type="checkbox"
                    value="monday_on"
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                </label>
              </div>

              <div className="checkbox-fade fade-in-primary">
                <label>
                  <span>T</span>
                  <input
                    checked={data.tuesday_on}
                    name="days"
                    onChange={checkboxChangeHandler}
                    type="checkbox"
                    value="tuesday_on"
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                </label>
              </div>

              <div className="checkbox-fade fade-in-primary">
                <label>
                  <span>W</span>
                  <input
                    checked={data.wednesday_on}
                    name="days"
                    onChange={checkboxChangeHandler}
                    type="checkbox"
                    value="wednesday_on"
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                </label>
              </div>

              <div className="checkbox-fade fade-in-primary">
                <label>
                  <span>T</span>
                  <input
                    checked={data.thursday_on}
                    name="days"
                    onChange={checkboxChangeHandler}
                    type="checkbox"
                    value="thursday_on"
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                </label>
              </div>

              <div className="checkbox-fade fade-in-primary">
                <label>
                  <span>F</span>
                  <input
                    checked={data.friday_on}
                    name="days"
                    onChange={checkboxChangeHandler}
                    type="checkbox"
                    value="friday_on"
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                </label>
              </div>

              <div className="checkbox-fade fade-in-primary">
                <label>
                  <span>S</span>
                  <input
                    checked={data.saturday_on}
                    name="days"
                    onChange={checkboxChangeHandler}
                    type="checkbox"
                    value="saturday_on"
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                </label>
              </div>

              <div className="checkbox-fade fade-in-primary">
                <label>
                  <span>S</span>
                  <input
                    checked={data.sunday_on}
                    name="days"
                    onChange={checkboxChangeHandler}
                    type="checkbox"
                    value="sunday_on"
                  />
                  <span className="cr">
                    <i className="cr-icon ion-checkmark txt-primary" />
                  </span>
                </label>
              </div>
              <div className="messages text-center" />
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-12 m-b-5 text-muted">Location *</div>
            <div className="col-12 form-group">
              <input
                className="form-control"
                name="location_search"
                onChange={changeLocationHandler}
                placeholder="Search for location"
                value={locationSearch}
                type="text"
              />
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    background: '#eee',
                    position: 'absolute',
                    zIndex: 2,
                  }}
                >
                  {locations.length
                    ? locations.map((item, index) => (
                        <div
                          className="outlined"
                          key={index}
                          onClick={() => selectResult(index)}
                          style={{
                            backgroundColor: '#fff',
                            borderRadius: '3px',
                            cursor: 'pointer',
                            padding: '.5rem .75rem',
                            marginTop: '3px',
                          }}
                        >
                          {item.name}
                        </div>
                      ))
                    : ''}
                </div>
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="location_lat"
                onChange={changeHandler}
                placeholder="Latitude"
                type="text"
                value={data.location_lat}
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="location_lon"
                onChange={changeHandler}
                placeholder="Longitude"
                type="text"
                value={data.location_lon}
              />
              <div className="messages" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="pin_code"
                onChange={changeHandler}
                placeholder="Pincode *"
                type="text"
                value={data.pin_code}
                autoComplete="none"
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <Select
                name="area"
                onChange={changeHandler}
                placeholder="Choose Clinic Area *"
                value={data.area}
                values={clinicAreaList}
              />
              <div className="messages" />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="landmark"
                onChange={changeHandler}
                placeholder="Landmark *"
                type="text"
                value={data.landmark}
              />
              <div className="messages" />
            </div>
            <div className="col-sm-6 form-group">
              <input
                className="form-control"
                name="pharmacy_phone_no"
                onChange={changeHandler}
                placeholder="Pharmacy Phone No *"
                type="text"
                maxLength={15}
                value={data.pharmacy_phone_no}
              />
              <div className="messages" />
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-2 text-center">
              <ImageUpload
                uploadImageFn={uploadImage}
                newImgWidth={250}
                newImgHeight={250}
                name="image_id"
                thumbnail_img={profilePic}
              />
              <span
                style={{
                  color:
                    imgUploadStatus.success === true
                      ? 'green'
                      : imgUploadStatus.success === false
                      ? 'red'
                      : 'black',
                }}
              >
                {imgUploadStatus.message}
              </span>
              {/* {medical_store_id && (
                <button className="btn btn-danger m-b-10 m-t-10" type="button">
                  Delete Account
                </button>
              )} */}
            </div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="admin_first_name"
                    onChange={changeHandler}
                    placeholder="First Name *"
                    type="text"
                    value={data.admin_first_name}
                    disabled={medical_store_id}
                  />
                  <div className="messages" />
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="admin_last_name"
                    onChange={changeHandler}
                    placeholder="Last Name *"
                    type="text"
                    value={data.admin_last_name}
                    disabled={medical_store_id}
                  />
                  <div className="messages" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="admin_email_id"
                    onChange={changeHandler}
                    placeholder="Email *"
                    type="text"
                    value={data.admin_email_id}
                    disabled={medical_store_id}
                  />
                  <div className="messages" />
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    className="form-control"
                    name="admin_user_name"
                    onChange={changeHandler}
                    placeholder="Username *"
                    type="text"
                    value={data.admin_user_name}
                    disabled={medical_store_id}
                  />
                  <div className="messages" />
                </div>
              </div>

              {!medical_store_id && (
                <div className="row">
                  <div className="col-sm-6 form-group">
                    <input
                      className="form-control"
                      name="admin_user_password"
                      onChange={changeHandler}
                      placeholder="Password"
                      type="password"
                      value={data.admin_user_password}
                    />
                    <div className="messages" />
                  </div>
                  <div className="col-sm-6 form-group">
                    <input
                      className="form-control"
                      name="confirm_password"
                      onChange={changeHandler}
                      placeholder="Confirm Password"
                      type="password"
                      value={data.confirm_password}
                    />
                    <div className="messages" />
                  </div>
                </div>
              )}

              {medical_store_id && (
                <div className="row">
                  <div
                    className="col-sm-6 checkbox-fade fade-in-info form-group"
                    style={{ marginRight: '0px' }}
                  >
                    <label>
                      <input
                        checked={data.phoneChangeStatus}
                        name="phoneChangeStatus"
                        onChange={phoneNumberChangeHandler}
                        type="checkbox"
                        value={data.phoneChangeStatus}
                      />
                      <span className="cr">
                        <i className="cr-icon ion-checkmark txt-primary" />
                      </span>
                      <span>Change {currentPhoneNumber} mobile no</span>
                    </label>
                    <div className="messages" />
                  </div>
                </div>
              )}

              {(data.phoneChangeStatus || !medical_store_id) && (
                <div className="row">
                  <div className="col-md-6 form-group">
                    <div className="input-group input-group-button">
                      <input
                        className="form-control"
                        name="admin_phone_no"
                        onChange={changeHandler}
                        placeholder="Mobile No *"
                        type="text"
                        maxLength={10}
                        value={data.admin_phone_no}
                      />
                      <span
                        className="bg-info input-group-addon"
                        id="basic-addon10"
                        onClick={sendOtp}
                      >
                        Send OTP
                      </span>
                    </div>
                    <div className="messages" />
                    <div
                      style={{ color: otpSendStatus.success ? 'green' : 'red' }}
                    >
                      {otpSendStatus.message}
                    </div>
                  </div>
                  <div className="col-md-6 form-group">
                    <div className="input-group input-group-button">
                      <input
                        className="form-control"
                        name="otp"
                        onChange={changeHandler}
                        placeholder="OTP"
                        type="text"
                        value={data.otp}
                      />
                      <span
                        className="bg-info input-group-addon"
                        id="basic-addon10"
                        onClick={verifyOtp}
                      >
                        Verify
                      </span>
                    </div>
                    <div
                      className="messages"
                      style={{
                        color: otpVerifyStatus.success ? 'green' : 'red',
                      }}
                    >
                      {otpVerifyStatus.message}
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-12 checkbox-fade fade-in-info form-group">
                  <label>
                    <input
                      checked={data.terms_accepted}
                      name="terms_accepted"
                      onChange={() =>
                        setData({
                          ...data,
                          terms_accepted: !data.terms_accepted,
                        })
                      }
                      type="checkbox"
                      value={data.terms_accepted}
                    />
                    <span className="cr">
                      <i className="cr-icon ion-checkmark txt-primary" />
                    </span>
                    <span>
                      I agree to the{' '}
                      <a href="/legal/terms.html" target="_blank">
                        terms & conditions
                      </a>{' '}
                      and{' '}
                      <a href="/legal/privacy.html" target="_blank">
                        privacy policy
                      </a>
                    </span>
                  </label>
                  <div className="messages" />
                </div>
              </div>
            </div>
            <div className="row">
              <p className="p-l-20">
                Note: <br />
                ** For the best result please upload an image(.png or .jpg) of
                width 250px and height 250px
              </p>
            </div>
          </div>
        </div>
      </FormWizard>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <Alert
        action={() => {
          navigate(-1);
        }}
        msg={alertBackMsg}
        setShow={setAlertBackShow}
        show={alertBackShow}
        type="success"
      />
      <Alert
        action={() => {
          navigate('/');
        }}
        msg={alertHomeMsg}
        setShow={setAlertHomeShow}
        show={alertHomeShow}
        type="success"
      />
      <DeleteConfirm onConfirm={deleteAccount} />
      <LoadingBox show={showLoading} />
    </div>
  );
};

export default MedicalStoreClinicConnect;
