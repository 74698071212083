import { Procedure } from '@/types/user';

export type WalkinSubmitDataType = {
  appointmentid: number;
  opid: number;
  clinicid: number;
  providerid: number;
  patientid: number;
  patientuin: string;
  tokenno: string;
  cliniclogourl: string;
  drsignurl: string;
  consultationdate: string;
  starttime: string;
  endtime: string;
  consultationtype: string;
  consultationsummary: string;
  drpatientinfo: DRPatientInfo;
  patientabsent: boolean;
  casesheet: InitialCasesheet;
  patientvitals?: Record<string, string>;
};

type DetailValue = {
  value: string;
  updatedAt: Date;
  isTouched: boolean;
};

export type PrescriptionDetailsKey =
  | 'History'
  | 'Observations'
  | 'Diagnosis'
  | 'Treatments'
  | 'Lab';

type PriscriptionDetails = Record<PrescriptionDetailsKey, DetailValue>;

type Casesheet = {
  casesheet_id: number | null;
  pdf_id?: string;
  prescription: { [key: string]: Prescription };
  prescription_details?: PriscriptionDetails;
  special_instruction: string;
  validity_in_days?: string;
  consultation_id?: number;
  attachment_id?: number;
  attachment_url?: string;
};

export type InitialCasesheet = Omit<Casesheet, 'prescription_details'> & {
  prescription_details: string;
};

type Prescription = {
  med?: string;
  dosage?: string;
  duration?: string;
  incredients?: string;
  note?: string;
};

type DRPatientInfo = {
  doctor_info: DoctorInfo;
  clinic_info: ClinicInfo;
  patient_info: PatientInfo;
};

type ClinicInfo = {
  clinic_uid: string;
  name: string;
  phone: string;
  logo: string;
  clinic_place: string;
  clinic_pin_code: string;
  clinic_working_time: string;
  clinic_working_days: string;
};

type DoctorInfo = {
  upn: string;
  name: string;
  speciality: string;
  dr_reg_no: string;
  degree_name: string;
  add_qualification: string;
  provider_id: number;
  degree_id: number;
};

type PatientInfo = {
  uin: string;
  name: string;
  phone: string;
  gender: null;
  age: number;
  place: string;
};

type TSingleVital = {
  value?: string;
  updatedAt?: Date;
  updatedBy?: number;
};

export type VitalsResponse = {
  clinic_id: number;
  id: number;
  modifiedat: string;
  modifiedby: number;
  patient_id: number;
  value: string;
  vitalname: keyof PatientVitals;
};

export type PatientVitals = {
  bp_sys?: TSingleVital;
  bp_diastolic?: TSingleVital;
  bmi?: TSingleVital;
  height_cm?: TSingleVital;
  weight_kg?: TSingleVital;
  pulse_rate?: TSingleVital;
  allergies?: TSingleVital;
  habits?: TSingleVital;
  medical_conditions?: TSingleVital;
  insurance_provider?: TSingleVital;
  policy_number?: TSingleVital;
  policy_expiry_date?: TSingleVital;
  blood_group?: TSingleVital;
};

export type PValueType = Record<PrescriptionDetailsKey, string | undefined> & {
  provider_id?: number;
  historyUpdatedAt?: string;
  observationupdatedAt?: string;
};

const vitalsSingleValue: TSingleVital = {
  updatedAt: new Date(),
  value: '',
};

export const vitalsInitialState: PatientVitals = {
  bp_sys: vitalsSingleValue,
  bp_diastolic: vitalsSingleValue,
  bmi: vitalsSingleValue,
  height_cm: vitalsSingleValue,
  weight_kg: vitalsSingleValue,
  pulse_rate: vitalsSingleValue,
  allergies: vitalsSingleValue,
  habits: vitalsSingleValue,
  medical_conditions: vitalsSingleValue,
  insurance_provider: vitalsSingleValue,
  policy_number: vitalsSingleValue,
  policy_expiry_date: vitalsSingleValue,
  blood_group: vitalsSingleValue,
};

export const casesheetInitialState: Casesheet = {
  casesheet_id: null,
  pdf_id: '',
  prescription: {},
  prescription_details: undefined,
  special_instruction: '',
  validity_in_days: '',
  attachment_id: undefined,
  attachment_url: undefined,
};

export const initialStateOfTab = {
  value: '',
  updatedAt: new Date(),
  isTouched: false,
};

export const prescriptionDetailsInitialState = (
  v: string = ''
): PriscriptionDetails => ({
  Diagnosis: { ...initialStateOfTab, value: v },
  Treatments: initialStateOfTab,
  History: initialStateOfTab,
  Observations: initialStateOfTab,
  Lab: initialStateOfTab,
});

export type CasesheetState = {
  caseSheet: Casesheet;
  vitals: PatientVitals;
  previousCsData?: {
    Observations: DetailValue;
    History: DetailValue;
  };
};

export type CasesheetActions = {
  setCaseSheet: (casesheet: InitialCasesheet, force?: boolean) => void;
  setVitals: (vitals: VitalsResponse[]) => void;
  setVitalValue: (key: keyof PatientVitals, value: string | number) => void;
  removeAttachment: () => void;
  resetCaseSheet: () => void;
  onChangeSpecialInstructions: (value: string) => void;
  onChangePrescriptionDetails: (
    item: keyof PriscriptionDetails,
    value?: string,
    silent?: boolean
  ) => void;
  clearPrescription: (force?: boolean) => void;
  onChangeMedicineName: (
    index: number,
    value: string,
    incredients?: string,
    note?: string
  ) => void;
  onChangeDosage: (index: number, value: string) => void;
  onChangeDuration: (index: number, value: string) => void;
  addAttachment: (id: number, url: string) => void;
  setPreviousCsData: (data: string) => void;
  clearMedicine: (index: number) => void;
  submitCaseSheet: () => InitialCasesheet;
  submitVitals: () => Record<string, string>;
  clearVitals: () => void;
};

export type CurrentAppoinment = {
  appointment_id?: number;
  is_tatkal?: boolean;
  fee: string;
  tatkal_consult_fee?: string;
  normal_consult_fee?: string;
  lot_name?: string;
  op_time?: string;
};

export type AppoinmentRes = CurrentAppoinment & {
  patient: PatientT;
  is_consulted?: boolean;
};

export type LoadWalkinsRes = {
  token_number: number;
  patient: PatientT;
  is_consulted: boolean;
  appointment_date: Date;
  appointment_id: number;
  slot_id: null;
  appointment_note: string;
  is_tatkal: boolean;
  transactions: null;
  proc_data: null;
  normal_consult_fee: string;
  tatkal_consult_fee: string;
  initial_op_time: string;
  op_time: string;
  booking_status: string;
  modify_by: string;
  op_scheduled_start: null;
  op_scheduled_end: null;
  op_actual_start: null;
  is_op_active: boolean;
  is_doctor_in_op: boolean;
  procedure_minutes: number;
  delay_minutes: number;
  transferred_doctor: null;
  is_patient_absent: null;
};

export type PatientT = {
  person_no: string;
  person_id: number;
  email_id: string;
  first_name: string;
  last_name: string;
  phone_no: string;
  sex: null | string;
  date_of_birth: Date;
  blood_group: null | string;
  family_name: null | string;
  em_contact_name: null | string;
  em_contact_no: null | string;
  place: string;
  age: number;
  visit_status: string;
  file_no: string;
  payment_due: number;
  id: number;
};

export type FamilyMember = {
  person_no: string;
  person_id: number;
  email_id: string;
  first_name: string;
  last_name: string;
  phone_no: string;
  sex: string;
  date_of_birth: Date;
  relationship_name: string;
  age: number;
};

export type AppointmentState = {
  appointment: CurrentAppoinment | null;
  patient: PatientT | null;
  allAppoinments: CurrentAppoinment[];
  familyMemberList: FamilyMember[];
  currentToken: number;
  proceduresList: Procedure[];
  startTime: Date;
};

export type AppointmentActions = {
  setAppointment: (appointment: CurrentAppoinment) => void;
  setPatient: (
    patient: PatientT & {
      family_members?: FamilyMember[];
    }
  ) => void;
  clearAppointment: () => void;
  setFee: (fee: string) => void;
  setProceduresList: (proceduresList: Procedure[]) => void;
};

export type GetPreviousVisitsResType = {
  previous_providers: Visits[];
  visits_at_provider: Visits[];
};

export type Visits = {
  appointment_date: Date;
  appointment_id: number;
  appointment_note: string;
  attachment_location: null;
  clinic_name: string;
  end_time: string;
  is_walkin: boolean;
  name?: string;
  op_time: string;
  pdf_location: null | string;
  proc_data: ProcedureData[] | null;
  procedure_type: null | string;
  provider_id?: number;
  speciality_name?: string;
  clinic_id?: number;
};

export type ProcedureData = {
  proc_cli_id: number;
  procedure_id: number;
  procedure_name: string;
  procedure_price: number;
};
