import { useEffect, useLayoutEffect } from 'react';
import { useLocation, Outlet, Link } from 'react-router-dom';

import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import logo from '@/assets/unq-logo-nav.png';
import { publicNavLinks } from './Nav';

const PublicCleanLayout = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname, search]);

  useLayoutEffect(() => {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = 'https://unpkg.com/tailwindcss@3.4.4/src/css/preflight.css';
    document.head.insertBefore(
      style,
      document.getElementById('bootstrap-style')
    );

    return () => {
      // Clean up the styles when the component unmounts
      document.head.removeChild(style);
    };
  }, []);
  return (
    <div className="_flex _flex-col _w-full _min-h-screen">
      <Helmet>
        <body className="_font-body !_w-full !_h-screen _relative" />
      </Helmet>
      <div className="_w-full _bg-slate-50 _border-b  _shadow-sm">
        <div className="_flex _justify-between _items-center _container _mx-auto  _p-4 ">
          <img
            alt="UnQ SCS"
            className="logo mr-auto"
            src={logo}
            style={{ height: '40px' }}
          />
		  <div className='_flex _justify-between _items-center _space-x-4 _font-bold'>
			{publicNavLinks.map((route) => (
			  <div className="_flex _items-center hover:_font-extrabold _space-x-2">
				<Link to={route.link}>{route.name}</Link>
			  </div>
			))}

		  </div>
        </div>
      </div>
      <div className="_relative _py-2 _flex _flex-col _grow _w-full _h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default PublicCleanLayout;
