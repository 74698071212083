import Checkbox from './Checkbox';
import CheckboxWithImage from './CheckboxWithImage';
import { useDentalChart } from './DentalChartContext';
import {
  CROWN_TYPES,
  FPD_TYPES,
  OBSERVATION_TYPES,
  OTHER_TREATMENTS,
} from './constants';

const TreatmentSelection = ({ type }) => {
  const { selectedTeeth, selectedTreatments, setSelectedTreatments } =
    useDentalChart();

  const handleSelection = (e, item) => {
    if (e.target.name === 'fillingType') {
      let fillingType = selectedTreatments?.fillingType || [];

      if (fillingType?.includes(item)) {
        fillingType = fillingType.filter((fItem) => fItem !== item);
      } else {
        fillingType = [item, ...fillingType];
      }

      return setSelectedTreatments((prevData) => ({
        ...prevData,
        fillingType,
      }));
    }

    setSelectedTreatments((prevData) => ({
      ...prevData,
      [e.target.name]: prevData[e.target.name]?.includes(item) ? null : item,
    }));
  };

  return (
    <div className="dental-chart__treatment-selection mt-3">
      <div className="dental-chart__treatment-selection__section pt-3">
        <h6 className="heading">Filling</h6>
        <div className="d-flex">
          {selectedTeeth?.fillingTypes.map((item) => (
            <Checkbox
              name="fillingType"
              checked={selectedTreatments['fillingType']?.includes(item)}
              onChange={(e) => handleSelection(e, item)}
              key={item.toLowerCase().trim()}
              label={item}
              id={`${item.toLowerCase().trim()}-${type}`}
            />
          ))}
        </div>
      </div>
      <div className="dental-chart__treatment-selection__section pt-3">
        <div className="d-flex">
          {OTHER_TREATMENTS.map((item) => (
            <CheckboxWithImage
              name="otherTreatment"
              checked={selectedTreatments['otherTreatment'] === item.label}
              onChange={(e) => handleSelection(e, item.label)}
              isTitleBold
              key={item.label.toLowerCase().trim()}
              id={`${item.label.toLowerCase().trim()}-${type}`}
              {...item}
            />
          ))}
        </div>
      </div>
      <div className="dental-chart__treatment-selection__section pt-3">
        <h6 className="heading">FPD(BRIDGE)</h6>
        <div className="d-flex">
          {FPD_TYPES.map((item) => (
            <Checkbox
              name="fpdType"
              checked={selectedTreatments['fpdType'] === item}
              onChange={(e) => handleSelection(e, item)}
              key={item.toLowerCase().trim()}
              label={item}
              id={`${item.toLowerCase().trim()}-${type}`}
            />
          ))}
        </div>
      </div>
      <div className="dental-chart__treatment-selection__section pt-3">
        <h6 className="heading">Crown</h6>
        <div className="d-flex">
          {CROWN_TYPES.map((item) => (
            <CheckboxWithImage
              name="crownType"
              checked={selectedTreatments['crownType'] === item.label}
              onChange={(e) => handleSelection(e, item.label)}
              key={item.label.toLowerCase().trim()}
              id={`${item.label.toLowerCase().trim()}-${type}`}
              {...item}
            />
          ))}
        </div>
      </div>
      <div className="dental-chart__treatment-selection__section pt-3">
        <h6 className="heading">Observations</h6>
        <div className="d-flex">
          {OBSERVATION_TYPES.map((item) => (
            <CheckboxWithImage
              name="observationType"
              checked={selectedTreatments['observationType'] === item.label}
              onChange={(e) => handleSelection(e, item.label)}
              key={item.label.toLowerCase().trim()}
              id={`${item.label.toLowerCase().trim()}-${type}`}
              {...item}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TreatmentSelection;
