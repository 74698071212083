import React, { useState, useEffect } from 'react';
import downloadIcon from './assets/download.svg';
import printIcon from './assets/printIcon.svg';
import signature from './assets/signature.png';
import './OPSessionMobile.css';
import OPPreviewPrescriptionModal from './OPPreviewPrescriptionModal';

const CaseSheetMobile = (props) => {
  const [isOPPreviewPrescriptionModal, setIsOPPreviewPrescriptionModal] =
    useState(false);
  useEffect(() => {
    setIsOPPreviewPrescriptionModal(false);
  }, []);
  return (
    <div className="case-sheet-mobile-container">
      <div className="case-sheet-container">
        {isOPPreviewPrescriptionModal && (
          <OPPreviewPrescriptionModal
            setIsOPPreviewPrescriptionModal={setIsOPPreviewPrescriptionModal}
          />
        )}
        <div className="case-sheet-header">
          <div>
            <p>Case Sheet</p>
          </div>
          <div>
            <button>Upload</button>
            <button>Writing Pad</button>
            <button>Email</button>
            <button>Sent to Pharmacy</button>
          </div>
        </div>
        <div className="clinic-doctor-details-container">
          <div className="clinic-doctor-details">
            <p>Dr. Abdul Manaf</p>
            <p>ENT Specialist</p>
            <p>ABC CLinic Makaraparamba</p>
          </div>
          <div className="clinic-doctor-details-print-download">
            {/* <img src={printIcon} alt="print" />
            <img src={downloadIcon} alt="download" /> */}
          </div>
        </div>
        <div className="prescription-container">
          <div className="prescription-patient-details">
            <div>
              <p>#5</p>
              <p>Mr Usman K</p>
              <p>M</p>
              <p>32</p>
            </div>
            <div>
              <div>
                <input type="checkbox" name="followup" />
                <label htmlFor="followup">Follow Up</label>
              </div>
              <div>
                <input type="checkbox" name="revisit" />
                <label htmlFor="revisit">Revisit</label>
              </div>
            </div>
          </div>
          <div className="op-session-prescription">
            <p className="op-session-prescription-title">Prescription</p>
            <div className="op-session-prescription-form">
              <div>
                <input type="text" placeholder="Drug Name" />
                <input type="text" placeholder="Dosage" />
                <input type="text" placeholder="Duration" />
              </div>
              <div>
                <input type="text" placeholder="Drug Name" />
                <input type="text" placeholder="Dosage" />
                <input type="text" placeholder="Duration" />
              </div>
              <div>
                <input type="text" placeholder="Drug Name" />
                <input type="text" placeholder="Dosage" />
                <input type="text" placeholder="Duration" />
              </div>
              <div className="op-session-wizard-container-mobile">
                <div>
                  <button className="op-session-wizard-bulid-button">
                    Build
                  </button>
                  <button
                    onClick={() => {
                      setIsOPPreviewPrescriptionModal(true);
                    }}
                  >
                    Preview
                  </button>
                </div>
                <div>
                  <button>Previous</button>
                  <button className="op-session-wizard-blue-button">1</button>
                  <button>2</button>
                  <button>3</button>
                  <button>4</button>
                  <button>5</button>
                  <button className="op-session-wizard-blue-button">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="op-session-prescription-extra-details-mobile">
            <h5>Details</h5>
            <textarea
              className="op-session-prescription-extra-details-mobile-textarea"
              name=""
              id=""
              cols="30"
              rows="4"
            />
          </div>
          <div className="op-session-prescription-extra-details-mobile">
            <h5>Instructions</h5>
            <textarea
              className="op-session-prescription-special-mobile-textarea"
              name=""
              id=""
              cols="30"
              rows="4"
            />
            <div className="op-sesssion-signature">
              <img src={signature} alt="Signature" />
              <p className="op-sesssion-signature-name">( Dr Abdul manaf )</p>
              <button className="op-session-wizard-blue-button">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseSheetMobile;
