import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { useNavigate, useParams } from 'react-router-dom';

import Alert from '../layout/Alert';
import { BACKEND_URL } from '../../config/api';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [loading, setLoading] = useState(false);

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  useEffect(() => {
    if (token) {
      setLoading(true);
      Axios.post(`${BACKEND_URL}/login/web_admin/verify_email`, { token })
        .then(({ data: resData }) => {
          setLoading(false);
          if (resData && resData.success) {
            showAlert(resData.message, 'success');
          } else {
            showAlert('Verification failed');
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="j-wrapper j-wrapper-400" style={{ paddingTop: '10px' }}>
      {loading ? <h3>Verifying email...</h3> : ''}
      <Alert
        action={() => window.location.replace('/')}
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
    </div>
  );
};

export default VerifyEmail;
