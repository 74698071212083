/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// import Alert from '../layout/Alert'
// import LoadingBox from '../layout/LoadingBox'
import Select from '../form/Select';
import Table from '../layout/Table';
import { useUser } from '@/hooks/useUser';

const consultData = [
  ['01-01-2021', '120', '40', '160', '-'],
  ['02-01-2021', '150', '25', '155', '-'],
  ['03-01-2021', '130', '40', '160', '-'],
  [{ className: 'text-right', colSpan: 3, value: 'Grand Total' }, '475', '-'],
];

const consultHeadings = ['Date', 'OP', 'Walkin', 'Total', 'Payment'];

const smsData = [
  ['01-01-2021', '3', '1', '14', '2', '-', '1', '16', '16', '₹8'],
  ['02-01-2021', '4', '4', '15', '3', '4', '4', '12', '28', '₹14'],
  ['03-01-2021', '3', '-', '17', '3', '7', '-', '11', '32', '₹16'],
  [{ className: 'text-right', colSpan: 8, value: 'Grand Total' }, '475', '-'],
];

const smsHeadings = [
  [
    { rowSpan: 2, value: 'Date' },
    {
      rowSpan: 2,
      value: (
        <>
          New
          <br />
          Reg
        </>
      ),
    },
    {
      rowSpan: 2,
      value: (
        <>
          Profile
          <br />
          Access
          <br />
          OTP
        </>
      ),
    },
    { className: 'text-center', colSpan: 4, value: 'Appointment' },
    {
      rowSpan: 2,
      value: (
        <>
          Consult
          <br />
          Success
        </>
      ),
    },
    { rowSpan: 2, value: 'Total' },
    { rowSpan: 2, value: 'Payments' },
  ],
  ['Confirm', 'Reminder', 'Delay', 'Cancel'],
];

const tableHeadings = [
  [
    'Date',
    'Medical Store',
    'Status',
    <>
      Subscription
      <br />
      <a
        data-toggle="modal"
        data-target="#subscription-modal"
        href="#subscription-modal"
        style={{
          color: '#ffffff99',
          textDecoration: 'online',
        }}
      >
        View Plans
      </a>
    </>,
    <>
      Card
      <br />
      Status
    </>,
    'Contacts',
    'Bookings',
    <>
      Booking
      <br />
      Commission
    </>,
    <>
      SMS
      <br />
      <a
        data-toggle="modal"
        data-target="#sms-modal"
        href="#sms-modal"
        style={{
          color: '#ffffff99',
          textDecoration: 'online',
        }}
      >
        View Plans
      </a>
    </>,
    <>
      Total
      <br />
      Payment
    </>,
    <>
      Online
      <br />
      Paid
    </>,
    'Profit',
    'Payment',
  ],
];

const PaymentsMedicalStores = () => {
  const navigate = useNavigate();
  const user = useUser();
  const user_role = user?.user_role;
  const [chargesData, setChargesData] = useState([]);
  const [chargesHeadings, setChargesHeadings] = useState([]);
  const [chargesInfo, setChargesInfo] = useState({
    date: '',
    title: '',
  });
  // const [showLoading, setShowLoading] = useState(true)
  const [tableData, setTableData] = useState([]);

  // const showAlert = (msg, type) => {
  // 	if (type) setAlertType(type)
  // 	else setAlertType('alert')
  // 	setAlertMsg(msg)
  // 	setAlertShow(true)
  // }

  useEffect(() => {
    setTableData([
      [
        'Jan-2021',
        <Link to="medicalstores/view/2">MedicalStoreID</Link>,
        <div style={{ minWidth: 72 }}>
          <Select
            className="outlined"
            name="status"
            values={[{ name: 'Active', value: 'Active' }]}
            value="Active"
          />
        </div>,
        'SCS Pro',
        'Yes',
        '5',
        '400',
        <a
          data-toggle="modal"
          data-target="#charges-modal"
          href="#consult-charges"
          onClick={() => {
            setChargesData(consultData);
            setChargesHeadings(consultHeadings);
            setChargesInfo({
              date: 'Jan-2021',
              title: 'Consult Charges',
            });
          }}
        >
          ₹431
        </a>,
        <a
          data-toggle="modal"
          data-target="#charges-modal"
          href="#consult-charges"
          onClick={() => {
            setChargesData(smsData);
            setChargesHeadings(smsHeadings);
            setChargesInfo({
              date: 'Jan-2021',
              title: 'SMS Charges',
            });
          }}
        >
          ₹431
        </a>,
        '₹1250',
        '₹250',
        '₹431',
        '₹1000',
      ],
    ]);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row m-b-10">
        <div className="col-md-4 m-b-10">
          <Select
            value="Payment"
            values={[
              { name: 'Payment', value: '' },
              { name: 'All', value: 'All' },
              { name: 'Paid', value: 'Paid' },
              { name: 'Unpaid', value: 'Unpaid' },
            ]}
          />
        </div>
        <div className="col-md-4 m-b-10">
          <Select
            value="Category"
            values={[{ name: 'Category', value: 'Category' }]}
          />
        </div>
        <div className="col-md-4" />
        <div className="col-md-4 m-b-10">
          <input className="form-control" placeholder="Place" />
        </div>
        <div className="col-md-4 m-b-10">
          <input className="form-control" placeholder="Medical Store" />
        </div>
      </div>

      <div className="row m-b-20">
        <div className="col-md-4">
          <div className="hex-user p-10">
            <span className="hex-small-box">Medical Store ID</span>
            <span className="hex-small-box">Medical Store Name</span>
            <span>
              <Link
                className="fa fa-edit"
                style={{ color: 'inherit', fontSize: '18px', margin: '3px' }}
                to="/medicalstores/edit/1/1"
              />
            </span>
            <br />
            <span className="hex-small-box">Area</span>
            <span className="hex-small-box">District</span>
            <span className="hex-small-box">Pincode</span>
            <br />
            <span className="hex-small-box">Owner Name</span>
            <span className="hex-small-box">Contact No</span>
            <br />
            <span className="hex-small-box">Mail ID</span>
          </div>
        </div>
        <div className="col-md-8">
          <div className="hex-user p-10">
            <div className="row">
              <div className="col-md-2">
                <div>
                  <Select
                    className="bg-inverse"
                    name="status"
                    style={{ border: 'none', fontSize: '12px', padding: '6px' }}
                    value="SCS Pro"
                    values={[{ name: 'SCS Pro', value: 'SCS Pro' }]}
                  />
                </div>
                <div>
                  <button
                    className="btn btn-danger btn-sm m-b-10 m-t-10"
                    style={{ display: 'block', padding: '6px', width: '100%' }}
                  >
                    Deactivate
                  </button>
                </div>
                <div>
                  <Select
                    className="bg-default"
                    name="status"
                    style={{ border: 'none', fontSize: '12px', padding: '6px' }}
                    value="Debit Card"
                    values={[{ name: 'Debit Card', value: 'Debit Card' }]}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <h6>Status</h6>
                <Select
                  className="bg-default outlined"
                  name="status"
                  style={{ color: '#000', fontSize: '12px', padding: '6px' }}
                  value="Active"
                  values={[{ name: 'Active', value: 'Active' }]}
                />
              </div>
              <div className="col-md-2">
                <h6>TP Booking</h6>
                <Select
                  className="bg-default outlined"
                  name="status"
                  style={{ color: '#000', fontSize: '12px', padding: '6px' }}
                  value="Active"
                  values={[{ name: 'Active', value: 'Active' }]}
                />
              </div>
              <div className="col-md-2">
                <h6>SMS</h6>
                <form>
                  <input name="sms" type="radio" checked /> OFF
                  <br />
                  <input name="sms" type="radio" /> BASIC
                  <br />
                  <input name="sms" type="radio" /> FULL
                </form>
              </div>
              <div className="col-md-2">
                <h6>Pending Payments</h6>
                <input
                  className="outlined"
                  disabled
                  style={{ width: '100%' }}
                  value="₹100"
                />
                <br />
                <button
                  className="btn btn-info btn-sm m-t-10"
                  style={{ display: 'block', padding: '6px', width: '100%' }}
                >
                  Pay Now
                </button>
              </div>
              <div className="col-md-2">
                <h6>Reminder</h6>
                <Select
                  className="bg-default outlined"
                  name="status"
                  style={{ color: '#000', fontSize: '12px', padding: '6px' }}
                  value="Done"
                  values={[{ name: 'Done', value: 'Done' }]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-b-10">
        <div className="col-md-12 m-b-10">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <div
              className="p-20"
              style={{ display: 'inline-block', width: '350px' }}
            >
              <b>Payment History</b>
            </div>
            <input className="form-control" type="date" />
            <div className="p-20" style={{ display: 'inline-block' }}>
              <b>To</b>
            </div>
            <input className="form-control" type="date" />
            <div className="p-10 m-l-30" style={{ fontSize: '18px' }}>
              <i className="ion-printer" />
            </div>
            <div className="p-10" style={{ fontSize: '18px' }}>
              <i className="ion-ios-cloud-download" />
            </div>
          </div>
        </div>
      </div>

      <div className="m-b-20">
        <span className="m-10">
          <b>Notes</b>
        </span>
        <div
          className="m-10 p-b-10 p-t-10"
          style={{ background: 'white', borderRadius: '6px' }}
        >
          <ol>
            <li>
              The amount will be automatically deducted from credit/debit card
              on the 1st of every month.
            </li>
            <li>
              The subscription will be automatically moved to the next tier when
              the limit is crossed.
            </li>
            <li>
              Failure to pay for two consequtive months may automatically
              deactivate your account.
            </li>
          </ol>
        </div>
      </div>

      <div className="m-b-40 m-t-10">
        <Table data={tableData} headings={tableHeadings} />
        {/* {pageCount > 1 && (
					<div className='card-block hex-pagination'>
						<nav aria-label='Page navigation example'>
							<ul className='pagination'>
								<li className='page-item'>
									<button
										className='page-link'
										onClick={() => {
											multipleCheck((curr - 1) * (data.length / number), false)
											setSelectedMain(false)
											if (curr !== 1) setCurr(curr - 1)
										}}
										aria-label='Previous'
									>
										<span aria-hidden='true'>«</span>
										<span className='sr-only'>Previous</span>
									</button>
								</li>
								{range(pageCount).map(page => (
									<li
										className={curr === page ? 'page-item active' : 'page-item'}
										key={page}
										onClick={() => {
											multipleCheck((curr - 1) * (data.length / number), false)
											setSelectedMain(false)
											setCurr(page)
										}}
									>
										<button className='page-link'>{page}</button>
									</li>
								))}
								<li className='page-item'>
									<button
										className='page-link'
										onClick={() => {
											multipleCheck((curr - 1) * (data.length / number), false)
											setSelectedMain(false)
											if (curr !== pageCount) setCurr(curr + 1)
										}}
										aria-label='Next'
									>
										<span aria-hidden='true'>»</span>
										<span className='sr-only'>Next</span>
									</button>
								</li>
							</ul>
						</nav>
					</div>
				)} */}
      </div>

      {/* <Alert
				msg={alertMsg}
				setShow={setAlertShow}
				show={alertShow}
				type={alertType}
			/> */}
      {/* <LoadingBox show={showLoading} /> */}
      <ChargesModal
        data={chargesData}
        headings={chargesHeadings}
        info={chargesInfo}
      />
      <SMSModal />
      <SubscriptionModal user_role={user_role} />
    </>
  );
};

const ChargesModal = ({ data, headings, info }) => (
  <div
    aria-hidden="true"
    className="modal fade"
    id="charges-modal"
    role="dialog"
    style={{ backgroundColor: '#000000aa' }}
    tabIndex="-1"
  >
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{info.title}</h4>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            style={{
              backgroundColor: '#e74c3c',
              borderRadius: '6px',
              color: '#fff',
              padding: '12px',
            }}
            type="button"
          >
            <span aria-hidden="true">
              <i className="fa fa-close" />
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <b>{info.date}</b>
            <div>
              <span className="p-10 m-l-30" style={{ fontSize: '18px' }}>
                <i className="ion-printer" />
              </span>
              <span className="p-10" style={{ fontSize: '18px' }}>
                <i className="ion-ios-cloud-download" />
              </span>
            </div>
          </div>
          <Table data={data} headings={headings} />
        </div>
      </div>
    </div>
  </div>
);

const SMSModal = () => {
  const data = [
    ['1', 'New Registration', '2', '₹1', <>New patient profile creation.</>],
    [
      '2',
      'OTP',
      '1',
      '₹0.5',
      <>
        Patient profile access OTP to view/Delete other
        <br />
        Medical Store bookings.
      </>,
    ],
  ];

  const headings = ['Sl no', 'Title', 'Length', 'Charges', 'Features'];

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="sms-modal"
      role="dialog"
      style={{ backgroundColor: '#000000aa' }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">SMS Charges</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              style={{
                backgroundColor: '#e74c3c',
                borderRadius: '6px',
                color: '#fff',
                padding: '12px',
              }}
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-6 col-md-3">Compulsory Service SMS</div>
              <div className="col-6 col-md-3">
                <form>
                  <div
                    className="form-radio radio radiofill radio-info radio-inline"
                    style={{ display: 'inline-flex' }}
                  >
                    <label>
                      <input
                        checked
                        name="sms_actvate"
                        type="radio"
                        value="on"
                      />
                      <i className="helper" />
                      <span className="radio-label">On</span>
                    </label>
                  </div>
                  <div
                    className="form-radio radio radiofill radio-info radio-inline"
                    style={{ display: 'inline-flex' }}
                  >
                    <label>
                      <input name="sms_actvate" type="radio" value="off" />
                      <i className="helper" />
                      <span className="radio-label">Off</span>
                    </label>
                  </div>
                </form>
              </div>
            </div>
            <Table data={data} headings={headings} />
          </div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionModal = ({ user_role }) => {
  const subscriptionOptions = [
    { name: 'SCS PRO', value: 'SCS PRO' },
    { name: 'Demo', value: 'Demo' },
    { name: 'Unlimited', value: 'Unlimited' },
    { name: 'Deactivate', value: 'Deactivate' },
  ];

  const validityOptions = [
    { name: ' ', value: ' ' },
    { name: '1 month', value: '1 month' },
    { name: '2 months', value: '2 months' },
    { name: '3 months', value: '3 months' },
    { name: '6 months', value: '6 months' },
    { name: '1 year', value: '1 year' },
  ];

  const data1 = [
    [
      '1',
      'SCS Pro',
      'Package',
      <i className="fa fa-refresh" />,
      '₹1000',
      <>
        Default
        <br />
        Consult upto 1,100 patients per month
        <br />
        without extra charges.
      </>,
    ],
    [
      '2',
      'SCS Pro',
      'Consultations',
      <i className="fa fa-refresh" />,
      '₹1',
      <>
        3 months left (Default ₹2)
        <br />
        Consultation above the Subscription package
        <br />
        limit is accounted.
      </>,
    ],
    [
      '3',
      'SCS Pro',
      'General Booking',
      <i className="fa fa-refresh" />,
      'Nil',
      <>
        General Booking from Medical Store
        <br />
        no extra charges.
      </>,
    ],
    [
      '4',
      'SCS Pro',
      'Priority Booking',
      <i className="fa fa-refresh" />,
      '8%',
      <>
        6 months left (Default 10%)
        <br />
        Priority Booking charges applicable
        <br />
        (Non refundable).
      </>,
    ],
    [
      '5',
      'SCS Pro',
      'TP Booking',
      <i className="fa fa-refresh" />,
      '7%',
      <>
        2 months left (Default 10%)
        <br />
        Third-party Booking charges applicable
        <br />
        only after consultation.
      </>,
    ],
  ];

  const data2 = [
    [
      '1',
      'Demo',
      'Nil',
      <>
        No subscription charges & no tatkal charges.
        <br />
        Demo will be converted to Base plan after 1 month.
      </>,
    ],
    [
      '2',
      'Deactivated',
      'Nil',
      <>
        Consultation & booking will be blocked.
        <br />
        All upcoming appointments will be cancelled.
      </>,
    ],
  ];

  const headings1 = [
    'Sl no',
    'Subscription',
    'Feature',
    'Reset',
    'Payment',
    'Validity & Details',
  ];

  const headings2 = ['Sl no', 'Title', 'Payment', 'Features'];

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="subscription-modal"
      role="dialog"
      style={{ backgroundColor: '#000000aa' }}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Subscription Plan</h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              style={{
                backgroundColor: '#e74c3c',
                borderRadius: '6px',
                color: '#fff',
                padding: '12px',
              }}
              type="button"
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <b>Special Offer</b>: Connect your Credit/Debit card now and enble
              recurring payment to get ₹100 discount every month.
            </p>
            <Table data={data1} headings={headings1} />
            <Table data={data2} headings={headings2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsMedicalStores;
