// import { useLayoutEffect } from 'react';
// import { create } from 'zustand';
import { Helmet } from 'react-helmet';

export const BootstrapStyles = () => {
  return (
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/bower_components/bootstrap/css/bootstrap.min.css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/icon/themify-icons/themify-icons.css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/icon/font-awesome/css/font-awesome.min.css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/icon/ion-icon/css/ionicons.min.css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/pages/j-pro/css/j-pro-modern.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/pages/j-pro/css/j-forms.css"
      />

      <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />

      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/bower_components/jquery.steps/css/jquery.steps.css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/pages/jquery.filer/css/jquery.filer.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/pages/jquery.filer/css/themes/jquery.filer-dragdropbox-theme.css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/linearicons.css"
      />
      <link rel="stylesheet" type="text/css" href="/assets/css/ionicons.css" />
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/jquery.mCustomScrollbar.css"
      />
    </Helmet>
  );
};

// function injectLinksToHead() {
//   const links = `

// 	`;

//   const head = document.getElementById('bootstrap-style');
//   head?.insertAdjacentHTML('afterend', links);
// }

// function removeLinksFromHead() {
//   const head = document.head || document.getElementsByTagName('head')[0];
//   const linksToRemove = head.querySelectorAll(
//     'link[href^="/assets/"], link[href*="j-pro"], link[href*="jquery.filer"], link[href*="mCustomScrollbar"]'
//   );

//   linksToRemove.forEach((link) => link.remove());
// }

// function injectScriptsToHead() {
//   const scripts = `

// 	`;

//   const main = document.getElementById('mainScripts');
//   main?.insertAdjacentHTML('beforebegin', scripts);
// }

// function removeScriptsFromHead() {
//   const body = document.body || document.getElementsByTagName('body')[0];
//   const scriptsToRemove = body.querySelectorAll(
//     'script[src^="/assets/"], script[src*="jquery."], script[src*="filer"], script[src*="wizard"], script[src*="pcoded"], script[src*="demo-12"], script[src*="mCustomScrollbar"], script[src*="mousewheel"]'
//   );

//   scriptsToRemove.forEach((script) => script.remove());
// }

// type BootstrapState = {
//   bootstrapEnabled: boolean;
//   setBootstrapEnabled: (enabled: boolean) => void;
//   isBootstrapInjected: () => boolean;
// };

// const useBootstrapState = create<BootstrapState>((set) => ({
//   bootstrapEnabled: false,
//   setBootstrapEnabled: (enabled: boolean) => set({ bootstrapEnabled: enabled }),
//   isBootstrapInjected: () => {
//     const head = document.head || document.getElementsByTagName('head')[0];
//     const body = document.body || document.getElementsByTagName('body')[0];
//     const bootstrapLink = head.querySelector('link[href*="bootstrap.min.css"]');
//     const bootstrapScript = body.querySelector(
//       'script[src*="bootstrap.min.js"]'
//     );
//     return !!bootstrapLink && !!bootstrapScript;
//   },
// }));

// const useBootstrap = (enabled: boolean) => {
//   const setBootstrapEnabled = useBootstrapState(
//     (state) => state.setBootstrapEnabled
//   );
//   const isBootstrapInjected = useBootstrapState(
//     (state) => state.isBootstrapInjected
//   );

//   useLayoutEffect(() => {
//     if (enabled && !isBootstrapInjected()) {
//       setBootstrapEnabled(enabled);
//       //   injectLinksToHead();
//       //   injectScriptsToHead();
//       const checkjQuery = () => {
//         if (window.jQuery) {
//           // jQuery is loaded, you can execute jQuery-dependent code here
//           console.log('jQuery is loaded');
//         } else {
//           // jQuery is not loaded yet, wait and check again
//           console.log('Jquery not loaded');
//           setTimeout(checkjQuery, 100);
//         }
//       };

//       checkjQuery();
//     } else if (!enabled && isBootstrapInjected()) {
//       setBootstrapEnabled(enabled);
//       //   removeLinksFromHead();
//       //   removeScriptsFromHead();
//     }
//   }, [enabled, isBootstrapInjected]);
// };

// export default useBootstrap;
