function TeethTop({ top, right, bottom, left, center }) {
  return (
    <svg className="my-2" width="35" height="35" viewBox="0 0 200 200">
      <g transform="translate(100,100)" stroke="#000" strokeWidth="1">
        <path
          d="M0 0-70 70A99 99 0 0 1-70-70Z"
          fill={left ? '#706666' : '#fff'}
        />
        <path
          d="M0 0-70-70A99 99 0 0 1 70-70Z"
          fill={top ? '#706666' : '#fff'}
        />
        <path
          d="M0 0 70-70A99 99 0 0 1 70 70Z"
          fill={right ? '#706666' : '#fff'}
        />
        <path
          d="M0 0 70 70A99 99 0 0 1-70 70Z"
          fill={bottom ? '#706666' : '#fff'}
        />
      </g>
      <circle
        cx="100"
        cy="100"
        r="50"
        stroke="#000"
        strokeWidth={1}
        fill={center ? '#706666' : '#fff'}
      />
    </svg>
  );
}

export default TeethTop;
