import React from 'react';
import moment from 'moment';
import useSWR from 'swr';
import { API_ENDPOINT } from '../../config/api';
import { getFetcher } from '../../_helpers/swr';

// walkin_count
// op_count

function ConsultPendingInfo({ provider_id, clinic_id, op = false }) {
  const { data } = useSWR(
    provider_id && clinic_id
      ? `${API_ENDPOINT}/PatientAppointments/get_consult_queue_count?provider_id=${provider_id}&clinic_id=${clinic_id}&date=${moment().format(
          'YYYY-MM-DD'
        )}`
      : null,
    getFetcher,
    {
      revalidateOnReconnect: true,
      revalidateOnFocus: true,
      revalidateIfStale: true,
      revalidateOnMount: true,
      keepPreviousData: true,
    }
  );

  if (!data) return null;

  return (
    <span className="_px-1 !_no-underline _border _border-solid _border-rose-500 _text-rose-800 _rounded-full _aspect-square _bg-rose-50">
      {op ? data?.op_count : data?.walkin_count}
    </span>
  );
}

export default ConsultPendingInfo;
