import React from 'react';
import { Link } from 'react-router-dom';

import Select from '../form/Select';
import Table from '../layout/Table';

const OPStatus = () => (
  <>
    <div className="j-unit row">
      <div className="j-unit m-b-20 m-l-15">
        <Select
          values={[{ name: 'All Doctors', value: 'All Doctors' }]}
          value="All Doctors"
        />
      </div>
    </div>
    <Block />
    <Block />
  </>
);

const Block = () => {
  const data = [
    [
      <>
        <i className="icon-speech" /> Dr Rahul (<Link to="/">signed in</Link>)
      </>,
      'Morning 9-10 AM',
      'Closed',
      <span className="text-danger">Doctor Busy</span>,
    ],
    [
      <>
        Miss Meera <i className="icon-speech" />
      </>,
      'Noon 2-4 PM',
      <>
        In Progress <span style={{ color: 'red' }}>(45/60)</span>
      </>,
      <span className="text-success">Doctor Consulting</span>,
    ],
    [
      <>
        Miss Rana <i className="icon-speech" />
      </>,
      'Evening 4-8 PM',
      <>
        Upcoming <span style={{ color: 'blue' }}>(0/60)</span>
      </>,
      <span className="text-success">Doctor Consulting</span>,
    ],
  ];
  const headings = [
    'Doctor/Assisstants',
    'OP Schedule',
    'OP Status',
    'Doctor Status',
  ];

  return <Table data={data} headings={headings} />;
};

export default OPStatus;
