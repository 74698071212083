import axios from '@/_helpers/axios_interceptor';
import React, { useEffect, useState } from 'react';
import { API_ENDPOINT } from '../../config/api';

// const sh_data = {
//   obj: row,
//   patient: {
//     name: row.person_name,
//     phone: row.phone_no,
//   },
//   title: 'Live OP Link',
//   description: encodeURIComponent(
//     `Dear User,\n\nYour appointment at ${selectedClinic?.clinic_name} is confirmed.\nTo view current status,\n${liveLink}\nYou should report at the reception 10 mins earlier.\n\nRegards,\n${selectedClinic?.clinic_name}`
//   ),
//   description_mob: `Dear User, Your appointment at ${selectedClinic?.clinic_name} is confirmed. To view current status, ${liveLink}. You should report at the reception 10 mins earlier. Regards, ${selectedClinic?.clinic_name}`,
//   link: liveLink,
// };

const ShareLinkModal = ({ data }) => {
  const [copied, setCopied] = useState(false);
  const closeModal = () => {
    window.$('#share-link-modal').modal('hide');
  };

  const copyToClipboard = (prefix = '') => {
    if (copied) return;
    if (navigator.clipboard) {
      navigator.clipboard?.writeText(data.description);
      setCopied(true);
      setTimeout(() => {
        closeModal();
        setCopied(false);
      }, 2500);
    }
  };

  //   const [billedList, setBilledList] = useState([]);

  //   useEffect(() => {
  //     if (!data) return;
  //     if (!data.obj) return;
  //     if (billedList.includes(data.obj.appointment_id)) return;
  //     axios
  //       .post(`${API_ENDPOINT}/billing/link-share`, {
  //         appointment_id: data.obj.appointment_id,
  //       })
  //       .then(() => {
  //         setBilledList([...billedList, data.obj.appointment_id]);
  //       });
  //   }, [data]);

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="share-link-modal"
      role="dialog"
      tabIndex="-1"
    >
      <div className="modal-dialog" role="document">
        {data ? (
          <div className="modal-content col-12">
            <div className="modal-header">
              <h5 className="modal-title">Share Live queue status link</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="ion-android-close" />
              </button>
            </div>
            <div className="modal-body">
              <div
                className="d-flex flex-column mb-3"
                style={{ fontSize: '15px' }}
              >
                <span>
                  Name: <b>{data.patient?.name}</b>
                </span>
                <span>
                  Phone: <b>{data.patient?.phone}</b>
                </span>
              </div>
              <div className="d-flex">
                <div className="">
                  <a
                    href={`https://wa.me/${data.patient?.phone || ''}?text=${
                      data.description
                    }`}
                    className="btn btn-light share-button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/img/icons/whatsapp-icon.svg"
                      width={20}
                      alt="Whatsapp Share icon"
                    />{' '}
                    Whatsapp
                  </a>
                </div>
                <div className="mx-3">
                  <a
                    href={`mailto:?subject=${data.title}&body=${data.description}`}
                    className="btn btn-light share-button"
                    style={{ padding: '5px 25px' }}
                  >
                    <img
                      src="/img/icons/email-icon.svg"
                      width={20}
                      alt="Whatsapp Share icon"
                    />{' '}
                    Email
                  </a>
                </div>
              </div>
            </div>
            <div className="row mx-0 mb-3 shareCopy">
              <input
                className="col-8 ml-3"
                type="url"
                placeholder={data.link}
                id="myInput"
                aria-describedby="inputGroup-sizing-default"
                disabled
              />
              <button
                className="px-3 btn btn-light"
                onClick={() => {
                  copyToClipboard();
                }}
              >
                {copied ? (
                  'Copied'
                ) : (
                  <>
                    Copy MSG <i className="fa fa-clone" />
                  </>
                )}
              </button>
            </div>
          </div>
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default ShareLinkModal;
