import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { BACKEND_URL } from '../config/api';
import TokenService from '../_services/TokenService';

const tokenService = TokenService.getService();

const axios = setupCache(Axios);

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    if (!config?.url?.includes('googleapis.com')) {
      const token = tokenService.getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    if (config.cache && typeof config.cache !== 'boolean') {
      config.cache.ttl = 1000 * 10;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error);
    if (error && error.response) {
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        originalRequest.url === `${BACKEND_URL}/login/web_admin/auth_token`
      ) {
        window.localStorage.clear();
        window.location.replace('/');
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = tokenService.getRefreshToken();
        const res = await axios.post(
          `${BACKEND_URL}/login/web_admin/auth_token`,
          { refresh_token: refreshToken }
        );
        if (res.status === 200) {
          tokenService.setToken(res.data.tokens);
          axios.defaults.headers.common.Authorization = `Bearer ${tokenService.getAccessToken()}`;
          return axios(originalRequest);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
