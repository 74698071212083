import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';
import ErrorPage from './pages/error-page';

import BookingNew from './components/pages/BookingNew';
import BookingProvider from './components/pages/BookingProvider';
import OPCaseSheetMobile from './components/pages/OPSession/OPCaseSheetMobile';
import OPSessionMobileEHR from './components/pages/OPSession/OPSessionMobileEHR';
import CreateAccount from './components/pages/CreateAccount';
import CheckIn from './components/pages/CheckIn';
import ClinicFrontDesk from './components/pages/ClinicFrontDesk';
import ClinicFrontdeskAdd from './components/pages/ClinicFrontdeskAdd';
import ClinicsPage from './components/pages/ClinicsPage';

import ConsultOPStatus from './components/pages/ConsultOPStatus';
import Dashboard from './components/pages/Dashboard';
import HomePage from './components/pages/HomePage';
import Live from './components/pages/Live';
import MedicalStoreAdd from './components/pages/MedicalStoreAdd';
import MedicalStoreClinicConnect from './components/pages/MedicalStoreClinicConnect';
import MedicalStoreView from './components/pages/MedicalStoreView';
import MedicalStoresPage from './components/pages/MedicalStoresPage';

import ConsultEdit from './components/pages/OPSession/ConsultEdit';
import PatientAdd from './components/pages/PatientAdd';
import PatientListBookmarked from './components/pages/PatientListBookmarked';
import PaymentsClinics from './components/pages/PaymentsClinics';
import PaymentsMedicalStores from './components/pages/PaymentsMedicalStores';
import PaymentsPage from './components/pages/PaymentsPage';
import ProviderAdd from './components/pages/ProviderAdd';
import ProvidersPage from './components/pages/ProvidersPage';
import Reset from './components/pages/Reset';
import NotFound from './components/pages/NotFound';
import SettingsPharmacy from './components/pages/SettingsPharmacy';

import SignIn from './components/pages/SignIn';

import ResetPassword from './components/pages/ResetPassword';
import VerifyEmail from './components/pages/VerifyEmail';
import Checkout from './components/pages/Checkout';
import PaymentStatus from './components/pages/PaymentStatus';
import ConsultTransfers from './components/pages/ConsultTransfers';

import DentalChart from './components/pages/DentalChart';
import Layout from './components/layout/Layout';
import PublicLayout from './components/layout/PublicLayout';
import LayoutClean from './components/layout/LayoutClean';
import LayoutFullScreen from './components/layout/LayoutFullScreen';
import PublicCleanLayout from './components/layout/PublicCleanLayout';

export const noNavList = [];

export const authenticatedRoutes: RouteObject[] = [
  { element: <HomePage />, path: '/home' },
  { element: <BookingNew />, path: '/booking/new' },

  { element: <BookingProvider />, path: '/booking/provider' },
  { element: <CheckIn />, path: '/webcheckin' },

  { lazy: () => import('@/components/pages/clinic-add'), path: '/clinic/add' },
  {
    lazy: () => import('@/components/pages/clinic-add'),

    path: '/clinic/edit/:clinic_id',
  },
  {
    element: <ClinicFrontdeskAdd />,

    path: '/clinic/frontdesk/add/:clinic_id',
  },
  {
    element: <ClinicFrontdeskAdd />,

    path: '/clinic/frontdesk/edit/:id/:clinic_id',
  },
  { element: <ClinicFrontDesk />, path: '/clinic/frontdesk/:id' },

  { element: <ClinicsPage />, path: '/clinics' },

  {
    element: <OPCaseSheetMobile />,

    path: '/consult/op-session/case-sheet-mobile',
  },
  {
    element: <OPSessionMobileEHR />,

    path: '/consult/op-session/ehr-mobile',
  },
  {
    element: <ConsultEdit />,

    path: '/consult/edit/:appointment_id',
  },

  {
    element: <ConsultTransfers />,

    path: '/consult/transfers',
  },

  { element: <ConsultOPStatus />, path: '/consult/status' },
  { element: <Dashboard />, path: '/dashboard' },
  { element: <Live />, path: '/lv/:person_no' },
  { element: <MedicalStoresPage />, path: '/medicalstores' },
  { element: <MedicalStoreAdd />, path: '/medicalstores/add' },
  {
    element: <MedicalStoreClinicConnect />,

    path: '/medicalstores/add-for-clinic',
  },
  {
    element: <MedicalStoreClinicConnect />,

    path: '/medicalstores/edit-for-clinic/:medical_store_id',
  },
  {
    element: <MedicalStoreAdd />,

    path: '/medicalstores/edit/:medical_store_id',
  },
  {
    element: <MedicalStoreView />,

    path: '/medicalstores/view/:id',
  },
  { element: <PatientAdd />, path: '/patient/add' },

  {
    element: <PatientListBookmarked />,

    path: '/patient/bookmarked',
  },

  { element: <PaymentsClinics />, path: '/payments/clinics' },
  {
    element: <PaymentsMedicalStores />,

    path: '/payments/medicalstores',
  },
  { element: <PaymentsPage />, path: '/payments' },
  {
    element: <ProviderAdd />,

    path: '/provider/add/:clinic_id/:clinic_name',
  },
  {
    element: <ProviderAdd />,

    path: '/provider/edit/:provider_id',
  },
  { element: <ProviderAdd />, path: '/user/edit' },
  { element: <ProvidersPage />, path: '/providers' },
  { element: <Reset />, path: '/reset/:type/:id' },
  { element: <SettingsPharmacy />, path: '/settings/pharmacy' },

  { element: <Checkout />, path: '/checkout/:invoice_id' },
  { element: <PaymentStatus />, path: '/payment-status' },
  { element: <VerifyEmail />, path: '/verify-email/:token' },
  { element: <NotFound /> },
  {
    path: '/patient/edit/:patient_id',
    lazy: () => import('@/components/pages/patient-edit'),
  },
  {
    path: '/patient/payments',
    lazy: () => import('@/components/pages/patient-payments'),
  },
  {
    lazy: () => import('@/components/pages/reports-followup-list'),
    path: '/reports/followuplist',
  },
  {
    path: '/patient/follow-up',
    lazy: () => import('@/components/pages/patient-follow-up'),
  },
  { path: '/patient', lazy: () => import('@/components/pages/patient-list') },
  {
    path: '/patient/list',
    lazy: () => import('@/components/pages/patient-list'),
  },
  {
    path: '/consult/recentconsultations',
    lazy: () => import('@/components/pages/consult-recent-list'),
  },
  {
    path: '/consult/master-list',
    lazy: () => import('@/components/pages/consult-master-list'),
  },
  {
    path: '/consult/walkins',
    lazy: () => import('@/components/pages/consult-walkin-list'),
  },
  {
    path: '/consult/oplist',
    lazy: () => import('@/components/pages/consult-op-list'),
  },
  {
    path: '/consult/appointments',
    lazy: () => import('@/components/pages/consult-appoinments'),
  },
  {
    path: '/myclinic/:id',
    lazy: () => import('@/components/pages/clinic-view'),
  },
  {
    path: '/clinic/view/:id',
    lazy: () => import('@/components/pages/clinic-view'),
  },
  {
    path: '/consult/op-session',
    lazy: () => import('@/components/pages/OPSession/page'),
  },
  {
    path: '/consult/walk-in',
    lazy: () => import('@/components/pages/WalkIn/page'),
  },
  {
    path: '/booking/availability',
    lazy: () => import('@/components/pages/booking-availability'),
  },
  {
    lazy: () => import('@/components/pages/settings-procedures'),
    path: '/settings/procedures',
  },
  {
    lazy: () => import('@/components/pages/settings-procedures'),
    path: '/settings/setup/procedures',
  },
  {
    lazy: () => import('@/components/pages/settings-procedures'),
    path: '/settings/setup/procedures/:provider_id',
  },
  {
    lazy: () => import('@/components/pages/settings-schedules'),
    path: '/settings/schedules',
  },
  {
    lazy: () => import('@/components/pages/settings-schedules'),
    path: '/settings/setup/schedules',
  },
  {
    lazy: () => import('@/components/pages/settings/medicines'),
    path: '/settings/medicines',
  },
  {
    path: '/booking/patient',
    lazy: () => import('@/components/pages/booking-patient'),
  },
];

export const publicRoutes: RouteObject[] = [
  { element: <CreateAccount />, path: '/createAccount' },
  { element: <Live />, path: '/lv/:person_no' },
  { element: <MedicalStoreAdd />, path: '/medicalstore' },
  { element: <ProviderAdd />, path: '/provider' },
  { element: <SignIn />, path: '/' },
  //   { element: <SignInClinic />, path: '/clinic-login' },
  { element: <ResetPassword />, path: '/reset-password/:token' },
  { element: <VerifyEmail />, path: '/verify-email/:token' },
  { element: <DentalChart />, path: '/dental-chart' },
  { lazy: () => import('@/components/pages/logout'), path: '/logout' },
];

export const publicKioskRoutes: RouteObject[] = [
  { element: <PatientAdd />, path: '/patient/add' },
  {
    path: '/booking/availability',
    lazy: () => import('@/components/pages/booking-availability'),
  },
  { element: <BookingNew />, path: '/booking/new' },
  {
    path: '/booking/patient',
    lazy: () => import('@/components/pages/booking-patient'),
  },
  {
    path: '/consult/oplist',
    lazy: () => import('@/components/pages/consult-op-list'),
  },
];

const cleanAuthenticatedRoutes: RouteObject[] = [
  {
    path: '/settings',
    lazy: () => import('@/components/pages/settings'),
  },
  {
    path: '/settings/rules',
    lazy: () => import('@/components/pages/settings/rules'),
  },
  {
    path: '/reports',
    lazy: () => import('@/components/pages/reports'),
  },
  {
    path: '/test',
    lazy: () => import('@/components/pages/test'),
  },
  {
    path: '/booking',
    lazy: () => import('@/components/pages/booking'),
  },
  { path: '/consult', lazy: () => import('@/components/pages/consult') },
  {
    path: '/settings/registration_fields',
    lazy: () => import('@/components/pages/settings/registration-fields'),
  },
  {
    path: '/settings/labs',
    lazy: () => import('@/components/pages/settings/labs'),
  },
  {
    path: '/settings/labs/:labId',
    lazy: () => import('@/components/pages/settings/labs-tests'),
  },
  {
    path: '/lab',
    lazy: () => import('@/components/pages/lab'),
  },
  {
    path: '/lab/:appoinmentId',
    lazy: () => import('@/components/pages/lab/tests'),
  },
];

const publicFullscreenRoutes: RouteObject[] = [
  {
    path: '/lab/public/:labId',
    lazy: () => import('@/components/pages/lab/public-display'),
  },
  {
    path: '/lab/public2/:labId',
    lazy: () => import('@/components/pages/lab/public-display2'),
  },
  {
    path: '/lab/token/:labId',
    lazy: () => import('@/components/pages/lab/token'),
  },
  {
    path: '/lab/lv/:appoinmentId',
    lazy: () => import('@/components/pages/lab/public-single-test'),
  },
];

const publicCleanRoutes: RouteObject[] = [
  {
    path: '/clinic',
    lazy: () => import('@/components/pages/clinic-add-or-edit'),
  },
  {
    path: '/clinic-login',
    lazy: () => import('@/components/pages/clinic-login'),
  },
  {
    lazy: () => import('@/components/pages/provider-login'),
    path: '/provider-login',
  },
  {
    lazy: () => import('@/components/pages/provider-add'),
    path: '/provider-new',
  },
  {
    lazy: () => import('@/components/pages/pharmacy-login'),
    path: '/pharmacy',
  },
  {
    path: '/forgot-password/:userType',
    lazy: () => import('@/components/pages/reset-password'),
  },
];

function KioskLayout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

const router = createBrowserRouter([
  {
    element: <PublicLayout />,
    errorElement: <ErrorPage />,
    children: publicRoutes,
  },
  {
    element: <PublicCleanLayout />,
    errorElement: <ErrorPage />,
    children: publicCleanRoutes,
  },
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: authenticatedRoutes,
  },
  {
    element: <LayoutClean />,
    errorElement: <ErrorPage />,
    children: cleanAuthenticatedRoutes,
  },
  {
    element: <KioskLayout />,
    errorElement: <ErrorPage />,
    children: publicKioskRoutes,
  },
  {
    element: <LayoutFullScreen />,
    errorElement: <ErrorPage />,
    children: publicFullscreenRoutes,
  },
]);

function AllRoutes() {
  return <RouterProvider router={router} />;
}

export default AllRoutes;
