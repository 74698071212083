import { useEffect, useState } from 'react';
import TreatmentsTable from './TreatmentsTable';
import { useDentalChart } from './DentalChartContext';
import { TREATMENT_PERIOD } from './constants';

const TreatmentPlanTab = () => {
  const { setTreatmentAddType } = useDentalChart();
  const [data, setData] = useState(null);

  useEffect(() => {
    getPlan();
  }, []);

  const getPlan = async () => {
    const res = await fetch('/assets/images/dental/patient-plan.json');
    const data = await res.json();
    setData(data);
  };

  const handleAdd = () => {
    setTreatmentAddType(TREATMENT_PERIOD.plan);
    document.querySelector('#add-tab').click();
  };
  return (
    <>
      <TreatmentsTable data={data} type="plan" />
      <div className="d-flex justify-content-center my-3">
        <button className="btn btn-primary" onClick={handleAdd}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-plus-lg mr-2"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
            />
          </svg>
          Add Plan
        </button>
      </div>
    </>
  );
};

export default TreatmentPlanTab;
