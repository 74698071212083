/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { Link } from 'react-router-dom';

import moment from 'moment';
import Alert from '../layout/Alert';
import DeleteConfirm from '../layout/DeleteConfirm';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import Table from '../layout/Table';
import { API_ENDPOINT } from '../../config/api';
import { usePageDetails } from '@/hooks/useUser';

const headings = [
  'UCN',
  'Name',
  'Created On',
  'Owner',
  'Phone',
  'Email',
  'Status',
  'KYC',
  'Delete',
];

const status = [
  { name: 'Active', value: true },
  { name: 'Inactive', value: false },
];

const IconButton = ({ icon, onClick, url, ...props }) =>
  url ? (
    <Link to={url} {...props}>
      <i className={`fa fa-${icon} hex-icon`} />
    </Link>
  ) : (
    <i className={`fa fa-${icon} hex-icon`} onClick={onClick} {...props} />
  );

const ClinicsPage = () => {
  const {
    preferences,
    user_data,
    clinic_data,
    doctors,
    schedules,
    procedures,
    navigate,
    pathname,
    user,
  } = usePageDetails();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [data, setData] = useState([]);
  const [deleteID, setDeleteID] = useState(null);
  const [filters, setFilters] = useState({
    city: '',
    clinic_type: '',
    search: '',
  });
  const [showLoading, setShowLoading] = useState(true);

  const deleteClinic = () => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Clinics/web_admin/delete_clinic`, {
      clinic_id: deleteID,
      delete_ind: true,
      modified_by: 'any name',
    })
      .then((res) => {
        if (res.data.message === 'deleted successfully') {
          downloadData();
          showAlert(res.data.message, 'success');
        } else showAlert(res.data.message);
      })
      .catch((err) => showAlert('Delete failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const downloadData = () =>
    Axios.get(`${API_ENDPOINT}/Clinics/web_admin/clinic_list`)
      .then(({ data: res }) => {
        setData(res.map((row) => ({ ...row, display: true })));
        setShowLoading(false);
      })
      .catch((err) => showAlert('Failed to load info', 'error'))
      .finally(() => setShowLoading(false));

  const filter = () => {
    const copy = data.map((row) => {
      if (
        (filters.city === '' || row.city === filters.city) &&
        (filters.clinic_type === '' ||
          row.clinic_type === filters.clinic_type) &&
        (filters.search === '' || searchRow(row))
      )
        return { ...row, display: true };
      return { ...row, display: false };
    });
    setData(copy);
  };

  const filterChangeHandler = ({ target: { name, value } }) =>
    setFilters({ ...filters, [name]: value });

  const toggleKycStatus = (clinic_id, kyc_verified) => {
    if (!clinic_id) return;
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Clinics/web_admin/toggle_kyc_status`, {
      clinic_id,
      kyc_verified,
      modified_by: user_data.user_no,
    })
      .then((res) => {
        showAlert('KYC status changed successfully', 'success');
        downloadData();
      })
      .catch((err) => showAlert("KYC status couldn't change", 'error'))
      .finally(() => setShowLoading(false));
  };

  const filterData = (name) =>
    data
      .map((row) => row[name])
      .reduce((col, item) => (col.includes(item) ? col : [...col, item]), [])
      .map((item) => ({ name: item, value: item }));

  const loadData = (rows) =>
    rows
      .filter((row) => row.display)
      .map((row) => [
        <Link to={`clinic/view/${row.clinic_id}`}>{row.clinic_no}</Link>,
        <span title={row.clinic_name}>{`${row.clinic_name.substring(
          0,
          12
        )}...`}</span>,
        moment(row.created_time).format('DD-MM-YY'),
        <span
          title={`${row.first_name} ${row.last_name}`}
        >{`${row.first_name.substring(0, 12)}...`}</span>,
        row.phone_no,
        row.email_id,
        // <Link to={'/reset/clinic/' + row.staff_id}>Reset</Link>,
        <div style={{ minWidth: 72 }}>
          <Select
            className="outlined"
            name="status"
            onChange={() =>
              statusSelectHandler(row.clinic_id, row.clinic_no, row.is_active)
            }
            value={row.is_active}
            values={status}
          />
        </div>,
        <div className="checkbox-fade fade-in-primary">
          <label>
            <input
              checked={row.is_kyc_verified}
              name="kyc_input"
              onChange={() =>
                toggleKycStatus(row.clinic_id, !row.is_kyc_verified)
              }
              type="checkbox"
              defaultValue={row.is_kyc_verified}
            />
            <span className="cr">
              <i className="cr-icon ion-checkmark txt-primary" />
            </span>
          </label>
        </div>,
        // <IconButton
        // 	icon='edit'
        // 	url={`clinic/edit/${row.clinic_id}/${row.staff_id}`}
        // />,
        <IconButton icon="trash" onClick={() => setDeleteID(row.clinic_id)} />,
      ]);

  const searchRow = (row) => {
    const keys = Object.keys(row);
    for (const i in keys) {
      const key = keys[i];
      const value = typeof row[key] === 'string' ? row[key] : `${row[key]}`;
      if (value.toLowerCase().includes(filters.search.toLowerCase()))
        return true;
    }
    return false;
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const statusSelectHandler = (clinic_id, clinic_no, status) => {
    setShowLoading(true);
    Axios.post(`${API_ENDPOINT}/Clinics/web_admin/is_active_status`, {
      clinic_id,
      clinic_no,
      is_active: !status,
      modified_by: 'any name',
    })
      .then((res) => {
        showAlert(res.data.message, 'success');
        downloadData();
      })
      .catch((err) => showAlert('Status change failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    downloadData();
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    filter();
    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <div className="form-group row" style={{ alignItems: 'center' }}>
        <div className="col-6 col-md-3">
          <Select
            autoFocus
            name="city"
            onChange={filterChangeHandler}
            placeholder="City"
            tabIndex={1}
            value={filters.city}
            values={filterData('city')}
          />
        </div>
        <div className="col-6 col-md-3">
          <Select
            name="clinic_type"
            onChange={filterChangeHandler}
            placeholder="Type"
            tabIndex={2}
            value={filters.clinic_type}
            values={filterData('clinic_type')}
          />
        </div>
        <div className="col-12 col-md-6 m-b-10 m-t-10">
          <input
            className="form-control"
            name="search"
            onChange={filterChangeHandler}
            placeholder="Search"
            tabIndex={3}
            type="text"
            value={filters.search}
          />
        </div>
        <div className="col-12">
          <Table data={loadData(data)} headings={headings} number={10} />
        </div>
        <div className="fab" title="Add Clinic">
          <Link
            className="btn btn-inverse btn-icon"
            tabIndex={4}
            to="/clinic/add"
          >
            <i className="ion-plus-round" />
          </Link>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <DeleteConfirm onConfirm={deleteClinic} />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default ClinicsPage;
