import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import Axios from '@/_helpers/axios_interceptor';
import moment from 'moment';

import Select from '../form/Select';
import LoadingBox from '../layout/LoadingBox';
import { API_ENDPOINT } from '../../config/api';
import showCurrency from '../common/showCurrency';
import { calculateStatitcs } from '../../_helpers/get-payable-bill-amount';
import { group } from '../../_helpers/misc';
import classNames from 'classnames';
import { usePageDetails } from '@/hooks/useUser';

const initTotalCounts = () => ({
  clinics: 0,
  appointments: 0,
  patients: 0,
});

const initTodayTotalCounts = () => ({
  patients: 0,
  appointments: 0,
  tatkalBookings: 0,
  tpBookings: 0,
  onlinePayments: 0,
  collection: 0,
  revisits: 0,
  cancellations: 0,
});

const getSum = (array, key) =>
  array?.reduce((a, b) => Number(a) + Number(b[key] || 0), 0) || 0;

const groupBy = (objectArray, property) =>
  objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

const today = moment().format('YYYY-MM-DD');

const Dashboard = () => {
  const {
    preferences,
    user_data,
    clinic_data,
    doctors,
    schedules,
    procedures,
    navigate,
    pathname,
    user,
  } = usePageDetails();
  const clinic_list =
    clinic_data && clinic_data.length
      ? clinic_data.map((item) => ({
          name: item.clinic_name,
          value: item.clinic_id,
        }))
      : [];
  clinic_list.unshift({ name: 'All Clinics', value: '' });

  const isProvider = user_data.user_role === 'provider';
  const isClinic =
    user_data.user_type === 'clinic_owner' ||
    user_data.user_type === 'clinic_staff';

  const [showLoading, setShowLoading] = useState(false);
  const [totalCounts, setTotalCounts] = useState(initTotalCounts);
  const [selectedClinic, setSelectedClinic] = useState(undefined);

  const getTotalCounts = () => {
    setShowLoading(true);
    let queryParams = '';
    if (isClinic) {
      queryParams = `clinic_id=${user_data.clinic_id}`;
    } else if (isProvider) {
      queryParams = selectedClinic
        ? `provider_id=${user_data.user_id}&clinic_id=${selectedClinic}`
        : `provider_id=${user_data.user_id}`;
    } else {
      queryParams = selectedClinic ? `clinic_id=${selectedClinic}` : '';
    }

    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/get_total_counts_for_dashboard?${queryParams}`
    )
      .then(({ data: resData }) => {
        setShowLoading(false);
        setTotalCounts({
          ...totalCounts,
          appointments: resData.total_appointments || 0,
          patients: resData.total_patients || 0,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setShowLoading(false));
  };

  const changeHandler = ({ target: { value } }) => {
    setSelectedClinic(value);
  };

  useEffect(() => {
    getTotalCounts();
    loadAllSchedules();
  }, [selectedClinic]);

  const [statitics, setStatitics] = useState();

  const loadAllSchedules = async () => {
    const { data } = await Axios.get(
      `${API_ENDPOINT}/ClinicProviderSchedule/get_recent_doctors_n_schedules?clinic_id=${
        selectedClinic || preferences?.selectedClinicId
      }&pref_date=${today}`,
      {
        id: 'get-recent-dr-schedules',
      }
    );

    console.log({
      data,
    });
    const _doctors = data?.doctors || [];
    const schdlList =
      data.schedules
        ?.filter((k) =>
          isProvider ? k.provider_id === user_data.user_id : true
        )
        .map((schdl) => ({
          ...schdl,
          name: `${moment(`${today} ${schdl.slot_start}`).format(
            'hh:mm A'
          )} to ${moment(`${today} ${schdl.slot_end}`).format('hh:mm A')}`,
          value: schdl.schedule_id,
        })) || [];
    const appoinments = [];
    const promises = schdlList.map(async (schdl) => {
      const { data: resData } = await Axios.get(
        `${API_ENDPOINT}/PatientAppointments/load_bookings_for_recent_list?schedule_id=${schdl.schedule_id}&appointment_date=${today}`
      );
      appoinments.push(...resData);
      return true;
    });
    await Promise.all(promises);
    const res = group(appoinments, (app) => app.provider_id);
    const st = Object.keys(res).map((key) => {
      const stats = calculateStatitcs(res[key]);

      const doc = _doctors.find((d) => d.provider_id === Number(key));
      return {
        providerId: key,
        name: doc?.name || doc?.rotation_name,
        ...stats,
      };
    });

    setStatitics(st);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-xl-4">
          {isProvider && (
            <Select
              className="outlined m-b-20"
              value="All Clinics"
              onChange={changeHandler}
              values={clinic_list}
            />
          )}
          <div className="card table-card widget-primary-card">
            <div className="row-table">
              <div className="col-sm-3 card-block-big">
                <i className="ti ti-calendar" />
              </div>
              <div className="col-sm-9" onClick={loadAllSchedules}>
                <h4>{totalCounts.appointments}</h4>
                <h6>Appointments</h6>
              </div>
            </div>
          </div>
          <div className="card table-card widget-success-card">
            <div className="row-table">
              <div className="col-sm-3 card-block-big">
                <i className="ti ti-user" />
              </div>
              <div className="col-sm-9">
                <h4>{totalCounts.patients}</h4>
                <h6>Patients</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-xl-8">
          <TodaysTotalsSection
            user_data={user_data}
            selectedClinic={selectedClinic}
            statitics={statitics}
          />
        </div>
      </div>
      <div
        className="hex-mob-hide"
        style={{ background: '#fff', marginBottom: '40px' }}
      >
        <div className="p-l-20 p-r-20 p-t-40 row">
          <ChartSection user_data={user_data} selectedClinic={selectedClinic} />
          <PatientVsRevenueSummary
            user_data={user_data}
            selectedClinic={selectedClinic}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 m-b-40">
          <BookingSummary statitics={statitics} />
        </div>
        <div className="col-md-5 m-b-40">
          <RevenueSummary
            user_data={user_data}
            selectedClinic={selectedClinic}
          />
        </div>
      </div>
      <LoadingBox show={showLoading} />
    </>
  );
};

const TodaysTotalsSection = ({ user_data, selectedClinic, statitics }) => {
  const [todayTotalCounts, setTodayTotalCounts] =
    useState(initTodayTotalCounts);

  const getTodayTotalCounts = () => {
    let queryParams = '';
    if (
      user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff'
    ) {
      queryParams = `clinic_id=${user_data.clinic_id}`;
    } else if (user_data.user_role === 'provider') {
      queryParams = selectedClinic
        ? `provider_id=${user_data.user_id}&clinic_id=${selectedClinic}`
        : `provider_id=${user_data.user_id}`;
    } else {
      queryParams = selectedClinic ? `clinic_id=${selectedClinic}` : '';
    }

    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/get_today_counts_for_dashboard?${queryParams}`
    )
      .then(({ data: resData }) => {
        setTodayTotalCounts({
          ...todayTotalCounts,
          patients: resData.total_patients || 0,
          appointments: resData.total_appointments || 0,
          tatkalBookings: resData.total_tatkal || 0,
          tpBookings: resData.total_tp_bookings || 0,
          onlinePayments: resData.total_online_payments || 0,
          collection: resData.total_collection || 0,
          revisits: resData.total_revisits || 0,
          cancellations: resData.total_cancelled_bookings || 0,
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTodayTotalCounts();
  }, []);

  const cummulatedStats = statitics?.reduce(
    (acc, curr) => {
      acc.opCount += curr.opCount;
      acc.walkinCount += curr.walkinCount;
      acc.rxPaid += curr.rxPaid;
      acc.pxPaid += curr.pxPaid;
      acc.due += curr.due;
      acc.totalCount += curr.totalCount;
      acc.partialPaid += curr.partialPaid;
      acc.partialDue += curr.partialDue;
      return acc;
    },
    {
      due: 0,
      dueCount: 0,
      opCount: 0,
      paidAmount: 0,
      paidCount: 0,
      partialDue: 0,
      partialPaid: 0,
      partialPaidCount: 0,
      pxPaid: 0,
      pxPaidCount: 0,
      rxOnlyAmount: 0,
      rxPaid: 0,
      rxPaidCount: 0,
      totalCount: 0,
      txOnlyAmount: 0,
      walkinCount: 0,
    }
  );

  return (
    <div className="row">
      <div className="col-12">
        <div className="bg-inverse m-b-10 p-10 text-center">
          <h4 style={{ margin: 0 }}>Today</h4>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card table-card">
          <div className="row-table">
            <div className="col-sm-6 card-block-big br text-center">
              <h4>{todayTotalCounts.appointments}</h4>
              <span>Appointments</span>
            </div>
            <div className="col-sm-6 card-block-big text-center">
              <h4>
                {showCurrency()}
                {cummulatedStats?.partialPaid}
              </h4>
              <span>Part Payment</span>
            </div>
          </div>
          <div className="row-table">
            <div className="col-sm-6 card-block-big br text-center">
              <h4>{todayTotalCounts.revisits}</h4>
              <span>Revisits</span>
            </div>
            <div className="col-sm-6 card-block-big text-center">
              <h4>
                {showCurrency()}
                {cummulatedStats
                  ? cummulatedStats.due + cummulatedStats.partialDue
                  : 0}
              </h4>
              <span>Due</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card table-card">
          <div className="row-table">
            <div className="col-sm-6 card-block-big br text-center">
              <h4>{cummulatedStats?.totalCount}</h4>
              <span>Patients</span>
            </div>
            <div className="col-sm-6 card-block-big text-center">
              <h4>
                {showCurrency()}
                {/* {todayTotalCounts.collection} */}
                {cummulatedStats &&
                  cummulatedStats.rxPaid +
                    cummulatedStats.pxPaid +
                    cummulatedStats.partialPaid}
              </h4>
              <span>Collection</span>
            </div>
          </div>
          <div className="row-table">
            <div className="col-sm-6 card-block-big text-center">
              <h4>
                {showCurrency()}
                {cummulatedStats?.pxPaid}
              </h4>
              <span>Px Total</span>
            </div>
            <div className="col-sm-6 card-block-big text-center">
              <h4>
                {showCurrency()}
                {cummulatedStats?.rxPaid}
              </h4>
              <span>Rx Total</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChartSection = ({ user_data, selectedClinic }) => {
  const [chartData, setChartData] = useState([]);

  const getChartData = (filter_name) => {
    let queryParams = '';
    if (
      user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff'
    ) {
      queryParams = `&clinic_id=${user_data.clinic_id}`;
    } else if (user_data.user_role === 'provider') {
      queryParams = selectedClinic
        ? `&provider_id=${user_data.user_id}&clinic_id=${selectedClinic}`
        : `&provider_id=${user_data.user_id}`;
    } else {
      queryParams = selectedClinic ? `&clinic_id=${selectedClinic}` : '';
    }

    // filter_by => week, month, year
    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/get_chart_data_for_dashboard?filter_by=${filter_name}${queryParams}`
    )
      .then(({ data: resData }) => {
        setChartData(resData && resData.length ? resData : []);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getChartData('week');
  }, []);

  return (
    <div className="col-md-9 m-b-40">
      <button
        className="btn btn-primary m-r-10"
        onClick={() => getChartData('week')}
      >
        Week
      </button>
      <button
        className="btn btn-primary m-r-10"
        onClick={() => getChartData('month')}
      >
        Month
      </button>
      <button className="btn btn-primary" onClick={() => getChartData('year')}>
        Year
      </button>
      <div className="m-t-30" style={{ height: '500px', minWidth: '700px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" tickLine={false} />
            <YAxis yAxisId="right" orientation="right" style={{ opacity: 0 }} />
            <Tooltip />
            <Legend />
            <Area
              yAxisId="left"
              type="monotone"
              dataKey="revenue"
              stroke="#2edae0"
              fill="#2edae0"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
            <Area
              yAxisId="right"
              type="monotone"
              dataKey="booking"
              stroke="#f3c1b3"
              fill="#f3c1b3"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const PatientVsRevenueSummary = ({ user_data, selectedClinic }) => {
  const [revenueData, setRevenueData] = useState([]);
  const [current, setCurrent] = useState('day');

  const getPatRevenueData = (filter_name) => {
    setCurrent(filter_name);
    let queryParams = '';
    if (
      user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff'
    ) {
      queryParams = `&clinic_id=${user_data.clinic_id}`;
    } else if (user_data.user_role === 'provider') {
      queryParams = selectedClinic
        ? `&provider_id=${user_data.user_id}&clinic_id=${selectedClinic}`
        : `&provider_id=${user_data.user_id}`;
    } else {
      queryParams = selectedClinic ? `&clinic_id=${selectedClinic}` : '';
    }
    // filter_by => day, month, quarter
    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/get_pat_revenue_summary_for_dashboard?filter_by=${filter_name}${queryParams}`
    )
      .then(({ data: resData }) => {
        setRevenueData(resData && resData.length ? resData : []);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPatRevenueData('day');
  }, []);

  return (
    <div className="col-md-3 m-b-40">
      <div
        className="bg-inverse card group-widget m-b-10 text-center _overflow-hidden"
        style={{ padding: '8px 8px 0px 8px' }}
      >
        <h3 className="m-b-20">Summary</h3>
        <div className="row">
          <span
            onClick={() => getPatRevenueData('day')}
            className={classNames('col-4 hover:_bg-sky-700 _py-2', {
              '_bg-sky-800': current === 'day',
            })}
            style={{ cursor: 'pointer' }}
          >
            Day
          </span>
          <span
            onClick={() => getPatRevenueData('month')}
            className={classNames('col-4 hover:_bg-sky-700 _py-2', {
              '_bg-sky-800': current === 'month',
            })}
            style={{
              borderLeft: '2px solid #fff',
              borderRight: '2px solid #fff',
              cursor: 'pointer',
            }}
          >
            Month
          </span>
          <span
            onClick={() => getPatRevenueData('quarter')}
            className={classNames('col-4 hover:_bg-sky-700 _py-2', {
              '_bg-sky-800': current === 'quarter',
            })}
            style={{ cursor: 'pointer' }}
          >
            Quarter
          </span>
        </div>
      </div>
      {revenueData.map((item, index) => (
        <div
          className="bg-info card group-widget m-b-10 p-10 text-center"
          key={index}
        >
          <div>{moment(item.name, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
          <div className="row">
            <div className="col-6">
              <h6 className="m-t-10">{item.patients} Patients</h6>
            </div>
            <div className="col-6" style={{ borderLeft: '2px solid #fff' }}>
              <h6 className="m-t-10">
                {showCurrency()}
                {item.revenue}
              </h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const BookingSummary = ({ statitics }) => {
  const bookingsHeadings = [
    'Slno',
    'Doctor',
    'OP',
    'WK',
    'Rx',
    'Px',
    'Part Pay',
    'Total',
    'Due',
  ];

  const bookingSummaryData =
    statitics?.map((st, i) => [
      st.name,
      st.opCount,
      st.walkinCount,
      `${showCurrency()} ${st.rxPaid}`,
      `${showCurrency()} ${st.pxPaid}`,
      `${showCurrency()} ${st.partialPaid}`,
      `${showCurrency()} ${st.paidAmount + st.partialPaid}`,
      `${showCurrency()} ${st.due + st.partialDue}`,
    ]) || [];

  bookingSummaryData.push([
    'Total',
    getSum(statitics, 'opCount'),
    getSum(statitics, 'walkinCount'),
    '₹' + getSum(statitics, 'rxPaid'),
    '₹' + getSum(statitics, 'pxPaid'),
    '₹' + getSum(statitics, 'partialPaid'),
    '₹' +
      (Number(getSum(statitics, 'paidAmount')) +
        Number(getSum(statitics, 'partialPaid'))),
    '₹' + (getSum(statitics, 'due') + getSum(statitics, 'partialDue')),
  ]);

  return (
    <>
      <div
        className="bg-info p-10 _flex _items-center _justify-center"
        style={{
          fontSize: '1.2rem',
        }}
      >
        <div className="_font-bold">Consulted Patients</div>
      </div>
      <div className="p-10" style={{ background: '#fff', color: '#000' }}>
        <DashboardTable data={bookingSummaryData} headings={bookingsHeadings} />
      </div>
    </>
  );
};

const RevenueSummary = ({ user_data, selectedClinic }) => {
  const revenueHeadings = ['Slno', 'Clinic', 'Bookings', 'Revenue'];
  const [revenueSummaryData, setRevenueSummaryData] = useState([]);
  const [revenueTotal, setRevenueTotal] = useState({
    name: 'Total',
    total_bookings: 0,
    total_revenue: `$0`,
  });

  const getRevenueSummary = (_date) => {
    let queryParams = '';
    if (
      user_data.user_type === 'clinic_owner' ||
      user_data.user_type === 'clinic_staff'
    ) {
      queryParams = `&clinic_id=${user_data.clinic_id}`;
    } else if (user_data.user_role === 'provider') {
      queryParams = selectedClinic
        ? `&provider_id=${user_data.user_id}&clinic_id=${selectedClinic}`
        : `&provider_id=${user_data.user_id}`;
    } else {
      queryParams = selectedClinic ? `&clinic_id=${selectedClinic}` : '';
    }

    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/get_revenue_summary_for_dashboard?date=${_date}${queryParams}`
    )
      .then(({ data: resData }) => {
        const lastRow = {
          name: 'Total',
          total_bookings: getSum(resData, 'total_bookings'),
          total_revenue: `${getSum(resData, 'total_revenue')}`,
        };
        setRevenueTotal(lastRow);
        if (
          user_data.user_type === 'clinic_owner' ||
          user_data.user_type === 'clinic_staff'
        ) {
          setRevenueSummaryData(groupBy(resData, 'speciality_name'));
        } else {
          setRevenueSummaryData([
            ...resData.map((e) => ({
              ...e,
              total_revenue: `${showCurrency()} ${e.total_revenue || 0}`,
            })),
            lastRow,
          ]);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRevenueSummary(moment().format('YYYY-MM-DD'));
  }, []);

  return (
    <>
      <div
        className="bg-info p-10 _flex _items-center _justify-center"
        style={{
          fontSize: '1.2rem',
        }}
      >
        <div className="_font-bold">Revenue</div>
      </div>
      <div className="p-10" style={{ background: '#fff', color: '#000' }}>
        {user_data.user_type === 'clinic_owner' ||
        user_data.user_type === 'clinic_staff' ? (
          <ClinicRevenueTable
            data={revenueSummaryData}
            headings={revenueHeadings}
            total={revenueTotal}
          />
        ) : (
          <DashboardTable
            data={revenueSummaryData}
            headings={revenueHeadings}
          />
        )}
      </div>
    </>
  );
};

const ClinicRevenueTable = ({ data, headings, total }) => (
  <div className="card-block table-border-style m-b-10 m-t-10">
    <div className="table-responsive">
      {data && Object.keys(data).length
        ? Object.keys(data).map((department, idx) => (
            <table className="table table table-striped nowrap" key={idx}>
              <thead>
                <tr>
                  <th>Slno</th>
                  <th>{department}</th>
                  <th>
                    {showCurrency()} {getSum(data[department], 'total_revenue')}
                  </th>
                </tr>
              </thead>
              {data[department].length &&
                data[department].map((depData, idx2) => (
                  <tbody key={idx2}>
                    <tr>
                      <td className="_w-[130px]">
                        {idx2 === data.length - 1 ? '' : idx2 + 1}
                      </td>
                      <td className="_w-[400px]">
                        {depData.name} ({depData.total_bookings})
                      </td>
                      <td>
                        {showCurrency()}
                        {depData.total_revenue || 0}
                      </td>
                    </tr>
                    {(!depData || depData.length === 0) && (
                      <tr>
                        <td colSpan={headings.length} className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                ))}
            </table>
          ))
        : ''}
      {(!data || Object.keys(data).length === 0) && (
        <div className="text-center">No data available</div>
      )}
      <table className="table table table-striped nowrap">
        <tr>
          <td className="_w-[130px]"></td>
          <td className="_w-[400px] _font-bold">
            Total({total.total_bookings})
          </td>
          <td className="_font-bold">
            {' '}
            {showCurrency()}
            {total.total_revenue}
          </td>
        </tr>
      </table>
    </div>
  </div>
);

const DashboardTable = ({ data, headings }) => (
  <div className="card-block table-border-style m-b-10 m-t-10">
    <div className="table-responsive">
      <table className="table table table-striped nowrap">
        <thead>
          <tr>
            {headings.map((heading, index) => (
              <th key={index}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className={index === data.length - 1 ? 'hex-total-row' : ''}
            >
              <td>{index === data.length - 1 ? '' : index + 1}</td>
              {row && Object.keys(row).length
                ? Object.keys(row)
                    .sort()
                    .map((key, idx) => <td key={idx}>{row[key]}</td>)
                : ''}
            </tr>
          ))}

          {!data ||
            (data.length === 0 && (
              <tr>
                <td colSpan={headings.length} className="text-center">
                  No data available
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default Dashboard;
