/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import validate from 'validate.js';

const closestParent = (child, className) => {
  if (!child || child === document) return null;
  if (child.classList.contains(className)) return child;
  return closestParent(child.parentNode, className);
};

const FormWizard = ({
  constraints,
  children,
  finishBtnName = 'Finish',
  headers,
  onSubmit = showSuccess,
  onCancel,
  isEditMode,
  ...args
}) => {
  const [pageNo, setPageNo] = useState(0);
  const [visitedPages, setVisitedPages] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
  });

  const form = useRef(null);

  const formConstraints = constraints.reduce(
    (item, elem) => ({ ...item, ...elem }),
    {}
  );

  const handleFormSubmit = (ev) => {
    ev.preventDefault();
    const errors = validate(form.current, formConstraints);
    if (errors) {
      const fieldName = Object.keys(errors)[0];
      let tabIndex;
      // eslint-disable-next-line array-callback-return
      constraints.map((item, index) => {
        if (item[fieldName]) {
          tabIndex = index;
        }
      });
      if (tabIndex >= 0) setPageNo(tabIndex);
      showErrors(form.current, errors);
    } else onSubmit();
  };

  const lastIndex = headers.length === 0 ? 0 : headers.length - 1;

  const pages = Array.isArray(children) ? children : [children];

  // entities set 2

  const headerClass = (no) => {
    let className =
      pageNo === no
        ? 'current'
        : visitedPages[no] === true || isEditMode
        ? 'done'
        : 'disabled';
    if (no === 0) className += ' first';
    if (no === lastIndex) className += ' last';
    return className;
  };

  const switchTab = (no) => {
    if (visitedPages[no] === true || isEditMode) {
      setPageNo(no);
    }
  };

  const nextButton = () => {
    const fieldset = document.getElementById(
      `example-advanced-form-p-${pageNo}`
    );

    const currentFormConstraints = constraints[pageNo];
    const errors = validate(fieldset, currentFormConstraints);
    console.log(errors);
    if (errors) showErrors(fieldset, errors);
    // Since OTP validation and its state is handled outside FORM constraints doing a work around for validation
    else if (!errors && currentFormConstraints && currentFormConstraints.otp) {
      if (
        args &&
        args.phoneChangeStatus &&
        args.otpVerifyStatus &&
        !args.otpVerifyStatus.success
      ) {
        const otpInput = document.querySelector("form input[name='otp']");
        showErrorsForInput(otpInput, ['OTP is not verified']);
      } else if (pageNo + 1 < pages.length) {
        setVisitedPages({ ...visitedPages, [pageNo + 1]: true });
        setPageNo(pageNo + 1);
      }
    } else if (pageNo + 1 < pages.length) {
      setVisitedPages({ ...visitedPages, [pageNo + 1]: true });
      setPageNo(pageNo + 1);
    }
  };

  const prevButton = () => {
    if (pageNo === 0) onCancel();
    if (pageNo !== 0) setPageNo(pageNo - 1);
  };

  useEffect(() => {
    // form setup
    form.current = document.getElementById('form');
    validate.extend(validate.validators.datetime, {
      parse: (value) => +moment.utc(value),
      format: (value) => moment.utc(value).format('DD/MM/YYYY'),
    });

    const inputs = form.current.querySelectorAll('input, textarea, select');
    for (let i = 0; i < inputs.length; i += 1) {
      // eslint-disable-next-line func-names
      inputs?.item(i)?.addEventListener('change', function (ev) {
        const errors = validate?.(form?.current, formConstraints) || {};
        // eslint-disable-next-line react/no-this-in-sfc
        showErrorsForInput(this, errors[this.name]);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <form
      autoComplete="off"
      className="wizard clearfix"
      id="form"
      noValidate="novalidate"
      onSubmit={handleFormSubmit}
    >
      <div className="steps clearfix !_pt-0">
        <ul role="tablist">
          {headers.map((header, index) => (
            <li
              aria-disabled={pageNo === index ? 'false' : 'true'}
              aria-selected={pageNo === index ? 'true' : 'false'}
              className={headerClass(index)}
              key={index}
              role="tab"
              style={{ width: `${100 / headers.length}%` }}
              onClick={() => {
                switchTab(index);
              }}
            >
              {/* eslint-disable-next-line */}
              <a
                id={`example-advanced-form-t-${index}`}
                aria-controls={`example-advanced-form-p-${index}`}
                disabled
                className="!_py-3 !px-6"
              >
                {index === 0 && (
                  <span className="current-info audible">current step: </span>
                )}
                <span className="number">{index + 1}.</span> {header}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="content clearfix">
        {pages.map((page, index) => (
          <Fragment key={index}>
            <h3
              id={`example-advanced-form-h-${index}`}
              tabIndex="-1"
              className={pageNo === index ? 'title current' : 'title'}
            >
              {headers[index]}
            </h3>
            <fieldset
              id={`example-advanced-form-p-${index}`}
              role="tabpanel"
              aria-labelledby={`example-advanced-form-h-${index}`}
              aria-hidden={pageNo === index ? 'false' : 'true'}
              className={`body !_py-4 ${pageNo === index ? ' current' : ''}`}
            >
              {page.props.children}
            </fieldset>
          </Fragment>
        ))}
      </div>
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li
            className={pageNo === 0 ? 'disabled' : ''}
            aria-disabled={pageNo === 0 ? 'true' : 'flase'}
            aria-hidden={lastIndex === 0 ? 'true' : 'flase'}
            style={args.hideCancelBtn ? { display: 'none' } : {}}
          >
            {/* eslint-disable-next-line */}
            <a onMouseDown={prevButton} role="menuitem">
              {pageNo === 0 ? 'Cancel' : 'Previous'}
            </a>
          </li>
          <li
            className={pageNo === lastIndex ? 'disabled' : ''}
            aria-disabled={pageNo === lastIndex ? 'true' : 'flase'}
            aria-hidden={pageNo === lastIndex ? 'true' : 'flase'}
            style={pageNo === lastIndex ? { display: 'none' } : {}}
          >
            {/* eslint-disable-next-line */}
            <a onMouseDown={nextButton} role="menuitem">
              Next
            </a>
          </li>
          <li
            aria-disabled={pageNo === lastIndex ? 'false' : 'true'}
            aria-hidden={pageNo === lastIndex ? 'false' : 'true'}
            style={pageNo === lastIndex ? {} : { display: 'none' }}
          >
            {/* eslint-disable-next-line */}
            <a
              className="bg-success"
              role="menuitem"
              onMouseDown={handleFormSubmit}
            >
              {finishBtnName}
            </a>
          </li>
        </ul>
      </div>
    </form>
  );
};

const showErrors = (form, errors) =>
  form
    .querySelectorAll('input[name], select[name]')
    .forEach((input) => showErrorsForInput(input, errors[input.name]));

const showErrorsForInput = (input, errors) => {
  if (!input) return;
  const formGroup = closestParent(input.parentNode, 'form-group');

  formGroup.classList.remove('has-error', 'has-success');
  formGroup
    .querySelectorAll('.text-danger')
    .forEach((el) => el.parentNode.removeChild(el));

  if (errors) {
    formGroup.classList.add('has-error');
    errors.forEach((error) => {
      const block = document.createElement('p');
      block.classList.add('error', 'text-danger');
      block.innerText = error;
      input.classList.add('input-danger');
      formGroup.querySelector('.messages').appendChild(block);
    });
  } else {
    formGroup.classList.add('has-success');
  }
};

// eslint-disable-next-line no-alert
const showSuccess = () => alert('Success!');

export default FormWizard;
