import React, { useEffect, useState } from 'react';
import { FlagIcon } from 'react-flag-kit';
import cn from 'classnames';
import Select from '../form/Select';

const PhoneInput = ({
  selectedCountryCode,
  phoneCodeInputName = 'phone_code',
  selectedPhoneCode,
  phoneCodeList,
  defaultPhoneNumber,
  onChangeHandler,
  onBlurHandler,
  classNames = '',
  inputName,
  inputLabel,
  isCountryLocked = false,
  onPhoneCodeChange,
  ...props
}) => {
  const [countryCode, setCountryCode] = useState(selectedCountryCode || '');
  useEffect(() => {
    setCountryCode(selectedCountryCode);
  }, [selectedCountryCode]);

  return (
    <div
      className={cn('', classNames && classNames)}
      style={{ minWidth: '200px' }}
    >
      <div className="d-flex align-items-center">
        <div
          className="input-group"
          style={{ marginBottom: '0px', width: '150px' }}
        >
          <div className="input-group-prepend">
            <span
              className="input-group-text px-2"
              style={{
                background: isCountryLocked ? '' : '#fff',
                marginRight: '-5px',
                zIndex: '9',
                borderRight: 'none',
              }}
            >
              <FlagIcon code={countryCode} size={20} />
            </span>
          </div>
          <Select
            name={phoneCodeInputName}
            onChange={({ target: { name, value } }) => {
              const _phoneCode = value;
              const _country = phoneCodeList?.find(
                (item) => item?.value === _phoneCode
              );
              setCountryCode(_country?.country_code || '');
              onPhoneCodeChange(_phoneCode);
            }}
            value={selectedPhoneCode}
            values={phoneCodeList}
            disabled={isCountryLocked}
            style={{
              padding: '0px',
              borderLeft: 'none',
              boxShadow: 'none',
              background: isCountryLocked ? '' : '#fff',
            }}
          />
        </div>
        <input
          className="form-control"
          name={inputName}
          onChange={onChangeHandler}
          onBlur={onBlurHandler && onBlurHandler}
          placeholder={inputLabel}
          value={defaultPhoneNumber}
          type="text"
          maxLength={15}
          {...props}
        />
      </div>

      <div className="messages" />
    </div>
  );
};

export { PhoneInput as default };
