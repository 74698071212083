import { createContext, useContext, useState } from 'react';
import { PATIENT_TYPES, TREATMENT_PERIOD } from './constants';

const DentalChartContext = createContext(null);

export const DentalChartContextProvider = ({ children }) => {
  const [selectedTeeth, setSelectedTeeth] = useState(null);
  const [selectedTreatments, setSelectedTreatments] = useState({});
  const [teethData, setTeethData] = useState({});
  const [patientType, setPatientType] = useState(PATIENT_TYPES.adult);
  const [treatmentAddType, setTreatmentAddType] = useState(
    TREATMENT_PERIOD.current
  );
  return (
    <DentalChartContext.Provider
      value={{
        selectedTeeth,
        setSelectedTeeth,
        selectedTreatments,
        setSelectedTreatments,
        teethData,
        setTeethData,
        patientType,
        setPatientType,
        treatmentAddType,
        setTreatmentAddType,
      }}
    >
      {children}
    </DentalChartContext.Provider>
  );
};

export const useDentalChart = () => useContext(DentalChartContext);
