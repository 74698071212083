import { useEffect, useLayoutEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Navbar from './Navbar';

import { useUser } from '@/hooks/useUser';
import ReactGA from 'react-ga';
import { Sidebar } from './Sidebar';
import { Helmet } from 'react-helmet';

const LayoutClean = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname, search]);

  const user = useUser();
  useLayoutEffect(() => {
    if (user === null) {
      console.log('user is null');
      window.location.href = '/';
    }
    if (user?.signedIn === false) {
      window.location.href = '/';
    }
  }, [user]);
  useLayoutEffect(() => {
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = 'https://unpkg.com/tailwindcss@3.4.4/src/css/preflight.css';
    document.head.insertBefore(
      style,
      document.getElementById('bootstrap-style')
    );

    return () => {
      // Clean up the styles when the component unmounts
      document.head.removeChild(style);
    };
  }, []);

  console.log(user);
  if (!user) return <div>Loading...</div>;

  return (
    <div className="_flex _flex-col _w-full _min-h-screen">
      <Helmet>
        <body className="_font-body !_w-full !_h-screen _relative" />
      </Helmet>
      <Navbar />
      <div className="_relative _flex _grow _w-full _h-full">
        <Sidebar />
        <div className="_px-4 _bg-white _pt-4 _flex _flex-col _grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LayoutClean;
