import { useNavBar } from '@/hooks/states';
import { useUser } from '@/hooks/useUser';
import { cn } from '@/lib/utils';
import {
  CircleUserRound,
  Cog,
  FileDigit,
  FlaskConical,
  GitBranch,
  GitCommitVertical,
  Hash,
  Home,
  Hospital,
  LucideIcon,
  Monitor,
  Mouse,
  Sheet,
  Stethoscope,
} from 'lucide-react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

let pages: {
  icon: LucideIcon;
  link: string;
  text: string;
}[] = [
  {
    icon: Home,
    link: '/home',
    text: 'Home',
  },
  {
    icon: CircleUserRound,
    link: '/patient',
    text: 'Patient',
  },
  {
    icon: Mouse,
    link: '/booking',
    text: 'Booking',
  },
  {
    icon: Stethoscope,
    link: '/consult',
    text: 'Consult',
  },
  {
    icon: FlaskConical,
    link: '/lab',
    text: 'Lab',
  },
  {
    icon: Cog,
    link: '/settings',
    text: 'Settings',
  },
  {
    icon: Sheet,
    link: '/reports',
    text: 'Reports',
  },
];

const menuItemClass =
  '_items-center _text-inherit hover:_text-inherit _px-2 _py-3 _text-base _font-bold  _duration-200 hover:_text-sky-700 hover:_bg-sky-100 hover:_border-l-4 _border-0 _border-solid _border-sky-500';

const MenuItem = ({
  icon: Icon,
  link,
  text,
}: {
  icon: LucideIcon;
  link: string;
  text: string;
}) => {
  const { setShow } = useNavBar();
  return (
    <>
      <NavLink
        className={({ isActive, isPending }) =>
          cn(menuItemClass, {
            '_bg-indigo-200 _text-indigo-600 _border-l-4 _border-l-indigo-500 hover:_bg-indigo-300 hover:_text-indigo-700':
              isActive,
            '_bg-sky-100': isPending,
            '_hidden md:_flex': true,
          })
        }
        to={link}
      >
        <Icon className="_size-6 _shrink-0 _mr-1" />

        <div className="_truncate">{text}</div>
      </NavLink>
      <NavLink
        className={({ isActive, isPending }) =>
          cn(menuItemClass, {
            '_bg-indigo-200 _text-indigo-600 _border-l-4 _border-l-indigo-500 hover:_bg-indigo-300 hover:_text-indigo-700':
              isActive,
            '_bg-sky-100': isPending,
            '_flex md:_hidden': true,
          })
        }
        to={link}
        onClick={() => setShow(false)}
      >
        <Icon className="_size-6 _shrink-0 _mr-1" />

        <div className="_truncate">{text}</div>
      </NavLink>
    </>
  );
};

export function Sidebar() {
  const user = useUser();
  const { show } = useNavBar();

  return (
    <div
      className={cn(
        '_overflow-hidden _flex _flex-col _max-w-[150px] _py-4 _duration-500 _bg-slate-50 _border-0 _ease-in-out _border-r _border-slate-200 _border-solid _text-gray-700',
        {
          '_hidden sm:_flex sm:_w-0': !show,
          '_absolute _bottom-0 _top-0 _z-20 sm:_relative _w-full': show,
        }
      )}
    >
      {pages.map((page, index) => (
        <MenuItem key={index} {...page} />
      ))}

      {(user?.user_data?.user_type === 'clinic_owner' ||
        user?.user_data?.user_type === 'clinic_staff') && (
        <MenuItem
          link={`/myclinic/${user?.user_data?.clinic_id}`}
          icon={Hospital}
          text="My Clinic"
        />
      )}
      <div className="_grow" />
      <div className="_text-xs _w-[150px] _p-1 _border-y _border-solid _border-0 _bg-slate-00">
        <div className="_text-sm _text-gray-500 _font-bold">
          <FileDigit className="_size-4 _inline-block" /> V{APP_VERSION}
        </div>
        <div className={'_text-gray-500 _truncate'}>
          <GitBranch className="_size-3 _inline-block" /> {GIT_BRANCH}
        </div>
        <div className="_text-gray-500">
          <GitCommitVertical className="_size-3 _inline-block" />{' '}
          {moment(GIT_COMMIT_DATE).format('MMMM DD YYYY')}
        </div>
        <div className="_text-gray-500">
          <Hash className="_size-3 _inline-block" /> {GIT_HASH.slice(0, 7)}
        </div>
        <TailwindIndicator />
      </div>
    </div>
  );
}

function TailwindIndicator() {
  if (process.env.NODE_ENV === 'production') return null;

  return (
    <div className="_flex _w-fit _items-center _justify-center _p-0 _font-mono _font-bold _text-xs _text-gray-500">
      <Monitor className="_size-3 _mr-1" />
      <div className="_block sm:_hidden">xs</div>
      <div className="_hidden sm:_block md:_hidden lg:_hidden xl:_hidden 2xl:_hidden">
        sm
      </div>
      <div className="_hidden md:_block lg:_hidden xl:_hidden 2xl:_hidden">
        md
      </div>
      <div className="_hidden lg:_block xl:_hidden 2xl:_hidden">lg</div>
      <div className="_hidden xl:_block 2xl:_hidden">xl</div>
      <div className="_hidden 2xl:_block">2xl</div>
    </div>
  );
}
