import React from 'react';
import { Link } from 'react-router-dom';

const ConsultLinksModal = ({
  patientData,
  userRole,
  showNewWalkinForm,
  isExistingPatient,
  onClose,
}) => {
  const closeModal = (redirect = false) => {
    window.$('#consult-links-modal').modal('hide');
    !redirect && onClose && onClose();
  };

  return (
    <div
      aria-hidden="true"
      className="modal fade"
      id="consult-links-modal"
      role="dialog"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-sm modal-center-content"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title"> </h6>
            <button
              aria-label="Close"
              className="btn btn-light"
              style={{
                padding: '0px 2px 0px 5px',
                border: 'none',
                background: '#fff',
              }}
              type="button"
              onClick={() => closeModal()}
            >
              <span aria-hidden="true">
                <i className="fa fa-close" />
              </span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!isExistingPatient ? (
              <>
                <span
                  style={{
                    alignItems: 'center',
                    borderRadius: '50%',
                    display: 'flex',
                    fontSize: '20px',
                    justifyContent: 'center',
                    height: '40px',
                    width: '40px',
                    fontWeight: 100,
                    backgroundColor: '#2ecc71',
                    color: '#fff',
                  }}
                >
                  <i className="ion-checkmark" />
                </span>
                <h5 className="modal-title text-center mt-1 mb-3">
                  Patient added successfully
                </h5>
              </>
            ) : null}
            <div className="mb-3">
              <span>
                {patientData?.first_name} {patientData?.last_name} <br />
                {patientData?.sex} <br />
              </span>
              <span>Contact: {patientData?.phone_no ?? 'No Phone'}</span>
            </div>
            {!isExistingPatient ? (
              <>
                <h6 className="mb-2">
                  <a href={`/patient/edit/${patientData?.person_id}#vitals`}>
                    Add Vitals
                  </a>
                </h6>
                <span className="mb-1 text-center">Or</span>
              </>
            ) : null}

            <h6 className="mb-3">Book Appointment?</h6>
            <div className="row d-flex justify-content-between">
              <Link
                className="btn btn-info mx-3 px-5"
                to={`/booking/availability?uin=${patientData.person_no}`}
                onClick={() => closeModal()}
              >
                OP
              </Link>
              {userRole === 'provider' ? (
                <Link
                  className="btn mx-3 px-4"
                  to={`/consult/walk-in?uin=${patientData?.person_no}`}
                  onClick={() => closeModal()}
                >
                  Walk-in
                </Link>
              ) : (
                <span
                  className="cursor-pointer text-scs-blue btn mx-3 px-4"
                  onClick={() => {
                    closeModal(true);
                    showNewWalkinForm();
                  }}
                >
                  Walkin
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultLinksModal;
