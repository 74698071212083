import { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Nav from '@/components/layout/Nav';

import ReactGA from 'react-ga';
import { BootstrapStyles } from '@/components/layout/Bootstrap';

const PublicLayout = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname, search]);

  return (
    <div
      id="pcoded"
      className="pcoded iscollapsed"
      // eslint-disable-next-line+++
      vertical-nav-type={'offcanvas'}
    >
      <BootstrapStyles />
      <div className="pcoded-container navbar-wrapper _flex !_flex-col _w-full _min-h-screen">
        <div className="pcoded-main-container _w-full _grow">
          <div className="pcoded-wrapper">
            <Nav />
            <div>
              <div
                className="pcoded-inner-content"
                style={{ marginTop: '20px' }}
              >
                <div className="main-body">
                  <div>
                    <div className="page-body">
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
