/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { Link, useLocation } from 'react-router-dom';

import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import Select from '../form/Select';
import Table from '../layout/Table';
import { API_ENDPOINT } from '../../config/api';
import NoResults from '../common/NoResults';
import { useUser } from '@/hooks/useUser';

const dateFormat = (value) => (value < 10 ? `0${value}` : value);

const dateReverse = (value) => value.split('-').reverse().join('-');

const dateToday = () => {
  const value = new Date();
  return `${value.getUTCFullYear()}-${dateFormat(
    value.getMonth() + 1
  )}-${dateFormat(value.getDate())}`;
};

const PatientListBookmarked = () => {
  const { pathname } = useLocation();
  const user = useUser();
  const user_role = user?.user_role;

  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    date: dateToday(),
    provider: null,
    person_id: '',
    phone_no: '',
  });
  const [providers, setProviders] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  const headings = [
    <>
      UPN{' '}
      <i
        className="fa fa-sort-amount-asc"
        onClick={() => sortCol('person_no')}
      />
    </>,
    <>
      Age & Sex{' '}
      <i className="fa fa-sort-amount-asc" onClick={() => sortCol('sex')} />
    </>,
    'Patient Name',
    'Phone No',
    'Email',
    'Bookmark',
    'Date Added',
  ];

  const downloadClinics = () =>
    Axios.get(`${API_ENDPOINT}/Clinics/web_admin/clinic_list`)
      .then(({ data: resData }) => {
        resData = resData.map((row) => ({
          value: row.clinic_id,
          name: row.clinic_name,
        }));
        setProviders(resData);
        filterChangeHandler({
          target: { name: 'provider', value: resData[0].value },
        });
      })
      .catch((err) => {
        showAlert('Loading clinics failed', 'error');
        setShowLoading(false);
      });
  const downloadData = (
    date_ref = filters.date,
    provider_id = filters.provider
  ) => {
    if (!provider_id) return;
    setShowLoading(true);

    const requestData = { date_ref, provider_id };

    Axios.post(
      `${API_ENDPOINT}/Bookmark/web_admin/bookmarked_patients`,
      requestData
    )
      .then(({ data: resData }) => {
        setData(resData.map((row) => ({ ...row, display: true })));
      })
      .catch((err) => showAlert('Loading failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const downloadProviders = () =>
    Axios.get(`${API_ENDPOINT}/Providers/web_admin/pro_list`)
      .then(({ data: resData }) => {
        resData = resData.map((row) => ({
          value: row.provider_id,
          name: row.prov_name,
        }));
        setProviders(resData);
        filterChangeHandler({
          target: { name: 'provider', value: resData[0].value },
        });
      })
      .catch((err) => {
        showAlert('Loading providers failed', 'error');
        setShowLoading(false);
      });

  const filter = () => {
    const copy = data.map((row) => {
      if (filters.search === '' || searchRow(row))
        return { ...row, display: true };
      return { ...row, display: false };
    });
    setData(copy);
  };

  const filterChangeHandler = ({ target: { name, value } }) => {
    if (name === 'date') downloadData(value, filters.provider);
    if (name === 'provider') {
      downloadData(filters.date, parseInt(value));
      setFilters({ ...filters, [name]: parseInt(value) });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const loadData = (rows) =>
    rows
      .filter((row) => row.display)
      .map((row) => [
        row.person_no,
        `23 ${row.sex}`,
        row.person_name,
        row.phone_no,
        row.email_id,
        <i
          className={
            row.bookmark_status ? 'fa fa-bookmark' : 'fa fa-bookmark-o'
          }
          onClick={() =>
            updateBookmarkStatus(row.person_id, !row.bookmark_status)
          }
          style={{ cursor: 'pointer' }}
        />,
        row.bookmark_date,
      ]);

  const searchRow = (row) => {
    const keys = Object.keys(row);
    for (const i in keys) {
      const key = keys[i];
      const value = typeof row[key] === 'string' ? row[key] : `${row[key]}`;
      if (value.toLowerCase().includes(filters.search.toLowerCase()))
        return true;
    }
    return false;
  };

  const sortCol = (prop) => {
    const copy = [...data];
    copy.sort((a, b) => (a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0));
    setData(copy);
  };

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const updateBookmarkStatus = (person_id, bookmark_status) => {
    const requestData = {
      provider_id: 1,
      person_id,
      bookmark_status,
      created_by: 'keerthi',
    };
    Axios.post(
      `${API_ENDPOINT}/Bookmark/web_admin/update_bookmark_status`,
      requestData
    )
      .then(({ data: resData }) => {
        if (resData.info === 'bookmarked  successfully') {
          setData(
            data.map((row) =>
              row.person_id === person_id ? { ...row, bookmark_status } : row
            )
          );
        }
      })
      .catch((err) => showAlert('Bookmarking failed', 'error'));
  };

  useEffect(() => {
    user_role === 'provider' ? downloadClinics() : downloadProviders();
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    filter();
    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <div className="row" style={{ alignItems: 'center' }}>
        <div className="col-md-4">
          <Select
            className="form-control m-b-20 outlined"
            name="provider"
            onChange={filterChangeHandler}
            values={providers}
            value={filters.providerName}
          />
        </div>
        <div className="col-md-3">
          <input
            className="form-control m-b-20 outlined"
            name="date"
            onChange={filterChangeHandler}
            type="date"
            value={filters.date}
          />
        </div>
        <div className="col-md-5">
          <input
            className="form-control m-b-20 outlined"
            name="search"
            onChange={filterChangeHandler}
            placeholder="Patient ID"
            tabIndex={1}
            type="text"
            value={filters.search}
          />
        </div>
      </div>
      <div className="m-b-40">
        {data.length ? (
          <Table data={loadData(data)} headings={headings} number={10} />
        ) : (
          <div className="w-100">
            <NoResults message="No reults found" />
          </div>
        )}
      </div>
      <div className="fab" title="Add Patient">
        <Link className="btn btn-inverse btn-icon" to="/patient/add">
          <i className="ion-plus-round" />
        </Link>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </>
  );
};

export default PatientListBookmarked;
