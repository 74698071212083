import React, { useState } from 'react';
import Axios from '@/_helpers/axios_interceptor';
import { useNavigate, useParams } from 'react-router-dom';

import Alert from '../layout/Alert';
import { API_ENDPOINT } from '../../config/api';

const TYPE_PROVIDER = 'provider';

const Reset = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [password, setPassword] = useState({
    old_password: '',
    new_password: '',
    confirm: '',
  });
  const [showError, setShowError] = useState(null);

  const inputHandler = ({ target: { name, value } }) =>
    setPassword({ ...password, [name]: value });

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (password.new_password !== password.confirm)
      return showAlert("Passwords don't match");

    const data = {
      old_password: password.old_password,
      new_password: password.new_password,
      modified_by: 'by me',
    };
    const url =
      type === TYPE_PROVIDER
        ? `${API_ENDPOINT}/Providers/web_admin/rest_password`
        : `${API_ENDPOINT}/Clinic_staff/web_admin/rest_password`;

    if (type === TYPE_PROVIDER) data.provider_id = id;
    else data.staff_id = id;

    Axios.post(url, data).then((res) => {
      if (!res.data.info) {
        showAlert('Success', 'success');
      } else setShowError(res.data.info);
    });
  };

  return (
    <div className="j-wrapper j-wrapper-400" style={{ paddingTop: '10px' }}>
      <form onSubmit={submitHandler} className="j-pro" id="sign-in" noValidate>
        <div className="j-content">
          <div className="j-unit">
            <div className="j-input">
              <label className="j-icon-right" htmlFor="password">
                <i className="ion-locked" />
              </label>
              <input
                autoFocus
                name="old_password"
                onChange={inputHandler}
                placeholder="Old Password"
                required
                type="password"
                value={password.old_password}
              />
            </div>
          </div>
          <div className="j-unit">
            <div className="j-input">
              <label className="j-icon-right" htmlFor="password">
                <i className="ion-locked" />
              </label>
              <input
                name="new_password"
                onChange={inputHandler}
                placeholder="New Password"
                required
                type="password"
                value={password.new_password}
              />
            </div>
          </div>
          <div className="j-unit">
            <div className="j-input">
              <label className="j-icon-right" htmlFor="password">
                <i className="ion-locked" />
              </label>
              <input
                name="confirm"
                onChange={inputHandler}
                placeholder="Confirm Password"
                required
                type="password"
                value={password.confirm}
              />
            </div>
          </div>
          {showError && (
            <div className="j-unit text-center text-danger">{showError}</div>
          )}
        </div>
        <div className="j-footer">
          <div className="j-unit p-b-20">
            <button className="btn btn-primary" type="submit">
              Reset
            </button>
          </div>
        </div>
      </form>
      <Alert
        action={() => navigate(-1)}
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
    </div>
  );
};

export default Reset;
