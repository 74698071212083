import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import Axios from '@/_helpers/axios_interceptor';
import { Link, useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';

import Alert from '../layout/Alert';
import LoadingBox from '../layout/LoadingBox';
import { API_ENDPOINT } from '../../config/api';
import Avatar from '../common/avatar';
import NoResults from '../common/NoResults';
import { useUser } from '@/hooks/useUser';

const Box = ({ className, children }) => (
  <div
    className={`${className}`}
    style={{
      background: '#F3F4FC',
      //   boxShadow: 'inset 0 5px 5px #15C927',
      borderTop: '6px solid #5dcabb',
      width: '100%',
      height: '160px',
      borderRadius: '10px',
    }}
  >
    {children}
  </div>
);

const _date = moment().format('YYYY-MM-DD');

const HomePage = () => {
  const navigate = useNavigate();
  const { user_data, clinic_data, staff_list, preferences } = useUser();

  const clinic_id = preferences?.selectedClinicId;
  const [alertMsg, setAlertMsg] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('alert');
  const [showLoading, setShowLoading] = useState(false);
  const [slotsData, setSlotsData] = useState({});
  const [totalTokens, setTotalTokens] = useState(0);
  const [bookedTokens, setBookedTokens] = useState(0);
  const [selectedDate, setSelectedDate] = useState(_date);
  const [showCalendar, setShowCalendar] = useState(false);

  const showAlert = (msg, type) => {
    if (type) setAlertType(type);
    else setAlertType('alert');
    setAlertMsg(msg);
    setAlertShow(true);
  };

  const getBookingSummary = (clinic_id, date = _date) => {
    setSlotsData({});
    if (!clinic_id) return;
    setShowLoading(true);
    Axios.get(
      `${API_ENDPOINT}/PatientAppointments/get_booking_summary?clinic_id=${clinic_id}&booking_date=${date}`
    )
      .then(({ data: resData }) => {
        if (resData && resData.appointments) {
          setSlotsData(resData.appointments || []);
          setTotalTokens(resData.total_tokens || 0);
          setBookedTokens(resData.booked_tokens || 0);
        }
      })
      .catch((err) => showAlert('Fetching booking summary failed', 'error'))
      .finally(() => setShowLoading(false));
  };

  const dateChangeHandler = (date) => {
    if (date && date !== selectedDate) {
      setShowCalendar(false);
      setSelectedDate(date);
      getBookingSummary(clinic_id, date);
    }
  };

  const doctorsCount = useMemo(() => {
    let docsLength = 0;
    if (slotsData && Object.keys(slotsData).length) {
      Object.keys(slotsData).forEach((speciality) => {
        docsLength += slotsData?.[speciality]?.length || 0;
      });
    }
    return docsLength.toString().padStart(3, '0');
  }, [slotsData]);

  useEffect(() => {
    getBookingSummary(preferences?.selectedClinicId, selectedDate);
  }, [preferences?.selectedClinicId]);

  return (
    <div className="home-page">
      <div className="panel panel-info" style={{ boxShadow: 'none' }}>
        <div className="panel-heading bg-info rounded">
          <h5>Staff</h5>
        </div>
        <div className="m-t-10 m-b-10 panel-body row px-2">
          <div className="col-lg-10 pl-0">
            <div
              className="row h-100 helpdesk-list"
              style={{ marginLeft: '8px' }}
            >
              {staff_list && Object.keys(staff_list).length ? (
                Object.keys(staff_list)
                  .filter((item) => item !== 'KIOSK')
                  .map((staffRole, idx) => (
                    <div
                      className="col-5 col-sm-5 col-md-2 m-b-10 d-flex mx-2 helpdesk-card"
                      key={idx}
                      style={{
                        boxShadow: '2px 0px 5px 3px #ccc',
                        padding: '0px',
                        borderRadius: '10px',
                      }}
                    >
                      <Box>
                        <div
                          className=" p-l-5 p-t-10 h-100"
                          style={{ overflowY: 'scroll' }}
                        >
                          <div
                            className="font-weight-bolder mb-2 pl-2"
                            style={{ fontSize: '17px' }}
                          >
                            {staffRole}
                          </div>
                          {staff_list[staffRole]
                            ? staff_list[staffRole].map((staff, idx2) => (
                                <div key={idx2} className="d-flex mb-2">
                                  <img
                                    src="/img/home_page/nurse-avatar.png"
                                    alt="Nurse Avatar Icon"
                                    style={{ height: '30px' }}
                                  />
                                  <div
                                    className="d-flex flex-column text-capitalize"
                                    style={{
                                      fontWeight: 600,
                                      lineHeight: '15px',
                                    }}
                                  >
                                    <span>{`${staff?.name?.substring?.(0, 15)}${
                                      staff?.name?.length > 15 ? '..' : ''
                                    }`}</span>
                                    <span>{staff.phone_no}</span>
                                  </div>
                                </div>
                              ))
                            : ''}
                        </div>
                      </Box>
                    </div>
                  ))
              ) : (
                <div className="w-100">
                  <NoResults message="No staffs created" classNames="p-2" />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-2 hex-home-link-box m-b-10 d-flex flex-lg-column justify-content-between p-l-2">
            <div className="hex-home-link">
              <Link
                className="btn btn-outline-primary"
                to="/dashboard"
                style={{
                  minWidth: '120px',
                  height: '40px',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                <span>
                  {/* <i
                    className="ion-ios-pie-outline"
                    style={{ fontSize: '14px', marginTop: '2px' }}
                  />{' '} */}
                  Dashboard
                </span>
              </Link>
            </div>
            <div className="hex-home-links">
              <Link
                className="btn btn-outline-primary"
                to="/webcheckin"
                style={{
                  minWidth: '120px',
                  height: '40px',
                  fontSize: '14px',
                  fontWeight: 600,
                  padding: '10px 10px',
                }}
              >
                <span>
                  {/* <i
                    className="ion-ios-checkmark-outline"
                    style={{ fontSize: '14px' }}
                  />{' '} */}
                  Web Check In
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-info">
        <div
          className="panel-heading bg-info rounded"
          style={{ padding: '0px' }}
        >
          <div className="d-flex stats align-items-center justify-content-center justify-content-md-start">
            <div
              className="d-flex align-items-center px-3"
              style={{ borderRight: '3px solid white' }}
            >
              <div className="popup-calendar-wrapper">
                <button
                  type="button"
                  onClick={() => setShowCalendar(true)}
                  className="btn pl-0 pr-3 py-1"
                >
                  <img
                    src="/img/home_page/calendar-icon-white.png"
                    alt="Calendar Icon"
                    style={{ height: '35px' }}
                  />
                </button>
                <Calendar
                  minDate={new Date()}
                  className={showCalendar ? '' : 'hide'}
                  value={
                    typeof selectedDate === 'string'
                      ? new Date(selectedDate)
                      : selectedDate
                  }
                  onChange={(date) =>
                    dateChangeHandler(moment(date).format('YYYY-MM-DD'))
                  }
                  tileClassName={(view) =>
                    view === 'month' ? 'hex-date-blocked' : ''
                  }
                />
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  fontWeight: 600,
                  fontSize: '14px',
                }}
              >
                <div>{moment(selectedDate).format('DD-MM')}</div>
                <div style={{ fontSize: '17px' }}>
                  {moment(selectedDate).format('YYYY')}
                </div>
              </div>
            </div>
            <div
              className="d-none d-sm-block px-3 my-3"
              style={{
                fontWeight: 600,
                fontSize: '15px',
                borderRight: '3px solid white',
                minWidth: '180px',
              }}
            >
              <div className="d-flex justify-content-between">
                <span>PROVIDERS</span> <span>{doctorsCount}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>SLOTS</span>{' '}
                <span>{totalTokens.toString().padStart(3, '0')}</span>
              </div>
            </div>
            <div
              className="px-3 my-3"
              style={{ fontWeight: 600, fontSize: '15px', minWidth: '180px' }}
            >
              <div className="d-flex justify-content-between">
                <span>AVAILABLE</span>{' '}
                <span>
                  {(totalTokens - bookedTokens).toString().padStart(3, '0')}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span>BOOKED</span>{' '}
                <span>{bookedTokens.toString().padStart(3, '0')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-2 pb-4">
          <div className="_grid  md:_grid-cols-2 xl:_grid-cols-3 _gap-4">
            {slotsData && Object.keys(slotsData).length ? (
              Object.keys(slotsData).map((speciality, index) => (
                <div className="" key={index}>
                  <div
                    className="panel panel-info _bg-slate-50"
                    style={{ paddingBottom: '1px' }}
                  >
                    <div className="bg-info panel-heading py-20 rounded">
                      <h5>{speciality}</h5>
                    </div>
                    <div className="_p-2 _pb-0 panel-body _max-h-[250px] _overflow-y-auto">
                      {slotsData[speciality].map((doctor, index) => (
                        <div
                          className="p-b-10 p-t-10"
                          key={index}
                          style={{
                            alignItems: 'left',
                            borderTop: index === 0 ? '' : '1px solid #777',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                          }}
                        >
                          <div
                            style={{
                              // border: '2px solid #ccc',
                              borderRadius: '50%',
                              height: '38px',
                              width: '38px',
                            }}
                          >
                            <Avatar
                              placeholder={doctor.provider_name}
                              height="38px"
                              width="38px"
                              gender={doctor.gender}
                              //   dynamicBg
                            />
                            {/* <div
                                style={{
                                  backgroundColor:
                                    doctor.status === 'active'
                                      ? doctor.booked_count !==
                                        doctor.total_token_number
                                        ? '#57b99d'
                                        : '#f1c40f'
                                      : '#dc3545',
                                  borderRadius: '50%',
                                  height: '12px',
                                  margin: '-36px 0px 0px 24px',
                                  width: '12px',
                                }}
                              /> */}
                          </div>
                          <div
                            className="grow"
                            style={{
                              fontSize: '1rem',
                              fontWeight: '500',
                              width: '120px',
                            }}
                          >
                            {`${doctor?.provider_name?.substring?.(0, 14)}${
                              doctor?.provider_name?.length > 14 ? '..' : ''
                            }`}
                          </div>
                          <div style={{ width: '50px' }}>
                            ({doctor.booked_count}/{doctor.total_token_number})
                          </div>
                          <a href="/booking" className="link-unstyled">
                            <img
                              src="/img/home_page/calendar-icon-black.png"
                              alt="Calendar Icon"
                              style={{ height: '26px' }}
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-100">
                <NoResults />
              </div>
            )}
          </div>
        </div>
      </div>
      <Alert
        msg={alertMsg}
        setShow={setAlertShow}
        show={alertShow}
        type={alertType}
      />
      <LoadingBox show={showLoading} />
    </div>
  );
};

export default HomePage;
