import { create } from 'zustand';

type NavBarState = {
  show: boolean;
  setShow: (show: boolean) => void;
  toggleShow: () => void;
};

export const useNavBar = create<NavBarState>()((set) => ({
  show: true,
  setShow: (show) => set({ show }),
  toggleShow: () => set((state) => ({ show: !state.show })),
}));
