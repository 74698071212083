import React from 'react';
import moment from 'moment';
import { API_ENDPOINT, STORAGE_DOMAIN } from '@/config/api';

import { DownloadButton } from '@/components/common/PrintButton';
import { generatePrescriptionSheet } from '@/components/pages/pdf/PrescriptionSheet';
import useSWRImmutable from 'swr/immutable';
import { getFetcher } from '@/_helpers/swr';
import classNames from 'classnames';
import { unique } from '@/_helpers/radash';
import { useUser } from '@/hooks/useUser';
import { useAppoinment, useCasesheet } from './states';

const _date = moment().format('YYYY-MM-DD');

const DoctorTab = ({ previousProviders }) => {
  const disabled = useAppoinment((s) => !s.patient);
  const history = disabled ? [] : previousProviders;
  return (
    <div className="op-session-doctor-card-container _scrollbar-thumb-rounded-full _scrollbar-thumb-slate-300 _scrollbar-track-sky-50 _scrollbar-thin">
      {history && history.length > 0 ? (
        <div>
          {unique(previousProviders, (k) => k.appointment_id)
            .sort((a, b) => {
              const date1 = new Date(a?.appointment_date);
              const date2 = new Date(b?.appointment_date);
              return date2 - date1;
            })
            .map((provider) => (
              <HistoryItem key={provider.appointment_id} provider={provider} />
            ))}
        </div>
      ) : (
        <div className="_flex _flex-col _items-center _py-8">
          <i className="fa fa-window-close _text-slate-300 fa-5x" />

          <div className="_text-slate-700 _text-center _text-lg">
            {disabled ? 'No patient selected' : 'No other consultations found'}
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorTab;

export function HistoryItem({ provider }) {
  const { data: cs } = useSWRImmutable(
    provider.appointment_id
      ? `${API_ENDPOINT}/ProviderPatientConsultation/get_casesheet_for_appointment?app_id=${provider.appointment_id}`
      : null,
    getFetcher
  );

  const caseSheet = Array.isArray(cs) && cs.length > 0 ? cs : null;

  const me = useUser().user_data;
  const isMe = provider.is_previous || me.user_id === provider.provider_id;
  const providerName = isMe
    ? `${me.first_name} ${me.last_name}`
    : provider.name;
  const speciality = isMe ? me.speciality : provider.speciality_name;

  const setCaseSheet = useCasesheet((s) => s.setCaseSheet);

  return (
    <div
      className={classNames(
        '_flex _justify-between _items-center _duration-300 _border-l-4 _px-2 _py-2 _border-b _border-0 _border-r first:_border-t _border-solid',
        {
          '_bg-teal-50 _border-teal-300 hover:_bg-teal-100/70': isMe,
          '_bg-orange-50 _border-orange-300 hover:_bg-orange-100/70': !isMe,
        }
      )}
      key={provider.appointment_id}
    >
      <div className="">
        <div>
          <div className="_mb-1 _flex _flex-wrap _items-center _text-sm _text-gray-700 _font-semibold">
            {providerName}{' '}
            {provider.procedure_type &&
              provider.procedure_type.split(',').map((p) => (
                <p
                  key={p}
                  className="_px-2 _inline-block _truncate _max-w-32 hover:_max-w-96 _overflow-hidden _mx-1 _cursor-pointer !_py-0 w-fit _text-[13px] !_mb-0 _text-xs _duration-500 _text-orange-700 _bg-orange-100 _rounded-full  _border _border-solid _border-orange-400"
                >
                  {p}
                </p>
              ))}
          </div>
        </div>
        <div className="_flex">
          <p className="_mr-1 _mb-0 _text-xs _text-gray-500 _border-r _border-r-slate-400 _border-0 last:_border-r-0 _border-solid _pr-1">
            {speciality}
          </p>
          <p className="_mr-1 _mb-0 _text-xs _text-gray-500 _border-r _border-r-slate-400 _border-0 last:_border-r-0 _border-solid _pr-1">
            {provider.clinic_name}
          </p>
          <p className="_mr-1 _mb-0 _text-xs _text-gray-500 _border-r _border-r-slate-400 _border-0 last:_border-r-0 _border-solid _pr-1">
            {moment(provider.appointment_date).format('DD-MM-YYYY')}{' '}
            {provider.op_time
              ? moment(`${_date} ${provider.op_time}`).format('hh:mm A')
              : ''}
          </p>
        </div>
        <div></div>
        <div className="">
          {/* <p className="op-session-doctor-card-infobox">#24</p> */}
        </div>
      </div>
      <div className="_flex ">
        {provider.attachment_location && (
          <a
            href={STORAGE_DOMAIN + provider.attachment_location}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Attachment"
          >
            <i className="fa fa-paperclip  border-icon _cursor-pointer " />
          </a>
        )}

        <DownloadButton
          onClick={() =>
            generatePrescriptionSheet(
              provider.appointment_id,
              provider.proc_data,
              provider.appointment_date,
              provider.end_time
            )
          }
          disabled={!caseSheet}
        />
        {caseSheet && caseSheet.length && (
          <div className="ml-1">
            <i
              onClick={() => setCaseSheet(caseSheet[0])}
              className={`fa border-icon _cursor-pointer fa-arrow-right ${
                !caseSheet && 'disabled'
              }`}
              aria-hidden="true"
              data-toggle="tooltip"
              data-placement="top"
              title="Load"
            />
          </div>
        )}
      </div>
    </div>
  );
}
