import React, { useEffect } from 'react';
import './SignIn.css';
import { useNavigate } from 'react-router';
import { useUser } from '@/hooks/useUser';

const smartFeatures = [
  {
    title: 'Time Saving',
    list: [
      'Attract 25% more patients to your schedule.',
      'Streamline the pre-visit process with paperless onboarding workflows',
    ],
    icon: 'fa-clock-o',
  },
  {
    title: 'Types of practice',
    list: ['Supports both token-based and time-based OP consultations.'],
    icon: 'fa-calendar',
  },
  {
    title: 'No long queue',
    list: [
      'Eliminate patient queues, ensuring safe social distancing.',
      'Eliminate the frustrations of patients arriving early or waiting too long to be seen.',
      'Enhance patient satisfaction',
    ],
    icon: 'fa-braille',
  },
  {
    title: 'Reports',
    list: [
      'Monitor your revenue',
      'Transparent subscription model',
      'No Hidden Charges',
    ],
    icon: 'fa-file-text-o',
  },
  {
    title: 'Security',
    list: ['HIPAA-compliant system ensuring data privacy'],
    icon: 'fa-shield',
  },
  {
    title: 'Bonus',
    list: [
      'Get bookings from Pharmacies, Akshaya centers, etc',
      'Software access available for multiple clinic staff members',
    ],
    icon: 'fa-rupee',
  },
];

const clinicFeatures = [
  {
    feature: 'Intelligent Booking Management System',
    description:
      "Our IIMS Technology offers specific, fixed-length booking slots tailored to each patient's visit purpose.",
  },
  {
    feature: 'Priority Booking Feature',
    description: 'Tatkal booking for doctors appointment.',
  },
  {
    feature: 'Payment History',
    description:
      "Struggling to monitor your clinic's revenue? We facilitate recording collections at various stages, including adjustments, for comprehensive tracking.",
  },
  {
    feature: 'SMS Facility',
    description: 'SMS with live queue tracking link for patients.',
  },
];

const providerFeatures = [
  {
    feature: 'One profile for any practice',
    description: 'Effortlessly manage your entire schedules and practices.',
  },
  {
    feature: 'Define rules for each practice',
    description:
      'You have the ability to set rules for each practice according to your preferences.',
  },
  {
    feature: 'Choose your preferred procedures for each clinic',
    description:
      'You have the option to select the list of procedures for each clinic.',
  },
  {
    feature: 'Smart Consultation Module',
    description:
      'The intelligent consultation module displays all essential information within the consultation window, giving providers complete control over the process.',
  },
  {
    feature: 'Digital Prescription',
    description:
      'Are patient files getting lost or hard to find? Forgotten file numbers? Say goodbye to these headaches with digital prescriptions. Access all information at your fingertips.',
  },
];

const faqList = [
  {
    question:
      'Do I have to create provider profile separately from home page after registering clinic?',
    answer:
      "No, you can register a provider from your clinic profile page. The provider's mobile OTP needs verification, and upon successful registration, the new provider will be connected with your clinic.",
  },
  {
    question: 'Can I start using the software right after registration?',
    answer:
      'No, completing the KYC process requires sending the necessary documents in response to the received email. Your clinic will become active after successful KYC verification.',
  },
  {
    question:
      'Do I have to create a new profile for a provider in my clinic who already have a profile in UNQ SCS?',
    answer:
      'No, you simply need to connect with the provider, an option available on your clinic profile page.',
  },
  {
    question: 'Can I add multiple staffs to manage software?',
    answer: 'Yes, you can do it from your clinic profile page',
  },
  {
    question: 'What is rotation profile?',
    answer:
      "The rotation profile generates profiles labeled as Doctor 1, Doctor 2, and so forth, visible for booking. You can input the practicing provider's details into the profile, which will be utilized in consultations and prescriptions.",
  },
  {
    question:
      'Is it possible to migrate all the patient details from previous software, I used before?',
    answer:
      'Certainly, certain fields are obligatory in the UNQ SCS Patient registration form. Our backend team will validate compatibility and assist in migration. Please reach out to [support@unq.ai](mailto:support@unq.ai) and attach sample data for further assistance.',
  },
  {
    question: 'Can I register a patient without mobile number?',
    answer:
      'No, OTP verification is essential to register a new patient to adhere to HIPAA regulations.',
  },
  {
    question: "Can I register a patient who is a minor & doesn't have mobile?",
    answer:
      "Yes, initially, register one of their parents with a mobile number and then add the child as a family member from the parent's profile.",
  },
  {
    question:
      "Most of my patient's name start with Mohammed and spelling varies, any solution?",
    answer:
      "Utilize the abbreviation 'MHD' for Mohammed to simplify future searches.",
  },
  {
    question: 'Is it possible for front end user to view consult screen?',
    answer:
      ' No, front-end users cannot access the consultation screen containing confidential patient information.',
  },
];

const SignIn = () => {
  const navigate = useNavigate();
  const user = useUser();
  useEffect(() => {
    if (user?.signedIn) {
      navigate('/home');
    }
  }, [user?.signedIn]);

  return (
    <>
      <section id="hero" className="d-flex align-items-center _pt-0">
        <div
          className="container d-flex flex-column align-items-center justify-content-center"
          data-aos="fade-up"
        >
          <img
            alt="UnQ SCS"
            className="mt-2 mb-4 unq_scs_logo"
            src="/logo/unq_home_banner_logo.png"
            height="105"
          />
          <h1>The most innovative clinic management software</h1>
          <h2>Specially designed for you</h2>
          <a href="/clinic-login" className="btn-get-started scrollto">
            Get Started
          </a>
          <img
            src="/img/home_page/dashboard_scrn.jpg"
            className="img-fluid hero-img"
            alt=""
            data-aos="zoom-in"
            data-aos-delay="150"
          />
        </div>
      </section>

      <main id="main">
        <section id="about" className="about">
          <div className="container">
            <div className="row no-gutters">
              <div
                className="content col-xl-4 d-flex align-items-stretch"
                data-aos="fade-right"
              >
                <div className="content">
                  <h3>Convert your clinic into a Smart Clinic…</h3>
                  <p>
                    Never before complete software package for the Clinic
                    Practices in India
                  </p>
                  <a href="/clinic-login" className="about-btn">
                    Start Free Trial <i className="fa fa-chevron-right" />
                  </a>
                </div>
              </div>
              <div
                className="col-xl-8 d-flex align-items-stretch"
                data-aos="fade-left"
              >
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row">
                    {smartFeatures?.map((item, index) => (
                      <div
                        key={index}
                        className="col-md-4 icon-box"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <i className={`fa ${item.icon}`} />
                        <h4>{item.title}</h4>
                        <div>
                          <ul
                            style={{
                              listStyleType: 'disc',
                              marginLeft: '16px',
                            }}
                          >
                            {item?.list?.map((feature, idx) => (
                              <li key={idx}>{feature}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="features" data-aos="fade-up">
          <div className="container">
            <div className="section-title">
              <h2>Features</h2>
              <p style={{ fontSize: '14px' }}>
                The UNQ Smart Clinic Suit is specifically designed for clinics
                after intensive research & feedback from various top practicing
                consultants. The system addresses the most pressing problems
                faced by clinic & providers. We assure you, it is the most
                clinic & provider friendly software in India. We have also
                associated with Pharmacies & other digital service providers
                like Akshaya to facilitate booking service for the common man.
                Our most unique features are as below.
              </p>
            </div>

            <div className="row content align-items-center">
              <div className="col-md-5 order-1 order-md-2" data-aos="fade-left">
                <img
                  src="/img/home_page/features/1.jpg"
                  className="img-fluid rounded"
                  alt=""
                />
              </div>
              <div
                className="col-md-7 order-2 order-md-1"
                data-aos="fade-right"
              >
                <h3>Do you own a Clinic?</h3>
                <ul>
                  {clinicFeatures?.map((item, index) => (
                    <li key={index} className="py-2">
                      <i className="fa fa-chevron-right" />
                      <span className="font-weight-bold">{item.feature}: </span>
                      <span>{item.description}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="row content align-items-center">
              <div className="col-md-5" data-aos="fade-right">
                <img
                  src="/img/home_page/features/2.jpg"
                  className="img-fluid rounded"
                  alt=""
                />
              </div>
              <div className="col-md-7" data-aos="fade-left">
                <h3>Are you a Provider?</h3>
                <ul>
                  {providerFeatures?.map((item, index) => (
                    <li key={index} className="py-2">
                      <i className="fa fa-chevron-right" />
                      <span className="font-weight-bold">{item.feature}: </span>
                      <span>{item.description}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>

            <ul className="faq-list">
              {faqList?.map((item, index) => (
                <li key={index} data-aos="fade-up" data-aos-delay="100">
                  <a
                    data-toggle="collapse"
                    className=""
                    href={`#faq${index + 1}`}
                  >
                    {item?.question}
                    <i className="fa fa-chevron-up" />
                  </a>
                  <div
                    id={`faq${index + 1}`}
                    className="collapse show"
                    data-parent=".faq-list"
                  >
                    <p>{item?.answer}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </main>

      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>UNQ</h3>
                <h6>Technologies</h6>
                <p>
                  GB Palaya, Bangalore <br />
                  Karnataka 560068
                  <br />
                  India <br />
                  <br />
                  <strong>Phone:</strong> +91 994 667 5555
                  <br />
                  <strong>Email:</strong> support@unq.ai
                  <br />
                </p>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="fa fa-chevron-right" /> <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="fa fa-chevron-right" />
                    <a href="/legal/terms.html">Terms of service</a>
                  </li>
                  <li>
                    <i className="fa fa-chevron-right" />
                    <a href="/legal/privacy.html">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="fa fa-chevron-right" />
                    <a href="/clinic-login">For Clinics</a>
                  </li>
                  <li>
                    <i className="fa fa-chevron-right" />
                    <a href="/provider-login">For Providers</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Social</h4>
                <ul>
                  <li>
                    <i className="fa fa-facebook" />
                    <a href="https://www.facebook.com/unqtechnologies">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <i className="fa fa-linkedin" />
                    <a href="https://www.linkedin.com/company/unqtech">
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <i className="fa fa-instagram" />
                    <a href="https://www.instagram.com/unqtechnologies">
                      Instagram
                    </a>
                  </li>
                  <li>
                    <i className="fa fa-twitter" />
                    <a href="https://twitter.com/unqtechnologies">Twitter</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4">
          <div className="mr-md-auto text-center text-md-left">
            <div className="copyright">
              &copy; Copyright
              <strong>
                <span> UNQ Technologies Pvt Ltd</span>&nbsp;
              </strong>
              . All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default SignIn;
